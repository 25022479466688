<div class="col-md-8 col_md_8 pageblock content_area">
    <div class="left_part">
        <menu-component></menu-component>
        <div class="boxs">
            <ol class="breadcrumb" id="breadcrumb">
                <li class="home"><a href="javascript:void(0);" [routerLink]="['../home']">Home</a> </li>
                <li class="arrow">&nbsp;</li>
                <li class="active"> Campus Connections </li>
            </ol>
            <div class="clr"></div>
        </div>
        <div class="contant_div">
            <div id="searchResultsDiv" class="campusConnections">
                <div class="" style="display:none;" id="sendInNetworkInvite">
                    <div class="seven columns last"> To:
                        <label id="userNameDisplay" style="color: #108dd5;  text-decoration: none;"></label>
                        <br>
                        <label id="userTypeInfo" class="gwt-Label profile-userType userype"></label>,
                        <label id="universityInfo" class="gwt-Label profile-userType userype"></label>
                    </div>
                    <div class="clear"></div>
                    <input type="hidden" id="userIdSend">
                    <div class="two columns alpha">
                        <label>Subject:</label>
                        <input type="text" id="subjectForNetwork" name="subjectForNetwork"> </div>
                    <div class="clear"></div>
                    <div class="seven columns last">
                        <label>Message:</label>
                        <textarea name="messageForNetwork" id="messageForNetwork"></textarea>
                    </div>
                </div>
                <form name="searchResultsForm" id="searchResultsForm" method="post">
                    <div style="display: none;" id="addConnectionPleaseWaitDiv"> <img src="assets/images/pleaseWait.gif"
                            width="15px" height="15px"> </div>
                    <input type="hidden" name="hdProfileId" id="hdProfileId">
                    <input type="hidden" name="hdCriteria" id="hdCriteria">
                    <div class="dim" style="display: none;" [style.display]="editDisplay(request)">
                        <div class="ten columns invite-pop-up marginbottom boxpad" style="display: none;"
                            [style.display]="editDisplay(request)">
                            <div class="popuptitle" id="requestForRecommend">
                                <h2>Request for Recommendation</h2>
                            </div>
                            <div class="popupclose" (click)="request = false;"> <img alt="Close"
                                    src="assets/images/close-button.png" id="close"> </div>
                            <div class="eight columns" id="subjectDiv">
                                <input type="text" value="" name="recommendationSubject" id="recommendationSubject"
                                    placeholder="Subject"> </div>
                            <div class="eight columns" id="CommentsDiv">
                                <textarea name="recommendationComments" id="recommendationComments"
                                    placeholder="Request"></textarea>
                            </div>
                            <div class="three columns" id="buttonDiv">
                                <input type="button" value="Send" name="btnSend" id="btnSend" class="pageLink1">
                                <input type="hidden" id="hdUserId" value=""> </div>
                        </div>
                    </div>
                    <div id="searchResultsResponseDiv">
                        <div class="searchresultsbox boxs boxpad last" *ngFor="let connection of campusConnection">
                            <div class="inner-post-image conprofilepic">
                                <!-- <img width="100" height="100" border="0" id="profile" src="{{connection.profilePicName}}">  -->
                                <img *ngIf="connection.profilePicName != null && !connection.profilePicName.includes('profilebig.png')"
                                    class="greyBorder profileImage" alt="" src="{{connection.profilePicName}}"
                                    height="100" width="100">

                                <ngx-avatar
                                    *ngIf="connection.profilePicName == null || connection.profilePicName.includes('profilebig.png')"
                                    name="{{connection.firstName}} {{connection.lastName}}"></ngx-avatar>
                            </div>
                            <div class="inner-post-desc">
                                <div class="profile-name"> <a href="javascript: void(0);" [routerLink]="['../profile']"
                                        [queryParams]="{ user: connection.userDetailsId }" class="profileLink">
                                        {{connection.firstName}} {{connection.lastName}}</a> </div>
                                <div class="add-network-block profilerat"> </div>
                                <div *ngIf="connection.isUserSecondaryDataCapturedForUser == 'T'"
                                    class="profilecou gwt-Label profile-userType userype">
                                    {{connection.userTypeFullDescription}} of {{connection.userEducationDegreeCourse}}
                                </div>
                                <div *ngIf="connection.isUserSecondaryDataCapturedForUser == 'T'"
                                    class="profileuni gwt-Label profile-userType userype">
                                    {{connection.educationalInstitutionFullName}} ,
                                    {{connection.educationalInstitutionCampus}} , </div>
                                <div class="profilecnt">
                                    <label><a href="javascript: void(0);">Connections:</a> </label> <span
                                        class="counter">{{connection.studentsCount}}</span> Students , <a
                                        href="javascript: void(0);"><span
                                            class="counter">{{connection.professorsCount}}</span> Professors</a> , <span
                                        class="counter">{{connection.alumniCount}}</span> Alumni </div> <span
                                    class="add-network-block" id="plsWaitImgCntnr_17"></span>
                                <div class="profilerqs">
                                    <span id="reqRecLinkDiv_17"
                                        *ngIf="( connection.invitationAction== 'In My Network')"><a
                                            (click)="request = true;" class="request-recommendation"
                                            href="javascript: void(0);"><img border="0" width="12px" height="12px"
                                                src="assets/images/request.png" style="margin-right: 2px;"> Request for
                                            Recommendation</a></span>
                                    <span id="rateProfessorLinkDiv_13"
                                        *ngIf="( connection.ratingAction== 'SEND_RATING')">
                                        <a href="javascript: void(0);" class="pageLink1"
                                            (click)="connection.ratebox = true;">
                                            <img border="0" width="12px" height="12px" src="assets/images/star.png"
                                                style="margin-right: 2px;"> Rate this User
                                        </a>
                                    </span>
                                    <div class="profilerqsbox">
                                        <div id="rateProfessorDiv_13" class="common" style="display: none;"
                                            [style.display]="editDisplay(connection.ratebox)">
                                            <input type="radio" name="rateProfessor_13" id="rateFair_13" value="FAIR">
                                            <label for="rateFair_13">Fair</label>&nbsp;
                                            <input type="radio" name="rateProfessor_13" id="rateGood_13" value="GOOD">
                                            <label for="rateGood_13">Good</label>&nbsp;
                                            <input type="radio" name="rateProfessor_13" id="rateBest_13" value="BEST">
                                            <label for="rateBest_13">Best</label>
                                            <input type="button" name="btnRate" id="btnRate" value="Rate"
                                                style="margin-top: 5px;">
                                            <input type="button" name="btnCancel" (click)="connection.ratebox = false;"
                                                id="btnCancel" value="Cancel"
                                                style="margin-left: 5px; margin-top: 5px;">
                                        </div>
                                        <div id="recResultDiv_17" class="recommendationPopup"
                                            style="display: none; min-height: 240px;">
                                            <div
                                                style="float: right; width: 16px; height: 15px; cursor: pointer; border: 1px solid #444444; font-weight: bold; text-align: center;">
                                                X</div>
                                            <div id="recResultDivContent_17"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="add-network-block" id="addToYourNetworkId_17">
                                    <div class="profilenet" *ngIf="( connection.invitationAction== 'In My Network')"> In
                                        Your Network </div>
                                    <div class="profileact" *ngIf="( connection.invitationAction== 'Accept')">
                                        <span id="inNetworkStatusId_17" style="margin-right: 10px;">
                                            <a class="pageLink" href="javascript: void(0);">Accept</a>
                                        </span>
                                    </div>
                                    <div class="add-network-block" id="addToYourNetworkId_22"
                                        *ngIf="( connection.invitationAction== 'Waiting for Response')">
                                        <div class="profileawa">
                                            Awaiting Response
                                        </div>
                                    </div>
                                    <div id="sendMessageInNetworkDiv_17"
                                        *ngIf="( connection.invitationAction== 'In My Network')"
                                        class="getSendMessageIn"> <a href="javascript:void(0);" class="pageLink"> Send
                                            Message</a> </div>
                                    <div class="clear"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clr"></div>
                    <div id="loadMoreResults" (click)="campusConnectionLoadM()" class="load-more-hide">Load More</div>
                    <div id="pleaseWaitDiv" style="display:none"> <img src="assets/images/pleaseWait.gif" width="30%"
                            height="30%"> </div>
                </form>
                <div id="ui-widget-overlay" class="ui-widget-overlay"
                    style="display: none; width: 1349px; height: 765px;"></div>
                <div class="ui-dialog ui-widget ui-widget-content ui-corner-all"
                    style="display: none; z-index: 1002; outline: 0px none; height: auto; width: 550px; top: 301.3px; left: 396px;"
                    tabindex="-1" role="dialog" aria-labelledby="ui-dialog-title-reqForRecommendationFromSearchDiv">
                    <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"> <span
                            id="ui-dialog-title-reqForRecommendationFromSearchDiv" class="ui-dialog-title">Request for
                            Recommendation</span>
                        <a class="ui-dialog-titlebar-close ui-corner-all" href="#" role="button"> <span
                                class="ui-icon ui-icon-closethick">close</span> </a>
                    </div>
                    <div id="reqForRecommendationFromSearchDiv" class="ui-dialog-content ui-widget-content"
                        style="width: auto; min-height: 3.26666px; height: auto;" scrolltop="0" scrollleft="0">
                        <form method="post" name="reqForRecommendationFromSearchForm">
                            <div id="reqForRecommendationFromSearchDivContent">
                                <div id="requestForRecommendationErrorDiv" class="common errorDiv"></div>
                                <div class="common" style="padding: 6px 0;">
                                    <div class="recLeftCell">
                                        <label for="recommendationSubject">Subject</label>
                                    </div>
                                    <div class="recRightCell">
                                        <input id="recommendationSubject" type="text" style="padding: 5px;"
                                            autocomplete="off" maxlength="100" size="50" name="recommendationSubject">
                                    </div>
                                </div>
                                <div class="common" style="padding: 5px 0;">
                                    <div class="recLeftCell">
                                        <label for="recommendationComments">Request</label>
                                    </div>
                                    <div class="recRightCell">
                                        <textarea id="recommendationComments" cols="80" rows="3"
                                            name="recommendationComments"></textarea>
                                    </div>
                                    <div id="update_recommendationComments" class="common"
                                        style="width: 87%; text-align: right; display: none;"> <span
                                            id="charCount_recommendationComments">1000</span> chars left </div>
                                </div>
                                <div class="common" style="padding: 5px 0;">
                                    <input id="btnSend" class="buttonStyle" type="button" value="Send" name="btnSend">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- right form start -->
    <div class="right_part">
        <div class="col-md-3 grow_your_network boxs boxpad ">
            <div class="widget refineresults">
                <form name="frmRefineResults" id="frmRefineResults" method="post">
                    <div class="title fblue pad15">Refine Results:</div>
                    <div class="chkbox">
                        <input type="checkbox" [(ngModel)]="connectionfilter.inMyNetworkFilterCriteria"
                            name="connectionfilter.inMyNetworkFilterCriteria" (change)="myNetworkCheck($event)"
                            id="search_inMyNetwork">
                        <label for="search_inMyNetwork">In my network </label>
                    </div>
                    <div class="chkbox">
                        <input type="checkbox" [(ngModel)]="connectionfilter.awaitingResponseFilterCriteria"
                            name="connectionfilter.awaitingResponseFilterCriteria" (change)="awaitResCheck($event)"
                            id="search_awaitingresponse">
                        <label for="search_outOfMyNetwork">Awaiting Response</label>
                    </div>
                    <div class="chkbox">
                        <input type="checkbox" [(ngModel)]="connectionfilter.professors"
                            name="connectionfilter.professors" (change)="professorsCheck($event)"
                            id="search_professors">
                        <label for="search_professors">Professors</label>
                    </div>
                    <div class="chkbox">
                        <input type="checkbox" [(ngModel)]="connectionfilter.students" name="connectionfilter.students"
                            (change)="studentsCheck($event)" id="search_students1">
                        <label for="search_students1">Students</label>
                    </div>
                    <div class="chkbox">
                        <input type="checkbox" [(ngModel)]="connectionfilter.alumni" name="connectionfilter.alumni"
                            (change)="alumniCheck($event)" id="search_alumni">
                        <label for="search_alumni">Alumni</label>
                    </div>
                    <div class="form-group">
                        <!--<input type="text" name="search_university" id="search_university" size="25" maxlength="100" autocomplete="off" class="form-control" placeholder="University/School/College"> -->
                        <p-autoComplete [suggestions]="eduInstresults" (completeMethod)="searcheduinst($event)"
                            [(ngModel)]="connectionfilter.educationalInstitutionFullName"
                            placeholder="University/School/College"
                            name="connectionfilter.educationalInstitutionFullName" id="educationInstitution"
                            class="form-control" autocomplete="off"></p-autoComplete>
                    </div>
                    <div class="refyear">
                        <div class="form-group">
                            <select name="from" id="fromYearRefine">
                                <option value="From">Year From</option>
                                <option value="2013">2013</option>
                                <option value="2012">2012</option>
                                <option value="2011">2011</option>
                                <option value="2010">2010</option>
                                <option value="2009">2009</option>
                                <option value="2008">2008</option>
                                <option value="2007">2007</option>
                                <option value="2006">2006</option>
                                <option value="2005">2005</option>
                                <option value="2004">2004</option>
                                <option value="2003">2003</option>
                                <option value="2002">2002</option>
                                <option value="2001">2001</option>
                                <option value="2000">2000</option>
                            </select>
                        </div>
                        <div class="form-group last">
                            <select name="to" id="toYearRefine">
                                <option value="To">Year To</option>
                                <option value="2013">2013</option>
                                <option value="2012">2012</option>
                                <option value="2011">2011</option>
                                <option value="2010">2010</option>
                                <option value="2009">2009</option>
                                <option value="2008">2008</option>
                                <option value="2007">2007</option>
                                <option value="2006">2006</option>
                                <option value="2005">2005</option>
                                <option value="2004">2004</option>
                                <option value="2003">2003</option>
                                <option value="2002">2002</option>
                                <option value="2001">2001</option>
                                <option value="2000">2000</option>
                            </select>
                        </div>
                        <div class="clr"></div>
                    </div>
                    <div class="form-group">
                        <input type="text" id="search_specializationCourse"
                            [(ngModel)]="connectionfilter.specialization" name="connectionfilter.specialization"
                            value="" autocomplete="off" class="form-control" placeholder="Specialization/Session">
                    </div>
                    <div class="form-group">
                        <input type="text" [(ngModel)]="connectionfilter.educationalInstitutionCampus"
                            name="connectionfilter.educationalInstitutionCampus" id="search_campus" size="25"
                            maxlength="50" autocomplete="off" class="form-control" placeholder="Campus"> </div>
                    <div class="form-group">
                        <select [(ngModel)]="connectionfilter.countryFullName" name="connectionfilter.countryFullName"
                            (change)="onChange()" placeholder="Select Country" id="country">
                            <option [value]="country.countryCode" *ngFor="let country of countries">
                                {{country.countryCode}}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <input type="text" [(ngModel)]="connectionfilter.cityFullName"
                            name="connectionfilter.cityFullName" id="search_city" size="25" maxlength="50"
                            autocomplete="off" class="form-control" placeholder="City"> </div>
                    <input type="button" class="view-topics" (click)="getSearchData()" value="Search">
                </form>
                <br>
            </div>
            <div class="clr"></div>
        </div>
    </div>
    <div class="clr"></div>
</div>