<div class="col-md-8 col_md_8 pageblock content_area">
    <menu-component></menu-component>
    <div class="row home_container">
        <div class="row">
            <div class="boxs">
                <ol class="breadcrumb" id="breadcrumb">
                    <li class="home"><a href="javascript:void(0);" [routerLink]="['../home']">Home</a>
                    </li>
                    <li class="arrow">&nbsp;</li>
                    <li class="active">View Profile</li>
                </ol>
                <div class="clr"></div>
            </div>
            <div class="row" id="printdiv">
                <div class="col-md-12 contant_div">
                    <!-- Left side start -->
                    <form #frmProfile name="frmProfile" id="frmProfile" method="post" enctype="multipart/form-data">
                        <input type="hidden" id="hdUserId" name="hdUserId" value="13">
                        <div style="display: none;" id="addConnectionPleaseWaitDiv"> <img
                                src="assets/images/pleaseWait.gif" width="15px" height="15px"> </div> <span
                            class="add-network-block" id="plsWaitImgCntnr_13"></span>
                        <div class="row">
                            <div class="boxs boxpad profileviewpage">
                                <div class="linkedInImg">
                                    <a *ngIf="viewProfile.linkedInProfile" target="_blank" href="{{addhttp(viewProfile.linkedInProfile)}}">
                                        <img height="40" width="40" src="assets/images/linkedinLogo.png" />
                                    </a>    
                                    <a (click)="linkedInProfileedit=true;" *ngIf="viewProfile.linkedInProfile == undefined || viewProfile.linkedInProfile == ''">
                                        <img height="40" width="40" src="assets/images/linkedinLogoDisable.png" />
                                    </a>
                                </div>
                                <div class="proleft">
                                    <div class="loader" [style.display]="editDisplay(profileImgLoader)"><img
                                            src="assets/images/ajax-loader.gif" /></div>
                                    <div class="col-md-2 profile col-sm-3">
                                        <a href="javascript:void(0);">
                                            <img *ngIf="viewProfile.profilePicName != null && !viewProfile.profilePicName.includes('profilebig.png')"
                                                width="150px" id="profilePicImage" class="greyBorder profileImage"
                                                src="{{viewProfile.profilePicName}}">
                                            <ngx-avatar
                                                *ngIf="viewProfile.profilePicName == null || viewProfile.profilePicName.includes('profilebig.png')"
                                                name="{{viewProfile.firstName}} {{viewProfile.lastName}}" size="150">
                                            </ngx-avatar>
                                        </a>
                                        <br> <a href="javascript:void(0);" (click)="ImgeCroper = true;"
                                            class="pic-show ico_add">Add</a> / <a href="javascript:void(0);"
                                            (click)="ImgeCroper = true;" class="pic-show ico_edit">Edit
                                            Photo</a>
                                        <div id="pic" class="editprofilepic profile_edit" style="display: none;"
                                            [style.display]="editDisplay(selectimg)">
                                            <div class="row fileupload-buttonbar">
                                                <div class="span7"> <span class="btn btn-success fileinput-button"> <i
                                                            class="icon-plus icon-white"></i> <span
                                                            style="text-decoration: none; color: #0f8dbf; display: inline; cursor:pointer;"
                                                            (click)="fileInput.click()">Select
                                                            Image</span>
                                                        <input type="file" name="profilePicImage" style="display:none;"
                                                            #fileInput id="editProfilePic" multiple=""
                                                            (change)="imageOnChange($event)"></span>
                                                </div>
                                                <br>
                                                <div id="progress"
                                                    class="ui-progressbar ui-widget ui-widget-content ui-corner-all"
                                                    style="height: 17px; display: none;" role="progressbar"
                                                    aria-valuemin="0" aria-valuemax="100" aria-valuenow="30">
                                                    <div id="fileUploadBar"
                                                        class="ui-progressbar-value ui-widget-header ui-corner-left"
                                                        style="width: 0%;"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- START Image Cropping DIV -->
                                        <div id="cropImageDiv">
                                            <div id="localImage" style="display: none;"></div>
                                            <div id="cropform" style="display: none;">
                                                <input type="hidden" id="cropUserId" name="cropUserId" value="13">
                                                <input type="hidden" id="localImageName" name="localImageName">
                                                <input type="hidden" id="imageExtention" name="imageExtention">
                                                <input type="hidden" id="x" name="x">
                                                <input type="hidden" id="y" name="y">
                                                <input type="hidden" id="w" name="w">
                                                <input type="hidden" id="h" name="h">
                                                <input type="button" value="Crop Image">
                                                <input type="button" value="Cancel"> </div>
                                        </div>
                                        <!-- END Image Cropping DIV -->
                                        <br>
                                        <div class="printbtn">
                                            <a href="javascript: void(0);"><img src="assets/images/print.png"> Print
                                                Profile</a>
                                        </div> <span class="add-network-block" id="addToYourNetworkId_13"> </span>
                                        <div class="profilePageRecoNRateLinks">
                                            <div class="profilerqs">
                                                <div class="profilerqsbox"> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="proright">
                                    <input value="1" name="hdProfileId" id="hdProfileId" type="hidden">
                                    <input value="Student" name="hdCriteria" id="hdCriteria" type="hidden">
                                    <div class="profilename"> {{viewProfile.firstName}} {{viewProfile.middleName}}
                                        {{viewProfile.lastName}}
                                        <span *ngIf="viewProfile.noOfRatingStars"
                                            style="font-size: 0px; color: #444444; vertical-align: sub;">
                                            <img width="32" border="0" *ngFor="let rate of myRating"
                                                src="assets/images/star.png" style="margin-right: 1px;">
                                        </span>
                                    </div>
                                    <!-- <div>
                                        <input type="radio" class="form-check-input" name="profType"
                                            [value]="userProfileType" (click)="changeProfileType('Academic')"
                                            [checked]="userProfileType == 'Academic'">Academic
                                        <input type="radio" class="form-check-input" name="profType"
                                            [value]="userProfileType" (click)="changeProfileType('Professional')"
                                            [checked]="userProfileType == 'Professional'">Professional

                                    </div> -->
                                    <div id="">
                                        <div class="profileclg"
                                            *ngIf="viewProfile.isUserSecondaryDataCapturedForUser != 'F'">
                                            <div *ngIf="userProfileType == 'Academic'">
                                                <label class="profile-subname" id="labelEduInsCampus">
                                                    {{viewProfile.userType}}, {{viewProfile.userEducationSubject}},
                                                    {{viewProfile.educationalInstitution}}, {{viewProfile.city}}</label>
                                            </div>
                                            <div *ngIf="userProfileType == 'Professional'">
                                                <div>
                                                    <!-- <label class="profile-subname" id="labelEduInsCampus">
                                                        <span>{{allwrkExp[0].projectResearchTitle}}</span><span
                                                            *ngIf="allwrkExp[0].projectResearchExpEducationInsitution != null && allwrkExp[0].projectResearchExpEducationInsitution != ''">,
                                                            {{allwrkExp[0].projectResearchExpEducationInsitution}}</span><span *ngIf="viewProfile.city != null">, {{viewProfile.city}}</span>
                                                    </label> -->
                                                    <label class="profile-subname" id="labelEduInsCampus">
                                                        {{viewProfile.userType}},
                                                        {{viewProfile.educationalInstitution}},
                                                        {{viewProfile.city}}</label>
                                                </div>

                                            </div>
                                            <!-- <a href="javascript:void(0);" id="univ-show" class="ico_edit"
                                                [hidden]="userProfileType !== 'Academic'"
                                                (click)="profileclg = true;">Edit</a>
                                            <a href="javascript:void(0);" id="univ-show"
                                                *ngIf="allwrkExp != null && allwrkExp.length > 0" class="ico_edit"
                                                [hidden]="userProfileType !== 'Professional'"
                                                (click)="allwrkExp[0].wrkexp=true; editdatawrkExp(allwrkExp[0]);">Edit</a> -->
                                            <a href="javascript:void(0);" id="univ-show" class="ico_edit"
                                                (click)="openSignUpTwoPopup(userProfileType, viewProfile, allwrkExp[0])">Edit</a>
                                            <br>
                                            <div class="five create-topic-block relative" style="display: block;"
                                                [style.display]="editDisplay(project)"></div>


                                            <div id="univ" class="five create-topic-block relative"
                                                style="display: none;" [style.display]="editDisplay(profileclg)">
                                                <div class="loader" [style.display]="editDisplay(eduqloading)"><img
                                                        src="assets/images/ajax-loader.gif" /></div>
                                                <div class="form-group leftsection">
                                                    <label style="width:100%; display: block;">User Type:</label>
                                                    <!--<input type="text" [(ngModel)]="updateeducationalQualification.userType" #userType="ngModel" name="updateeducationalQualification.userType" id="userSubTypeMaster" size="28" maxlength="200" placeholder="eg. Student, Assitant Professor, Teacher, Visiting Professor" class="form-control" autocomplete="off">-->

                                                    <p-autoComplete
                                                        [(ngModel)]="updateeducationalQualification.userType"
                                                        name="updateeducationalQualification.userType"
                                                        [suggestions]="userTyperesults"
                                                        (completeMethod)="searchusertype($event)"
                                                        placeholder="eg. Student, Assitant Professor, Teacher, Visiting Professor"
                                                        class="form-control" size="28" maxlength="200">
                                                    </p-autoComplete>

                                                    <label for="educationInstitution">University/School/College:</label>
                                                    <p-autoComplete [suggestions]="eduInstresults"
                                                        (completeMethod)="searcheduinst($event)"
                                                        [(ngModel)]="updateeducationalQualification.educationalInstitution"
                                                        #educationalInstitution="ngModel"
                                                        name="updateeducationalQualification.educationalInstitution"
                                                        id="educationInstitution" class="form-control"
                                                        autocomplete="off"></p-autoComplete>
                                                </div>
                                                <div class="form-group rightsection">
                                                    <label for="subject">Subject:</label>
                                                    <input type="text"
                                                        [(ngModel)]="updateeducationalQualification.userEducationSubject"
                                                        #userEducationSubject="ngModel"
                                                        name="updateeducationalQualification.userEducationSubject"
                                                        id="subject" class="form-control" style="margin-bottom:1px;">
                                                </div>
                                                <div class="form-group rightsection">
                                                    <label for="campus">Campus:</label>
                                                    <input type="text"
                                                        [(ngModel)]="updateeducationalQualification.campus"
                                                        #campus="ngModel" name="updateeducationalQualification.campus"
                                                        id="campus" class="form-control"> </div>
                                                <div class="clr"></div>
                                                <input class="btn btn-primary btn-default btn-120" type="button"
                                                    (click)="saveeducationalQualification()"
                                                    name="btnSaveInstitutionCampus" id="btnSaveInstitutionCampus"
                                                    value="Save">
                                                <input class="btn btn-primary btn-default btn-120" type="button"
                                                    (click)="cancelEducationalQualification();" id="cancel-univ"
                                                    name="cancel-univ" value="Cancel">
                                            </div>
                                            <div class="clear"></div>
                                            <div class="common errorDiv" id="institutionError"></div>
                                            <div class="common errorDiv" id="campusError"></div>
                                            <div class="common errorDiv" id="subjectError"></div>
                                        </div>
                                        <div class="profileclg"
                                            *ngIf="viewProfile.isUserSecondaryDataCapturedForUser == 'F'">
                                            <a href="javascript:void(0)" (click)="openStep2Popup = true">
                                                <i>Add your title, Company/Institution, Location</i>
                                            </a>
                                        </div>
                                        <div class="profiletools">
                                            <label><a href="javascript: void(0);"
                                                    [routerLink]="['/dashboard/myconnections']">Connections:</a>
                                            </label>
                                            <a href="javascript: void(0);" [routerLink]="['../myconnections']"
                                                [queryParams]="{ s: 'student', i:'true' }">
                                                <span class="counter"> {{viewProfile.studentConnectionCount}}</span>
                                                Students,</a>
                                            <a href="javascript: void(0);" [routerLink]="['../myconnections']"
                                                [queryParams]="{ s: 'professor', i:'true' }">
                                                <span class="counter"> {{viewProfile.professorConnectionCount}}</span>
                                                Professors,</a>
                                            <a href="javascript: void(0);" [routerLink]="['../myconnections']"
                                                [queryParams]="{ s: 'alumni', i:'true' }">
                                                <span class="counter"> {{viewProfile.alumniConnectionCount}}</span>
                                                Alumni</a>
                                        </div>
                                        <div id="linkedInDivWrap" class="clearfix">
                                            <label class="lblblue">LinkedIn Profile Url :</label>
                                            <span id="linkedInDiv" class="contentarea">
                                                <label class="lblblue">{{viewProfile.linkedInProfile}}</label>
                                                <a href="javascript:void(0);" *ngIf="viewProfile.linkedInProfile" (click)="linkedInProfileedit=true;"
                                                    id="degree-show" class="ico_edit">Edit</a>
                                                <a class="educ-show-add ico_add" *ngIf="viewProfile.linkedInProfile == undefined || viewProfile.linkedInProfile == ''" href="javascript:void(0);"
                                                    (click)="linkedInProfileedit=true;">Add</a>
                                                    <br>
                                                <div id="degree" class="four create-topic-block leftsection relative"
                                                    style="display: none;" [style.display]="editDisplay(linkedInProfileedit)">
                                                    <div class="loader" [style.display]="editDisplay(linkedInProfileloading)">
                                                        <img src="assets/images/ajax-loader.gif" /></div>
                                                    <input type="text" style="margin-bottom: 5px !important;"
                                                        [(ngModel)]="updatelinkedInProfile.linkedInProfile"
                                                        #linkedInProfile="ngModel"
                                                        name="updatelinkedInProfile.linkedInProfile"
                                                        id="linkedInProfile" autocomplete="off" value="">
                                                    <!-- <div *ngIf="!linkedInProfile.valid && saveClicked" class="errorDiv" style="color: #f00">
                                                        LinkedIn Profile Url is Invalid
                                                    </div> -->
                                                    <input type="button" (click)="editlinkedInProfile()"
                                                        name="btnSavelinkedInProfile" class="btn-120"
                                                        id="btnSavelinkedInProfile" value="Save">
                                                    <input type="button" id="cancel-degree"
                                                        class="cancel-buttonz btn-120" (click)="linkedInProfileedit=false;"
                                                        name="cancel-degree" value="Cancel">
                                                </div>
                                                <div class="clr"></div>
                                                <div class="common errorDiv" id="linkedInProfileError"></div>
                                            </span>
                                        </div>
                                        <div id="degreeDiv" class="clearfix">
                                            <label class="lblblue">Highest Education Level :</label>
                                            <span id="degreeCourseDiv" class="contentarea">
                                                <input type="hidden" name="hidDegreeOrCourse" id="hidDegreeOrCourse"
                                                    value="Phd">
                                                <label class="lblblue">{{viewProfile.userEducationDegreeCourse}}</label>
                                                <a href="javascript:void(0);" (click)="degreeedit=true;"
                                                    id="degree-show" class="ico_edit">Edit</a><br>
                                                <div id="degree" class="four create-topic-block leftsection relative"
                                                    style="display: none;" [style.display]="editDisplay(degreeedit)">
                                                    <div class="loader" [style.display]="editDisplay(degreeloading)">
                                                        <img src="assets/images/ajax-loader.gif" /></div>
                                                    <input type="text" style="margin-bottom: 5px !important;"
                                                        [(ngModel)]="updatedegreeCourse.userEducationDegreeCourse"
                                                        #userEducationDegreeCourse="ngModel"
                                                        name="updatedegreeCourse.userEducationDegreeCourse"
                                                        id="degreeCourse" autocomplete="off" value="">
                                                    <input type="button" (click)="editdegreeCourse()"
                                                        name="btnSaveDegreeCourse" class="btn-120"
                                                        id="btnSaveDegreeCourse" value="Save">
                                                    <input type="button" id="cancel-degree"
                                                        class="cancel-buttonz btn-120" (click)="degreeedit=false;"
                                                        name="cancel-degree" value="Cancel">
                                                </div>
                                                <div class="clr"></div>
                                                <div class="common errorDiv" id="degreeCourseError"></div>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="contentarea">
                                        <div class="ten columns" id="pastEducationDiv">
                                            <label class="lblblue">Past Education:</label>
                                            <span class="edit_links">
                                                <a class="educ-show-add ico_add" href="javascript:void(0);"
                                                    (click)="resetpastEdu(); pastedu=true; pastupdate = false; pastsave = true;">Add</a>
                                            </span>
                                            <div *ngFor="let pastEdu of allpastEdu">
                                                <div class="pstedu">
                                                    <strong> {{pastEdu.degreeCourse}} from
                                                        {{pastEdu.pastEducationEducationInstitution}} </strong> ,
                                                    {{pastEdu.pastEducationCampus}}
                                                    <i *ngIf="pastEdu.isPresent == 'F'">
                                                        {{pastEdu.educationStartDateForDisplay}} –
                                                        {{pastEdu.educationEndDateForDisplay}} </i>
                                                    <i *ngIf="pastEdu.isPresent == 'T'">
                                                        {{pastEdu.educationStartDateForDisplay}} – Present </i>
                                                    <span class="edit_links">
                                                        <a class="educ-show-edit ico_edit" (click)="editpastedu(pastEdu); pastedu = true; pastupdate = true;
                                             pastsave = false;" href="javascript:void(0);"> Edit
                                                        </a>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="fourteen columns create-topic-block relative" id="educ"
                                                style="display: none;" [style.display]="editDisplay(pastedu)">
                                                <div class="loader" [style.display]="editDisplay(pastloading)"><img
                                                        src="assets/images/ajax-loader.gif" /></div>
                                                <div id="past-educ-form">
                                                    <input type="hidden" [(ngModel)]="addpastEducation.pastEducationId"
                                                        name="addpastEducation.pastEducationId" value="">
                                                    <div class="past-educ-block">
                                                        <div class="pasteduuni columns alpha form-group">
                                                            <p-autoComplete [suggestions]="eduInstresults"
                                                                (completeMethod)="searcheduinst($event)" value=""
                                                                [(ngModel)]="addpastEducation.pastEducationEducationInstitution"
                                                                #pastEducationEducationInstitution="ngModel"
                                                                placeholder="University/College/School"
                                                                name="addpastEducation.pastEducationEducationInstitution"
                                                                id="educ-univ-txt" autocomplete="off"></p-autoComplete>
                                                        </div>
                                                        <div class="pastedudegree columns form-group">
                                                            <input type="text"
                                                                [(ngModel)]="addpastEducation.degreeCourse"
                                                                #degreeCourse="ngModel" value=""
                                                                placeholder="Degree/Course"
                                                                name="addpastEducation.degreeCourse"
                                                                id="educ-degree-txt">
                                                        </div>
                                                        <div class="pasteducity columns form-group">
                                                            <input type="text"
                                                                [(ngModel)]="addpastEducation.pastEducationCampus"
                                                                #pastEducationCampus="ngModel" value=""
                                                                placeholder="Campus"
                                                                name="addpastEducation.pastEducationCampus"
                                                                id="educ-city-txt">
                                                        </div>
                                                        <div
                                                            class="three columns alpha inptselect form-group block_left">
                                                            <p-dropdown [options]="frommonths"
                                                                name="addpastEducation.fromMonth"
                                                                [(ngModel)]="addpastEducation.fromMonth"
                                                                #fromMonth="ngModel"></p-dropdown>
                                                        </div>
                                                        <div class="two columns form-group block_left">
                                                            <input type="text" id="fromyearPast"
                                                                [(ngModel)]="addpastEducation.fromYear"
                                                                #fromYear="ngModel" name="addpastEducation.fromYear"
                                                                placeholder="From Year" value="">
                                                        </div>
                                                        <div class="three columns inptselect form-group block_left"
                                                            id="toSelectIdPast" style="display: none;"
                                                            [style.display]="editDisplayInline(present)">
                                                            <p-dropdown [options]="tomonths"
                                                                name="addpastEducation.toMonth"
                                                                [(ngModel)]="addpastEducation.toMonth"
                                                                #toMonth="ngModel"></p-dropdown>
                                                        </div>
                                                        <div class="two columns form-group block_right"
                                                            id="toYearIdPast" style="display: none;"
                                                            [style.display]="editDisplayInline(present)">
                                                            <input type="text" id="toyearPast" placeholder="To Year"
                                                                [(ngModel)]="addpastEducation.toYear" #toYear="ngModel"
                                                                name="addpastEducation.toYear" value="">
                                                        </div>
                                                        <div class="two columns last chk form-group">
                                                            <input type="checkbox" id="presentPast"
                                                                (change)="presentChange($event)" [(ngModel)]="isPresent"
                                                                name="isPresent" value="true"> Present
                                                        </div>
                                                        <div class="clear"></div>
                                                    </div>
                                                    <div class="clr"></div>
                                                </div>
                                                <div class="three columns alpha form-group">
                                                    <div class="inlineblock">
                                                        <input type="button" value="Save" name="save-educ"
                                                            (click)="editpastEducation()"
                                                            [style.display]="editDisplay(pastsave)" id="save-educ">
                                                    </div>
                                                    <div class="inlineblock">
                                                        <input type="button" value="Update"
                                                            (click)="updatePastEdu(addpastEducation)" id="update-educ"
                                                            [style.display]="editDisplay(pastupdate)"
                                                            style="display: none;">
                                                    </div>
                                                    <div class="inlineblock">
                                                        <input type="button" value="Cancel" name="cancel-educ"
                                                            class="cancel-buttonz" (click)="pastedu=false;"
                                                            id="cancel-educ">
                                                    </div>
                                                    <div class="inlineblock">
                                                        <input type="button" value="Delete"
                                                            (click)="deletePastEducation(addpastEducation)"
                                                            id="delete-educ">
                                                    </div>
                                                </div>
                                                <div class="three columns last deletestyle"> </div>
                                            </div>
                                            <div class="clear"></div>
                                            <input type="hidden" name="showerrorpast" id="showerrorpast" value="">
                                        </div>
                                    </div>
                                </div>
                                <div class="clr"></div>
                            </div>
                            <div class="post-block inner-block" style="word-break: break-all; word-wrap: break-word;">
                                <!-- About Me Block -->
                                <div class="fifteen columns addrmcl-content2 boxs" id="aboutMeDiv">
                                    <div class="title fblue bglightgray"><span class="drakbg"><i
                                                class="icon_about"></i>About <label>Me</label>:</span> <span><a
                                                href="javascript: void(0);" (click)="aboutme=true;"
                                                class="pageLink1 ico_edit">Edit</a></span> </div>
                                    <div id="view_taAboutMe" maxlength="25" class="common boxpad" style="width: 100%;"
                                        *ngIf="viewProfile.aboutMe == ''"> No details entered</div>
                                    <div id="view_taAboutMe" class="common boxpad" style="width: 100%;"
                                        *ngIf="viewProfile.aboutMe != ''">
                                        <pre
                                            style="font-family: 'Ubuntu', sans-serif; font-size:13px; color:#6d6d6d;">{{viewProfile.aboutMe}}</pre>
                                    </div>
                                    <div id="about" class="ten create-topic-block leftsection relative"
                                        style="display: none;" [style.display]="editDisplay(aboutme)">
                                        <div class="loader" [style.display]="editDisplay(aboutloading)"><img
                                                src="assets/images/ajax-loader.gif" /></div>
                                        <div class="form-group">
                                            <textarea id="taAboutMe" [(ngModel)]="editAboutMe.aboutMe"
                                                #aboutMe="ngModel" name="editAboutMe.aboutMe" ng-trim="false"
                                                maxlength="2000">{{viewProfile.aboutMe}}</textarea>
                                        </div>
                                        <input type="submit" (click)="editaboutMe()" id="update-about" class="btn-120"
                                            name="update-about" value="Update">
                                        <input type="button" value="Cancel Edit" id="cancel-about"
                                            class="cancel-buttonz btn-120" name="cancel-about" (click)="aboutme=false;">
                                        <div class="charcount" *ngIf="!editAboutMe.aboutMe"><span>2000 left</span></div>
                                        <div class="charcount" *ngIf="editAboutMe.aboutMe">
                                            <span>{{2000 - editAboutMe.aboutMe.length}} left</span></div>
                                        <div class="clr"></div>
                                    </div>
                                    <div class="clr"></div>
                                </div>
                                <!-- About Me Block Ends -->
                                <div class="clear"></div>
                                <!-- Specialist Block -->
                                <div class="fifteen columns addrmcl-content2 boxs" id="specialtiesDiv">
                                    <div class="title fblue bglightgray">
                                        <span class="drakbg"><i class="icon_about"></i>Specialties/Skills : </span>
                                        <span><a href="javascript: void(0);" class="pageLink1 ico_edit"
                                                id="special-show" (click)="skills=true;"> Edit </a></span>
                                    </div>
                                    <div id="view_taSpecialties" class="common boxpad" style="width: 100%;"
                                        *ngIf="getSkills == null">No details entered
                                    </div>
                                    
                                    <div id="view_taSpecialties" class="common boxpad" style="width: 100%;"
                                        *ngIf="getSkills != null && getSkills.length > 0"><span
                                            *ngFor="let getskill of getSkills; let i = index">{{(getskill.userSkillSetName != null && getskill.userSkillSetName !=undefined) ? getskill.userSkillSetName : getskill.skillSet.name}}<span
                                                *ngIf="getSkills.length > 0 && i < getSkills.length - 1">,</span>&nbsp;</span>
                                    </div>
                                    <div class="ten create-topic-block leftsection" id="special" style="display: none;"
                                        [style.display]="editDisplay(skills)">
                                        <rl-tag-input name="skillInput" [(ngModel)]="allskills"
                                            placeholder="Add Skills"></rl-tag-input>
                                        <br>
                                        <div class="clear"></div>
                                        <div class="three columns alpha">
                                            <input type="button" value="Update" (click)="skillsinput()" class="btn-120"
                                                id="update-special">
                                            <input type="button" value="Cancel" name="cancel-special"
                                                class="cancel-buttonz btn-120" (click)="skills=false;"
                                                id="cancel-special"> </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="clr"></div>
                                </div>
                                <!-- Specialist Block Ends-->
                                <div class="clear"></div>
                                <div class="boxs ">
                                    <div class="fifteen columns addrmcl-content2" id="projResExpDiv">
                                        <div class="title fblue bglightgray">
                                            <span class="drakbg"><i class="icon_about"></i>Project/Research/Work
                                                <label>Experience</label>:
                                            </span>
                                            <span>
                                                <a class="exp-show-add ico_add" href="javascript:void(0);"
                                                    (click)="project=true;">Add</a>
                                            </span>
                                        </div>
                                        <div class="clear"></div>
                                        <form #frmAddPrwe name="frmAddPrwe" method="post" role="search">
                                            <div class="fifteen columns create-topic-block boxpad leftsection" id="exp"
                                                style="display: block;" [style.display]="editDisplay(project)">
                                                <div id="exp-form">
                                                    <div class="exp-block">
                                                        <div class="six columns alpha form-group">
                                                            <p-autoComplete value=""
                                                                [(ngModel)]="wrkExp.projectResearchExpEducationInsitution"
                                                                name="wrkExp.projectResearchExpEducationInsitution"
                                                                [suggestions]="eduInstresults"
                                                                (completeMethod)="searcheduinst($event)"
                                                                placeholder="Organization" name="eduins"
                                                                id="exp-inst-txt" autocomplete="off"></p-autoComplete>
                                                        </div>
                                                        <div class="three columns form-group">
                                                            <input type="text" value=""
                                                                [(ngModel)]="wrkExp.projectResearchTitle"
                                                                name="wrkExp.projectResearchTitle"
                                                                placeholder="Designation" id="exp-title-txt"> </div>
                                                        <div class="two columns last form-group ">
                                                            <input type="text" value="" placeholder="Location"
                                                                [(ngModel)]="wrkExp.projectResearchExpCampus"
                                                                name="wrkExp.projectResearchExpCampus" id="exp-loc-txt"
                                                                autocomplete="off"> </div>
                                                    </div>
                                                    <div class="clr"></div>
                                                    <div class="duration">
                                                        <div
                                                            class="three columns alpha inptselect form-group block_left">
                                                            <p-dropdown [options]="frommonths"
                                                                [(ngModel)]="wrkExp.fromMonth" name="wrkExp.fromMonth"
                                                                tabindex=4 placeholder="From Month"></p-dropdown>
                                                        </div>
                                                        <div class="two columns form-group block_left clr">
                                                            <input type="text" id="fromyear"
                                                                [(ngModel)]="wrkExp.fromYear" name="wrkExp.fromYear"
                                                                placeholder="From Year" value=""> </div>
                                                        <div class="three columns inptselect form-group block_left"
                                                            id="toSelectId" style="display: none;"
                                                            [style.display]="editDisplayInline(wspresent)">
                                                            <p-dropdown [options]="tomonths"
                                                                [(ngModel)]="wrkExp.toMonth" name="wrkExp.toMonth"
                                                                tabindex=6 placeholder="To Month"></p-dropdown>
                                                        </div>
                                                        <div class="two columns form-group block_right clr"
                                                            id="toYearId" style="display: none;"
                                                            [style.display]="editDisplayInline(wspresent)">
                                                            <input type="text" id="toyear" [(ngModel)]="wrkExp.toYear"
                                                                name="wrkExp.toYear" placeholder="To Year" value="">
                                                        </div>
                                                        <div class="two columns last chk form-group">
                                                            <input type="checkbox" id="present"
                                                                (change)="wschangePresent($event)"
                                                                [(ngModel)]="wsisPresent" name="wsisPresent"> Present
                                                        </div>
                                                        <div class="clr"></div>
                                                        <div class="clear"></div>
                                                        <div class="fourteen columns alpha form-group editor">
                                                            <!-- <textarea placeholder="Description (Up to 1000 characters)"
                                                                [(ngModel)]="wrkExp.projectResearchDescription"
                                                                name="wrkExp.projectResearchDescription"
                                                                id="exp-desc-txt" ng-trim="false"
                                                                maxlength="5000"> </textarea> -->
                                                        </div>
                                                            <ejs-richtexteditor [insertImageSettings]='insertImageSettings' #toolsRTE
                                                                [height]="300" style="display: block;margin: 0;" [(ngModel)]="wrkExp.projectResearchDescription"
                                                                placeholder="Description"
                                                                name="wrkExp.projectResearchDescription" [toolbarSettings]="tools">
                                                            </ejs-richtexteditor>
                                                        <div class="clear"></div>
                                                    </div>
                                                </div>
                                                <div class="three columns alpha">
                                                    <input type="submit" class="btn-120" value="Save"
                                                        (click)="addNewWrkExp()" id="save-exp">
                                                    <input type="button" value="Cancel" name="cancel-exp"
                                                        class="cancel-buttonz btn-120" (click)="cancelNewWrkExp();">
                                                    <!-- <div class="charcount" *ngIf="!wrkExp.projectResearchDescription">
                                                        <span>5000 left</span></div>
                                                    <div class="charcount" *ngIf="wrkExp.projectResearchDescription">
                                                        <span>{{5000 - wrkExp.projectResearchDescription.length}}
                                                            left</span></div> -->
                                                    <div class="clr"></div>
                                                </div>
                                            </div>
                                        </form>
                                        <div class="clr"></div>
                                        <div class="boxpad" *ngIf="allwrkExp == null || allwrkExp.length == 0">No
                                            details entered</div>

                                        <div class="profile-work profile-hover boxpad"
                                            *ngIf="allwrkExp != null && allwrkExp.length > 0">
                                            <div *ngFor="let workexp of allwrkExp; let counter=index;" class="clr">
                                                <label
                                                    class="label-bold protitle">{{workexp.projectResearchTitle}}</label><span
                                                    class="edit_links"> &nbsp;
                                                    <a class="exp-show-edit2 ico_edit" href="javascript:void(0)"
                                                        (click)="workexp.wrkexp=true; editdatawrkExp(workexp);">Edit</a></span><br>
                                                <label class="topbr">
                                                    <span
                                                        *ngIf="workexp.projectResearchExpEducationInsitution != null">{{workexp.projectResearchExpEducationInsitution}}</span><span
                                                        *ngIf="workexp.projectResearchExpCampus != null && workexp.projectResearchExpCampus.length > 0">,
                                                        {{workexp.projectResearchExpCampus}}</span>
                                                </label>
                                                <label class="label-bold">
                                                    <i *ngIf="workexp.isPresent == 'F'">{{workexp.projResearchStartDateForDisplay}}
                                                        – {{workexp.projResearchEndDateForDisplay}}</i>
                                                    <i *ngIf="workexp.isPresent == 'T'">{{workexp.projResearchStartDateForDisplay}}
                                                        – Present</i>
                                                </label>
                                                <br>
                                                <p>
                                                    <br>
                                                    <span class="exp-desc-txt-span" id="exp-desc-txt-span_1" [innerHTML]="workexp.projectResearchDescription | safeHtml">
                                                        <!-- <pre
                                                            style="font-family: 'Ubuntu', sans-serif; font-size:13px; color:#6d6d6d;">{{workexp.projectResearchDescription}}</pre> -->
                                                    </span>
                                                </p>
                                                <!-- START Adding Edit for Each Block -->
                                                <form #frmEditPrResWe name="frmEditPrwe" method="post" role="search"
                                                    id="frmEditPrwe_{{workexp.projectResearchExpId}}">
                                                    <div class="fifteen columns create-topic-block boxpad leftsection"
                                                        id="exp_1" style="display: block;"
                                                        [style.display]="editDisplay(workexp.wrkexp)">
                                                        <div class="loader"
                                                            [style.display]="editDisplay(addwrkexploading)"><img
                                                                src="assets/images/ajax-loader.gif" /></div>
                                                        <div id="exp-form_1">
                                                            <div class="exp-block">
                                                                <div class="six columns alpha form-group">
                                                                    <p-autoComplete [suggestions]="eduInstresults"
                                                                        (completeMethod)="searcheduinst($event)"
                                                                        value="" placeholder="Organization"
                                                                        [(ngModel)]="workexp.editValue.projectResearchExpEducationInsitution"
                                                                        name="editWorkexp.projectResearchExpEducationInsitution">
                                                                    </p-autoComplete>
                                                                </div>
                                                                <div class="three columns form-group">
                                                                    <input type="text" value=""
                                                                        [(ngModel)]="workexp.editValue.projectResearchTitle"
                                                                        name="editWorkexp.projectResearchTitle"
                                                                        placeholder="Designation" id="exp-title-txt_1">
                                                                </div>
                                                                <div class="two columns last form-group ">
                                                                    <input type="text" value="" placeholder="Location"
                                                                        [(ngModel)]="workexp.editValue.projectResearchExpCampus"
                                                                        name="editWorkexp.projectResearchExpCampus"
                                                                        id="exp-loc-txt_1" autocomplete="off">
                                                                </div>
                                                            </div>
                                                            <div class="clr"></div>
                                                            <div class="duration">
                                                                <div
                                                                    class="three columns alpha inptselect form-group block_left">
                                                                    <p-dropdown [options]="frommonths"
                                                                        [(ngModel)]="workexp.editValue.fromMonth"
                                                                        name="editWorkexp.fromMonth"
                                                                        placeholder="From Month"></p-dropdown>
                                                                </div>
                                                                <div class="two columns form-group block_left">
                                                                    <input type="text" id="fromyear_1"
                                                                        [(ngModel)]="workexp.editValue.fromYear"
                                                                        name="editWorkexp.fromYear"
                                                                        placeholder="From Year" value="">
                                                                </div>

                                                                <div class="three columns inptselect form-group block_left"
                                                                    id="toSelectId_1"
                                                                    [style.display]="editDisplayInline(workexp.wspresent)">
                                                                    <p-dropdown [options]="tomonths"
                                                                        [(ngModel)]="workexp.editValue.toMonth"
                                                                        name="editWorkexp.toMonth"
                                                                        placeholder="To Month"></p-dropdown>
                                                                </div>
                                                                <div class="two columns form-group block_right"
                                                                    id="toYearId_1"
                                                                    [style.display]="editDisplayInline(workexp.wspresent)">
                                                                    <input type="text" id="toyear_1"
                                                                        [(ngModel)]="workexp.editValue.toYear"
                                                                        name="editWorkexp.toYear" placeholder="To Year"
                                                                        value="">
                                                                </div>
                                                                <div class="two columns last chk form-group">
                                                                    <input type="checkbox" id="present_1"
                                                                        (change)="presentwsChange($event, workexp)"
                                                                        [(ngModel)]="workexp.editValue.wsisPresent"
                                                                        name="editWorkexp.wsisPresent" value="true">
                                                                    Present
                                                                </div>
                                                                <div class="clr"></div>
                                                                <div class="clear"></div>
                                                                <div class="fourteen columns alpha form-group">
                                                                    <!-- <textarea
                                                                        placeholder="Description (Up to 5000 characters)"
                                                                        [(ngModel)]="workexp.editValue.projectResearchDescription"
                                                                        name="workexp.projectResearchDescription"
                                                                        id="exp-desc-txt_1" ng-trim="false"
                                                                        maxlength="5000"> </textarea> -->
                                                                    
                                                                    <ejs-richtexteditor [(value)]="workexp.editValue.projectResearchDescription" [insertImageSettings]='insertImageSettings' #toolsRTE
                                                                        [height]="300" [(ngModel)]="workexp.editValue.projectResearchDescription"
                                                                        placeholder="Description"
                                                                        name="workexp.projectResearchDescription" [toolbarSettings]="tools">
                                                                    </ejs-richtexteditor>
                                                                </div>
                                                                <div class="clear"></div>
                                                            </div>
                                                        </div>
                                                        <div class="three columns alpha">
                                                            <!--<input type="button" value="Save" name="save-exp" id="save-exp_1">-->
                                                            <input type="submit" value="Update"
                                                                (click)="updateWorkExp(workexp)" name="update-exp"
                                                                id="update-exp_1" style="width:30.5%;">
                                                            &nbsp; <input type="button" value="Cancel" name="cancel-exp"
                                                                (click)="workexp.wrkexp=false;" class="cancel-buttonz "
                                                                id="cancel-exp_1"> &nbsp;
                                                            <input type="button" value="Delete"
                                                                (click)="deleteWorkExp(workexp)" id="delete-exp_1">
                                                            <!-- <div class="charcount"
                                                                *ngIf="!editWorkexp.projectResearchDescription">
                                                                <span>5000 left</span></div>
                                                            <div class="charcount"
                                                                *ngIf="editWorkexp.projectResearchDescription">
                                                                <span>{{5000 - workexp.editValue.projectResearchDescription.length}}
                                                                    left</span></div> -->
                                                        </div>
                                                    </div>
                                                </form>
                                                <br>
                                                <!-- END Adding Edit for Each Block -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clr"></div>
                                </div>
                                <!--<div class="fifteen columns addrmcl-content2 boxs" id="booksIRecommendDiv">
                                <div class="title fblue bglightgray"><span class="drakbg"><i class="icon_about"></i>Books I <label>recommend</label>:</span> <span><a href="javascript: void(0);" class="pageLink1 ico_edit" id="books-show" (click)="book=true;">Edit</a></span> </div>
                                <div id="view_taBooksIRecommend" class="common boxpad" style="width: 100%;" *ngIf="viewProfile.userRecommendedBooks == ''">No Courses recommended so far.</div>
                                <div id="view_taBooksIRecommend" class="common boxpad" style="width: 100%;" *ngIf="viewProfile.userRecommendedBooks != ''">{{viewProfile.booksIRecommend}}
                                </div>
                                    <div id="books" class="ten create-topic-block leftsection" style="display: none;" [style.display]="editDisplay(book)">
                                        <div class="form-group">
                                            <textarea id="taBooksIRecommend" name="taBooksIRecommend" autocomplete="off"></textarea>
                                        </div>
                                        <input type="button" id="update-about" name="update-about" value="Update">
                                        <input type="button" id="cancel-books" (click)="book=false;" class="cancel-buttonz" name="cancel-books" value="Cancel">
                                        <div class="charcount"> <span id="charCount_taBooksIRecommend">500</span> chars left </div>
                                    </div>
                                    <div class="clr"></div>
                            </div>
                            <div class="clear"></div>-->
                                <div class="fifteen columns addrmcl-content2 boxs" id="coursesIRecommendDiv">
                                    <div id="coursesIRecommendInnerDiv">
                                        <div class="title fblue bglightgray"> <span class="drakbg"> <i
                                                    class="icon_about"></i> Sessions I <label>recommend</label>:
                                            </span> <span> <a href="javascript: void(0);" class="pageLink1 ico_edit"
                                                    (click)="CorseIR=true;" id="courses-show-data">Edit</a></span>
                                        </div>
                                        <div class="ten columns topcoures" *ngFor="let courseir of allcir">

                                            <div class="proleft printhide">

                                                <img *ngIf="courseir.course.imageURL == null"
                                                    class="greyBorder profileImage" alt=""
                                                    src="assets/images/default_course.png" height="60" width="60">
                                                <img *ngIf="courseir.course.imageURL != null"
                                                    class="greyBorder profileImage" alt=""
                                                    src="{{courseir.course.imageURL}}" height="60" width="60">


                                            </div>

                                            <div class="proright">
                                                <div class="coursetitle coursetitle1">
                                                    <a style="font-weight: bold;" href="javascript:void(0)"
                                                        [routerLink]="['../viewsession']"
                                                        [queryParams]="{ sessionId: courseir.course.courseId }">{{courseir.course.courseFullName}}</a>
                                                </div>
                                                <div class="label-bold">
                                                    <span class="gwt-Label profile-userBy userBy">by:</span> <a
                                                        href="javascript: void(0);" [routerLink]="['../profile']"
                                                        [queryParams]="{ user: courseir.courseCreator.userDetailsId }"
                                                        class="fblue deco-none"><span
                                                            class="gwt-Anchor profile-name2 fblue deco-none topicowner">
                                                            {{courseir.courseCreator.firstName}}
                                                            {{courseir.courseCreator.lastName}}</span></a>,<span
                                                        *ngIf="courseir.courseCreator.isUserSecondaryDataCapturedForUser == 'T'"
                                                        class="gwt-Label profile-userType userype">{{courseir.courseCreator.userType}},&nbsp;{{courseir.courseCreator.educationalInstitution}},&nbsp;{{courseir.courseCreator.campus}}</span>
                                                </div>
                                            </div>
                                            <div class="cirDelete"
                                                (click)="deletecir(courseir.course.courseId, 'exist')"
                                                [style.display]="editDisplay(CorseIR)"><img alt="Remove"
                                                    src="assets/images/course_delete.png"></div>
                                            <div class="clr"></div>

                                        </div>
                                        <!-- For Displaying all the courses that are recommended by this user.No Courses recommended so far. -->
                                        <div id="view_taCoursesIRecommend" class="common boxpad"
                                            style="width: 100%;display:none;" [style.display]="editDisplay(CorseIR)">
                                            <div id="courses" class="ten create-topic-block leftsection"
                                                style="display: none;" [style.display]="editDisplay(CorseIR)">
                                                <div class="form-group">

                                                    <p-autoComplete field="content" [(ngModel)]="courseiRcmd"
                                                        name="courseiRcmd" id="taCoursesIRecommend"
                                                        [suggestions]="courseIRresults"
                                                        (completeMethod)="searchcourses($event)"> </p-autoComplete>

                                                    <a class="course-show-add ico_add" (click)="courseIrAdd()"
                                                        id="course-show-add" href="javascript:void(0);">Add</a>
                                                    <table id="courseData" border="0">
                                                        <tbody>
                                                            <tr border="0" id="uv1" *ngFor="let cirr of cir">
                                                                <td id="uvTd2"><a class="enabled"
                                                                        [routerLink]="['../viewsession']"
                                                                        [queryParams]="{ sessionId: cirr.entityId }"
                                                                        href="#">{{cirr.content}}</a></td>
                                                                <td><img src="assets/images/course_delete.png"
                                                                        (click)="deletecir(cirr, 'new')" alt="Remove">
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <!--<input type="button" (click)="updatecIr();" id="update-about" name="update-about" value="Update">-->
                                                <!--<input type="button" id="cancel-courses" class="cancel-buttonz" (click)="CorseIR = false;" name="cancel-courses" value="Cancel">-->
                                                <div class="charcount"> <span id="charCount_taCoursesIRecommend"></span>
                                                </div>
                                                <div class="clr"></div>
                                            </div>
                                            <div class="clr"></div>
                                        </div>
                                        <div class="clr"></div>
                                        <input type="hidden" name="hdProfileId" id="hdProfileId">
                                        <div class="clr"></div>
                                    </div>
                                </div>
                                <div class="clear"></div>
                                <div id="myInterestsDiv" class="fifteen columns addrmcl-content2 boxs">
                                    <div class="title fblue bglightgray"><span class="drakbg"><i
                                                class="icon_about"></i>My <label>Interests</label>:</span> <span><a
                                                href="javascript: void(0);" class="pageLink1 ico_edit"
                                                id="interests-show" (click)="interest=true;">Edit</a></span> </div>
                                    <div id="view_taMyInterests" class="common boxpad" style="width: 100%;"
                                        *ngIf="viewProfile.myInterests == ''">No details entered
                                    </div>
                                    <div id="view_taMyInterests" class="common boxpad" style="width: 100%;"
                                        *ngIf="viewProfile.myInterests != ''">
                                        <pre
                                            style="font-family: 'Ubuntu', sans-serif; font-size:13px; color:#6d6d6d;">{{viewProfile.myInterests}}</pre>
                                    </div>
                                    <div id="interests" class="ten create-topic-block leftsection relative"
                                        style="display: none;" [style.display]="editDisplay(interest)">
                                        <div class="loader" [style.display]="editDisplay(interestloading)"><img
                                                src="assets/images/ajax-loader.gif" /></div>
                                        <div class="form-group">
                                            <textarea id="taMyInterests" [(ngModel)]=myInterest.myInterests
                                                #myInterests="ngModel" name="myInterest.myInterests" ng-trim="false"
                                                maxlength="2000"></textarea>
                                        </div>
                                        <input type="button" (click)="editmyInterest()" class="btn-120"
                                            id="update-about" name="update-about" value="Update">
                                        <input type="button" (click)="interest=false;" id="cancel-interests"
                                            class="cancel-buttonz btn-120" name="cancel-interests" value="Cancel">
                                        <div class="charcount" *ngIf="!myInterest.myInterests"><span>2000 left</span>
                                        </div>
                                        <div class="charcount" *ngIf="myInterest.myInterests">
                                            <span>{{2000 - myInterest.myInterests.length}} left</span></div>
                                    </div>
                                    <div class="clr"></div>
                                </div>
                                <div class="clear"></div>
                                <div id="recommendVisiDiv" class="fifteen columns addrmcl-content2 boxs relative"
                                    *ngIf="userRcmd != null && userRcmd.length > 0">
                                    <div class="uppercase sky_color title fblue bglightgray"> <span class="drakbg"><i
                                                class="icon_about"></i> Recommendations</span> </div>
                                    <div class="loader" [style.display]="editDisplay(userRcmdloading)"><img
                                            src="assets/images/ajax-loader.gif" /></div>
                                    <form>
                                        <div *ngIf="userRcmd != ''">
                                            <div class="edituserrcmd" *ngFor="let usrrcmd of userRcmd">
                                                <div
                                                    class="fifteen columns recommendation-checkbox addrmcl-content2 boxpad">
                                                    <span>
                                                        <input type="checkbox"
                                                            [(ngModel)]="usrrcmd.isRecommendationRequiredForDisplayOnProfile"
                                                            [ngModelOptions]="{standalone: true}"> Show
                                                        <input type="hidden"
                                                            [(ngModel)]="usrrcmd.userAcceptedRecommendationId"
                                                            name="usrrcmd.userAcceptedRecommendationId">
                                                    </span>
                                                </div>
                                                <div class="clear"></div>
                                                <div class="profile-recommend profile-hover boxpad">
                                                    <label><a class="pageLink" [routerLink]="['../profile']"
                                                            [queryParams]="{ user: usrrcmd.recommendationResponse.responseFrom.userDetailsId }"
                                                            href="javascript: void(0);">{{usrrcmd.recommendationResponse.responseFrom.firstName}}
                                                            {{usrrcmd.recommendationResponse.responseFrom.lastName}}</a>
                                                        <span> </span> </label>
                                                    <br>
                                                    <label
                                                        *ngIf="usrrcmd.recommendationResponse.responseFrom.isUserSecondaryDataCapturedForUser == 'T'">

                                                        {{usrrcmd.recommendationResponse.responseFrom.userBaseType}} ,
                                                        {{usrrcmd.recommendationResponse.responseFrom.educationalInstitution}}

                                                    </label>
                                                    <br>
                                                    <label>
                                                        {{usrrcmd.recommendationResponse.responseFrom.campus}}, </label>
                                                    <br>
                                                    <div class="userAcceptedRecommendations">
                                                        <label class="write">
                                                            <p> <i>{{usrrcmd.recommendationResponse.recommendation.recommendation}}</i>
                                                            </p>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="clear"></div>
                                            </div>
                                        </div>
                                        <div class="boxpad">
                                            <input type="button" id="submit" (click)="updateEditRcmd(userRcmd)"
                                                class="btn-120" value="Save">
                                            <input type="submit" id="cancel" name="cancel" class="btn-120"
                                                value="Cancel"> </div>
                                    </form>
                                </div>
                                <div class="clear"></div>
                                <div class="dim" id="dimReco" name="dimReco" style="display:none">
                                    <div id="invite-pop-up-reco" name="invite-pop-up-reco"
                                        class="ten columns invite-pop-up marginbottom boxpad" style="display: none;">
                                        <div class="popuptitle" id="requestForRecommend">
                                            <h2>Request for Recommendation</h2>
                                        </div>
                                        <div class="popupclose"> <img alt="Close" src="assets/images/close-button.png"
                                                id="closeReco"> </div>
                                        <div class="eight columns" id="subjectDivReco">
                                            <input type="text" value="" name="recommendationSubject"
                                                id="recommendationSubject" placeholder="Subject"> </div>
                                        <div class="eight columns" id="CommentsDivReco">
                                            <textarea name="recommendationComments" id="recommendationComments"
                                                placeholder="Request"></textarea>
                                        </div>
                                        <div class="three columns" id="buttonDivReco">
                                            <input type="button" value="Send" name="btnSend" id="btnSendReco"
                                                class="pageLink1">
                                            <input type="hidden" id="hdUserIdReco" value=""> </div>
                                    </div>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div [style.display]="editDisplay(ImgeCroper)"
        class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
        tabindex="-1" role="dialog" aria-describedby="errorDialog" aria-labelledby="ui-id-1"
        style="display: none;position: absolute;width: 360px;left: 30%;top: 0%;">
        <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix">
            <span id="ui-id-1" class="ui-dialog-title">Crop Image</span>
            <button
                class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
                role="button" aria-disabled="false" title="close" style="display: none;" (click)="popUp = false;">
                x
            </button>
        </div>
        <div class="ui-dialog-content ui-widget-content"
            style="width: auto; min-height: 33px; max-height: none; height: 80%;overflow:auto;">
            <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>
            <br>
            <span class="result rounded" *ngIf="data.image">
                <img [src]="data.image" [width]="cropperSettings.croppedWidth" [height]="cropperSettings.croppedHeight">
            </span>
        </div>
        <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <div class="ui-dialog-buttonset">
                <button type="button" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
                    (click)="updateImage(data)" role="button" aria-disabled="false"><span
                        class="ui-button-text">Update</span> </button>
                <button type="button" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
                    (click)="ImgeCroper = false;" role="button" aria-disabled="false"><span
                        class="ui-button-text">Close</span> </button>
            </div>
        </div>
    </div>
    <signuptwo-modal-popup [countryId]="countryId" [signUpTwoData]="signUpTwoData" [city]="city"
        [isSignUpTwoModalDialogToBeDisplayed]="openStep2Popup" (signupTwoModalPopupClosed)="updateUser($event)">
    </signuptwo-modal-popup>

</div>
<div class="overLay" *ngIf="openStep2Popup"></div>
<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>