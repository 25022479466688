<div class="content_area pageblock" id="myAccountDiv">
  <div class="sixteen columns">
    <menu-component></menu-component>
    <div class="boxs">
      <div id="content-top">
        <ol id="breadcrumb" class="twelve columns alpha breadcrumb">
          <li class="home">
            <a href="javascript:void(0);" [routerLink]="['../home']">Home</a>
          </li>
          <li class="arrow">&nbsp;</li>
          <li>My Account</li>
        </ol>
        <div class="clr"></div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
  <div id="myAccountDetailDiv">
    <input type="hidden" name="userAddressId" value="13" />
    <input type="hidden" name="privacySettingsId" value="13" />
    <div class="siixteen columns addrmcl-content boxs" id="myAccountDetailDiv">
      <div class="fifteen columns addrmcl-content2">
        <span style="margin-left: 10px; font-size: 12px; color: #666666;" style="display: none;"
          [style.display]="editDisplay(update)">
          <img border="0" width="12px" height="12px" src="assets/images/check.png" style="margin-right: 2px;" />
          Your changes have been updated
        </span>
      </div>
      <div class="fifteen columns addrmcl-content2 paymentbox" id="payPalSubmitDiv" style="display: none"
        [style.display]="editDisplay(paypalactivate)">
        <div class="title fblue bglightgray">
          <span class="drakbg"><i class="icon_about"></i>Payment</span>
        </div>
        <div class="leftsection boxpad">
          <div>
            <img src="assets/images/paypal_1.png" alt="Paypal Payment" /> <br />
            <span style="font-size: 11px;"></span>
          </div>
          <input type="text" [(ngModel)]="payPalSub.payPalId" name="payPalSub.payPalId" id="payPalId" size="25"
            maxlength="100" value="" placeholder="Accept payments through PayPal's website" />
          <div class="three columns alpha marginbottom">
            <input type="button" (click)="paypalSumbit('new')" style="display: none"
              [style.display]="editinlineDisplay(paypalactivatenew)" name="btnSubmit" id="btnSubmit" value="Save"
              class="btn-120 buttonStyle" />
            <input type="button" (click)="paypalSumbit('update')" style="display: none"
              [style.display]="editinlineDisplay(paypalupdate)" name="btnSubmit" id="btnSubmit" value="Save"
              class="btn-120 buttonStyle" />
            <a href="javascript:void(0);" (click)="paypalactivate = false; paypalactivatehide = true;"
              class="btn-120  pageLink1 btnstyle" style="display: inline-block">
              Cancel
            </a>
          </div>
        </div>
      </div>

      <div id="payPalActivateDiv" style="display: none;" [style.display]="editDisplay(paypalactivatehide)">
        <div class="title fblue bglightgray">
          <span class="drakbg"><i class="icon_about"></i>Payment</span>
        </div>
        <div class="leftsection boxpad">
          <div>
            <img src="assets/images/paypal_1.png" alt="Paypal Payment" /> <br />
            <span style="font-size: 11px;"></span>
          </div>
          Accept payments through PayPal's website
          <br />
          <br />
          <div class="paypal-user" *ngIf="accountdetails.payPalId"
            style="padding: 8px 12px;border: 1px solid #ccc;width: 60%;margin-bottom:10px;text-align: center;background: #eee;font-size: 15px;">
            <label id="paypal-user"><span style="color: #41C337;" class="icon-check"></span>
              <strong>Account: </strong>{{accountdetails.payPalId}}</label>
          </div>
          <!--<div class="paypal-actions"><label><span class="icon-edit"></span> <a href="#" id="editpayl-user">Edit</a> </label><span class="bullet">&bull;</span><label id="xpaypal-user"><span class="icon-x"></span> <a href="#">Deactivate</a></label></div>-->
          <input *ngIf="!accountdetails.payPalId" type="button"
            (click)="paypalactivate = true; paypalactivatenew = true; paypalactivatehide = false;" name="btnSubmit"
            id="btnSubmit" value="Activate" class="btn-120  buttonStyle" />
          <input *ngIf="accountdetails.payPalId" type="button"
            (click)="paypalactivate = true; paypalactivatenew = false; paypalupdate = true; paypalactivatehide = false;"
            name="btnSubmit" id="btnEdit" value="Edit Paypal" class="btn-120  buttonStyle" />
          <input type="button" *ngIf="accountdetails.payPalId" name="btnSubmit" id="btnSubmit"
            (click)="payPalpopUp = true;" value="Deactivate" class="btn-120  buttonStyle" />
        </div>
      </div>

      <div class="fifteen columns addrmcl-content2 profile-work">
        <div class="title fblue bglightgray">
          <span class="drakbg"><i class="icon_about"></i>My Account</span>
        </div>
        <div class="newloginform">
          <form name="form" method="post" (ngSubmit)="f.form.valid && saveDetails()" #f="ngForm" novalidate>
            <div class="fifteen columns create-topic-block myaccount  boxpad">
              <div class="four columns alpha leftsection">
                <label for="first-name1" class="" style="margin-top:10px;">First Name *</label>
                <input type="text" name="accountdetails.firstName" [(ngModel)]="accountdetails.firstName"
                  #firstName="ngModel" required id="firstName" size="25" maxlength="25" style="margin-bottom:2px;" />
                <div *ngIf="f.submitted && !firstName.valid" class="errorDiv"
                  style="left: auto;width: 48%;padding:0px;">
                  First Name is required
                </div>
              </div>
              <div class="four columns last rightsection">
                <label for="last-name1" class="" style="margin-top:10px;">Last Name *</label>
                <br />
                <input type="text" name="accountdetails.lastName" #lastName="ngModel" required
                  [(ngModel)]="accountdetails.lastName" id="lastName" size="25" maxlength="25"
                  style="margin-bottom:2px;" />
                <div *ngIf="f.submitted && !lastName.valid" class="errorDiv" style="left: auto;width: 48%;padding:0px;">
                  Last Name is required
                </div>
              </div>
              <div class="clr"></div>
              <div class="four columns alpha inptselect leftsection">
                <label for="country" class="" style="margin-top:25px;">Country *</label>
                <br />
                <select name="address.countryTO.countryId" (change)="onChange(countryTO.value)"
                  [(ngModel)]="address.countryTO.countryId" #countryTO="ngModel" required id="country"
                  style="margin-bottom:2px;">
                  <option [value]="null">Select Country</option>
                  <option [value]="country.countryId" *ngFor="let country of countries">{{country.countryFullName}}
                  </option>
                </select>
                <div *ngIf="f.submitted && !countryTO.valid" class="errorDiv"
                  style="left: auto;width: 48%;padding:0px;">Please select the Country</div>
              </div>
              <div class="four columns last rightsection">
                <label for="city" class="" style="margin-top:25px;">City *</label>
                <br />
                <input type="text" name="address.cityTO.cityId" [(ngModel)]="address.cityTO.cityFullName"
                  #city="ngModel" id="city" size="28" maxlength="50" value="" required placeholder="City" />
                <div *ngIf="f.submitted && !city.valid" class="errorDiv" style="left: auto;width: 48%;padding:0px;">
                  Please select a City from the choices available
                </div>
              </div>
              <div class="clr"></div>
              <div class="four columns alpha leftsection">
                <label for="zip-code" style="margin-top:25px;">Zip Code </label>
                <br />
                <input type="text" name="address.zipCode" [(ngModel)]="address.zipCode" #zipCode="ngModel"
                  id="zipCode" size="25" maxlength="10" placeholder="Zipcode" style="margin-bottom:2px;" />
                <!-- <div *ngIf="f.submitted && !zipCode.valid" class="errorDiv" style="left: auto;width: 48%;padding:0px;">
                  Zip Code is required
                </div> -->
              </div>
              <div class="four columns last rightsection">
                <label for="phone-mobile" class="" style="margin-top:25px;">Phone (Mobile) :</label>
                <br />
                <input type="text" name="accountdetails.phoneMobile" [(ngModel)]="accountdetails.phoneMobile"
                  id="phoneMobile" size="18" maxlength="15" value="" class="phoneNumber defaultTextActive"
                  title="Optional" placeholder="Phone (Mobile)" style="margin-bottom:2px;" />
              </div>
              <div class="clr"></div>
              <div class="four columns alpha leftsection">
                <br />
                <label for="phone-home" class="" style="margin-top:25px;">Phone (Home) :</label>
                <br />
                <input type="text" name="accountdetails.phoneHome" [(ngModel)]="accountdetails.phoneHome" id="phoneHome"
                  size="18" maxlength="15" value="" class="phoneNumber defaultTextActive" title="Optional"
                  placeholder="Phone (Home)" style="margin-bottom:20px;" />
              </div>
              <div class="four columns alpha rightsection">
                <label for="phone-work" class="" style="margin-top:25px;">Phone (Work) :</label>
                <br />
                <input type="text" name="accountdetails.phoneWork" [(ngModel)]="accountdetails.phoneWork" id="phoneWork"
                  size="18" maxlength="15" value="" class="phoneNumber defaultTextActive" title="Optional"
                  placeholder="Phone (Work)" style="margin-bottom:2px;" />
                <br />
              </div>

              <div class="clr"></div>

              <div class="fifteen columns create-topic-block myaccount boxpad">
                <div class="fifteen columns alpha priset">
                  <div class="two columns alpha">
                    <label class="label-bold protitle">View Profile:</label>
                  </div>
                  <div class="two columns topbr">
                    <input type="radio" name="privacy.profileVisibility" [(ngModel)]="privacy.profileVisibility"
                      [checked]="privacy.profileVisibility == 'EVERYONE'" id="profileVisibilityEveryone"
                      value="EVERYONE" checked="" />
                    <label for="profileVisibilityEveryone">Everyone</label>
                  </div>
                  <div class="three columns">
                    <input type="radio" name="privacy.profileVisibility" [(ngModel)]="privacy.profileVisibility"
                      [checked]="privacy.profileVisibility == 'FRIENDS_IN_MY_NETWORK'" id="profileVisibilityInMyNetwork"
                      value="FRIENDS_IN_MY_NETWORK" />
                    <label for="profileVisibilityInMyNetwork">People in my network</label>
                  </div>
                  <div class="three columns last">
                    <input type="radio" name="privacy.profileVisibility" [(ngModel)]="privacy.profileVisibility"
                      [checked]="privacy.profileVisibility == 'PEOPLE_IN_MY_UNIVERSITY'"
                      id="profileVisibilityInMyEducationInstitution" value="PEOPLE_IN_MY_UNIVERSITY" />
                    <label for="profileVisibilityInMyEducationInstitution">In my education institution</label>
                  </div>
                </div>
                <div class="eleven columns alpha priset">
                  <div class="two columns alpha">
                    <label class="label-bold protitle">Profile Visitors:</label>
                  </div>
                  <div class="two columns topbr">
                    <input type="radio" name="privacy.showProfileVisitors" [(ngModel)]="privacy.showProfileVisitors"
                      [checked]="privacy.showProfileVisitors == 'ENABLED'" id="profileVisitorsEnable" value="ENABLED"
                      checked="" />
                    <label for="profileVisitorsEnable">Enable</label>
                  </div>
                  <div class="three columns">
                    <input type="radio" name="privacy.showProfileVisitors" [(ngModel)]="privacy.showProfileVisitors"
                      [checked]="privacy.showProfileVisitors == 'DISABLED'" id="profileVisitorsDisable"
                      value="DISABLED" />
                    <label for="profileVisitorsDisable">Disable</label>
                  </div>
                  <div class="three columns last">&nbsp;&nbsp;&nbsp;</div>
                </div>
                <div class="fifteen columns alpha priset">
                  <div class="two columns alpha">
                    <label class="label-bold protitle">Notifications:</label>
                  </div>
                  <div class="two columns topbr">
                    <input type="radio" name="privacy.emailNotifications" [(ngModel)]="privacy.emailNotifications"
                      [checked]="privacy.emailNotifications == 'ENABLED'" id="notificationsEnable" value="ENABLED"
                      checked="" />
                    <label for="notificationsEnable">Enable</label>
                  </div>
                  <div class="three columns">
                    <input type="radio" name="privacy.emailNotifications" [(ngModel)]="privacy.emailNotifications"
                      [checked]="privacy.emailNotifications == 'DISABLED'" id="notificationsDisable" value="DISABLED" />
                    <label for="notificationsDisable">Disable</label>
                  </div>
                  <div class="three columns last">&nbsp;&nbsp;&nbsp;</div>
                </div>
                <div class="clr"></div>
                <br />
                <div class="three columns alpha marginbottom">
                  <input type="submit" id="btnSubmit" value="Save" class="btn-120 buttonStyle" />
                  <input type="button" id="btnCancel" value="Cancel" (click)="cancelDetails()"
                    class="btn-120  pageLink1 btnstyle" />
                </div>
              </div>
            </div>
          </form>
        </div>
        <br />

        <div class="title fblue bglightgray">
          <span class="drakbg"><i class="icon_about"></i>Privacy Settings</span>
        </div>
        <div class="boxpad">
          <div *ngIf="accountdetails.isUserSignedInViaGoogle != 'T'" class="eleven columns alpha" id="emailIdsOuter">
            <div class="eleven columns" id="emailIds">
              <div class="two columns">
                <label class="label-bold protitle">Email Id :</label>
                <a href="javascript: void(0);" (click)="addemail = true;" class="pageLink1 myaccaddemail ico_add"
                  id="addEmailLink">
                  Add / Update email
                </a>
                <div class="clr"></div>
              </div>

              <div class="topbr myacclist boxpad">
                <div class="three columns ">
                  <label>{{this.accountdetails.emailId}}</label>
                </div>
                <div *ngIf="!this.isAlternateEmailPrimaryInResponse" class="three columns">
                  <img border="0" width="12px" height="12px" src="assets/images/check.png" style="margin-right: 2px;" />
                  Primary for communication
                </div>
                <div class="clr"></div>
              </div>

              <div *ngIf="isAlternateEmailIdPresent == true" class="topbr myacclist boxpad">
                <div class="three columns ">
                  <label>{{this.accountdetails.alternateEmailId}}</label>
                </div>
                <div *ngIf="this.isAlternateEmailPrimaryInResponse" class="three columns">
                  <img border="0" width="12px" height="12px" src="assets/images/check.png" style="margin-right: 2px;" />
                  Primary for communication
                </div>
                <div class="clr"></div>
              </div>
            </div>
            <div class="eleven columns" id="emailIds"></div>
            <div id="addEmailIdRowTemplate" class="eleven columns" style="display: none;"
              [style.display]="editDisplay(addemail)">
              <div class="six columns leftsection">
                <div class="common error" id="emailIdsError" style="display: none;"></div>
                <span class="label-bold protitle">Add / Update email</span>
                <br />
                <input type="text" name="updateEmail.primaryCommunication.emailId"
                  [(ngModel)]="updateEmail.alternateEmailId" id="emailId" size="18" maxlength="50" />
                <input type="checkbox" name="updateEmail.primaryCommunication.isPrimary"
                  [(ngModel)]="this.isAlternateEmailPrimary" (change)="updatePrimaryForCommunication($event)"
                  id="isPrimaryForCommunication" value="Primary For Communication" />
                <label for="isPrimaryForCommunication">Primary For Communication</label>
                <div class="three columns alpha marginbottom">
                  <input type="button" name="btnSave" (click)="addEmail()" id="btnSave" value="Save"
                    class="btn-120 buttonStyle" />
                  <input type="button" name="btnCancel" (click)="addemail = false;" id="btnCancel" value="Cancel"
                    class="btn-120 buttonStyle" />
                </div>
                <br /><br /><br />
              </div>
              <div class="clr"></div>
            </div>
            <div id="addEmailIdSaveDiv"></div>
          </div>
          <div class="fifteen columns alpha">
            <div class="six columns last">
              <div *ngIf="accountdetails.isUserSignedInViaGoogle != 'T'" style="float: left; margin-left: 10px;"
                id="changePasswordDiv">
                <a href="javascript:void(0)" (click)="changepass = true;" class="pageLink1 btnstyle">Change Password</a>
              </div>
              <div *ngIf="accountdetails.isUserSignedInViaGoogle != 'T'"
                style="float: left; margin-left: 10px; display: block;" id="cancelChangePasswordDiv">
                <a href="javascript: void(0);" (click)="changepass = false;" class="pageLink1 btnstyle">Cancel Change
                  Password</a>
              </div>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <div style="float: left;  margin-left: 10px;" id="deleteAccountDiv">
                <a href="javascript: void(0);" (click)="confirmDeletionOfAccount()" class="pageLink1 btnstyle">Delete
                  Account</a>
              </div>
            </div>
          </div>
          <!-- Change Password -->
          <div class="eight columns success" style="padding: 5px 0; display: none;" id="passwordUpdateSuccessDiv"></div>
          <div class="eight columns" style="padding: 5px 0; display: none;" id="changePasswordErrorDiv">
            <div id="changePasswordErrorContentDiv" class="error"></div>
          </div>
          <div class="eight columns" [style.display]="editDisplay(changepass)" id="changePasswordContentDiv"
            style="padding: 5px 0; display: none; width: 49%;">
            <br />
            <span class="lblblue">
              Change Password
            </span>
            <br />
            <input type="password" name="updatePass.userCredential.plainTextOldPassword"
              [(ngModel)]="updatePass.userCredential.plainTextOldPassword" id="oldPassword" size="20" maxlength="20"
              placeholder="Old Password" />
            <input type="password" name="updatePass.userCredential.plainTextPassword"
              [(ngModel)]="updatePass.userCredential.plainTextPassword" id="newPassword" size="20" maxlength="20"
              placeholder="New Password" />
            <input type="password" name="cPassword" [(ngModel)]="cPassword" id="confirmPassword" size="20"
              maxlength="20" placeholder="Confirm Password" />
            <input type="button" (click)="updatePassword()" id="btnChangePassword" value="Change Password"
              class="buttonStyle" />
          </div>
          <div class="clr"></div>
          <!-- Change Password Ends-->
          <!-- Delete Account -->
          <div class="eight columns success" style="padding: 5px 0; display: none;" id="deleteAccountErrorDiv">
            <div class="error" id="deleteAccountErrorContentDiv"></div>
          </div>
          <div class="eight columns leftsection" id="deleteAccountContentDiv" style="padding: 5px 0; display: none;">
            <br />
            <span class="lblblue">
              Delete Account
            </span>
            <br />
            <input type="password" name="password" id="password" size="20" maxlength="20" placeholder="Password" />
            <input type="button" name="btnDeleteAccount" id="btnDeleteAccount" value="Delete Account"
              class="buttonStyle" />
          </div>
          <div class="clr"></div>
          <!-- Delete Account Ends-->
        </div>
        <div class="clr"></div>
        <br />
        <!--  membership begins -->
        <div class="title fblue bglightgray">
          <span class="drakbg"><i class="icon_about"></i>Membership</span>
        </div>
        <div class="fifteen columns create-topic-block myaccount boxpad">
          <div class="fifteen columns alpha priset">
            <div class="two columns alpha">
              <label class="label-bold protitle">Current Memership:</label>
            </div>
            <div class="two columns topbr">
              <!-- membership type here -->
              <div style="width:755px"
                *ngIf="accountdetails.planBenefit == null && accountdetails.currentMembershipType != null">
                {{accountdetails.currentMembershipType}}
              </div>
              <div style="width:755px"
                *ngIf="accountdetails.planBenefit != null && (accountdetails.planBenefit.effectivePlan == null || accountdetails.planBenefit.effectivePlan =='')">
                {{accountdetails.currentMembershipType}}
              </div>
              <div style="width:755px"
                *ngIf="accountdetails.planBenefit != null && accountdetails.planBenefit.effectivePlan != null && accountdetails.planBenefit.effectivePlan !='' ">
                *{{accountdetails.planBenefit.effectivePlan}}
              </div>
              <div class="clr"></div>
              <br />
              <div
                *ngIf="accountdetails.currentMembershipType != null && accountdetails.currentMembershipType != 'Basic' && accountdetails.currentMembershipType != 'BASIC'"
                class="three columns alpha marginbottom">
                <input type="button" name="btnCancelMem" id="btnCancelMem" (click)="cancelMem()"
                  value="Cancel Membership" class="buttonStyle" />
              </div>
            </div>
          </div>
          <div class="clr"></div>
          <br />
          <!--  membership ends-->
        </div>
      </div>
      <div class="clr"></div>
    </div>
  </div>

  <div class="clr"></div>
  <div class="ui-widget-overlay ui-front" style="display: none;" [style.display]="editDisplay(popUp)"></div>
  <div [style.display]="editDisplay(popUp)"
    class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
    tabindex="-1" role="dialog" aria-describedby="errorDialog" aria-labelledby="ui-id-1"
    style="position: fixed; height: auto; width: 300px; top: auto; left: 801px; display: none;">
    <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix">
      <span id="ui-id-1" class="ui-dialog-title">{{msgTitle}}</span>
      <button class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
        role="button" aria-disabled="false" title="close" style="display: none;">
        <span class="ui-button-icon-primary ui-icon ui-icon-closethick"></span><span class="ui-button-text"
          (click)="popUp = false;">close</span>
      </button>
    </div>
    <div class="ui-dialog-content ui-widget-content"
      style="width: auto; min-height: 33px; max-height: none; height: auto;">
      <ul>
        <li>{{msgContent}}</li>
      </ul>
    </div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
      <div class="ui-dialog-buttonset">
        <button type="button" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
          (click)="closeMessagePopup()" role="button" aria-disabled="false">
          <span class="ui-button-text">Close</span>
        </button>
      </div>
    </div>
    <div class="ui-resizable-handle ui-resizable-n" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-e" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-s" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-w" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-sw" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-ne" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-nw" style="z-index: 90;"></div>
  </div>
</div>
<div class="container" [style.display]="editDisplay(payPalpopUp)"
  style="display: none; position: fixed;top: 0;left: 0;right: 0;bottom: 0;padding: 0;z-index: 2;">
  <div class="dim4" style="height: 100%;width: 100%;background: rgba(0, 0, 0, 0.5);">
    <!--<form method="post" action="#">-->
    <div class="ten columns invite-pop-up4 marginbottom"
      style="background: #fff;width: 30%;position: absolute;left: 25%;top: 15%;padding: 10px;">
      <div class="nine columns" style="font-size: 15px;margin-bottom: 25px;">
        <h3>Are you sure you want to deactivate PayPal?</h3>
      </div>
      <div class="clear"></div>
      <div class="three columns alpha" style="width: 45%;display: inline-block;">
        <input type="submit" id="submit-deactivate-paypal" (click)="paypalDactivate()" name="submit-deactivate-paypal"
          value="Deactivate" />
      </div>
      <div class="three columns last" style="width: 45%;display: inline-block;">
        <input type="button" id="cancel4" name="cancel4" (click)="payPalpopUp = false;" value="Cancel" />
      </div>
      <div class="clear"></div>
    </div>
    <!--</form>-->
  </div>
</div>

<div class="container" [style.display]="editDisplay(deleteAccountConfirmationPopup)"
  style="display: none; position: fixed;top: 0;left: 0;right: 0;bottom: 0;padding: 0;z-index: 2;">
  <div class="dim4" style="height: 100%;width: 100%;background: rgba(0, 0, 0, 0.5);">
    <div class="ten columns invite-pop-up4 marginbottom"
      style="background: #fff;width: 30%;position: absolute;left: 32%;top: 15%;padding: 10px;text-align: center;">
      <div class="nine columns" style="font-size: 15px;margin-bottom: 25px;">
        <h3>Are you sure you want to delete your account?</h3>
      </div>
      <div class="clear"></div>
      <div class="three columns alpha" style="width: 45%;display: inline-block;">
        <input type="submit" id="submitDeleteAccount" (click)="verifyPasswordForAccountDeletion()"
          name="submitDeleteAccount" value="Yes" />
      </div>
      <div class="three columns last" style="width: 45%;display: inline-block;">
        <input type="button" id="cancelDeleteAccount" name="cancelDeleteAccount"
          (click)="deleteAccountConfirmationPopup = false;" value="Cancel" />
      </div>
      <div class="clear"></div>
    </div>
  </div>
</div>

<div class="container" [style.display]="editDisplay(logoutConfirmationPopup)"
  style="display: none; position: fixed;top: 0;left: 0;right: 0;bottom: 0;padding: 0;z-index: 2;">
  <div class="dim4" style="height: 100%;width: 100%;background: rgba(0, 0, 0, 0.5);">
    <div class="ten columns invite-pop-up4 marginbottom"
      style="background: #fff;width: 30%;position: absolute;left: 35%;top: 15%;padding: 10px;text-align: center;">
      <div class="nine columns" style="font-size: 15px;margin-bottom: 25px;">
        <h4>Account Deleted Successfully. You will now be logged off.</h4>
      </div>
      <div class="clear"></div>
      <div class="three columns alpha" style="width: 45%;display: inline-block;">
        <input type="Submit" id="submitLogout" (click)="submitLogoutSuccess()" name="submitLogout" value="Ok" />
      </div>
      <div class="clear"></div>
    </div>
  </div>
</div>

<form name="deleteAccountForm" method="post" (ngSubmit)="delAcForm.form.valid && deleteAccount(delAcForm);"
  #delAcForm="ngForm" novalidate>
  <div class="dim" style="display: none;" [style.display]="editDisplay(deleteAccountPasswordVerificationPopup)">
    <div class="ten columns invite-pop-up marginbottom boxpad" style="display: none;"
      [style.display]="editDisplay(deleteAccountPasswordVerificationPopup)">
      <div class="popupclose" (click)="deleteAccountPasswordVerificationPopup = false;delAcForm.resetForm();">
        <img alt="Close" src="assets/images/close-button.png" id="close" />
      </div>
      <div class="eight columns" id="CommentsDiv">
        <input type="password" [(ngModel)]="deleteAccountVerificationPassword" name="deleteAccountVerificationPassword"
          id="recommendationComments" required placeholder="Password" #delAccntVerifPwd="ngModel"
          style="margin-bottom:0px" />
        <div *ngIf="delAcForm.submitted && !delAccntVerifPwd.valid" class="errorDiv"
          style="left: auto;width: 100%;padding:0px;background:#cc0a0a;color:#f6f7f9">
          Please enter the password
        </div>
      </div>
      <div class="three columns" id="buttonDiv">
        <input type="submit" value="Delete" name="deleteAccount" id="deleteAccount" class="pageLink1"
          style="margin-top:10px" />
      </div>
      <br />
      <div class="three columns" id="buttonDiv">
        <input type="button" value="Cancel"
          (click)="deleteAccountPasswordVerificationPopup = false;delAcForm.resetForm();" name="deleteAccountCancel"
          id="deleteAccountCancel" class="pageLink1" />
      </div>
    </div>
  </div>
</form>

<div class="container" [style.display]="editDisplay(basicPopup)"
  style="display: none; position: fixed;top: 0;left: 0;right: 0;bottom: 0;padding: 0;z-index: 2;">
  <div class="dim4" style="height: 100%;width: 100%;background: rgba(0, 0, 0, 0.5);">
    <!--<form method="post" action="#">-->
    <div class="ten columns invite-pop-up4 marginbottom"
      style="background: #fff;width: 50%;position: absolute;left: 25%;top: 15%;padding: 10px;">
      <div class="nine columns" style="font-size: 15px;margin-bottom: 25px;">
        <h3>
          Your current membership "{{accountdetails.currentMembershipType}}"
          will remain effective till {{billingCycleEndDate | amDateFormat:'ll'}}
          and will not be renewed for next billing period." Are you sure you
          want to Cancel?
        </h3>
      </div>
      <div class="clear"></div>
      <div class="three columns alpha" style="width: 25%;display: inline-block;">
        <input type="submit" id="basicMem" (click)="BacisSelect()" name="basicMem" value="Yes" />
      </div>
      <div class="three columns last" style="width: 25%;display: inline-block;">
        <input type="button" id="cancel4" name="cancel4" (click)="basicPopup = false;" value="Cancel" />
      </div>
      <div class="clear"></div>
    </div>
    <!--</form>-->
  </div>
</div>

<div class="deleteAccOverlay" [style.display]="editDisplay(deleteAccShow)"></div>
<div class="deleteAccWrap" [style.display]="editDisplay(deleteAccShow)">
  <div class="deleteAccClose">
    <h3>Delete Account OTP</h3>
  </div>
  <div class="deleteAccSubHead">
    <p>
      We have sent you a 6 digit OTP to your registered email id.
    </p>
    <p>
      You have only 3 minutes to enter a valid OTP to delete your account.
    </p>
  </div>
  <form name="form" method="post" (ngSubmit)="df.form.valid && submitOTP()" #df="ngForm" novalidate>
    <div class="deleteAcc">
      <label>Enter OTP*</label>
      <input type="text" [(ngModel)]="OTP" name="OTP" #Otp="ngModel" required class="gwt-TextBox" maxlength="200"
        style="margin-bottom: 0px;" />
      <div *ngIf="df.submitted && !Otp.valid" class="errorDiv" style="color: #f00">
        OTP is required
      </div>
    </div>
    <div class="deleteAccAction">
      <input type="submit" class="buttonStyle2" value="Submit" style="width: 49%; margin-top: 10px;" />
      <button type="button" class="buttonStyle2" (click)="deleteAccClose()"
        style="width: 49%; margin-top: 10px;">Cancel</button>
    </div>
  </form>
</div>

<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>

<uvsity-modal-popup [isModalDialogToBeDisplayed]="showErrorModalDialog" [messageTitle]="showErrorMessageTitle"
  [messages]="showErrorMessages" (uvsityModalPopupClosed)="closeErrorPopup()"
  [popupWidthPercentage]="showErrorPopupWidthInPercentage" [pleaseWait]="pleaseWait" [isSignUp]="isSignUp"
  [isValidationError]="isValidationError">
</uvsity-modal-popup>