<div class="container">
    <!-- BEGIN Header -->

    <!-- END Header -->

    <div id="content-wrapper3">
        <div class="container">
            <div class="clear"></div>

            <div class="content_area pageblock">

                <div class="login-box" style="">

                    <div class="boxs boxpad" style="width: 330px !important;">
                        <div class="logo"></div>
                        <div class="">

                            <h3>
                        You need to validate your Uvsity Account via the Registration Verification Mail sent to your Personal Email Id. Please Check Your Inbox, or <strong>Re-Send Below.</strong>
                    </h3>

                        </div>

                        <form method="post" id="frmEmailVerify" name="frmEmailVerify">
                            <div class="four columns alpha posres">
                              <br>
                            </div>
                            <input tabindex="3" type="button" (click)="backToLogin()" id="btnLogin" value="Login" class="buttonStyle">
                            <div class="four columns alpha posres">
                              <br>
                            </div>
                            <input tabindex="3" type="button" (click)="resendEmail()" id="btnResend" value="Re-Send" class="buttonStyle">

                            <div class="clr"></div>
                        </form>
                    </div>

                </div>
            </div>

        </div>
    </div>

    <!-- BEGIN Footer -->

    <!-- END Footer -->
</div>
<div [style.display]="editDisplay(popUp)" class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable" tabindex="-1" role="dialog" aria-describedby="errorDialog" aria-labelledby="ui-id-1" style="position: fixed; height: auto; width: 300px; top: auto; left: 801px; display: none;"><div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"><span id="ui-id-1" class="ui-dialog-title">{{msgTitle}}</span><button class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close" role="button" aria-disabled="false" title="close" style="display: none;"><span class="ui-button-icon-primary ui-icon ui-icon-closethick"></span><span class="ui-button-text" (click)="popUp = false;">close</span></button></div><div  class="ui-dialog-content ui-widget-content" style="width: auto; min-height: 33px; max-height: none; height: auto;"><ul><li>{{msgContent}}</li></ul></div><div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix"><div class="ui-dialog-buttonset"><button type="button" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" (click)="popUp = false;" role="button" aria-disabled="false"><span class="ui-button-text">Close</span></button></div></div><div class="ui-resizable-handle ui-resizable-n" style="z-index: 90;"></div><div class="ui-resizable-handle ui-resizable-e" style="z-index: 90;"></div><div class="ui-resizable-handle ui-resizable-s" style="z-index: 90;"></div><div class="ui-resizable-handle ui-resizable-w" style="z-index: 90;"></div><div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style="z-index: 90;"></div><div class="ui-resizable-handle ui-resizable-sw" style="z-index: 90;"></div><div class="ui-resizable-handle ui-resizable-ne" style="z-index: 90;"></div><div class="ui-resizable-handle ui-resizable-nw" style="z-index: 90;"></div></div>
