<div class="poopupWrapper" (keyup.esc)="handleEscape()" *ngIf="isValidationError == true" #validationErrorButton
  [style.width]="popupWidthPercentage" [style.display]="showModalDialog()" (resize)="focusCloseButton()">
  <div class="poopupWrapHeader">
    <h2 class="poopupH2red">{{messageTitle}}</h2>
    <span class="poopupClose" (click)="handleModalPopupClose()">x</span>
  </div>
  <div class="poopupWrapBody">
    <div class="poopupUlWrap">
      <ul class="poopupUl">
        <li class="poopupLi" *ngFor="let message of messages">{{message}}</li>
      </ul>
    </div>
  </div>
  <div class="poopupWrapFooter"></div>
</div>

<div class="poopupWrapper" [class.pleaseWaitBg]="pleaseWait" (keyup.esc)="handleEscape()" *ngIf="isValidationError == false"
  [style.width]="popupWidthPercentage" [style.display]="showModalDialog()" (resize)="focusCloseButton()">
  <div class="poopupWrapHeader" *ngIf="!pleaseWait">
    <h2 class="poopupH2Blue">{{messageTitle}}</h2>
    <span class="poopupClose" (click)="handleModalPopupClose()" #nonValidationErrorButton>x</span>
  </div>
  <div class="poopupWrapBody">
    <div class="poopupUlWrap">
      <ul class="poopupUl" [class.pleaseWaitMargin]="pleaseWait">
        <li class="poopupLi" [class.isSignUpStyle]="isSignUp" *ngFor="let message of messages">{{message}}</li>
      </ul>
    </div>
  </div>
  <div class="poopupWrapFooter"></div>
</div>

<!-- <div id="valDiv" *ngIf = "isValidationError == true" #validationErrorButton style="position: fixed; height: auto; top: 20%; left: 30%; display: none;"
  [style.width]="popupWidthPercentage" [style.display]="showModalDialog()" (resize)="focusCloseButton()"
  class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
  tabindex="-1" role="dialog" aria-describedby="errorDialog" aria-labelledby="ui-id-1" (keyup.esc) = "handleEscape()">

  <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix">
    <span id="ui-id-1" class="ui-dialog-title">{{messageTitle}}</span>
    <button  class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
      role="button" aria-disabled="false" title="close" style="display: none;">
      <span class="ui-button-icon-primary ui-icon ui-icon-closethick"></span>
      <span class="ui-button-text" (click)="handleModalPopupClose()">Close</span>
    </button>
  </div>

  <div class="ui-dialog-content ui-widget-content"
    style="width: auto; min-height: 33px; max-height: none; height: auto;">
    <ul>
      <li *ngFor="let message of messages">{{message}}</li>
    </ul>
  </div>

  <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
    <div class="ui-dialog-buttonset">
      <button type="button" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" (click)="handleModalPopupClose()"
        role="button" aria-disabled="false">
        <span class="ui-button-text">Close</span>
      </button>
    </div>
  </div>

  <div class="ui-resizable-handle ui-resizable-n" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-e" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-s" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-w" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-sw" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-ne" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-nw" style="z-index: 90;"></div>

</div>

<div *ngIf = "isValidationError == false" style="position: fixed; height: 80%; top: 20%; left: 30%; display: none;" [style.width]="popupWidthPercentage" [style.display]="showModalDialog()"
  class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
  tabindex="-1" role="dialog" aria-describedby="errorDialog" aria-labelledby="ui-id-1" (keyup.enter) = "handleEnter()">

  <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix">
    <span id="ui-id-1" class="ui-dialog-title">{{messageTitle}}</span>
    <button #nonValidationErrorButton class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
      role="button" aria-disabled="false" title="close" style="display: none;">
      <span class="ui-button-icon-primary ui-icon ui-icon-closethick"></span>
      <span class="ui-button-text" (click)="handleModalPopupClose()">Close</span>
    </button>
  </div>

  <div class="ui-dialog-content ui-widget-content"
    style="width: auto; min-height: 33px; max-height: none; height: 80%;overflow:auto;">
    <ul>
      <li *ngFor="let message of messages">{{message}}</li>
    </ul>
  </div>

  <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
    <div class="ui-dialog-buttonset">
      <button type="button" class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" (click)="handleModalPopupClose()"
        role="button" aria-disabled="false">
        <span class="ui-button-text">Close</span>
      </button>
    </div>
  </div>

  <div class="ui-resizable-handle ui-resizable-n" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-e" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-s" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-w" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-sw" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-ne" style="z-index: 90;"></div>
  <div class="ui-resizable-handle ui-resizable-nw" style="z-index: 90;"></div>

</div> -->