<header>
	<form name="frmMenu" method="post">
		<input type="hidden" name="viewOrEdit" id="viewOrEdit">
		<input type="hidden" name="startIndex" id="startIndex">

		<ul id="gn-menu" class="gn-menu-main supformatter" #leftMenuElement>
			<li class="gn-trigger"><a class="gn-icon gn-icon-menu"
					style="visibility: hidden; position: absolute; top: -100px; left: -100px;"><span></span></a>
				<nav class="gn-menu-wrapper gn-open-part" (mouseover)="navFlag = 1;" (mouseleave)="navFlag = 0;"
					[ngClass]="{'gn-open-all': navFlag === 1}">
					<div class="gn-scroller">
						<ul class="gn-menu">
							<li class="active"><a class="gn-icon gn-icon-home" (click)="gotoHome()"
									href="javascript:void(0);">Home</a> </li>
							<li class="has_submenu" [ngClass]="{'open': showSubmenuProfile}" id="profilemenuID"><a
									(click)="showSubmenuProfile = !showSubmenuProfile;"
									class="gn-icon gn-icon-profile">My Profile</a>
								<ul class="gn-submenu" id="profilechildmenuID"
									[style.display]="getDisplay(showSubmenuProfile)">
									<li><a class="gn-icon gn-icon-subicon" href="javascript:void(0);"
											(click)="gotoViewProfile()">View Profile</a> </li>
									<li><a class="gn-icon gn-icon-subicon" href="javascript:void(0);"
											(click)="gotoEditProfile()">Edit Profile</a> </li>
								</ul>
							</li>
							<li class="has_submenu" [ngClass]="{'open': showSubmenuNetwork}" id="networkmenuID"><a
									(click)="showSubmenuNetwork = !showSubmenuNetwork;"
									class="gn-icon gn-icon-network">Network</a>
								<ul class="gn-submenu" id="networkchildmenuID"
									[style.display]="getDisplay(showSubmenuNetwork)">
									<li><a class="gn-icon gn-icon-subicon" href="javascript:void(0);"
											(click)="gotoMyConnections()">My Connections</a> </li>
									<li><a class="gn-icon gn-icon-subicon" href="javascript:void(0);"
											(click)="gotoAddConnections()">Add Connections</a> </li>
								</ul>
							</li>
							<li class="has_submenu"> <a class="gn-icon gn-icon-inbox" href="javascript:void(0);"
									(click)="gotoInbox()">
									Inbox
									<div *ngIf="isTotalNoOfNewNotifications == true"><sup
											id="totalNoOfNewNotificationsLeftMenuGlobal">{{totalNoOfNewNotifications}}</sup>
									</div>
								</a> </li>
						</ul>
					</div>
				</nav>
			</li>
		</ul>
	</form>

	<div class="header">
		<div class="logo">
			<a href="javascript:void(0)" [routerLink]="['/']" class="pull-left mainlogo" style="font-size: 30px;
  font-weight: 600;
  font-family: inherit;">uvsity<sub>.com</sub> </a>
		</div>
		<div class="pageblock" style="position: relative;">
			<div class="panel-multicomplete-results multicomplete-668" *ngIf="(searchResult$ | async) as searchdata;"
				[style.display]="getDisplay(topsearch)" style="display :none; position: absolute; top: 54px;">
				<div class="results">
					<div class="group Course" *ngIf="(searchdata.ESUserDetails)">
						<div class="group-title">User</div>
						<div class="group-items" *ngFor="let usersearch of searchdata.ESUserDetails">
							<a class="result" (click)="topsearch = false;" [routerLink]="['profile']"
								[queryParams]="{ user: usersearch.entityId }">
								<div class="pointer">
									<div class="image">
										<!-- <img class="greyBorder profileImage" src="{{usersearch.imageURL}}"> -->
										<img *ngIf="usersearch.imageURL != null && !usersearch.imageURL.includes('profilebig.png')"
											class="greyBorder profileImage" alt="" src="{{usersearch.imageURL}}">

										<ngx-avatar
											*ngIf="usersearch.imageURL == null || usersearch.imageURL.includes('profilebig.png')"
											name="{{usersearch.content}}" size="40"></ngx-avatar>
									</div>
									<div class="blue">
										<a href="#"></a>{{usersearch.content}}</div>
								</div>
							</a>
						</div>
						<div class="clearfix"></div>
					</div>
					<div class="group Course" *ngIf="(searchdata.ESCourseDetails)">
						<div class="group-title">Session</div>
						<div class="group-items" *ngFor="let coursesearch of searchdata.ESCourseDetails">
							<a class="result" (click)="topsearch = false;" [routerLink]="['viewsession']"
								[queryParams]="{ sessionId: coursesearch.entityId }">
								<div class="pointer">
									<div class="image">
										<!-- <img class="greyBorder profileImage" src="{{coursesearch.imageURL}}"> -->

										<img *ngIf="coursesearch.imageURL != null && !coursesearch.imageURL.includes('profilebig.png')"
											class="greyBorder profileImage" alt="" src="{{coursesearch.imageURL}}">

										<ngx-avatar
											*ngIf="coursesearch.imageURL == null || coursesearch.imageURL.includes('profilebig.png')"
											name="{{coursesearch.content}}"></ngx-avatar>
									</div>
									<div class="blue">
										{{coursesearch.content}}</div>
								</div>
							</a>
						</div>
						<div class="clearfix"></div>
					</div>

					<div class="group Course" *ngIf="(searchdata.ESTopic)">
						<div class="group-title">Topics</div>
						<div class="group-items" *ngFor="let topicssearch of searchdata.ESTopic">
							<div class="result" (click)="topsearch = false;" [routerLink]="['viewtopic']"
								[queryParams]="{ topic: topicssearch.entityId }">
								<div class="pointer">
									<div class="image">
										<!-- <img class="greyBorder profileImage" src="{{topicssearch.imageURL}}"> -->
										<img *ngIf="topicssearch.imageURL != null && !topicssearch.imageURL.includes('profilebig.png')"
											class="greyBorder profileImage" alt="" src="{{topicssearch.imageURL}}">

										<ngx-avatar
											*ngIf="topicssearch.imageURL == null || topicssearch.imageURL.includes('profilebig.png')"
											name="{{topicssearch.content}}"></ngx-avatar>
									</div>
									<div class="blue">
										<a href="javascript:void(0)"></a>{{topicssearch.content}}</div>
								</div>
							</div>
						</div>
						<div class="clearfix"></div>
					</div>

				</div>
			</div>
			<div class="searchbox">
				<form action="post" (ngSubmit)="f.form.valid && searchClick(searchText) && topsearch = false"
					#f="ngForm" method="get">
					<input type="text" name="searchUser" id="input-multicomplete" [(ngModel)]="searchText" required
						(blur)="searchBlur()" (keyup)="topSearch();" class="form-control"
						placeholder="Firstname, Lastname, Session, Topic etc..." autocomplete="off">
					<a href="javascript:void(0);" onclick="" class="btn-search"></a>
				</form>
			</div>

			<ul id="filter2" class="filters usermenu">
				<li class="parent">

					<a href="javascript:void(0)" class="gn-icon ngx-avatar-welcome fa fa-user">
						<div class="Welcomeher">
							<img *ngIf="user.profilePicName != null && !user.profilePicName.includes('profilebig.png')"
								width="25px" class="greyBorder profileImage" src="{{user.profilePicName}}">
							<ngx-avatar
								*ngIf="user.profilePicName == null || user.profilePicName.includes('profilebig.png')"
								name="{{user.firstName}} {{user.lastName}}" size="25"></ngx-avatar>
						</div>
						<!-- <label class="headerwel">{{user.firstName}} {{user.lastName}}</label> -->
					</a>
					<ul class="children">
						<li *ngIf="user.adminUserInd == 1"> <a href="javascript:void(0)"
								[routerLink]="['./courseadmin']">
								Session Admin
							</a> </li>
						<li *ngIf="user.adminUserInd == 1"> <a href="javascript:void(0)"
								[routerLink]="['./adminrefund']">
								Admin Refund
							</a> </li>
						<li *ngIf="user.adminUserInd == 1"> <a href="javascript:void(0)" [routerLink]="['./payout']">
								Payout
							</a> </li>
						<li *ngIf="user.adminUserInd == 1"> <a href="javascript:void(0)">
								Inquire Payout Status
							</a> </li>
						<li *ngIf="user.adminUserInd == 1"> <a href="javascript:void(0)">
								Make Adhoc Payments
							</a> </li>
						<li *ngIf="user.adminUserInd == 1"> <a href="javascript:void(0)"
								[routerLink]="['./billingplans']">
								Billing Plans Admin
							</a> </li>
						<!-- <li> <a href="javascript:void(0)" [routerLink]="['./upgrade']">
								Upgrade
							</a> </li> -->
						<li> <a href="javascript:void(0)" [routerLink]="['./myearning']">
								My Earnings
							</a> </li>
						<li> <a href="javascript:void(0)" [routerLink]="['./myaccount']">
								My Account
							</a> </li>
						<li> <a href="javascript:void(0);" id="logoutBtn" (click)="logout()">
								Logout
							</a> </li>
					</ul>
				</li>
			</ul>
			<div class="notefication">
				<ul id="filter2" class="filters noteficationnemu">
					<li class="parent">

						<a href="javascript:void(0)">
							<img src="../../assets/images/notification.png" width="25x">
							<div *ngIf="noOfUnreadNotifications > 0">
								<sup id="totalNoOfNewNotificationsLeftMenuGlobal">{{noOfUnreadNotifications}}</sup>
							</div>
						</a>

						<ul *ngIf="notificationData != null" class="children menunotification">
							<!-- Invitations -->
							<li
								*ngIf="notificationData != null && notificationData.invitations!= null && notificationData.invitations.length > 0">
								<i><span class="a-btn-icon-right"></span></i> Invitations
							</li>
							<li *ngFor="let invitation of notificationData.invitations" class="notimassage">
								<div *ngIf="invitation.event == 'INVITATION'">
									<div class="img hoverPointer" [routerLink]="['../dashboard/profile']"
										[queryParams]="{ user: invitation.displayUserDetailsId }">
										<ngx-avatar
											*ngIf="invitation.profilePicURL == null || invitation.profilePicURL.includes('profilebig.png')"
											name="{{invitation.firstName}} {{invitation.lastName}}" size="40">
										</ngx-avatar>
										<img *ngIf="invitation.profilePicURL != null && !invitation.profilePicURL.includes('profilebig.png')"
											border="0" class="greyBorder profileImage" height="50" onclick="" width="50"
											src="{{invitation.profilePicURL}}">
									</div>
									<div class="notiname">
										<p class="hoverPointer" [routerLink]="['../dashboard/profile']"
											[queryParams]="{ user: invitation.displayUserDetailsId }">
											<b>{{invitation.firstName}} {{invitation.lastName}}</b></p>
										<p *ngIf="invitation.isUserSecondaryDataCapturedForUser == 'T'">
											{{invitation.userType}}, {{invitation.userEducationalInstitution}},
											{{invitation.city}}</p>
									</div>
									<div class="rows">
										<div class="naaccept">
											<a class="anotiaccept" href="javascript: void(0)" (click)="
									  acceptconReq(notificationData.notificationReceiverUserDetailsId, 'INVITATION', invitation.id)">Accept</a>
										</div>
										<div class="notignore">
											<a class="anotiacceptign" href="javascript: void(0)"
												(click)="
									  ignoreNotification(notificationData.notificationReceiverUserDetailsId, 'INVITATION', invitation.id, 'INVITATION')">Ignore</a>
										</div>
									</div>
								</div>

								<div *ngIf="invitation.event == 'COURSE_JOIN_INVITATION'">
									<div class="img hoverPointer" [routerLink]="['../dashboard/profile']"
										[queryParams]="{ user: invitation.displayUserDetailsId }">
										<ngx-avatar
											*ngIf="invitation.profilePicURL == null || invitation.profilePicURL.includes('profilebig.png')"
											name="{{invitation.firstName}} {{invitation.lastName}}" size="40">
										</ngx-avatar>
										<img *ngIf="invitation.profilePicURL != null && !invitation.profilePicURL.includes('profilebig.png')"
											border="0" class="greyBorder profileImage" height="50" onclick="" width="50"
											src="{{invitation.profilePicURL}}">
									</div>
									<div class="notiname">
										<p class="hoverPointer" [routerLink]="['../dashboard/profile']"
											[queryParams]="{ user: invitation.displayUserDetailsId }">
											<b>{{invitation.firstName}} {{invitation.lastName}}</b></p>
										<p>invited you to join his session</p>
										<p>{{invitation.courseName}}</p>
									</div>
									<div class="rows">
										<div class="notignore">
											<a class="anotiacceptign" href="javascript: void(0)"
												(click)="
									  ignoreNotification(notificationData.notificationReceiverUserDetailsId, 'COURSE_JOIN_INVITATION', invitation.id, 'INVITATION')">Ignore</a>
										</div>
									</div>
								</div>
							</li>

							<!-- Requests -->
							<li
								*ngIf="notificationData != null && notificationData.requests!= null && notificationData.requests.length > 0">
								<i><span class="a-btn-icon-right"></span></i> Requests
							</li>
							<li *ngFor="let request of notificationData.requests" class="notimassage">
								<div *ngIf="request.event == 'RECOMMENDATION_REQUEST'">
									<div class="img hoverPointer" [routerLink]="['../dashboard/profile']"
										[queryParams]="{ user: request.displayUserDetailsId }">
										<ngx-avatar
											*ngIf="request.profilePicURL == null || request.profilePicURL.includes('profilebig.png')"
											name="{{request.firstName}} {{request.lastName}}" size="40"></ngx-avatar>
										<img *ngIf="request.profilePicURL != null && !request.profilePicURL.includes('profilebig.png')"
											border="0" class="greyBorder profileImage" height="50" onclick="" width="50"
											src="{{request.profilePicURL}}">
									</div>
									<div class="notiname">
										<p class="hoverPointer" [routerLink]="['../dashboard/profile']"
											[queryParams]="{ user: request.displayUserDetailsId }">
											<b>{{request.firstName}} {{request.lastName}}</b></p>
										<p>requested you for recommendation</p>
									</div>
									<div class="rows">
										<div class="naaccept">
											<a class="anotiaccept" href="javascript: void(0)" (click)="
									  showWriteRecommendationDialog(notificationData.notificationReceiverUserDetailsId, request.id)">Write
												Recommendation</a>
										</div>
										<div class="notignore">
											<a class="anotiacceptign" href="javascript: void(0)"
												(click)="
									  ignoreNotification(notificationData.notificationReceiverUserDetailsId, 'RECOMMENDATION_REQUEST', request.id, 'REQUEST')">Ignore</a>
										</div>
									</div>
								</div>

								<div *ngIf="request.event == 'RATING_REQUEST'">
									<div class="img hoverPointer" [routerLink]=" ['../dashboard/profile']"
										[queryParams]="{ user: request.displayUserDetailsId }">
										<ngx-avatar
											*ngIf="request.profilePicURL == null || request.profilePicURL.includes('profilebig.png')"
											name="{{request.firstName}} {{request.lastName}}" size="40"></ngx-avatar>
										<img *ngIf="request.profilePicURL != null && !request.profilePicURL.includes('profilebig.png')"
											border="0" class="greyBorder profileImage" height="50" onclick="" width="50"
											src="{{request.profilePicURL}}">
									</div>
									<div class="notiname">
										<p class="hoverPointer" [routerLink]=" ['../dashboard/profile']"
											[queryParams]="{ user: request.displayUserDetailsId }">
											<b>{{request.firstName}} {{request.lastName}}</b></p>
										<p>requested you for rating</p>
									</div>
									<div class="rows">
										<div class="naaccept">
											<a class="anotiaccept" href="javascript: void(0)" (click)="
									  showSendRatingDialog(notificationData.notificationReceiverUserDetailsId, request.id)">Rate this user</a>
										</div>
										<div class="notignore">
											<a class="anotiacceptign" href="javascript: void(0)"
												(click)="
									  ignoreNotification(notificationData.notificationReceiverUserDetailsId, 'RATING_REQUEST', request.id, 'REQUEST')">Ignore</a>
										</div>
									</div>
								</div>

								<div *ngIf="request.event == 'CREATE_COURSE_REQUEST'">
									<div class="img hoverPointer" [routerLink]=" ['../dashboard/profile']"
										[queryParams]="{ user: request.displayUserDetailsId }">
										<ngx-avatar
											*ngIf="request.profilePicURL == null || request.profilePicURL.includes('profilebig.png')"
											name="{{request.firstName}} {{request.lastName}}" size="40"></ngx-avatar>
										<img *ngIf="request.profilePicURL != null && !request.profilePicURL.includes('profilebig.png')"
											border="0" class="greyBorder profileImage" height="50" onclick="" width="50"
											src="{{request.profilePicURL}}">
									</div>
									<div class="notiname">
										<p class="hoverPointer" [routerLink]=" ['../dashboard/profile']"
											[queryParams]="{ user: request.displayUserDetailsId }">
											<b>{{request.firstName}} {{request.lastName}}</b></p>
										<p>requested you to conduct a session on your skillset:
											{{request.courseSubject}}
										</p>
									</div>
									<div class="rows">
										<div class="naaccept">
											<a class="anotiaccept" href="javascript: void(0)"
												(click)="
									  performCreateCourse(notificationData.notificationReceiverUserDetailsId, 'CREATE_COURSE_REQUEST', request.id)">Create Session</a>
										</div>
									</div>
								</div>

								<div *ngIf="request.event == 'MEETING_REQUEST'">

									<div class="img hoverPointer" [routerLink]="['../dashboard/profile']"
										[queryParams]="{ user: request.displayUserDetailsId }">
										<ngx-avatar
											*ngIf="request.profilePicURL == null || request.profilePicURL.includes('profilebig.png')"
											name="{{request.firstName}} {{request.lastName}}" size="40"></ngx-avatar>
										<img *ngIf="request.profilePicURL != null && !request.profilePicURL.includes('profilebig.png')"
											border="0" class="greyBorder profileImage" height="50" onclick="" width="50"
											src="{{request.profilePicURL}}">
									</div>
									<div class="notiname">
										<p class="hoverPointer" [routerLink]="['../dashboard/profile']"
											[queryParams]="{ user: request.displayUserDetailsId }">
											<b>{{request.firstName}} {{request.lastName}}</b> requested you for meeting
										</p>
										<p>{{request.meetingDateTime}}</p>
									</div>
									<div class="rows">
										<div class="naaccept">
											<a class="anotiaccept" href="javascript: void(0)"
												(click)="displayMyCalendar(request.id)"
												style="background: #0099ff !important;">View Calendar</a></div>
										<div class="naaccept">
											<a class="anotiaccept" href="javascript: void(0)"
												(click)="acceptMeetingReq(notificationData.notificationReceiverUserDetailsId, 'MEETING_REQUEST', request.id)">Accept</a>
										</div>
										<div class="notignore">
											<a class="anotiacceptign" href="javascript: void(0)"
												(click)="rejectMeetingReq(notificationData.notificationReceiverUserDetailsId, 'MEETING_REQUEST', request.id)">Reject</a>
										</div>
									</div>
								</div>
							</li>


							<!-- Messages -->
							<li
								*ngIf="notificationData != null && notificationData.messages!= null && notificationData.messages.length > 0">
								<i><span class="a-btn-icon-right"></span></i> New Messages
							</li>
							<li *ngFor="let message of notificationData.messages" class="notimassage">
								<div *ngIf="message.event == 'TEXT_MESSAGE'">
									<div class="img hoverPointer" [routerLink]="['../dashboard/profile']"
										[queryParams]="{ user: message.displayUserDetailsId }">
										<ngx-avatar
											*ngIf="message.profilePicURL == null || message.profilePicURL.includes('profilebig.png')"
											name="{{message.firstName}} {{message.lastName}}" size="40"></ngx-avatar>
										<img *ngIf="message.profilePicURL != null && !message.profilePicURL.includes('profilebig.png')"
											border="0" class="greyBorder profileImage" height="50" onclick="" width="50"
											src="{{message.profilePicURL}}">
									</div>
									<div class="notiname">
										<p class="hoverPointer" [routerLink]="['../dashboard/profile']"
											[queryParams]="{ user: message.displayUserDetailsId }">
											<b>{{message.firstName}} {{message.lastName}}</b></p>
										<p>sent you message
											<span>"</span>{{message.textMessageShort}}<span>......"</span></p>
									</div>
									<div class="rows">
										<div class="naaccept">
											<a class="anotiaccept" href="javascript: void(0)" (click)="
								  performViewMessage(notificationData.notificationReceiverUserDetailsId, 'TEXT_MESSAGE', message.id)">View
												Message</a>
										</div>
										<div class="notignore">
											<a class="anotiacceptign" href="javascript: void(0)"
												(click)="
								  ignoreNotification(notificationData.notificationReceiverUserDetailsId, 'TEXT_MESSAGE', message.id, 'MESSAGE')">Ignore</a>
										</div>
									</div>
								</div>

								<div *ngIf="message.event == 'RECOMMENDATION_MESSAGE'">
									<div class="img hoverPointer" [routerLink]="['../dashbaord/profile']"
										[queryParams]="{ user: message.displayUserDetailsId }">
										<ngx-avatar
											*ngIf="message.profilePicURL == null || message.profilePicURL.includes('profilebig.png')"
											name="{{message.firstName}} {{message.lastName}}" size="40"></ngx-avatar>
										<img *ngIf="message.profilePicURL != null && !message.profilePicURL.includes('profilebig.png')"
											border="0" class="greyBorder profileImage" height="50" onclick="" width="50"
											src="{{message.profilePicURL}}">
									</div>
									<div class="notiname">
										<p class="hoverPointer" [routerLink]="['../dashbaord/profile']"
											[queryParams]="{ user: message.displayUserDetailsId }">
											<b>{{message.firstName}} {{message.lastName}}</b></p>
										<p>wrote recommendation for you
											<span>"</span>{{message.textMessageShort}}<span>......"</span></p>
									</div>
									<div class="rows">
										<div class="naaccept">
											<a class="anotiaccept" href="javascript: void(0)"
												(click)="
								  performViewRecommendation(notificationData.notificationReceiverUserDetailsId, 'RECOMMENDATION_MESSAGE', message.id)">View Recommendation</a>
										</div>
										<div class="naaccept">
											<a class="anotiaccept" href="javascript: void(0)">Accept</a>
										</div>
										<div class="notignore">
											<a class="anotiacceptign" href="javascript: void(0)"
												(click)="
								  ignoreNotification(notificationData.notificationReceiverUserDetailsId, 'RECOMMENDATION_MESSAGE', message.id, 'MESSAGE')">Ignore</a>
										</div>
									</div>
								</div>
							</li>

							<li
								*ngIf="notificationData != null && notificationData.intimations!= null && notificationData.intimations.length > 0">
								<i><span class="a-btn-icon-right"></span></i> Important
							</li>
							<li *ngFor="let intimation of notificationData.intimations" class="notimassage">
								<div *ngIf="intimation.event == 'COURSE_REVIEW_ADDED'">
									<div class="img hoverPointer" [routerLink]="['../dashboard/profile']"
										[queryParams]="{ user: intimation.displayUserDetailsId }">
										<ngx-avatar
											*ngIf="intimation.profilePicURL == null || intimation.profilePicURL.includes('profilebig.png')"
											name="{{intimation.firstName}} {{intimation.lastName}}" size="40">
										</ngx-avatar>
										<img *ngIf="intimation.profilePicURL != null && !intimation.profilePicURL.includes('profilebig.png')"
											border="0" class="greyBorder profileImage" height="50" onclick="" width="50"
											src="{{intimation.profilePicURL}}">
									</div>
									<div class="notiname">
										<p><b class="hoverPointer" [routerLink]="['../dashboard/profile']"
												[queryParams]="{ user: intimation.displayUserDetailsId }">{{intimation.firstName}}
												{{intimation.lastName}}</b> reviewed your session
											<span
												(click)="performViewCourse(intimation.id)"><i>{{intimation.courseName}}</i></span>
										</p>
									</div>
									<div class="rows">
										<div class="naaccept">
											<a class="anotiaccept" href="javascript:;"
												(click)="
									  performViewCourseReviews(notificationData.notificationReceiverUserDetailsId, 'COURSE_REVIEW_ADDED', intimation.id)">View All Reviews</a>
										</div>
										<div class="notignore">
											<a class="anotiacceptign" href="javascript: void(0)"
												(click)="
									  ignoreNotification(notificationData.notificationReceiverUserDetailsId, 'COURSE_REVIEW_ADDED', intimation.id, 'INTIMATION')">Ignore</a>
										</div>
									</div>
								</div>

								<div *ngIf="intimation.event == 'NEW_COURSE_ATTENDEE'">
									<div class="img hoverPointer" [routerLink]="['../dashboard/profile']"
										[queryParams]="{ user: intimation.displayUserDetailsId }">
										<ngx-avatar
											*ngIf="intimation.profilePicURL == null || intimation.profilePicURL.includes('profilebig.png')"
											name="{{intimation.firstName}} {{intimation.lastName}}" size="40">
										</ngx-avatar>
										<img *ngIf="intimation.profilePicURL != null && !intimation.profilePicURL.includes('profilebig.png')"
											border="0" class="greyBorder profileImage" height="50" onclick="" width="50"
											src="{{intimation.profilePicURL}}">
									</div>
									<div class="notiname">
										<p><b class="hoverPointer" [routerLink]="['../dashboard/profile']"
												[queryParams]="{ user: intimation.displayUserDetailsId }">{{intimation.firstName}}
												{{intimation.lastName}}</b> has registered for
											your session
											<span
												(click)="performViewCourse(intimation.id)"><i>{{intimation.courseName}}</i></span>
										</p>
									</div>
									<div class="rows">
										<div class="naaccept">
											<a class="anotiaccept" href="javascript:;"
												(click)="
									  performViewCourseAttendees(notificationData.notificationReceiverUserDetailsId, 'NEW_COURSE_ATTENDEE', intimation.id)">View All Attendees</a>
										</div>
										<div class="notignore">
											<a class="anotiacceptign" href="javascript: void(0)"
												(click)="
									  ignoreNotification(notificationData.notificationReceiverUserDetailsId, 'NEW_COURSE_ATTENDEE', intimation.id, 'INTIMATION')">Ignore</a>
										</div>
									</div>
								</div>

								<div *ngIf="intimation.event == 'COURSE_APPROVAL'">
									<div class="notiname">
										<p>Congratulations!! Your session <span
												(click)="performViewCourse(intimation.id)"><i>{{intimation.courseName}}</i></span>
											has been approved.</p>
									</div>
									<div class="rows">
										<div class="notignore">
											<a class="anotiacceptign" href="javascript: void(0)"
												(click)="
									  ignoreNotification(notificationData.notificationReceiverUserDetailsId, 'COURSE_APPROVAL', intimation.id, 'INTIMATION')">Ignore</a>
										</div>
									</div>
								</div>

								<div *ngIf="intimation.event == 'COURSE_ABOUT_TO_START'">
									<div class="notiname">
										<p>Your enrolled session <span
												(click)="performViewCourse(intimation.id)"><i>{{intimation.courseName}}</i></span>
											starts in {{intimation.timeTillStartOfCourse}}.</p>
									</div>
									<div class="rows">
										<div class="naaccept">
											<a class="anotiaccept" href="{{intimation.courseJoinURL}}">Join Session</a>
										</div>
										<div class="naaccept">
											<a class="anotiaccept" href="javascript: void(0)" (click)="
									  displayMeetingCredentials(intimation.courseJoinKey, intimation.courseJoinPassword)">Session Joining
												Credentials</a>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<div class="clr"></div>
		</div>
	</div>
	<div id="showmenu" style="height: 20px; width: 20px;" (click)="showHideMenuWhenCompressed()" #showCompressedMenuDiv>
	</div>
	<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
	<uvsity-modal-popup [isModalDialogToBeDisplayed]="showLogOffModalDialog" [messageTitle]="showLogOffMessageTitle"
		[messages]="showLogOffMessages" (uvsityModalPopupClosed)="closeErrorMessagePopup()"
		[popupWidthPercentage]="showLogOffPopupWidthInPercentage" [isValidationError]="isValidationError">
	</uvsity-modal-popup>

	<div style="display: none;" [style.display]="editDisplay(displayCourseJoinCredentials)"
		class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
		tabindex="-1" role="dialog" aria-describedby="sendInNetworkInvite" aria-labelledby="ui-id-1"
		style="position: absolute; height: auto; width: 600px; top: 10% !important; left: 30%; display: block;">
		<div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"><span id="ui-id-1"
				class="ui-dialog-title">Session Joining Credentials</span>
			<button
				class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
				role="button" aria-disabled="false" title="close" style="display: none;"><span
					class="ui-button-icon-primary ui-icon ui-icon-closethick"></span><span
					class="ui-button-text">close</span> </button>
		</div>
		<div class="ui-dialog-content ui-widget-content"
			style="width: auto; min-height: 33px; max-height: none; height: auto;" id="sendInNetworkInvite">
			<div class="two columns alpha">
				<label>Meeting Key:</label>
				{{courseJoinKey}}
			</div>
			<div class="clear"></div>
			<div class="seven columns last">
				<label>Meeting Password:</label>
				{{courseJoinPassword}}
			</div>
		</div>
	</div>

	<!--Send Recommendation Request popup-->
	<div style="display: none;" [style.display]="editDisplay(displayWriteRecommendationPopup)"
		class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
		tabindex="-1" role="dialog" aria-describedby="sendInNetworkInvite" aria-labelledby="ui-id-1"
		style="position: fixed; height: auto; width: 600px; top: 30% !important; left: 30%; display: block;">
		<div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix">
			<span id="ui-id-1" class="ui-dialog-title">Send Recommendation Request</span>
			<button
				class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
				role="button" aria-disabled="false" title="close" style="display: none;">
				<span class="ui-button-icon-primary ui-icon ui-icon-closethick"></span><span
					class="ui-button-text">close</span>
			</button>
		</div>
		<div class="ui-dialog-content ui-widget-content"
			style="width: auto; min-height: 33px; max-height: none; height: auto;" id="sendInNetworkInvite">
			<div class="seven columns last"> To: &nbsp;
				<label id="userNameDisplay" style="color: #108dd5;  text-decoration: none;">
					{{recoResponseToFirstName}} {{recoResponseToLastName}}
				</label>
			</div>
			<div class="clear"></div>
			<input type="hidden" id="userIdSend" value="22">
			<div class="seven columns last">
				<label>Recommendation Text:</label>
				<textarea [(ngModel)]="recoResp.recommendation.recommendation" name="recoRespMessageBody"
					id="messageForNetwork"></textarea>
			</div>
		</div>
		<div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
			<div class="ui-dialog-buttonset">
				<button type="button" id="sendMessage" (click)="sendResponseForRecommendationRequest()"
					class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" role="button"
					aria-disabled="false">
					<span class="ui-button-text">Send</span>
				</button>
				<button type="button" id="cancelsendRecoRequest" (click)="cancelSendResponseForRecommendationRequest();"
					class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" role="button"
					aria-disabled="false">
					<span class="ui-button-text">Cancel</span>
				</button>
			</div>
		</div>
	</div>

	<!--Send Rating popup-->
	<div style="display: none;" [style.display]="editDisplay(displaySendRatingPopup)"
		class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
		tabindex="-1" role="dialog" aria-describedby="sendInNetworkInvite" aria-labelledby="ui-id-1"
		style="position: fixed; height: auto; width: 30%; top: 30% !important; left: 30%; display: block;">
		<div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix">
			<span id="ui-id-1" class="ui-dialog-title">Send Rating</span>
			<button
				class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
				role="button" aria-disabled="false" title="close" style="display: none;">
				<span class="ui-button-icon-primary ui-icon ui-icon-closethick"></span><span
					class="ui-button-text">close</span>
			</button>
		</div>
		<div class="ui-dialog-content ui-widget-content"
			style="width: auto; min-height: 33px; max-height: none; height: auto;" id="sendRatingDiv">
			<div class="seven columns last"> To: &nbsp;
				<label id="userNameDisplayRating" style="color: #108dd5;  text-decoration: none;">
					{{ratingToFirstName}} {{ratingToLastName}}
				</label>
			</div>
		</div>

		<div id="ratingUser" class="common">
			<span>&nbsp;&nbsp;&nbsp;</span>
			<!--FAIR-->
			<input type="radio" [(ngModel)]="rating.rating.ratingText" name="fairRating" id="fairRating" value="FAIR">
			<label for="fairRating"
				style="color: #2b9dff;  text-decoration: none;">Fair</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			<!--GOOD-->
			<input type="radio" [(ngModel)]="rating.rating.ratingText" name="goodRating" id="goodRating" value="GOOD">
			<label for="goodRating"
				style="color: #2b9dff;  text-decoration: none;">Good</label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			<!--BEST-->
			<input type="radio" [(ngModel)]="rating.rating.ratingText" name="bestRating" id="bestRating" value="BEST">
			<label for="bestRating" style="color: #2b9dff;  text-decoration: none;">Best</label>
		</div>

		<div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
			<div class="ui-dialog-buttonset">
				<button type="button" id="sendRating" (click)="sendRating()"
					class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" role="button"
					aria-disabled="false">
					<span class="ui-button-text">Send</span>
				</button>
				<button type="button" id="cancelRating" (click)="cancelRating();"
					class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" role="button"
					aria-disabled="false">
					<span class="ui-button-text">Cancel</span>
				</button>
			</div>
		</div>
	</div>

</header>
<router-outlet></router-outlet>

<div id="footer-wrapper" class="footer-part pageblock">
	<footer class="boxs">
		<div class="footer-menu footer-left">
			<ul>
				<li class="first"><a href="help/faq.html" target="_blank">Help Center</a></li>
				<li><a href="javascript:void(0)" [routerLink]="['./upgrade']">Upgrade Your Account</a></li>
			</ul>
		</div>
		<div class="footer-menu footer-right">
			<ul>
				<li class="first"><a href="javascript:void(0)">UVSiTY Corporation © 2022</a></li>
				<li><a href="legal/useragreement.html" target="_blank">User Agreement</a></li>
				<li><a href="legal/privacypolicy.html" target="_blank">Privacy Policy</a></li>
				<li><a href="help/contactus.html" target="_blank">Contact Us</a></li>
			</ul>
		</div>
		<div class="clear"></div>

	</footer>
</div>
