<div class="content_area pageblock" id="sessionQuestionnaireDiv" *ngIf=!isPreview>
  <div class="sixteen columns">
    <menu-component></menu-component>
    <div class="boxs">
      <div id="content-top">
        <ol id="breadcrumb" class="twelve columns alpha breadcrumb">
          <li class="home">
            <a href="javascript:void(0);" [routerLink]="['../home']">Home</a>
          </li>
          <li class="arrow">&nbsp;</li>
          <li>Questionnaire</li>
        </ol>
        <div class="clr"></div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
  <div id="questionnaireDetailDiv">
    <div class="siixteen columns addrmcl-content boxs clearfix" id="questionnaireDetailDiv">
      <form name="form" [formGroup]="questionnaireForm" novalidate (ngSubmit)="onSubmit()">
        <div class="questionnaireeTable coursequestionnaireeWrap clearfix">
          <div class="descriptionWrap">
            <h3>{{questionnaireForm.controls.description.value}}</h3>
          </div>
          <div class="questionsWrap">
            <ul class="questionsUl" formArrayName="responses">
              <li class="questionsLi" *ngFor="let que of questions().controls; let queIndex = index">
                <div class="leftQuestion">
                  {{que.controls.number.value}}.
                </div>
                <div class="rightQuestion" [formGroupName]="queIndex">
                  <div class="questionWrap">
                    <label class="questionLabel">
                      {{que.controls.question.value}}
                    </label>
                    <div class="optionalDiv" *ngIf="que.controls.optional.value">
                      <label class="optionalLabel">(Optional)</label>
                    </div>
                  </div>
                  <div class="answerWrap">
                    <div *ngIf="que.controls.answerTypeCode.value == 1" class="answerText">
                      <textarea [maxlength]="(isAns) ? (que.controls.answer.value == null || que.controls.answer.value.length  < 100) ? 100 : que.controls.answer.value.length + 50 : que.controls.maxLength.value" 
                      [rows]="(isAns) ? (que.controls.answer.value == null || que.controls.answer.value.length  < 100) ? 2 : roundValue((que.controls.answer.value.length + 50)/100) : roundValue(que.controls.maxLength.value/100)" [required]="!que.controls.optional.value"
                      name="answer" formControlName="answer"></textarea>
                      <span *ngIf="!isAns">{{getCount(que.controls.maxLength.value, que.controls.answer.value)}}</span>
                      <div class="errorDiv" *ngIf="que.controls.answer.errors && que.controls.answer.touched">
                          <div [hidden]="!que.controls.answer.errors.required">Required.</div>
                      </div>
                    </div>
                    <div *ngIf="que.controls.answerTypeCode.value == 2" class="answerRadio" formArrayName="options">
                      <div class="radioWrap gwt-RadioButton" *ngFor="let option of getOptions(queIndex).controls;
                      let optionIndex = index">
                        <div [formGroupName]="optionIndex">
                          <input *ngIf="!isAns" type="radio" (change)="radioChange(queIndex, $event)"
                          name="selected" value="{{option.controls.value.value}}" id="radioOption-{{optionIndex}}"/>
                          <input *ngIf="isAns" type="radio" name="selected_{{optionIndex}}" formControlName="selected_{{optionIndex}}" [value]="true" id="radioOption-{{optionIndex}}"/>
                          <label for="radioOption-{{optionIndex}}">{{option.controls.value.value}}</label>
                        </div>
                      </div>
                      <div class="errorDiv" *ngIf="que.controls.invalid.value">
                          <div>Required.</div>
                      </div>
                    </div>
                    <div *ngIf="que.controls.answerTypeCode.value == 3" class="answerCheckbox" formArrayName="options">
                      <div class="checkboxWrap gwt-CheckBox clearfix" *ngFor="let option of getOptions(queIndex).controls;
                      let optionIndex = index">
                        <div [formGroupName]="optionIndex">
                          <input type="checkbox" (change)="changeCheckBox(optionIndex, queIndex)" name="selected" formControlName="selected" [value]="true" id="checkboxOption-{{optionIndex}}"/>
                          <label for="checkboxOption-{{optionIndex}}">{{option.controls.value.value}}</label>
                        </div>
                      </div>
                      <div class="errorDiv" *ngIf="que.controls.invalid.value">
                        <div>Required.</div>
                      </div>
                    </div>

                    <div *ngIf="que.controls.answerTypeCode.value == 4" class="answerDropdown">
                      <div class="dropdownWrap" formArrayName="options">
                        <select *ngIf="isAns" disabled class="dropDownSelect">
                          <option *ngFor="let option of getOptions(queIndex).controls; let optionIndex = index" 
                          [ngValue]="option.controls.value.value" [selected]="option.controls.selected.value">{{option.controls.value.value}}</option>
                        </select>
                        <select *ngIf="!isAns" class="dropDownSelect" (change)="dropdownChange(queIndex, $event)">
                          <option *ngFor="let option of getOptions(queIndex).controls; let optionIndex = index" 
                          [ngValue]="option.controls.value.value">{{option.controls.value.value}}</option>
                        </select>
                      </div>
                      <div class="errorDiv" *ngIf="que.controls.invalid.value">
                        <div>Required.</div>
                      </div>
                    </div>

                    <div *ngIf="que.controls.answerTypeCode.value == 5" class="answermCheckbox" formArrayName="options">
                      <div class="mCheckboxWrap gwt-CheckBox clearfix" *ngFor="let option of getOptions(queIndex).controls;
                      let optionIndex = index">
                        <div [formGroupName]="optionIndex">
                          <input type="checkbox" (change)="que.controls.invalid.value = false;"
                          name="selected" formControlName="selected" [value]="true" id="mCheckboxOption-{{optionIndex}}"/>
                          <label for="mCheckboxOption-{{optionIndex}}">{{option.controls.value.value}}</label>
                        </div>
                      </div>
                      <div class="errorDiv" *ngIf="que.controls.invalid.value">
                        <div>Required.</div>
                      </div>
                    </div>
                    <div *ngIf="que.controls.answerTypeCode.value == 6" class="answerRating">
                      <rating [required]="!que.controls.optional.value" [readonly]="isAns" name="answer" formControlName="answer" [max]="que.controls.maxRating.value"></rating>
                      <div class="errorDiv" *ngIf="que.controls.answer.errors && que.controls.answer.touched">
                          <div [hidden]="!que.controls.answer.errors.required">Required.</div>
                      </div>
                    </div>
                    <div *ngIf="que.controls.answerTypeCode.value == 7" class="answerDateTime">
                      <p-calendar dateFormat="mm/dd/yy" [required]="!que.controls.optional.value" name="answer" formControlName="answer"></p-calendar>
                      <div class="errorDiv" *ngIf="que.controls.answer.errors && que.controls.answer.touched">
                          <div [hidden]="!que.controls.answer.errors.required">Required.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="submitWrap" *ngIf="!isAns">
            <input type="submit" class="submitButton" value="Continue with Registration"/>
            <input type="button" [routerLink]="['../../viewsession']"
            [queryParams]="{ sessionId: questionnaireForm.controls.questionnaireTargetId.value }" value="Cancel"/>
          </div>
        </div>
      </form>
      <div class="questionnaireFooter">
        <p><i>This questionnaire form was created by session organizer. Never submit password or personal information. Uvsity is not responsible for privacy of data submitted in this form.</i></p>
        <p><i>By clicking "Register Now", I accept the <a target="_blank" href="https://www.uvsity.com/legal/useragreement.html">Terms of Service</a> and have read the <a target="_blank" href="https://www.uvsity.com/legal/privacypolicy.html">Privacy Policy.</a></i></p>
      </div>
    </div>
  </div>
  <uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
</div>

<div class="" id="sessionQuestionnaireDiv" *ngIf=isPreview>
  <div id="questionnaireDetailDiv">
    <div class="siixteen columns addrmcl-content boxs clearfix" id="questionnaireDetailDiv">
      <form name="form" [formGroup]="questionnaireForm" novalidate (ngSubmit)="onSubmit()">
        <div class="questionnaireeTable coursequestionnaireeWrap clearfix">
          <div class="descriptionWrap">
            <h3>{{questionnaireForm.controls.description.value}}</h3>
          </div>
          <div class="questionsWrap">
            <ul class="questionsUl" formArrayName="responses">
              <li class="questionsLi" *ngFor="let que of questions().controls; let queIndex = index">
                <div class="leftQuestion">
                  {{que.controls.number.value}}.
                </div>
                <div class="rightQuestion" [formGroupName]="queIndex">
                  <div class="questionWrap">
                    <label class="questionLabel">
                      {{que.controls.question.value}}
                    </label>
                    <div class="optionalDiv" *ngIf="que.controls.optional.value">
                      <label class="optionalLabel">(Optional)</label>
                    </div>
                  </div>
                  <div class="answerWrap">
                    <div *ngIf="que.controls.answerTypeCode.value == 1" class="answerText">
                      <textarea style="margin: 0;" [maxlength]="(isAns) ? (que.controls.answer.value == null || que.controls.answer.value.length  < 100) ? 100 : que.controls.answer.value.length + 50 : que.controls.maxLength.value" 
                      [rows]="(isAns) ? (que.controls.answer.value == null || que.controls.answer.value.length  < 100) ? 2 : roundValue((que.controls.answer.value.length + 50)/100) : roundValue(que.controls.maxLength.value/100)" [required]="!que.controls.optional.value"
                      name="answer" formControlName="answer"></textarea>
                      <span *ngIf="!isAns">{{getCount(que.controls.maxLength.value, que.controls.answer.value)}}</span>
                      <div class="errorDiv" *ngIf="que.controls.answer.errors && que.controls.answer.touched">
                          <div [hidden]="!que.controls.answer.errors.required">Required.</div>
                      </div>
                    </div>
                    <div *ngIf="que.controls.answerTypeCode.value == 2" class="answerRadio" formArrayName="options">
                      <div class="radioWrap gwt-RadioButton" *ngFor="let option of getOptions(queIndex).controls;
                      let optionIndex = index">
                        <div [formGroupName]="optionIndex">
                          <input *ngIf="!isAns" type="radio" (change)="radioChange(queIndex, $event)"
                          name="selected" value="{{option.controls.value.value}}" id="radioOption-{{optionIndex}}"/>
                          <input *ngIf="isAns" type="radio" name="selected_{{optionIndex}}" formControlName="selected_{{optionIndex}}" [value]="true" id="radioOption-{{optionIndex}}"/>
                          <label for="radioOption-{{optionIndex}}">{{option.controls.value.value}}</label>
                        </div>
                      </div>
                      <div class="errorDiv" *ngIf="que.controls.invalid.value">
                          <div>Required.</div>
                      </div>
                    </div>
                    <div *ngIf="que.controls.answerTypeCode.value == 3" class="answerCheckbox" formArrayName="options">
                      <div class="checkboxWrap gwt-CheckBox clearfix" *ngFor="let option of getOptions(queIndex).controls;
                      let optionIndex = index">
                        <div [formGroupName]="optionIndex">
                          <input type="checkbox" (change)="changeCheckBox(optionIndex, queIndex)" name="selected" formControlName="selected" [value]="true" id="checkboxOption-{{optionIndex}}"/>
                          <label for="checkboxOption-{{optionIndex}}">{{option.controls.value.value}}</label>
                        </div>
                      </div>
                      <div class="errorDiv" *ngIf="que.controls.invalid.value">
                        <div>Required.</div>
                      </div>
                    </div>

                    <div *ngIf="que.controls.answerTypeCode.value == 4" class="answerDropdown">
                      <div class="dropdownWrap" formArrayName="options">
                        <select *ngIf="isAns" disabled class="dropDownSelect">
                          <option *ngFor="let option of getOptions(queIndex).controls; let optionIndex = index" 
                          [ngValue]="option.controls.value.value" [selected]="option.controls.selected.value">{{option.controls.value.value}}</option>
                        </select>
                        <select *ngIf="!isAns" class="dropDownSelect" (change)="dropdownChange(queIndex, $event)">
                          <option *ngFor="let option of getOptions(queIndex).controls; let optionIndex = index" 
                          [ngValue]="option.controls.value.value">{{option.controls.value.value}}</option>
                        </select>
                      </div>
                      <div class="errorDiv" *ngIf="que.controls.invalid.value">
                        <div>Required.</div>
                      </div>
                    </div>

                    <div *ngIf="que.controls.answerTypeCode.value == 5" class="answermCheckbox" formArrayName="options">
                      <div class="mCheckboxWrap gwt-CheckBox clearfix" *ngFor="let option of getOptions(queIndex).controls;
                      let optionIndex = index">
                        <div [formGroupName]="optionIndex">
                          <input type="checkbox" (change)="que.controls.invalid.value = false;"
                          name="selected" formControlName="selected" [value]="true" id="mCheckboxOption-{{optionIndex}}"/>
                          <label for="mCheckboxOption-{{optionIndex}}">{{option.controls.value.value}}</label>
                        </div>
                      </div>
                      <div class="errorDiv" *ngIf="que.controls.invalid.value">
                        <div>Required.</div>
                      </div>
                    </div>
                    <div *ngIf="que.controls.answerTypeCode.value == 6" class="answerRating">
                      <rating [required]="!que.controls.optional.value" [readonly]="isAns" name="answer" formControlName="answer" [max]="que.controls.maxRating.value"></rating>
                      <div class="errorDiv" *ngIf="que.controls.answer.errors && que.controls.answer.touched">
                          <div [hidden]="!que.controls.answer.errors.required">Required.</div>
                      </div>
                    </div>
                    <div *ngIf="que.controls.answerTypeCode.value == 7" class="answerDateTime">
                      <p-calendar dateFormat="mm/dd/yy" [required]="!que.controls.optional.value" name="answer" formControlName="answer"></p-calendar>
                      <div class="errorDiv" *ngIf="que.controls.answer.errors && que.controls.answer.touched">
                          <div [hidden]="!que.controls.answer.errors.required">Required.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  </div>
  <uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
</div>