<div class="sixteen columns pageblock content_area">
    <div class="col-md-8 col_md_8">
        <menu-component></menu-component>
        <div class="row home_container">
            <div class="row">
                <div class="boxs">
                    <ol class="breadcrumb" id="breadcrumb">
                        <li class="home"><a href="javascript:void(0);" [routerLink]="['../home']">Home</a> </li>
                        <li class="arrow">&nbsp;</li>
                        <li class="active">New Event</li>
                    </ol>
                    <div class="clr"></div>
                </div>
            </div>
            <div class="clear"></div>
            <div class="sixteen columns marginbottom">
                <div class="fifteen columns ">
                    <!-- Left Content -->
                    <form name="frmNewEvent" method="post" enctype="multipart/form-data">
                        <div class="boxs boxpad">
                            <div class="title fblue bglightgray"> <span class="drakbg"><i
                                        class="icon_about"></i>Logo:</span> </div>
                            <div class="boxpad">
                                <div class="">
                                    <input type="file" name="eventImages" id="eventImages" multiple="multiple">
                                    <input type="hidden" name="imageFileName" id="imageFileName">
                                    <div class="cropImageDisplay" style="display: none;"> <img src="" id="croppedImage"
                                            name="croppedImage"> </div>
                                    <div id="cropImageDiv">
                                        <div id="localImage" style="display: none;"></div>
                                        <div id="cropform" style="display: none;">
                                            <input type="hidden" id="localImageName" name="localImageName">
                                            <input type="hidden" id="imageExtention" name="imageExtention">
                                            <input type="hidden" id="x" name="x">
                                            <input type="hidden" id="y" name="y">
                                            <input type="hidden" id="w" name="w">
                                            <input type="hidden" id="h" name="h">
                                            <input type="button" value="Crop Image">
                                            <input type="button" value="Cancel"> </div>
                                    </div>
                                    <!-- END Image Cropping DIV -->
                                    <!-- Image Event End -->
                                    <br>
                                    <div>
                                        <input type="checkbox" name="eventLogoAgree" id="eventLogoAgree"
                                            value="eventLogoAgree"> I acknowledge and agree that the logo/image I am
                                        uploading does not infringe upon any third party copyrights, trademarks, or
                                        other proprietary rights or otherwise violate the User Agreement. </div>
                                </div>
                                <div class="clr"></div>
                            </div>
                        </div>
                        <div class="boxs boxpad">
                            <div class="title fblue bglightgray"> <span class="drakbg"><i class="icon_about"></i>Event
                                    Details:</span> </div>
                            <div class="ten columns" style="margin-left: 0px;">
                                <input type="text" name="eventHeadline" id="eventHeadline" size="40" maxlength="100"
                                    autocomplete="off" placeholder="Event headline"> </div>
                            <div class="clear"></div>
                            <div class="exp-block">
                                <div class="fourparts columns alpha">
                                    <input type="text" name="fromDate" id="fromDate" size="10" maxlength="10"
                                        autocomplete="off" placeholder="From Date" class="hasDatepicker"> </div>
                                <div class="fourparts columns inptselect">
                                    <select id="startTime" name="startTime">
                                        <option value="">Start Time</option>
                                        <option value="242">00:00</option>
                                        <option value="243">00:30</option>
                                        <option value="244">01:00</option>
                                        <option value="245">01:30</option>
                                        <option value="246">02:00</option>
                                        <option value="247">02:30</option>
                                        <option value="248">03:00</option>
                                        <option value="249">03:30</option>
                                        <option value="250">04:00</option>
                                        <option value="251">04:30</option>
                                        <option value="252">05:00</option>
                                        <option value="253">05:30</option>
                                        <option value="254">06:00</option>
                                        <option value="255">06:30</option>
                                        <option value="256">07:00</option>
                                        <option value="257">07:30</option>
                                        <option value="258">08:00</option>
                                        <option value="259">08:30</option>
                                        <option value="260">09:00</option>
                                        <option value="261">09:30</option>
                                        <option value="262">10:00</option>
                                        <option value="263">10:30</option>
                                        <option value="264">11:00</option>
                                        <option value="265">11:30</option>
                                        <option value="266">12:00</option>
                                        <option value="267">12:30</option>
                                        <option value="268">13:00</option>
                                        <option value="269">13:30</option>
                                        <option value="270">14:00</option>
                                        <option value="271">14:30</option>
                                        <option value="272">15:00</option>
                                        <option value="273">15:30</option>
                                        <option value="274">16:00</option>
                                        <option value="275">16:30</option>
                                        <option value="276">17:00</option>
                                        <option value="277">17:30</option>
                                        <option value="278">18:00</option>
                                        <option value="279">18:30</option>
                                        <option value="280">19:00</option>
                                        <option value="281">19:30</option>
                                        <option value="282">20:00</option>
                                        <option value="283">20:30</option>
                                        <option value="284">21:00</option>
                                        <option value="285">21:30</option>
                                        <option value="286">22:00</option>
                                        <option value="287">22:30</option>
                                        <option value="288">23:00</option>
                                        <option value="289">23:30</option>
                                    </select>
                                </div>
                                <div class="fourparts columns">
                                    <input type="text" name="toDate" id="toDate" size="10" maxlength="10"
                                        autocomplete="off" placeholder="To Date" class="hasDatepicker"> </div>
                                <div class="fourparts columns last inptselect">
                                    <select id="endTime" name="endTime">
                                        <option value="">End Time</option>
                                        <option value="242">00:00</option>
                                        <option value="243">00:30</option>
                                        <option value="244">01:00</option>
                                        <option value="245">01:30</option>
                                        <option value="246">02:00</option>
                                        <option value="247">02:30</option>
                                        <option value="248">03:00</option>
                                        <option value="249">03:30</option>
                                        <option value="250">04:00</option>
                                        <option value="251">04:30</option>
                                        <option value="252">05:00</option>
                                        <option value="253">05:30</option>
                                        <option value="254">06:00</option>
                                        <option value="255">06:30</option>
                                        <option value="256">07:00</option>
                                        <option value="257">07:30</option>
                                        <option value="258">08:00</option>
                                        <option value="259">08:30</option>
                                        <option value="260">09:00</option>
                                        <option value="261">09:30</option>
                                        <option value="262">10:00</option>
                                        <option value="263">10:30</option>
                                        <option value="264">11:00</option>
                                        <option value="265">11:30</option>
                                        <option value="266">12:00</option>
                                        <option value="267">12:30</option>
                                        <option value="268">13:00</option>
                                        <option value="269">13:30</option>
                                        <option value="270">14:00</option>
                                        <option value="271">14:30</option>
                                        <option value="272">15:00</option>
                                        <option value="273">15:30</option>
                                        <option value="274">16:00</option>
                                        <option value="275">16:30</option>
                                        <option value="276">17:00</option>
                                        <option value="277">17:30</option>
                                        <option value="278">18:00</option>
                                        <option value="279">18:30</option>
                                        <option value="280">19:00</option>
                                        <option value="281">19:30</option>
                                        <option value="282">20:00</option>
                                        <option value="283">20:30</option>
                                        <option value="284">21:00</option>
                                        <option value="285">21:30</option>
                                        <option value="286">22:00</option>
                                        <option value="287">22:30</option>
                                        <option value="288">23:00</option>
                                        <option value="289">23:30</option>
                                    </select>
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="clear"></div>
                            <div class="ten columns" style="margin-left: 0px;">
                                <textarea name="eventDescription" id="eventDescription" rows="4" cols="80"
                                    placeholder="Event Description"> </textarea>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div>
                            <input type="button" name="btnSubmitEvent" id="btnSubmitEvent" value="Save Event"
                                class="buttonStyle">
                            <input type="reset" id="cancel-newevent" name="cancel-newevent" value="Cancel"> </div>
                    </form>
                    <!-- Left Content Ends Here -->
                </div>
                <div class="clr"></div>
            </div>
            <div class="clear"></div>
        </div>
    </div>
</div>
<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
<uvsity-modal-popup [isModalDialogToBeDisplayed]="showErrorModalDialog" [messageTitle]="showErrorMessageTitle"
    [messages]="showErrorMessages" (uvsityModalPopupClosed)="closeErrorModalPopup()"
    [popupWidthPercentage]="showErrorMessageWidthInPercentage"></uvsity-modal-popup>