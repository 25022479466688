<div class="sixteen columns pageblock content_area">
    <menu-component></menu-component>
    <div class="row home_container">
        <div class="row">
            <div class="boxs">
                <ol class="breadcrumb" id="breadcrumb">
                    <li class="home"><a href="javascript:void(0);" [routerLink]="['../home']">Home</a>
                    </li>
                    <li class="arrow">&nbsp;</li>
                    <li class="active">Post Job</li>
                </ol>
                <div class="clr"></div>
            </div>
        </div>
    </div>
    <!-- Old UI breadcrumb div -->
    <div class="twelve columns addrmcl-content boxs boxpad">
        <div class="leftsection">
            <form name="form" method="post" (ngSubmit)="f.form.valid && postNewJob()" #f="ngForm" novalidate>
                <input type="hidden" name="jobId" id="jobId">
                <div class="postjob">
                    <label for="country" class="lblblue">Location:</label>
                    <div class="clr"></div>
                    <div class="three columns alpha">
                        <select name="postJob.countryId" (change)="onChange(countryTO.value)"
                            [(ngModel)]="postJob.countryId" #countryTO="ngModel" placeholder="Please Select" required
                            id="country">
                            <option [value]="country.countryId" *ngFor="let country of countries">
                                {{country.countryCode}}</option>
                        </select>
                        <div *ngIf="f.submitted && !countryTO.valid" class="errorDiv" style="color:#f00">Location is
                            required</div>
                    </div>
                    <div class="three columns">
                        <p-autoComplete field="name" dataKey="value" name="temp.state"
                            (onSelect)="handleDropdownClick(state.value)" [(ngModel)]="temp.state" #state="ngModel"
                            [suggestions]="results" (completeMethod)="searchstate($event)" id="state"
                            placeholder="State"></p-autoComplete>
                        <div *ngIf="f.submitted && temp.state != ''" class="errorDiv" style="color:#f00">State is
                            required</div>
                    </div>
                    <div class="three columns last">
                        <p-autoComplete field="name" name="temp.city" [suggestions]="cityresults"
                            [(ngModel)]="temp.city" #city="ngModel" (completeMethod)="searchcity($event)"
                            (onSelect)="handleDropdownClick2(city.value)" id="city" maxlength="50" value=""
                            placeholder="City"></p-autoComplete>
                        <div *ngIf="f.submitted && temp.city != ''" class="errorDiv" style="color:#f00">City is required
                        </div>
                    </div>
                </div>
                <div class="clear"></div>
                <div class="nine columns">
                    <input type="text" id="jobLocation" title="(Optional) e.g. Building name / street"
                        placeholder="(Optional) e.g. Building name / street" size="60" maxlength="100"
                        autocomplete="off" value=""> </div>
                <div class="clear"></div>
                <div class="postjob">
                    <div class="nine columns">
                        <label for="headline" class="lblblue">Headline :</label>
                        <input type="text" name="postJob.jobsHeadline" [(ngModel)]="postJob.jobsHeadline"
                            id="jobHeadline" #jobsHeadline="ngModel" maxlength="100" required autocomplete="off"
                            value="">
                        <div *ngIf="f.submitted && !jobsHeadline.valid" class="errorDiv" style="color:#f00">Headline is
                            required</div>
                    </div>
                </div>
                <div class="clear"></div>
                <div class="postjob">
                    <label for="job-description" class="lblblue">Job Description :</label>
                    <textarea name="postJob.jobsDescription" [(ngModel)]="postJob.jobsDescription"
                        #jobsDescription="ngModel" required id="jobDescription" rows="4" cols="80"></textarea>
                    <div *ngIf="f.submitted && !jobsDescription.valid" class="errorDiv" style="color:#f00">Description
                        is required</div>
                    <div class="charcount"> <span id="charCount_jobDescription">1000</span>&nbsp; chars left </div>
                </div>
                <div class="clr"></div>
                <div class="two columns alpha marginbottom" style="padding-left: 0px;">
                    <input type="submit" name="btnSubmitJob" id="btnSubmitJob" value="Save"
                        class="btn-120  buttonStyle"> <a class="pageLink4 btnstyle btn-120 "
                        href="javascript: void(0);">Cancel</a> </div>
                <script>
                </script>
                <div class="clear"></div>
            </form>
        </div>
        <div class="clr"></div>
    </div>
</div>