<section id="home" class="section1 homeSection clearfix"
    style=" background-repeat: no-repeat; background-size: 100% 800px !important;" #home>
    <div class="imgOverlaySection1">

    </div>
    <div class="main">
        <div class="clearfix">
            <br>
            <div class="subsection animated fadeInLeft videoSec" style="float: right;">
                <div class="HeaderText">
                    <h2 class="clearfix"><span (click)="signUpNowClick()">Sign Up Now</span></h2>
                    <!-- <h2></h2> -->
                    <h2>&amp; Create your own session today</h2>
                <h2>No Payment Required</h2></div>
            </div>
            <div class="subsection animated fadeInRight contentSec">
                <div class="gifContentWrap HeaderTextContent">
                    <ul class="section3Ul">
                        <!-- <li class="section3Li">
                            <i aria-hidden="true" class="fa fa-check"></i>A Marketplace for all educators, coaches and session organizers.</li>
                        <li class="section3Li">
                            <i aria-hidden="true" class="fa fa-check"></i>Earn from your paid session or sponsorships from your sessions.</li>
                        <li class="section3Li">
                            <i aria-hidden="true" class="fa fa-check"></i>Earn from your one on one appointment.</li>
                        <li class="section3Li">
                            <i aria-hidden="true" class="fa fa-check"></i>Stay connected with your attendees and participants - Social Networking.</li> -->
                        <li class="section3Li">
                            <i aria-hidden="true" class="fa fa-check"></i>Connect, Learn, and Earn: Expand your network, access valuable resources, and turn your expertise into income.</li>
                        <li class="section3Li">
                            <i aria-hidden="true" class="fa fa-check"></i>Empower your online presence: Host engaging sessions, connect with your audience, and stay in touch.</li>
                        <li class="section3Li">
                            <i aria-hidden="true" class="fa fa-check"></i>Earn income: Turn your expertise into income. Host paid sessions, find sponsors, or offer one-on-one consultations.</li>
                        <li class="section3Li">
                            <i aria-hidden="true" class="fa fa-check"></i>Effortless appointment management: Schedule appointments with ease. No more emails or calls. Integrated with Zoom and social media.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<section id="sessions"  class="section2 homeSection clearfix padding120"
    style=" background-repeat: no-repeat; background-size: 100% 650px !important;" #sessions>
    <div class="imgOverlay">

    </div>
    <div class="main">
        <div class="main clearfix" style="padding: 40px 99px;"
            *ngIf="logintopCourse != null && logintopCourse.length > 0">
            <div>
                <h2 style="text-align: center;">Live Sessions</h2>
            </div>
            <br>
            <div class="courses animated bounceIn" style="cursor: pointer;"
                *ngFor="let course of logintopCourse; let i = index"
                [class.showMore]="(i > ((logintopCourse.length - (logintopCourse.length%3)) - 1)) && logintopCourse.length > 3">
                <a href="javascript:void(0);" [routerLink]="isLoggedIn ? ['/dashboard/viewsession'] : ['/public/viewsession']"
                    [queryParams]="{ sessionId: course.courseId }">
                    <h1>
                        <img alt="" *ngIf="course.imageURL == null" src="assets/images/default_course.png" height="142"
                            width="266">
                        <img alt="" *ngIf="course.imageURL != null" src="{{course.imageURL}}" height="142" width="266">
                    </h1>
                    <h3 title="Click here to view more details"><a href="#">{{course.courseFullName}}</a></h3>
                    <div class="courseby">

                        <img *ngIf="course.profilePicName != null && !course.profilePicName.includes('profilebig.png')"
                            class="greyBorder profileImage" alt="" src="{{course.profilePicName}}" height="40"
                            width="40">

                        <ngx-avatar
                            *ngIf="course.profilePicName == null || course.profilePicName.includes('profilebig.png')"
                            name="{{course.createdByUserName}}" size="40">
                        </ngx-avatar>

                    </div>
                    <a *ngIf="course.creator.isUserSecondaryDataCapturedForUser == 'T'" class="more"
                        href="javascript: void(0);">by {{course.createdByUserName}}
                        &nbsp;<span>{{course.userType}},&nbsp;{{course.creator.educationalInstitute}}</span>
                    </a>
                    <a *ngIf="course.creator.isUserSecondaryDataCapturedForUser == 'F'" class="more"
                        href="javascript: void(0);">by {{course.createdByUserName}}</a>
                    <label>Starts on {{course.displayStartDate}}</label>
                    <div
                        style="display: inline-block; margin-bottom: 10px; margin-right: 8px; margin-left: 38px;     font-size: 13px;">
                        {{course.reviewCount}} Reviews </div>
                    <rating style="    font-size: 11px;" [readonly]="true" [(ngModel)]="course.avgReviewIntValue"
                        name="course.avgReviewIntValue">
                    </rating>
                    <div style="display: inline-block; margin-right: 0px;    font-size: 13px;">
                        {{course.avgReviewIntValue}}/5 </div>
                    <div class="clr"></div>
                    <div class="courseatte"
                        style="float: none;  display: inline-block; margin-left: 32px; margin-top: 0px;">
                        <span class="counter">{{course.numberOfAttendees}}</span>&nbsp;Attending </div>
                    <label
                        style="text-align: center; margin: 0 -19px 0 0; position: absolute; right: 10px;     bottom: 24px; width: auto; padding: 0px 6px; background: url(assets/images/new_label.png) no-repeat;height: 20px; width: 56px; line-height: 20px;">New</label>
                    <div class="coursecost" *ngIf="course.cost">${{course.cost}}</div>
                    <div class="coursecost" *ngIf="!course.cost">Free</div>
                    <div class="clr"></div>
                </a>
            </div>
            <!-- <p-carousel pageLinks="10" numVisible="4" firstVisible="1" [value]="logintopCourse">
                <ng-template let-course pTemplate="item">
                    
                </ng-template>
            </p-carousel> -->
            <div class="LoadMoreWrap clearfix"
                *ngIf="((logintopCourse.length > 15) || ((logintopCourse.length%3) != 0)) && logintopCourse.length > 3">
                <a class="LoadMoreA" href="javascript:void(0)" (click)="showall(boolFlag)">
                    {{loadMore}}
                </a>
            </div>
        </div>
    </div>
</section>
<section id="counterSection" class="section3 clearfix" #counterSection>
    <div class="sectionOverlay"></div>
    <div class="main">
        <div class="counter1 animated fadeInLeft">
            {{sessionCount}}+
            <span>
                Live sessions
            </span>
        </div>
        <div class="counter1 animated bounceIn">
            {{meetingCount}}+
            <span>
                Meetings
            </span>
        </div>
        <div class="counter1 animated fadeInRight">
            {{countryCount}}+
            <span>
                Countries
            </span>
        </div>
    </div>
</section>
<section id="howto" class="section2 clearfix" #howto>
    <div class="main">
        <br>
        <div class="howToWrap one clearfix">
            <h3 style="text-align: center;">How to create session</h3>
            <div class="subsection animated fadeInLeft videoSec">
                <div class="gifWrap">
                    <video width="650" autoplay muted controls loop>
                        <source src="https://s3.amazonaws.com/uvsityImages/HowToCreateSession.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </div>
            </div>
            <div class="subsection animated fadeInRight contentSec">
                <div class="gifContentWrap">
                    <ul class="section3Ul">
                        <li class="section3Li">
                            <i class="fa fa-check" aria-hidden="true"></i> Signup today and host your online sessions,
                            talks or events.
                        </li>
                        <li class="section3Li">
                            <i class="fa fa-check" aria-hidden="true"></i> To create your session, go to Sessions -->
                            Create Session.
                        </li>
                        <li class="section3Li">
                            <i class="fa fa-check" aria-hidden="true"></i> Fill out session details, select Schedule,
                            price, Preview then Submit.
                        </li>
                        <li class="section3Li">
                            <i class="fa fa-check" aria-hidden="true"></i> Share the session url in social media, Stay
                            in network with all attendee after your sessions or events.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="howToWrap two clearfix">
            <h3 style="text-align: center;">Setting up meeting is just few clicks away!</h3>
            <div class="subsection animated fadeInRight videoSec" style="float: right;">
                <div class="gifWrap">
                    <video width="650" autoplay muted controls loop>
                        <source src="https://s3.amazonaws.com/uvsityImages/HowToScheduleMeeting.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </div>
            </div>
            <div class="subsection animated fadeInRight contentSec">
                <div class="gifContentWrap">
                    <ul class="section3Ul">
                        <li class="section3Li">
                            <i class="fa fa-check" aria-hidden="true"></i> No back and forth emails or phone calls.
                            Setting up appointment is just few clicks away.
                        </li>
                        <li class="section3Li">
                            <i class="fa fa-check" aria-hidden="true"></i> Signup today and get your free personal
                            appointment app with integrated zoom web conferencing.
                        </li>
                        <li class="section3Li">
                            <i class="fa fa-check" aria-hidden="true"></i> Share your personal appointment link in
                            email, social media or embed in your website!
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="clearfix"></div>
        <div class="howToWrap three clearfix">
            <h3 style="text-align: center;">How to set availability in your calendar</h3>
            <div class="subsection animated fadeInLeft videoSec">
                <div class="gifWrap">
                    <video width="650" autoplay muted controls loop>
                        <source src="https://s3.amazonaws.com/uvsityImages/HowToSetAvailability.mp4" type="video/mp4">
                        Your browser does not support HTML video.
                    </video>
                </div>
            </div>
            <div class="subsection animated fadeInRight contentSec">
                <div class="gifContentWrap">
                    <ul class="section3Ul">
                        <li class="section3Li">
                            <i class="fa fa-check" aria-hidden="true"></i> Don't forget to set your availability after
                            you signup.
                        </li>
                        <li class="section3Li">
                            <i class="fa fa-check" aria-hidden="true"></i> To set, click on My Calendar, click on
                            Availability Settings
                        </li>
                        <li class="section3Li">
                            <i class="fa fa-check" aria-hidden="true"></i> Copy your appointment link and embed in your
                            website or share in email signature.
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="courses" style="display: none;" class="section3 clearfix" #courses>
    <div class="main clearfix" style="    padding: 40px 99px;"
        *ngIf="logintopCourse != null && logintopCourse.length > 0">
        <div>
            <h2 style="text-align: center;">Sessions</h2>
            <!-- <h3><a href="javascript:void(0)" [routerLink]="['/allcourse']">All Sessions</a></h3> -->
        </div>
        <br>
        <p-carousel pageLinks="10" numVisible="3" firstVisible="1" [value]="logintopCourse">
            <ng-template let-course pTemplate="item">
                <div class="courses animated bounceIn" style="cursor: pointer;">
                    <a href="javascript:void(0);" [routerLink]="['/public/viewsession']"
                        [queryParams]="{ sessionId: course.courseId }">
                        <h1>
                            <img alt="" *ngIf="course.imageURL == null" src="assets/images/default_course.png"
                                height="142" width="266">
                            <img alt="" *ngIf="course.imageURL != null" src="{{course.imageURL}}" height="142"
                                width="266">
                        </h1>
                        <h3 title="Click here to view more details"><a href="#">{{course.courseFullName}}</a></h3>
                        <div class="courseby">

                            <img *ngIf="course.profilePicName != null && !course.profilePicName.includes('profilebig.png')"
                                class="greyBorder profileImage" alt="" src="{{course.profilePicName}}" height="40"
                                width="40">

                            <ngx-avatar
                                *ngIf="course.profilePicName == null || course.profilePicName.includes('profilebig.png')"
                                name="{{course.createdByUserName}}" size="40">
                            </ngx-avatar>

                        </div>
                        <a *ngIf="course.creator.isUserSecondaryDataCapturedForUser == 'T'" class="more"
                            href="javascript: void(0);">by {{course.createdByUserName}}
                            &nbsp;<span>{{course.userType}},&nbsp;{{course.creator.educationalInstitute}}</span>
                        </a>
                        <a *ngIf="course.creator.isUserSecondaryDataCapturedForUser == 'F'" class="more"
                            href="javascript: void(0);">by {{course.createdByUserName}}</a>
                        <label>Starts on {{course.displayStartDate}}</label>
                        <div
                            style="display: inline-block; margin-bottom: 10px; margin-right: 8px; margin-left: 38px;     font-size: 13px;">
                            {{course.reviewCount}} Reviews </div>
                        <rating style="    font-size: 11px;" [readonly]="true" [(ngModel)]="course.avgReviewIntValue"
                            name="course.avgReviewIntValue">
                        </rating>
                        <div style="display: inline-block; margin-right: 0px;    font-size: 13px;">
                            {{course.avgReviewIntValue}}/5 </div>
                        <div class="clr"></div>
                        <div class="courseatte"
                            style="float: none;  display: inline-block; margin-left: 32px; margin-top: 0px;">
                            <span class="counter">{{course.numberOfAttendees}}</span>&nbsp;Attending </div>
                        <label
                            style="text-align: center; margin: 0 -19px 0 0; position: absolute; right: 10px;     bottom: 24px; width: auto; padding: 0px 6px; background: url(assets/images/new_label.png) no-repeat;height: 20px; width: 56px; line-height: 20px;">New</label>
                        <div class="coursecost" *ngIf="course.cost">${{course.cost}}</div>
                        <div class="coursecost" *ngIf="!course.cost">Free</div>
                        <div class="clr"></div>
                    </a>
                </div>
            </ng-template>
        </p-carousel>
        <!-- <div class="courses animated bounceIn" *ngFor="let course of logintopCourse" style="cursor: pointer;">
            <a href="javascript:void(0);" [routerLink]="['/public/viewcourse']"
                [queryParams]="{ courseId: course.courseId }">
                <h1>
                    <img alt="" *ngIf="course.imageURL == null" src="assets/images/default_course.png" height="142"
                        width="266">
                    <img alt="" *ngIf="course.imageURL != null" src="{{course.imageURL}}" height="142" width="266">
                </h1>
                <h3 title="Click here to view more details"><a href="#">{{course.courseFullName}}</a></h3>
                <div class="courseby">

                    <img *ngIf="course.profilePicName != null && !course.profilePicName.includes('profilebig.png')"
                        class="greyBorder profileImage" alt="" src="{{course.profilePicName}}" height="40" width="40">

                    <ngx-avatar
                        *ngIf="course.profilePicName == null || course.profilePicName.includes('profilebig.png')"
                        name="{{course.createdByUserName}}" size="40">
                    </ngx-avatar>

                </div>
                <a class="more" href="javascript: void(0);">by {{course.createdByUserName}}
                    <span
                        *ngIf="course.isUserSecondaryDataCapturedForUser == 'T'">,&nbsp;{{course.userType}},&nbsp;{{course.creator.educationalInstitute}}</span>
                </a>
                <label>Starts on {{course.displayStartDate}}</label>
                <div style="display: inline-block; margin-bottom: 10px; margin-right: 8px; margin-left: 38px;">
                    {{course.reviewCount}} Reviews </div>
                <rating [readonly]="true" [(ngModel)]="course.avgReviewIntValue" name="course.avgReviewIntValue">
                </rating>
                <div style="display: inline-block; margin-right: 0px;">{{course.avgReviewIntValue}}/5 </div>
                <div class="clr"></div>
                <div class="courseatte"
                    style="float: none;  display: inline-block; margin-left: 32px; margin-top: 0px;">
                    <span class="counter">{{course.numberOfAttendees}}</span>&nbsp;Attending </div>
                <label
                    style="text-align: center; margin: 0 -19px 0 0; position: absolute; right: 10px;     bottom: 24px; width: auto; padding: 0px 6px; background: url(assets/images/new_label.png) no-repeat;height: 20px; width: 56px; line-height: 20px;">New</label>
                <div class="coursecost" *ngIf="course.cost">${{course.cost}}</div>
                <div class="coursecost" *ngIf="!course.cost">Free</div>
                <div class="clr"></div>
            </a>
        </div> -->
    </div>
</section>
<!-- <section class="section3 peopleSearch clearfix">
    <div class="main homepagesearch">
        <h2> Search for People: </h2>
        <form name="frmGrowNetwork" method="post" role="search">
            <input type="hidden" name="hdCriteria" id="hdCriteria">
            <div style="margin-left: 0px; margin-top: 20px;" class="twelve columns">
                <div class="four columns alpha">
                    <input type="text" name="searchFirstName" [(ngModel)]="searchfname" id="searchFirstName" size="18"
                        maxlength="50" autocomplete="off" placeholder="First Name">
                </div>
                <div class="four columns">
                    <input type="text" name="searchLastName" [(ngModel)]="searchlname" id="searchLastName" size="18"
                        maxlength="50" autocomplete="off" placeholder="Last Name">
                </div>
                <div class="three columns last">
                    <input type="submit" name="btnSearch" id="btnSearch" [routerLink]="['/public/search']"
                        [queryParams]="{ fname: searchfname, lname: searchlname }" value="Search" class="buttonStyle">
                </div>
                <div class="clr"></div>
            </div>
            <div class="clr"></div>
        </form>
    </div>
</section> -->

<div id="footer-wrapper" class="footer-part pageblock">
    <footer class="boxs">

        <div class="footer-menu footer-right">
            <ul>
                <li class="first"><a href="javascript:void(0)">UVSiTY Corporation © 2022</a></li>
                <li><a href="legal/useragreement.html" target="_blank">User Agreement</a></li>
                <li><a href="legal/privacypolicy.html" target="_blank">Privacy Policy</a></li>
                <li class="first"><a href="help/faq.html" target="_blank">Help Center</a></li>
                <li><a href="help/contactus.html" target="_blank">Contact Us</a></li>
            </ul>
        </div>
        <div class="clear"></div>

    </footer>
</div>

<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
<uvsity-modal-popup [isModalDialogToBeDisplayed]="showErrorModalDialog" [messageTitle]="showErrorMessageTitle"
    [messages]="showErrorMessages" (uvsityModalPopupClosed)="closeErrorPopup()"
    [popupWidthPercentage]="showErrorPopupWidthInPercentage" [isValidationError]="isValidationError">
</uvsity-modal-popup>
