<header-component></header-component>
<div class="sixteen columns pageblock content_area">
  <div id="unsubscribeDetailDiv">
    <div class="siixteen columns addrmcl-content boxs clearfix" id="unsubscribeDetailDiv">
      <form name="form" *ngIf="isOtpForm" [formGroup]="unsubscribeForm" novalidate (ngSubmit)="onSubmit()">
        <div class="unsubscribeWrapper clearfix">
          <div class="descriptionWrap">
            <h3>{{title}}</h3>
          </div>
          <uvsity-modal-popup [isModalDialogToBeDisplayed]="showLogOffModalDialog" [messageTitle]="showLogOffMessageTitle"
            [messages]="showLogOffMessages" (uvsityModalPopupClosed)="closeErrorMessagePopup()"
            [popupWidthPercentage]="showLogOffPopupWidthInPercentage" [isValidationError]="isValidationError">
          </uvsity-modal-popup>
          <div class="formWrap">
            <div class="form-group">
              <label class="center-block">Enter your registered email id:
                <input type="text" class="form-control" required formControlName="emailId">
              </label>
            </div>
            <div *ngIf="unsubscribeForm.controls['emailId'].invalid && (unsubscribeForm.controls['emailId'].dirty || unsubscribeForm.controls['emailId'].touched)" class="errorDiv">
              <div *ngIf="unsubscribeForm.controls['emailId'].errors.required">
                Email is required.
              </div>
              <div *ngIf="unsubscribeForm.controls['emailId'].errors.email">
                Email is invalid.
              </div>
            </div>
            <div class="submitWrap">
              <input type="submit" value="Send me OTP"/>
            </div>
          </div>
        </div>
      </form>
      <form name="form" *ngIf="!isOtpForm" [formGroup]="otpForm" novalidate (ngSubmit)="onSubmitOtp()">
        <div class="unsubscribeWrapper clearfix">
          <div class="descriptionWrap">
            <h3>{{title}}</h3>
          </div>
          <div class="NoteWrap">
            <p>You are unsubscribing from all automated emails from uvsity.com
              This means that you will not be emailed all of the important notifications, New session notifications, latest updates, feature announcements etc.
              Didn't mean to do this? You can re-subscribe emails via the "Notifications" section of the "My Account" page.</p>
          </div>
          <uvsity-modal-popup [isModalDialogToBeDisplayed]="showLogOffModalDialog" [messageTitle]="showLogOffMessageTitle"
            [messages]="showLogOffMessages" (uvsityModalPopupClosed)="closeErrorMessagePopup()"
            [popupWidthPercentage]="showLogOffPopupWidthInPercentage" [isValidationError]="isValidationError">
          </uvsity-modal-popup>
          <div class="formWrap otpForm">
            <div class="form-group">
              <label class="center-block">Enter OTP:
                <input type="text" required class="form-control" formControlName="otp">
              </label>
            </div>
            <div *ngIf="otpForm.controls['otp'].invalid && (otpForm.controls['otp'].dirty || otpForm.controls['otp'].touched)" class="errorDiv">
              <div *ngIf="otpForm.controls['otp'].errors.required">
                OTP is required.
              </div>
              <div *ngIf="otpForm.controls['otp'].errors.pattern">
                OTP should be in 6 digits.
              </div>
            </div>
            <div class="submitWrap">
              <input type="submit" value="Unsubscribe Now"/>
              <input type="button" [routerLink]="['/login']" value="Never mind"/>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
</div>