<div class="boxs topmenu">
	<ul id="filter1" class="filters topmenupart">
		<li class="parent"> <a href="javascript:void(0)">Sessions</a>
			<ul class="children">
				<li><a [routerLink]="['/dashboard/onlinecourse']">Online Sessions</a> </li>
				<li><a [routerLink]="['/dashboard/myenrollment']">My Enrollments</a> </li>
				<li><a [routerLink]="['/dashboard/createcourse']">Create Session</a> </li>
				<li><a [routerLink]="['/dashboard/courseaccount']">My Sessions</a></li>
			</ul>
		</li>
		<li class="parent"><a href="javascript:void(0)">Topics</a>
			<ul class="children">
				<li> <a [routerLink]="['/dashboard/topiclist']">
						Topics List
					</a> </li>
				<li> <a [routerLink]="['/dashboard/mytopics']">
						My Topics
					</a> </li>
				<li> <a [routerLink]="['/dashboard/createtopic']">
						Create Topic
					</a> </li>


			</ul>
		</li>
		<!-- code added by Shankar Singh for redirecting calendar app	 -->
		<li><a href="{{myCalendarUrl}}">My Calendar</a>
		</li>
	</ul>
	<div class="clr"></div>
</div>