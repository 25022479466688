<div id="content-wrapper2">
   <!-- right start -->
   <div class="content_area pageblock">
      <div class="left_part">
         <menu-component></menu-component>
         <div class="boxs">
            <ol class="breadcrumb">
               <li><a href="javascript:void(0);" [routerLink]="['../home']">Home</a> </li>
               <li class="arrow">&nbsp;</li>
               <li class="active">My Profile: View</li>
            </ol>
            <div class="clr"></div>
         </div>
         <div class="boxs profilepart1">
            <div class="profilepic proleft homeprofile">
               <img *ngIf="user.profilePicName != null && !user.profilePicName.includes('profilebig.png')" width="150"
                  border="0" src="{{user.profilePicName}}?temp={{ts}}">
               <ngx-avatar *ngIf="user.profilePicName == null || user.profilePicName.includes('profilebig.png')"
                  name="{{user.firstName}} {{user.lastName}}" size="150"></ngx-avatar>
            </div>
            <div class="profiledesc proright descwidth100">
               <div class="profilename">{{user.firstName}} {{user.lastName}}
                  <span *ngIf="user.noOfRatingStars"
                     style="font-size: 0px;color: #444444;vertical-align: middle;display: inline-block;">
                     <img width="32" border="0" *ngFor="let rate of myRating" src="assets/images/star.png"
                        style="margin-right: 1px;">
                  </span>
               </div>
               <div class="profileclg" *ngIf="user.isUserSecondaryDataCapturedForUser != 'F'">
                  {{user.userType}}<span *ngIf="user.userProfileTypeAcademic == true">,
                     {{user.userEducationSubject}}</span><span>, {{user.educationalInstitution}}, {{user.city}}</span>
               </div>
               <div class="profileclg" *ngIf="user.isUserSecondaryDataCapturedForUser == 'F'">
                  <a href="javascript:void(0)" (click)="openStep2Popup = true">
                     <i>Add your title, Company/Institution, Location</i>
                  </a>
               </div>
               <div class="profiletools">
                  <label class="block-display"><a href="javascript: void(0);"
                        [routerLink]="['/dashboard/myconnections']">Connections:</a>
                  </label>
                  <a href="javascript: void(0);" [routerLink]="['../myconnections']"
                     [queryParams]="{ s: 'student', i:'true', o:'true'}">
                     <span class="counter"> {{user.studentConnectionCount}}</span> Students,</a>
                  <a href="javascript: void(0);" [routerLink]="['../myconnections']"
                     [queryParams]="{ s: 'professor', i:'true', o:'true' }">
                     <span class="counter"> {{user.professorConnectionCount}}</span> Professors,</a>
                  <a href="javascript: void(0);" [routerLink]="['../myconnections']"
                     [queryParams]="{ s: 'alumni', i:'true', o:'true' }">
                     <span class="counter"> {{user.alumniConnectionCount}}</span> Alumni</a>
               </div>
            </div>
            <div class="clr"></div>
            <a class="educ-show-edit ico_edit dashboard" [routerLink]="['../editProfile']" href="javascript:void(0);">
               Edit</a>
         </div>
         <div class="boxs profilepart2" *ngIf="profilePercentage.percentageOfProfileAlreadyCompleted  != 100">
            <div class="profilestatus proleft">
               <div class="title fblue">Profile</div>
               <div class="">
                  <div>
                     <!-- chart-content -->
                     <circle-progress [percent]="profilePercentage.percentageOfProfileAlreadyCompleted ">
                     </circle-progress>

                  </div>
               </div>
               <!-- chart -->
            </div>
            <div class="profiledesc proright descwidth100">
               <div class="title fgray">Complete your Profile</div>
               <div class="profiledetails">
                  <div class="common" style="width: 99%;">
                     <div class="common" *ngIf="!profilePercentage.isAboutYouCompleted"> Add
                        {{profilePercentage.aboutYouPercent}}% to your profile by updating about you. <a
                           class="pageLink" href="javascript: void(0);" [routerLink]="['../editProfile']">Update your
                           profile</a> now </div>
                     <div class="common" *ngIf="!profilePercentage.isSkllsetAdded"> Add
                        {{profilePercentage.skillSetPercent}}% to your profile by updating your Specialties/Skills. <a
                           class="pageLink" href="javascript: void(0);" [routerLink]="['../editProfile']">Update your
                           profile</a> now </div>
                     <div class="common" *ngIf="!profilePercentage.isProjResExpAdded"> Add
                        {{profilePercentage.projResExpsPercent}}% to your profile by updating your Project/Research/Work
                        Experience. <a class="pageLink" href="javascript: void(0);"
                           [routerLink]="['../editProfile']">Update your profile</a> now </div>
                     <div class="common" *ngIf="!profilePercentage.isPastEducationAdded"> Add
                        {{profilePercentage.pastEducationPercent}}% to your profile by updating your Past Education
                        details. <a class="pageLink" href="javascript: void(0);"
                           [routerLink]="['../editProfile']">Update your profile</a> now </div>
                     <div class="common" *ngIf="!profilePercentage.isPhotoAdded"> Add
                        {{profilePercentage.photoAddedPercent}}% to your profile by adding photo. <a class="pageLink"
                           href="javascript: void(0);" [routerLink]="['../editProfile']">Update your profile</a> now
                     </div>
                     <div class="common" *ngIf="!profilePercentage.isFriendsPresent"> Add
                        {{profilePercentage.friendPresentPercent}}% to your profile by growing your network. <a
                           class="pageLink" href="javascript: void(0);" [routerLink]="['../editProfile']">Update your
                           profile</a> now </div>
                     <div class="common" *ngIf="!profilePercentage.isRecommendationsPresentInInbox">
                        Add {{profilePercentage.recommendationPercent}}% to your profile by getting recommended. <a
                           class="pageLink" href="javascript: void(0);" [routerLink]="['../inbox']">Request for
                           Recommendation</a>
                     </div>
                  </div>
               </div>
            </div>
            <div class="clr"></div>
         </div>
         <!-- Top Courses -->
         <div class="eleven columns boxs boxpad" *ngIf="topCourses.length > 0">
            <form name="frmTopCourses" method="post">
               <input type="hidden" name="hdProfileId" id="hdProfileId">
               <div class="title fblue pad15">Popular
                  <label>Sessions</label>:</div>
               <div class="ten columns topcoures" *ngFor="let course of topCourses">
                  <div class="priceWrapFree" *ngIf="!course.cost">
                     <div class="priceLabelFree">
                        Free
                     </div>
                  </div>
                  <div class="priceLabel" *ngIf="course.cost">
                     ${{course.cost}}
                  </div>
                  <div class="proleft">

                     <!--  <img *ngIf="!course.profilePicName" class="greyBorder profileImage" alt="" src="assets/images/default_course.png" height="60" width="60"> -->

                     <img *ngIf="course.profilePicName != null && !course.profilePicName.includes('profilebig.png')"
                        class="greyBorder profileImage" alt="" src="{{course.profilePicName}}" height="60" width="60">

                     <ngx-avatar
                        *ngIf="course.profilePicName == null || course.profilePicName.includes('profilebig.png')"
                        name="{{course.createdByUserName}}" size="60"></ngx-avatar>

                  </div>
                  <div class="proright">
                     <div class="coursetitle">
                        <div class="clr"></div><a class="gwt-Anchor topic-name2 coursefullname"
                           [routerLink]="['../viewsession']" [queryParams]="{ sessionId: course.courseId }"
                           href="javascript:void(0)">{{course.courseFullName}}</a>
                     </div>
                     <div class="label-bold"> <span class="gwt-Label profile-userBy userBy">by:</span> <a
                           href="javascript: void(0);" [routerLink]="['../profile']"
                           [queryParams]="{ user: course.createdByUser }" class="fblue deco-none"><span
                              class="gwt-Anchor profile-name2 fblue deco-none topicowner">{{course.creator.name}},
                           </span></a><span *ngIf="course.creator.isUserSecondaryDataCapturedForUser == 'T'"
                           class="gwt-Label profile-userType userype">{{course.creator.userType}},
                           {{course.creator.educationalInstitute}}, {{course.creator.city}}</span> </div>
                     <div class="coHostWrap clearfix"
                        *ngIf="course.coHosts != undefined && course.coHosts[0] != undefined">
                        <span class="coHostSubWrap label">Co-Host: </span>
                        <div class="four columns profilevisitors cohostUser">
                           <div class="proleft">
                              <img
                                 *ngIf="course.coHosts[0].profilePicName != null && !course.coHosts[0].profilePicName.includes('profilebig.png')"
                                 width="60" height="60" border="0" src="{{course.coHosts[0].profilePicName}}"
                                 class="greyBorder profileImage">
                              <ngx-avatar
                                 *ngIf="course.coHosts[0].profilePicName == null || course.coHosts[0].profilePicName.includes('profilebig.png')"
                                 name="{{course.coHosts[0].firstName}} {{course.coHosts[0].lastName}}" size="60">
                              </ngx-avatar>
                           </div>
                           <div class="">
                              <label>
                                 <a href="javascript: void(0);" class="styledtooltip" [routerLink]="['../profile']"
                                    [queryParams]="{ user: course.coHosts[0].userDetailsId }">
                                    {{course.coHosts[0].firstName}} {{course.coHosts[0].lastName}}
                                    <span></span></a>
                              </label>
                              <br>
                              <div
                                 *ngIf="course.coHosts[0].userBaseType != undefined && course.coHosts[0].userBaseType != null"
                                 class="profilecou gwt-Label profile-userType userype">
                                 {{(course.coHosts[0].userBaseType == undefined) ? "" : course.coHosts[0].userBaseType}},
                                 <label
                                    *ngIf="course.coHosts[0].educationalInstitution != undefined && course.coHosts[0].educationalInstitution != null">
                                    {{(course.coHosts[0].educationalInstitution == undefined) ? "" : course.coHosts[0].educationalInstitution}},
                                    {{(course.coHosts[0].campus == undefined) ? "" : course.coHosts[0].campus}}</label>
                              </div>
                           </div>
                           <div class="clr"></div>
                        </div>
                     </div>
                     <div *ngIf="course.registrationQuestionnaireId == undefined || course.registrationQuestionnaireId == null || course.registrationQuestionnaireId == 0" class="startlabel"><a href="javascript:void(0)" [routerLink]="['../attendees']"
                           [queryParams]="{ courseId: course.courseId }">
                           <span class="counter">{{course.numberOfAttendees}}</span>&nbsp;Attending</a> </div>
                     <div *ngIf="course.registrationQuestionnaireId != undefined && course.registrationQuestionnaireId != null && course.registrationQuestionnaireId > 0" class="startlabel"><a href="javascript:void(0)" [routerLink]="['../attendees']"
                           [queryParams]="{ courseId: course.courseId, queId: course.registrationQuestionnaireId }">
                           <span class="counter">{{course.numberOfAttendees}}</span>&nbsp;Attending</a> </div>
                     <div class="coursedate">
                        Starts on {{course.displayStartDateOnlyConverted | amDateFormat:'ll'}}
                        {{course.startTime.displayConverted}} ({{course.timeZoneConverted}})</div>
                     <br>
                     <div>
                        <!--<rating [readonly]="true" [(ngModel)]="course.avgReviewIntValue" name="course.avgReviewIntValue"></rating>-->
                        <ac-stars class="average-reviev-counts" [starCount]="5" [rating]="course.avgReviewIntValue"
                           [readonly]="true"></ac-stars>
                        <div class="average-reviev-counts">{{course.avgReviewIntValue}}/5 </div>
                        <div class="reviev-counts"> {{course.reviewCount}} Reviews</div>
                     </div>
                     <!-- <div style="float:left; width:100%;margin-top:20px">
	                     <div class="coursecost" *ngIf="course.cost">${{course.cost}}</div>
	                     <div class="coursecost" *ngIf="!course.cost">Free</div>
	                 </div> -->
                  </div>
                  <div class="clr"></div>
               </div>
               <!--<div class="ten columns topcoures">
                  <div class="proleft"> <img class="greyBorder profileImage" alt="" src="" height="60" width="60"> </div>
                  <div class="proright">
                     <div class="coursetitle"><a class="gwt-Anchor topic-name2 coursefullname" href="displayCreateCourse.do?courseId=30">Earth Science</a> </div>
                     <div class="label-bold"> <span class="gwt-Label profile-userBy userBy">by:</span> <a href="javascript: void(0);" onclick="" class="fblue deco-none"><span class="gwt-Anchor profile-name2 fblue deco-none topicowner">Shalav  Jaiswal  </span>
               </a>,<span class="gwt-Label profile-userType userype">Professor&nbsp;,Alabama A &amp; M University ,&nbsp;Arizona</span> </div>
                     <div class="startlabel"><a href="courseAttendees.do?courseId=30"><span class="counter">0</span>&nbsp;Attending</a> </div>
                     <div class="coursedate">Starts on 02/28/2017 10:00:00</div>
                     <div class="coursecost">$&nbsp;40.0</div>
                  </div>
                  <div class="average-reviev-counts">0/5 </div>
                  <div class="reviev-counts"> 0 Reviews</div>
                  <div class="proleft"> <span style="font-size: 0px; color: #444444; vertical-align: sub;" class="stars"><span style="width: 0px;"></span></span>
                  </div>
                  <div class="clr"></div>
               </div>-->
               <br>
               <div class="padd"> <a class="morebtn" [routerLink]="['../onlinecourse']">More</a> </div>
            </form>
         </div>
         <!-- Recent Activities -->
         <!-- In My Network -->
         <div class="eleven columns boxs boxpad" *ngIf="networkUpdates.length > 0">
            <form name="frmInMyNetwork" method="post">
               <input id="jobId" type="hidden" name="jobId">
               <input type="hidden" name="hdProfileId" id="hdProfileId">
               <div class="title fblue">Recent
                  <label>updates</label>:</div>
               <div class="ten columns recent-updates list type1 ">
                  <div class="ten columns tophirings" *ngFor="let recentUpdate of networkUpdates">

                     <img
                        *ngIf="recentUpdate.eventTriggerProfilePicImageURL != null && !recentUpdate.eventTriggerProfilePicImageURL.includes('profilebig.png')"
                        width="60" height="60" border="0" src="{{recentUpdate.eventTriggerProfilePicImageURL}}"
                        class="greyBorder profileImage">
                     <div
                        *ngIf="recentUpdate.eventTriggerProfilePicImageURL == null || recentUpdate.eventTriggerProfilePicImageURL.includes('profilebig.png')"
                        class="greyBorder profileImage" style="float: left;">
                        <ngx-avatar name="{{recentUpdate.eventTriggerFirstName}} {{recentUpdate.eventTriggerLastName}}"
                           size="60"></ngx-avatar>
                     </div>
                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'ADD_USER_TO_NETWORK'"
                        style="vertical-align: top">
                        <a [routerLink]="['../profile']" [queryParams]="{ user: recentUpdate.eventTriggerUserId }"
                           class="pageLink" href="javascript: void(0);">
                           {{recentUpdate.eventTriggerFirstName}} {{recentUpdate.eventTriggerLastName}}</a>
                        added <a [routerLink]="['../profile']"
                           [queryParams]="{ user: recentUpdate.eventReceiverUserId }" class="pageLink"
                           href="javascript: void(0);">
                           {{recentUpdate.eventRecieverFirstName}} {{recentUpdate.eventRecieverLastName}}</a>
                        to <span>their network</span>
                     </span>

                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'RECEIVE_AND_ACCEPT_RECOMMENDATION'"
                        style="vertical-align: top"><a [routerLink]="['../profile']"
                           [queryParams]="{ user: recentUpdate.eventTriggerUserId }" class="pageLink"
                           href="javascript: void(0);">{{recentUpdate.eventTriggerFirstName}}
                           {{recentUpdate.eventTriggerLastName}} </a> received recommendation from <a
                           [routerLink]="['../profile']" [queryParams]="{ user: recentUpdate.eventReceiverUserId }"
                           class="pageLink" href="javascript: void(0);">{{recentUpdate.eventRecieverFirstName}}
                           {{recentUpdate.eventRecieverLastName}}</a></span>
                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'UPDATE_PROFILE'"
                        style="vertical-align: top"><a [routerLink]="['../profile']"
                           [queryParams]="{ user: recentUpdate.eventTriggerUserId }" class="pageLink"
                           href="javascript: void(0);">{{recentUpdate.eventTriggerFirstName}}
                           {{recentUpdate.eventTriggerLastName}}</a> updated <span>their profile.</span></span>
                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'SIGNUP_FOR_UVSITY_SAME_UNIVERSITY'"
                        style="vertical-align: top"><a [routerLink]="['../profile']"
                           [queryParams]="{ user: recentUpdate.eventTriggerUserId }" class="pageLink"
                           href="javascript: void(0);">{{recentUpdate.eventTriggerFirstName}}
                           {{recentUpdate.eventTriggerLastName}}</a> joined
                        {{recentUpdate.educationalInstitution}}</span>
                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'ACCEPT_INVITATION_REQUEST'"
                        style="vertical-align: top"><a [routerLink]="['../profile']"
                           [queryParams]="{ user: recentUpdate.eventTriggerUserId }" class="pageLink"
                           href="javascript: void(0);">{{recentUpdate.eventTriggerFirstName}}
                           {{recentUpdate.eventTriggerLastName}}</a> accepted your connection request</span>
                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'REJECT_INVITATION_REQUEST'"
                        style="vertical-align: top"><a [routerLink]="['../profile']"
                           [queryParams]="{ user: recentUpdate.eventTriggerUserId }" class="pageLink"
                           href="javascript: void(0);">{{recentUpdate.eventTriggerFirstName}}
                           {{recentUpdate.eventTriggerLastName}}</a> rejected your connection request</span>
                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'ACCEPT_RECOMMENDATION_RESPONSE'"
                        style="vertical-align: top"><a [routerLink]="['../profile']"
                           [queryParams]="{ user: recentUpdate.eventTriggerUserId }" class="pageLink"
                           href="javascript: void(0);">{{recentUpdate.eventTriggerFirstName}}
                           {{recentUpdate.eventTriggerLastName}}</a> accepted your recommendation</span>
                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'REJECT_RECOMMENDATION_RESPONSE'"
                        style="vertical-align: top"><a [routerLink]="['../profile']"
                           [queryParams]="{ user: recentUpdate.eventTriggerUserId }" class="pageLink"
                           href="javascript: void(0);">{{recentUpdate.eventTriggerFirstName}}
                           {{recentUpdate.eventTriggerLastName}}</a> rejected your recommendation</span>
                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'STAR_RATING_CHANGE'"
                        style="vertical-align: top"><a [routerLink]="['../profile']"
                           [queryParams]="{ user: recentUpdate.eventTriggerUserId }" class="pageLink"
                           href="javascript: void(0);">{{recentUpdate.eventTriggerFirstName}}
                           {{recentUpdate.eventTriggerLastName}}'s</a> rating increased to
                        {{recentUpdate.starRatingCount}}</span>
                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'CREATE_JOB'" style="vertical-align: top"><a
                           [routerLink]="['../profile']" [queryParams]="{ user: recentUpdate.eventTriggerUserId }"
                           class="pageLink" href="javascript: void(0);">{{recentUpdate.eventTriggerFirstName}}
                           {{recentUpdate.eventTriggerLastName}}</a> has created a new Job,
                        {{recentUpdate.jobName}}</span>
                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'OWN_RATING_CHANGE'"
                        style="vertical-align: top"><a [routerLink]="['../profile']"
                           [queryParams]="{ user: recentUpdate.eventTriggerUserId }" class="pageLink"
                           href="javascript: void(0);"></a>Congratulations. Your rating has increased to
                        {{recentUpdate.attr1}}</span>

                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'CREATE_TOPIC'">
                        <a [routerLink]="['../profile']" [queryParams]="{ user: recentUpdate.eventTriggerUserId }"
                           class="pageLink" href="javascript: void(0);">
                           {{recentUpdate.eventTriggerFirstName}} {{recentUpdate.eventTriggerLastName}}
                        </a>
                        created the Topic
                        <a href="javascript:void(0);" [routerLink]="['../viewtopic']"
                           [queryParams]="{ topic: recentUpdate.eventId }" class="pageLink" href="javascript: void(0);">
                           {{recentUpdate.topicName}}
                        </a>
                     </span>

                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'CREATE_COURSE'">
                        <a [routerLink]="['../profile']" [queryParams]="{ user: recentUpdate.eventTriggerUserId }"
                           class="pageLink" href="javascript: void(0);">
                           {{recentUpdate.eventTriggerFirstName}} {{recentUpdate.eventTriggerLastName}}
                        </a>
                        created the Session
                        <a href="javascript:void(0);" [routerLink]="['../viewsession']"
                           [queryParams]="{ sessionId: course.courseId }" class="pageLink" href="javascript: void(0);">
                           {{recentUpdate.courseName}}
                        </a>
                     </span>

                     <span *ngIf="recentUpdate.netowrkUpdateEventType == 'JOIN_COURSE'">
                        <a [routerLink]="['../profile']" [queryParams]="{ user: recentUpdate.eventTriggerUserId }"
                           class="pageLink" href="javascript: void(0);">
                           {{recentUpdate.eventTriggerFirstName}} {{recentUpdate.eventTriggerLastName}}
                        </a>
                        subscribed to the Session
                        <a href="javascript:void(0);" [routerLink]="['../viewsession']"
                           [queryParams]="{ sessionId: course.courseId }" class="pageLink" href="javascript: void(0);">
                           {{recentUpdate.courseName}}
                        </a>
                     </span>


                  </div>
                  <div id="loadMoreResults" class="load-more-hide" (click)="loadmore();">Load More</div>
               </div>
            </form>
         </div>
         <!-- In My Network -->
      </div>
      <div class="right_part">
         <div class="boxs formelement" *ngIf="sgstFriends.length > 0">
            <form name="frmConnectionsInterested" method="post">
               <div style="display: none;" id="addConnectionPleaseWaitDiv"> <img src="images/pleaseWait.gif"
                     width="15px" height="15px"> </div>
               <input type="hidden" name="hdProfileId" id="hdProfileId">
               <div class="title fblue">Connections you might be interested in:</div>
               <div class="four columns profilevisitors" *ngFor="let sgstfrnd of sgstFriends">
                  <div class="proleft">
                     <img
                        *ngIf="sgstfrnd.suggestedFriend.profilePicName != null && !sgstfrnd.suggestedFriend.profilePicName.includes('profilebig.png')"
                        width="60" height="60" border="0" src="{{sgstfrnd.suggestedFriend.profilePicName}}"
                        class="greyBorder profileImage">
                     <ngx-avatar
                        *ngIf="sgstfrnd.suggestedFriend.profilePicName == null || sgstfrnd.suggestedFriend.profilePicName.includes('profilebig.png')"
                        name="{{sgstfrnd.suggestedFriend.firstName}} {{sgstfrnd.suggestedFriend.lastName}}" size="60">
                     </ngx-avatar>
                  </div>
                  <div class="proright">
                     <label>
                        <a href="javascript: void(0);" class="styledtooltip" [routerLink]="['../profile']"
                           [queryParams]="{ user: sgstfrnd.suggestedFriend.userDetailsId }">
                           {{sgstfrnd.suggestedFriend.firstName}} {{sgstfrnd.suggestedFriend.lastName}}
                           <span></span></a>
                     </label>
                     <br>
                     <div *ngIf="sgstfrnd.suggestedFriend.isUserSecondaryDataCapturedForUser == 'T'"
                        class="profilecou gwt-Label profile-userType userype">
                        {{sgstfrnd.suggestedFriend.userBaseType}},
                        <label> {{sgstfrnd.suggestedFriend.educationalInstitution}}, {{sgstfrnd.suggestedFriend.campus}}
                        </label>
                     </div>
                     <br *ngIf="sgstfrnd.suggestedFriend.isUserSecondaryDataCapturedForUser == 'T'">
                     <span class="add-network-block" id="addToYourNetworkId_19">
                        <a href="javascript: void(0)" class="a-btn">
                           <span class="a-btn-slide-text"
                              (click)="sendInvitationForSuggestedFriend(sgstfrnd.suggestedFriend.userDetailsId, sgstfrnd)">Connect</span>
                           <span class="a-btn-icon-right">
                              <span></span>
                           </span>
                        </a>
                     </span>
                     <span class="add-network-block" id="plsWaitImgCntnr_19" style="display:none"
                        [style.display]="editDisplay(sgstfrnd)">Connection request sent</span>
                  </div>
                  <div class="clr"></div>
               </div>
               <label class="padd"> <a class="morebtn" [routerLink]="['../addconnection']"
                     href="javascript:void(0);">More</a> </label>
            </form>
         </div>
         <div class="boxs formelement" *ngIf="profileVisits.length > 0">
            <form name="frmProfileVisitors" method="post">
               <input type="hidden" name="hdProfileId" id="hdProfileId">
               <div class="title fblue">People visited your profile</div>

               <div class="four columns profilevisitors" *ngFor="let user of profileVisits">
                  <div class="proleft">
                     <img
                        *ngIf="user.visitorUserSummary.profilePicName != null && !user.visitorUserSummary.profilePicName.includes('profilebig.png')"
                        width="60" height="60" border="0" src="{{user.visitorUserSummary.profilePicName}}"
                        class="greyBorder profileImage" onclick="">
                     <ngx-avatar
                        *ngIf="user.visitorUserSummary.profilePicName == null || user.visitorUserSummary.profilePicName.includes('profilebig.png')"
                        name="{{user.visitorUserSummary.firstName}} {{user.visitorUserSummary.lastName}}" size="60">
                     </ngx-avatar>
                     <!--<img *ngIf="user.visitorUserSummary.profilePicName == null" width="60" height="60" border="0" src="assets/images/profilebig.png" class="greyBorder profileImage">-->
                  </div>
                  <label><a href="javascript: void(0);" [routerLink]="['../profile']"
                        [queryParams]="{ user: user.visitorUserSummary.userDetailsId }" class="pageLink"
                        onclick="">{{user.visitorUserSummary.firstName}} {{user.visitorUserSummary.lastName}}</a> <span
                        style="float:right">{{user.lastVisitTime}}</span></label>
                  <br>
                  <div class="proright gwt-Label profile-userType userype searchresultsbox"
                     style="height: auto !important; margin-right:0;">
                     <label
                        *ngIf="user.visitorUserSummary.isUserSecondaryDataCapturedForUser == 'T'">{{user.visitorUserSummary.userType}}</label>
                     <span *ngIf="user.invitationAction.invitationAction == 'INVITE'" class="add-network-block"
                        id="addToYourNetworkId_13_{{user.visitorUserSummary.userDetailsId}}">
                        <a href="javascript: void(0)"
                           (click)="sendInvitationForProfileVisit(user.visitorUserSummary.userDetailsId, user)"
                           class="pageLink1 addnetlink">Add to your network</a>
                     </span>
                     <span *ngIf="user.invitationAction.invitationAction != 'INVITE'" class="add-network-block"
                        id="addToYourNetworkId_13_{{user.visitorUserSummary.userDetailsId}}">
                        <span class="innetlink" *ngIf="user.invitationAction.invitationAction == 'IN_MY_NETWORK'">In
                           Your Network</span>
                     </span>
                     <div class="add-network-block" id="addToYourNetworkId_23"
                        *ngIf="user.invitationAction.invitationAction == 'WAITING_FOR_RESPONSE'">
                        <div class="profileawa" style="margin: 0; border: 0">
                           Awaiting Response
                        </div>
                     </div>
                     <div class="add-network-block" id="addToYourNetworkId_17_{{user.visitorUserSummary.userDetailsId}}"
                        *ngIf="user.invitationAction.invitationAction == 'ACCEPT'">
                        <div class="profileact">
                           <span id="inNetworkStatusId_17" style="margin-right: 10px;"><a class="pageLink"
                                 (click)="acceptInvite(user.invitationAction.invitationRequestId)"
                                 href="javascript: void(0);">Accept</a></span>
                        </div>
                     </div>
                  </div>
                  <div class="clr"></div>
               </div>
               <div class="clr"></div>
            </form>
            <label class="padd"> <a class="morebtn" [routerLink]="['../profilevisits']">More</a> </label>
         </div>
         <!-- Top Hirings -->
         <!--<div class="boxs formelement" *ngIf="hotJobs.length > 0">
                <form name="frmTopHirings" method="post">
                    <input type="hidden" name="jobId" id="jobId">
                    <input type="hidden" name="hdProfileId" id="hdProfileId">
                    <div class="title fblue pad15">Top
                        <label>Hirings</label>:</div>
                    <div class="four columns profilevisitors" *ngFor="let jobs of hotJobs">
                        <div class="proleft">
                            <img *ngIf="jobs.userDetailsSummary.profilePicName" width="60" height="60" border="0" src="{{jobs.userDetailsSummary.profilePicName}}" class="greyBorder profileImage">
                            <img *ngIf="jobs.userDetailsSummary.profilePicName == null" width="60" height="60" border="0" src="assets/images/profilebig.png" class="greyBorder profileImage">
                        </div>
                        <div class="proright">
                            <label><a href="javascript: void(0);" [routerLink]="['../viewjob']" [queryParams]="{ jobId: jobs.jobsId }">{{jobs.jobsHeadline}}</a> </label>
                            <br> <span class="gwt-Label profile-userBy userBy">Posted By:</span><a class="gwt-Anchor profile-name2 fblue deco-none topicowner" [routerLink]="['../profile']" [queryParams]="{ user: jobs.userDetailsSummary.userDetailsId }" href="javascript: void(0);">{{jobs.userDetailsSummary.firstName}} {{jobs.userDetailsSummary.lastName}}</a><span class="gwt-Label profile-userBy userBy"> on {{jobs.createdOnDateForDisplay}}</span>
                            <label class="gwt-Label profile-userType userype">{{jobs.userDetailsSummary.educationalInstitution}}, {{jobs.userDetailsSummary.campus}}, </label>
                        </div>
                        <div class="clr"></div>
                    </div>
                    <label class="padd"> <a class="morebtn" [routerLink]="['../fresherjobs']" href="javascript:void(0);">More</a> </label>
                </form>
            </div>-->
         <!-- Top Hirings -->
         <!-- Hot Topics -->
         <!-- <div class="boxs formelement" *ngIf="hotTopics.length > 0">
            <form method="post" name="topicOwnerForm" id="topicOwnerForm">
               <input type="hidden" name="hdProfileId" id="hdProfileId">
               <div class="title fblue pad15">
                  <label>Hot Topics</label>:</div>
               <div class="four columns profilevisitors" *ngFor="let topics of hotTopics">
                  <div class="proleft"> <img id="profilePicImage_4" class="greyBorder profileImage" width="60"
                        height="60" border="0" src="{{topics.topic.topicImageUrl}}"> </div>
                  <div class="proright">
                     <label> <a href="javascript:void(0);" [routerLink]="['../viewtopic']"
                           [queryParams]="{ topic: topics.topic.topicDetailId }">
                           {{topics.topic.topicName}}
                        </a> </label>
                     <br> <span class="gwt-Label profile-userBy userBy">by:</span>
                     <a [routerLink]="['../profile']"
                        [queryParams]="{ user: topics.topic.userDetailsSummaryTO.userDetailsId }"
                        href="javascript: void(0);" class="fblue deco-none"> <span
                           class="gwt-Anchor profile-name2 fblue deco-none topicowner">{{topics.topic.userDetailsSummaryTO.firstName}}
                           {{topics.topic.userDetailsSummaryTO.lastName}}</span> </a>
                     <br> <span class="gwt-Label profile-userBy userBy">website:</span> <span>
                        <a class="fblue deco-none" href="{{topics.topic.topicWebsite}}" target="_blank">
                           <span class="gwt-Anchor profile-name2 fblue deco-none topicowner">
                              {{topics.topic.topicWebsite}}
                           </span> </a>
                     </span>
                  </div>
                  <div class="clr"></div>
               </div>
            </form>
            <label class="padd"> <a class="morebtn" [routerLink]="['../topiclist']">More</a> </label>
            <form method="post" name="viewTopicForm">
               <input type="hidden" id="topicId" name="topicId" value="0"> </form>
         </div> -->
         <!-- Campus Activity -->
         <!-- Campus Activity -->
         <div class="boxs formelement">
            <form name="frmGrowNetwork" method="post" role="search" class="navbar-form navbar-left">
               <input type="hidden" name="hdProfileId" id="hdProfileId">
               <input type="hidden" name="hdCriteria" id="hdCriteria">
               <div class="title fblue"> Grow Your Network </div>
               <input type="text" name="searchFirstName" [(ngModel)]="searchfname" id="searchFirstName" size="18"
                  maxlength="50" autocomplete="off" placeholder="First Name">
               <input type="text" name="searchLastName" [(ngModel)]="searchlname" id="searchLastName" size="18"
                  maxlength="50" autocomplete="off" placeholder="Last Name">
               <div class="aright">
                  <input type="button" name="btnSearch" [routerLink]="['../search']"
                     [queryParams]="{ fname: searchfname, lname: searchlname }" id="btnSearch" value="Search"
                     class="buttonStyle btn-120"> </div>
               <br>
               <br>
               <input type="text" name="inviteemail" [(ngModel)]="inviteemail" id="emailId" size="22" maxlength="50"
                  autocomplete="off" onchange="" onkeyup="" placeholder="Email ID">
               <div class="aright">
                  <input type="button" name="btnInvite" id="btnInvite" value="Invite" (click)="inviteEmail()"
                     class="buttonStyle btn-120" onclick=""> </div>
               <div id="inviteDiv" style="color:green;"> <span></span> </div>
               <div class="clr"></div>
            </form>
         </div>
      </div>
      <div class="clr"></div>
   </div>

   <signuptwo-modal-popup [countryId]="countryId" [city]="city" [isSignUpTwoModalDialogToBeDisplayed]="openStep2Popup"
      (signupTwoModalPopupClosed)="updateUser($event)"></signuptwo-modal-popup>
</div>
<div class="overLay" *ngIf="openStep2Popup"></div>