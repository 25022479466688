<div class="content_area pageblock" id="myAccountDiv">
  <div class="sixteen columns">
    <menu-component></menu-component>
    <div class="boxs">
      <div id="content-top">
        <ol id="breadcrumb" class="twelve columns alpha breadcrumb">
          <li class="home">
            <a href="javascript:void(0);" [routerLink]="['../../home']">Home</a>
          </li>
          <li class="arrow">&nbsp;</li>
          <li>Sponsor Details</li>
        </ol>
        <div class="clr"></div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
  <div id="sponsorThankYouDiv">
    <div class="siixteen columns addrmcl-content boxs clearfix" *ngIf="queryData.status == 'SUCCESS'"
      id="sponsorThankDiv">
      <div class="ThankyouWrap">
        <div class="ThankyouMsg">
          <span>Thank you <b>"{{queryData.companyName}}"</b> for sponsoring my session
            <b>"{{queryData.sessionName}}"</b></span>
        </div>
        <div class="ThankyouLevel">
          <h4>Sponsor Level: </h4> <span class="sponsorDetailsSpan">{{queryData.level}}</span>
        </div>
        <div class="ThankyouPrice">
          <h4>Price Paid: </h4> <span class="sponsorDetailsSpan">$ {{queryData.price}}</span>
        </div>
        <div class="ThankyouBenefits">
          <h4>Your benefits include: </h4>
          <div class="ThankyouBenefit" [innerHtml]="queryData.benefits | safeHtml"></div>
        </div>
        <div class="ThankyouWebsite">
          <h4>Website: </h4>
          <a class="sponsorWebsite" (click)="navigateURL(queryData.companyWebsite)"
            href="javascript:void(0)">{{queryData.companyWebsite}}</a>
        </div>
        <div class="ThankyouLogo">
          <img [src]="queryData.companyLogo" alt="companyLogo">
        </div>
      </div>
    </div>
    <div class="siixteen columns addrmcl-content boxs clearfix" *ngIf="queryData.status != 'SUCCESS'"
      id="sponsorThankDiv" style="text-align: center;">
      <h3>Something went wrong. Please contact administrator.</h3>
    </div>
  </div>
  <uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
</div>