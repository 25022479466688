<div class="container pageblock content_area">
    <div class="sixteen columns">
        <div class="col-md-8 col_md_8">
            <menu-component></menu-component>
            <div class="row home_container">
                <div class="row">
                    <div class="boxs">
                        <ol class="breadcrumb" id="breadcrumb">
                            <li class="home"><a href="javascript:void(0);" [routerLink]="['../home']">Home</a> </li>
                            <li class="arrow">&nbsp;</li>
                            <li class="active">My Connections: Gmail Connections</li>
                        </ol>
                        <div class="clr"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <div class="sixteen columns">
        <div class="twelve columns add-connections">
            <div class="three columns alpha">
                <div class="addconnleft">
                    <div class="contant_div">
                        <div id="googleContactsDiv" class="myconnections">
                            <gmailContactRow *ngFor="let contact of connections" [contact]="contact"> </gmailContactRow>
                        </div>
                    </div>

                    <div [style.display]="editDisplay(isGoogleContactsAvailable)" class="clr navv" style="position: relative; margin-bottom: 10px; padding:9px; background: #fff; box-shadow: 0 0px 5px 0px rgba(0, 0, 0, 0.2); ">
                        <a href="javascript: void(0);" (click)="fetchContactsInPreviousPage()" style="display: block; position: absolute; left: 0; top: 15%; ">
                          <img class="navBtn" src="assets/images/st_prev.png" style="height: 35px;width: 21px; padding:5px; transition: all 0.01s ease;">
                        </a>
                        <input type="button" (click)="inviteClick()" value="Send Invite" style="margin: 0 auto; display: block; width:20%">
                        <a href="javascript: void(0);" (click)="fetchContactsInNextPage()" style="position: absolute; right: 10px; top: 15%; ">
                          <img class="navBtn" src="assets/images/st_next.png" style="margin-left: 45%;height: 35px;width: 21px; padding:5px; transition: all 0.01s ease;">
                        </a>
                    </div>

                    <div class="clr"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
