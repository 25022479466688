<div class="content_area pageblock">
    <div class="left_part">

        <menu-component></menu-component>

        <div class="boxs">
            <ol id="breadcrumb" class="ten columns alpha breadcrumb">
                <li class="home"><a href="javascript:void(0);" [routerLink]="['../home']">Home</a>
                </li>
                <li class="arrow">&nbsp;</li>
                <li>Jobs/ Internship</li>
            </ol>
            <div class="clr"></div>
        </div>
        <div style="display: none; text-align: center;" id="refineResultsPleaseWaitDiv">
            <img src="images/pleaseWait.gif" width="15px" height="15px" border="0">
        </div>
        <div class="myconnections">
            <form name="frmAllJobs" method="post">

                <input type="hidden" name="jobId" id="jobId">
                <input type="hidden" name="hdProfileId" id="hdProfileId">

                <div name="mainContentsJobs" id="mainContentsJobs">
                    <div id="searchResultsResponseDiv">

                        <div class="common errorDiv" *ngIf="jobs == []" style="width: 99%; margin-top: 10px;">
                            No records found
                        </div>
                        <div class="boxs profilepart1 " *ngFor="let job of jobs">
                            <div class="proleft inner-post-image profilepic">
                                <div class="featured-image">

                                    <!-- <img width="100" height="100" border="0" src="{{job.userDetailsSummary.profilePicName}}" class="greyBorder profileImage"> -->

                                    <img *ngIf="job.userDetailsSummary.profilePicName != null && !job.userDetailsSummary.profilePicName.includes('profilebig.png')"
                                        class="greyBorder profileImage" alt=""
                                        src="{{job.userDetailsSummary.profilePicName}}" height="100" width="100">

                                    <ngx-avatar
                                        *ngIf="job.userDetailsSummary.profilePicName == null || job.userDetailsSummary.profilePicName.includes('profilebig.png')"
                                        name="{{job.createdBy}}"></ngx-avatar>


                                </div>
                            </div>

                            <div class="profile-hover proright profiledesc">
                                <div class="profilename">
                                    <a href="javascript: void(0);" [routerLink]="['../viewjob']"
                                        [queryParams]="{ jobId: job.jobsId }" class="pageLink3">{{job.jobsHeadline}}</a>
                                </div>
                                <div class="profileclg">
                                    <span class="gwt-Label profile-userBy userBy">Posted By:</span> <a
                                        href="javascript: void(0);" [routerLink]="['../profile']"
                                        [queryParams]="{ user: job.userDetailsSummary.userDetailsId }"
                                        class="gwt-Anchor profile-name2 fblue deco-none topicowner">{{job.createdBy}}</a><span
                                        class="gwt-Label profile-userBy userBy"> on
                                        {{job.createdOnDateInDDMmYYYFormat}}</span>
                                    <br>
                                    <label class="gwt-Label profile-userType userype"
                                        *ngIf="job.userDetailsSummary.isUserSecondaryDataCapturedForUser == 'T'">{{job.userDetailsSummary.educationalInstitution}}
                                        , {{job.userDetailsSummary.campus}} , </label>
                                    <br>
                                </div>

                                <!-- START Job Description -->

                                <div id="descColl_2">
                                    <span>{{job.jobsDescription}}..............</span>&nbsp;&nbsp;&nbsp;&nbsp; <a
                                        href="" class="moreless">More</a>
                                </div>

                                <div id="descExpe_2" style="display: none">
                                    {{job.jobsDescription}} &nbsp;&nbsp;&nbsp;&nbsp; <a href=""
                                        class="moreless">Less</a>
                                </div>

                                <!-- END Job Description -->

                                <br>
                                <br>

                                <div class="profiletools">
                                    <label class="lblblue">Location: </label>

                                    {{job.cityFullName}} , {{job.stateFullName}} , {{job.countryFullName}}
                                </div>
                            </div>
                            <div class="clr"></div>

                        </div>
                    </div>
                </div>

                <div class="clr"></div>

                <div id="loadMoreResults" (click)="moreJobs()" class="load-more-hide">Load More</div>

                <div id="pleaseWaitDiv" style="display:none">
                    <img src="images/pleaseWait.gif" width="30%" height="30%">
                </div>

            </form>
        </div>
    </div>
    <div class="right_part">
        <div class="boxs formelement">

            <form name="frmRefineJobResults" method="post" id="frmRefineJobResults">
                <div class="title fblue pad15">
                    <strong>Refine Results</strong>
                </div>
                <div class="refineCriteriaBlock">

                    <div class="common" style="padding: 1px 0px;">
                        <input type="text" [(ngModel)]="filter.filterCountry" name="filter.filterCountry"
                            class="form-control" placeholder="Country" id="search_country" size="25" maxlength="50"
                            autocomplete="off" value="">
                    </div>
                </div>
                <div class="refineCriteriaBlock">

                    <div class="common" style="padding: 1px 0px;">
                        <input type="text" [(ngModel)]="filter.filterState" name="filter.filterState"
                            class="form-control" placeholder="State" id="search_country" size="25" maxlength="50"
                            autocomplete="off" value="">
                    </div>
                </div>
                <div class="refineCriteriaBlock">

                    <div class="common" style="padding: 1px 0px;">
                        <input type="text" [(ngModel)]="filter.filterCity" name="filter.filterCity" class="form-control"
                            placeholder="City" id="search_city" size="25" maxlength="50" autocomplete="off" value="">
                    </div>
                </div>

                <div class="refineCriteriaBlock">

                    <div class="common form-group" style="padding: 1px 0px;">
                        <select name="recency" [(ngModel)]="filter.filterRecency" name="filter.filterRecency"
                            id="recency" class="form-control">
                            <option value="">Recency</option>
                            <option value="7d">Last 7 days</option>
                            <option value="15d">Last 7 days</option>
                            <option value="1m">Last 1 month</option>
                        </select>
                    </div>
                </div>
                <input type="button" (click)="filterSearch()" class="view-topics" value="Search">
            </form>
        </div>

    </div>
    <div class="clr"></div>
</div>