<div class="sixteen columns pageblock content_area">
  <div id="content-top">
    <menu-component></menu-component>
    <div class="boxs">
      <ol id="breadcrumb" class="breadcrumb">
        <li class="home"><a href="#" [routerLink]="['../home']">Home</a></li>
        <li class="arrow">&nbsp;</li>
        <li>Sessions: Create Session</li>
        <li style="float:right">
          <select class="form-control timezoons" [(ngModel)]="timezoneTopbar" name="timezoneTopbar" class="timezonelist"
            #t (change)="timeZonechange(t.value);">
            <optgroup ng-reflect-label="Afghanistan" label="Afghanistan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Kabul"
                    }-->
              <option ng-reflect-value="Asia/Kabul" value="Asia/Kabul">Asia/Kabul</option>
            </optgroup>
            <optgroup ng-reflect-label="Aland Islands" label="Aland Islands">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Mariehamn"
                    }-->
              <option ng-reflect-value="Europe/Mariehamn" value="Europe/Mariehamn">Europe/Mariehamn
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Albania" label="Albania">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Tirane"
                    }-->
              <option ng-reflect-value="Europe/Tirane" value="Europe/Tirane">Europe/Tirane</option>
            </optgroup>
            <optgroup ng-reflect-label="Algeria" label="Algeria">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Algiers"
                    }-->
              <option ng-reflect-value="Africa/Algiers" value="Africa/Algiers">Africa/Algiers</option>
            </optgroup>
            <optgroup ng-reflect-label="American Samoa" label="American Samoa">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Pago_Pago"
                    }-->
              <option ng-reflect-value="Pacific/Pago_Pago" value="Pacific/Pago_Pago">Pacific/Pago_Pago
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Andorra" label="Andorra">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Andorra"
                    }-->
              <option ng-reflect-value="Europe/Andorra" value="Europe/Andorra">Europe/Andorra</option>
            </optgroup>
            <optgroup ng-reflect-label="Angola" label="Angola">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Luanda"
                    }-->
              <option ng-reflect-value="Africa/Luanda" value="Africa/Luanda">Africa/Luanda</option>
            </optgroup>
            <optgroup ng-reflect-label="Anguilla" label="Anguilla">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Anguilla"
                    }-->
              <option ng-reflect-value="America/Anguilla" value="America/Anguilla">America/Anguilla
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Antarctica" label="Antarctica">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Antarctica/McMurdo,Antarctica/Rothera,Antarctica/Palmer,Antarctica/Mawson,Antarctica/Davis,Antarctica/Casey,Antarctica/Vostok,Antarctica/DumontDUrville,Antarctica/Syowa,Antarctica/Troll"
                    }-->
              <option ng-reflect-value="Antarctica/McMurdo" value="Antarctica/McMurdo">Antarctica/McMurdo
              </option>
              <option ng-reflect-value="Antarctica/Rothera" value="Antarctica/Rothera">Antarctica/Rothera
              </option>
              <option ng-reflect-value="Antarctica/Palmer" value="Antarctica/Palmer">Antarctica/Palmer
              </option>
              <option ng-reflect-value="Antarctica/Mawson" value="Antarctica/Mawson">Antarctica/Mawson
              </option>
              <option ng-reflect-value="Antarctica/Davis" value="Antarctica/Davis">Antarctica/Davis
              </option>
              <option ng-reflect-value="Antarctica/Casey" value="Antarctica/Casey">Antarctica/Casey
              </option>
              <option ng-reflect-value="Antarctica/Vostok" value="Antarctica/Vostok">Antarctica/Vostok
              </option>
              <option ng-reflect-value="Antarctica/DumontDUrville" value="Antarctica/DumontDUrville">
                Antarctica/DumontDUrville</option>
              <option ng-reflect-value="Antarctica/Syowa" value="Antarctica/Syowa">Antarctica/Syowa
              </option>
              <option ng-reflect-value="Antarctica/Troll" value="Antarctica/Troll">Antarctica/Troll
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Antigua And Barbuda" label="Antigua And Barbuda">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Antigua"
                    }-->
              <option ng-reflect-value="America/Antigua" value="America/Antigua">America/Antigua</option>
            </optgroup>
            <optgroup ng-reflect-label="Argentina" label="Argentina">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Argentina/Buenos_Aires,America/Argentina/Cordoba,America/Argentina/Salta,America/Argentina/Jujuy,America/Argentina/Tucuman,America/Argentina/Catamarca,America/Argentina/La_Rioja,America/Argentina/San_Juan,America/Argentina/Mendoza,America/Argentina/San_Luis,America/Argentina/Rio_Gallegos,America/Argentina/Ushuaia"
                    }-->
              <option ng-reflect-value="America/Argentina/Buenos_Aires" value="America/Argentina/Buenos_Aires">
                America/Argentina/Buenos_Aires</option>
              <option ng-reflect-value="America/Argentina/Cordoba" value="America/Argentina/Cordoba">
                America/Argentina/Cordoba</option>
              <option ng-reflect-value="America/Argentina/Salta" value="America/Argentina/Salta">
                America/Argentina/Salta</option>
              <option ng-reflect-value="America/Argentina/Jujuy" value="America/Argentina/Jujuy">
                America/Argentina/Jujuy</option>
              <option ng-reflect-value="America/Argentina/Tucuman" value="America/Argentina/Tucuman">
                America/Argentina/Tucuman</option>
              <option ng-reflect-value="America/Argentina/Catamarca" value="America/Argentina/Catamarca">
                America/Argentina/Catamarca</option>
              <option ng-reflect-value="America/Argentina/La_Rioja" value="America/Argentina/La_Rioja">
                America/Argentina/La_Rioja</option>
              <option ng-reflect-value="America/Argentina/San_Juan" value="America/Argentina/San_Juan">
                America/Argentina/San_Juan</option>
              <option ng-reflect-value="America/Argentina/Mendoza" value="America/Argentina/Mendoza">
                America/Argentina/Mendoza</option>
              <option ng-reflect-value="America/Argentina/San_Luis" value="America/Argentina/San_Luis">
                America/Argentina/San_Luis</option>
              <option ng-reflect-value="America/Argentina/Rio_Gallegos" value="America/Argentina/Rio_Gallegos">
                America/Argentina/Rio_Gallegos</option>
              <option ng-reflect-value="America/Argentina/Ushuaia" value="America/Argentina/Ushuaia">
                America/Argentina/Ushuaia</option>
            </optgroup>
            <optgroup ng-reflect-label="Armenia" label="Armenia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Yerevan"
                    }-->
              <option ng-reflect-value="Asia/Yerevan" value="Asia/Yerevan">Asia/Yerevan</option>
            </optgroup>
            <optgroup ng-reflect-label="Aruba" label="Aruba">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Aruba"
                    }-->
              <option ng-reflect-value="America/Aruba" value="America/Aruba">America/Aruba</option>
            </optgroup>
            <optgroup ng-reflect-label="Australia" label="Australia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Australia/Lord_Howe,Antarctica/Macquarie,Australia/Hobart,Australia/Currie,Australia/Melbourne,Australia/Sydney,Australia/Broken_Hill,Australia/Brisbane,Australia/Lindeman,Australia/Adelaide,Australia/Darwin,Australia/Perth,Australia/Eucla"
                    }-->
              <option ng-reflect-value="Australia/Lord_Howe" value="Australia/Lord_Howe">
                Australia/Lord_Howe</option>
              <option ng-reflect-value="Antarctica/Macquarie" value="Antarctica/Macquarie">
                Antarctica/Macquarie</option>
              <option ng-reflect-value="Australia/Hobart" value="Australia/Hobart">Australia/Hobart
              </option>
              <option ng-reflect-value="Australia/Currie" value="Australia/Currie">Australia/Currie
              </option>
              <option ng-reflect-value="Australia/Melbourne" value="Australia/Melbourne">
                Australia/Melbourne</option>
              <option ng-reflect-value="Australia/Sydney" value="Australia/Sydney">Australia/Sydney
              </option>
              <option ng-reflect-value="Australia/Broken_Hill" value="Australia/Broken_Hill">
                Australia/Broken_Hill</option>
              <option ng-reflect-value="Australia/Brisbane" value="Australia/Brisbane">Australia/Brisbane
              </option>
              <option ng-reflect-value="Australia/Lindeman" value="Australia/Lindeman">Australia/Lindeman
              </option>
              <option ng-reflect-value="Australia/Adelaide" value="Australia/Adelaide">Australia/Adelaide
              </option>
              <option ng-reflect-value="Australia/Darwin" value="Australia/Darwin">Australia/Darwin
              </option>
              <option ng-reflect-value="Australia/Perth" value="Australia/Perth">Australia/Perth</option>
              <option ng-reflect-value="Australia/Eucla" value="Australia/Eucla">Australia/Eucla</option>
            </optgroup>
            <optgroup ng-reflect-label="Austria" label="Austria">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Vienna"
                    }-->
              <option ng-reflect-value="Europe/Vienna" value="Europe/Vienna">Europe/Vienna</option>
            </optgroup>
            <optgroup ng-reflect-label="Azerbaijan" label="Azerbaijan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Baku"
                    }-->
              <option ng-reflect-value="Asia/Baku" value="Asia/Baku">Asia/Baku</option>
            </optgroup>
            <optgroup ng-reflect-label="Bahamas" label="Bahamas">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Nassau"
                    }-->
              <option ng-reflect-value="America/Nassau" value="America/Nassau">America/Nassau</option>
            </optgroup>
            <optgroup ng-reflect-label="Bahrain" label="Bahrain">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Bahrain"
                    }-->
              <option ng-reflect-value="Asia/Bahrain" value="Asia/Bahrain">Asia/Bahrain</option>
            </optgroup>
            <optgroup ng-reflect-label="Bangladesh" label="Bangladesh">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Dhaka"
                    }-->
              <option ng-reflect-value="Asia/Dhaka" value="Asia/Dhaka">Asia/Dhaka</option>
            </optgroup>
            <optgroup ng-reflect-label="Barbados" label="Barbados">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Barbados"
                    }-->
              <option ng-reflect-value="America/Barbados" value="America/Barbados">America/Barbados
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Belarus" label="Belarus">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Minsk"
                    }-->
              <option ng-reflect-value="Europe/Minsk" value="Europe/Minsk">Europe/Minsk</option>
            </optgroup>
            <optgroup ng-reflect-label="Belgium" label="Belgium">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Brussels"
                    }-->
              <option ng-reflect-value="Europe/Brussels" value="Europe/Brussels">Europe/Brussels</option>
            </optgroup>
            <optgroup ng-reflect-label="Belize" label="Belize">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Belize"
                    }-->
              <option ng-reflect-value="America/Belize" value="America/Belize">America/Belize</option>
            </optgroup>
            <optgroup ng-reflect-label="Benin" label="Benin">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Porto-Novo"
                    }-->
              <option ng-reflect-value="Africa/Porto-Novo" value="Africa/Porto-Novo">Africa/Porto-Novo
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Bermuda" label="Bermuda">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/Bermuda"
                    }-->
              <option ng-reflect-value="Atlantic/Bermuda" value="Atlantic/Bermuda">Atlantic/Bermuda
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Bhutan" label="Bhutan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Thimphu"
                    }-->
              <option ng-reflect-value="Asia/Thimphu" value="Asia/Thimphu">Asia/Thimphu</option>
            </optgroup>
            <optgroup ng-reflect-label="Bolivia" label="Bolivia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/La_Paz"
                    }-->
              <option ng-reflect-value="America/La_Paz" value="America/La_Paz">America/La_Paz</option>
            </optgroup>
            <optgroup ng-reflect-label="Bosnia And Herzegovina" label="Bosnia And Herzegovina">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Sarajevo"
                    }-->
              <option ng-reflect-value="Europe/Sarajevo" value="Europe/Sarajevo">Europe/Sarajevo</option>
            </optgroup>
            <optgroup ng-reflect-label="Botswana" label="Botswana">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Gaborone"
                    }-->
              <option ng-reflect-value="Africa/Gaborone" value="Africa/Gaborone">Africa/Gaborone</option>
            </optgroup>
            <optgroup ng-reflect-label="Bouvet Island" label="Bouvet Island">
              <!--template bindings={
                    "ng-reflect-ng-for-of": ""
                    }-->
            </optgroup>
            <optgroup ng-reflect-label="Brazil" label="Brazil">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Noronha,America/Belem,America/Fortaleza,America/Recife,America/Araguaina,America/Maceio,America/Bahia,America/Sao_Paulo,America/Campo_Grande,America/Cuiaba,America/Santarem,America/Porto_Velho,America/Boa_Vista,America/Manaus,America/Eirunepe,America/Rio_Branco"
                    }-->
              <option ng-reflect-value="America/Noronha" value="America/Noronha">America/Noronha</option>
              <option ng-reflect-value="America/Belem" value="America/Belem">America/Belem</option>
              <option ng-reflect-value="America/Fortaleza" value="America/Fortaleza">America/Fortaleza
              </option>
              <option ng-reflect-value="America/Recife" value="America/Recife">America/Recife</option>
              <option ng-reflect-value="America/Araguaina" value="America/Araguaina">America/Araguaina
              </option>
              <option ng-reflect-value="America/Maceio" value="America/Maceio">America/Maceio</option>
              <option ng-reflect-value="America/Bahia" value="America/Bahia">America/Bahia</option>
              <option ng-reflect-value="America/Sao_Paulo" value="America/Sao_Paulo">America/Sao_Paulo
              </option>
              <option ng-reflect-value="America/Campo_Grande" value="America/Campo_Grande">
                America/Campo_Grande</option>
              <option ng-reflect-value="America/Cuiaba" value="America/Cuiaba">America/Cuiaba</option>
              <option ng-reflect-value="America/Santarem" value="America/Santarem">America/Santarem
              </option>
              <option ng-reflect-value="America/Porto_Velho" value="America/Porto_Velho">
                America/Porto_Velho</option>
              <option ng-reflect-value="America/Boa_Vista" value="America/Boa_Vista">America/Boa_Vista
              </option>
              <option ng-reflect-value="America/Manaus" value="America/Manaus">America/Manaus</option>
              <option ng-reflect-value="America/Eirunepe" value="America/Eirunepe">America/Eirunepe
              </option>
              <option ng-reflect-value="America/Rio_Branco" value="America/Rio_Branco">America/Rio_Branco
              </option>
            </optgroup>
            <optgroup ng-reflect-label="British Indian Ocean Territory" label="British Indian Ocean Territory">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Chagos"
                    }-->
              <option ng-reflect-value="Indian/Chagos" value="Indian/Chagos">Indian/Chagos</option>
            </optgroup>
            <optgroup ng-reflect-label="Brunei Darussalam" label="Brunei Darussalam">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Brunei"
                    }-->
              <option ng-reflect-value="Asia/Brunei" value="Asia/Brunei">Asia/Brunei</option>
            </optgroup>
            <optgroup ng-reflect-label="Bulgaria" label="Bulgaria">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Sofia"
                    }-->
              <option ng-reflect-value="Europe/Sofia" value="Europe/Sofia">Europe/Sofia</option>
            </optgroup>
            <optgroup ng-reflect-label="Burkina Faso" label="Burkina Faso">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Ouagadougou"
                    }-->
              <option ng-reflect-value="Africa/Ouagadougou" value="Africa/Ouagadougou">Africa/Ouagadougou
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Burundi" label="Burundi">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Bujumbura"
                    }-->
              <option ng-reflect-value="Africa/Bujumbura" value="Africa/Bujumbura">Africa/Bujumbura
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Cambodia" label="Cambodia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Phnom_Penh"
                    }-->
              <option ng-reflect-value="Asia/Phnom_Penh" value="Asia/Phnom_Penh">Asia/Phnom_Penh</option>
            </optgroup>
            <optgroup ng-reflect-label="Cameroon" label="Cameroon">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Douala"
                    }-->
              <option ng-reflect-value="Africa/Douala" value="Africa/Douala">Africa/Douala</option>
            </optgroup>
            <optgroup ng-reflect-label="Canada" label="Canada">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/St_Johns,America/Halifax,America/Glace_Bay,America/Moncton,America/Goose_Bay,America/Blanc-Sablon,America/Toronto,America/Nipigon,America/Thunder_Bay,America/Iqaluit,America/Pangnirtung,America/Resolute,America/Atikokan,America/Rankin_Inlet,America/Winnipeg,America/Rainy_River,America/Regina,America/Swift_Current,America/Edmonton,America/Cambridge_Bay,America/Yellowknife,America/Inuvik,America/Creston,America/Dawson_Creek,America/Vancouver,America/Whitehorse,America/Dawson,America/Montreal"
                    }-->
              <option ng-reflect-value="America/St_Johns" value="America/St_Johns">America/St_Johns
              </option>
              <option ng-reflect-value="America/Halifax" value="America/Halifax">America/Halifax</option>
              <option ng-reflect-value="America/Glace_Bay" value="America/Glace_Bay">America/Glace_Bay
              </option>
              <option ng-reflect-value="America/Moncton" value="America/Moncton">America/Moncton</option>
              <option ng-reflect-value="America/Goose_Bay" value="America/Goose_Bay">America/Goose_Bay
              </option>
              <option ng-reflect-value="America/Blanc-Sablon" value="America/Blanc-Sablon">
                America/Blanc-Sablon</option>
              <option ng-reflect-value="America/Toronto" value="America/Toronto">America/Toronto</option>
              <option ng-reflect-value="America/Nipigon" value="America/Nipigon">America/Nipigon</option>
              <option ng-reflect-value="America/Thunder_Bay" value="America/Thunder_Bay">
                America/Thunder_Bay</option>
              <option ng-reflect-value="America/Iqaluit" value="America/Iqaluit">America/Iqaluit</option>
              <option ng-reflect-value="America/Pangnirtung" value="America/Pangnirtung">
                America/Pangnirtung</option>
              <option ng-reflect-value="America/Resolute" value="America/Resolute">America/Resolute
              </option>
              <option ng-reflect-value="America/Atikokan" value="America/Atikokan">America/Atikokan
              </option>
              <option ng-reflect-value="America/Rankin_Inlet" value="America/Rankin_Inlet">
                America/Rankin_Inlet</option>
              <option ng-reflect-value="America/Winnipeg" value="America/Winnipeg">America/Winnipeg
              </option>
              <option ng-reflect-value="America/Rainy_River" value="America/Rainy_River">
                America/Rainy_River</option>
              <option ng-reflect-value="America/Regina" value="America/Regina">America/Regina</option>
              <option ng-reflect-value="America/Swift_Current" value="America/Swift_Current">
                America/Swift_Current</option>
              <option ng-reflect-value="America/Edmonton" value="America/Edmonton">America/Edmonton
              </option>
              <option ng-reflect-value="America/Cambridge_Bay" value="America/Cambridge_Bay">
                America/Cambridge_Bay</option>
              <option ng-reflect-value="America/Yellowknife" value="America/Yellowknife">
                America/Yellowknife</option>
              <option ng-reflect-value="America/Inuvik" value="America/Inuvik">America/Inuvik</option>
              <option ng-reflect-value="America/Creston" value="America/Creston">America/Creston</option>
              <option ng-reflect-value="America/Dawson_Creek" value="America/Dawson_Creek">
                America/Dawson_Creek</option>
              <option ng-reflect-value="America/Vancouver" value="America/Vancouver">America/Vancouver
              </option>
              <option ng-reflect-value="America/Whitehorse" value="America/Whitehorse">America/Whitehorse
              </option>
              <option ng-reflect-value="America/Dawson" value="America/Dawson">America/Dawson</option>
              <option ng-reflect-value="America/Montreal" value="America/Montreal">America/Montreal
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Cape Verde" label="Cape Verde">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/Cape_Verde"
                    }-->
              <option ng-reflect-value="Atlantic/Cape_Verde" value="Atlantic/Cape_Verde">
                Atlantic/Cape_Verde</option>
            </optgroup>
            <optgroup ng-reflect-label="Cayman Islands" label="Cayman Islands">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Cayman"
                    }-->
              <option ng-reflect-value="America/Cayman" value="America/Cayman">America/Cayman</option>
            </optgroup>
            <optgroup ng-reflect-label="Central African Republic" label="Central African Republic">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Bangui"
                    }-->
              <option ng-reflect-value="Africa/Bangui" value="Africa/Bangui">Africa/Bangui</option>
            </optgroup>
            <optgroup ng-reflect-label="Chad" label="Chad">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Ndjamena"
                    }-->
              <option ng-reflect-value="Africa/Ndjamena" value="Africa/Ndjamena">Africa/Ndjamena</option>
            </optgroup>
            <optgroup ng-reflect-label="Chile" label="Chile">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Santiago,Pacific/Easter"
                    }-->
              <option ng-reflect-value="America/Santiago" value="America/Santiago">America/Santiago
              </option>
              <option ng-reflect-value="Pacific/Easter" value="Pacific/Easter">Pacific/Easter</option>
            </optgroup>
            <optgroup ng-reflect-label="China" label="China">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Shanghai,Asia/Harbin,Asia/Chongqing,Asia/Urumqi,Asia/Kashgar"
                    }-->
              <option ng-reflect-value="Asia/Shanghai" value="Asia/Shanghai">Asia/Shanghai</option>
              <option ng-reflect-value="Asia/Harbin" value="Asia/Harbin">Asia/Harbin</option>
              <option ng-reflect-value="Asia/Chongqing" value="Asia/Chongqing">Asia/Chongqing</option>
              <option ng-reflect-value="Asia/Urumqi" value="Asia/Urumqi">Asia/Urumqi</option>
              <option ng-reflect-value="Asia/Kashgar" value="Asia/Kashgar">Asia/Kashgar</option>
            </optgroup>
            <optgroup ng-reflect-label="Christmas Island" label="Christmas Island">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Christmas"
                    }-->
              <option ng-reflect-value="Indian/Christmas" value="Indian/Christmas">Indian/Christmas
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Cocos (Keeling) Islands" label="Cocos (Keeling) Islands">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Cocos"
                    }-->
              <option ng-reflect-value="Indian/Cocos" value="Indian/Cocos">Indian/Cocos</option>
            </optgroup>
            <optgroup ng-reflect-label="Colombia" label="Colombia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Bogota"
                    }-->
              <option ng-reflect-value="America/Bogota" value="America/Bogota">America/Bogota</option>
            </optgroup>
            <optgroup ng-reflect-label="Comoros" label="Comoros">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Comoro"
                    }-->
              <option ng-reflect-value="Indian/Comoro" value="Indian/Comoro">Indian/Comoro</option>
            </optgroup>
            <optgroup ng-reflect-label="Congo" label="Congo">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Brazzaville"
                    }-->
              <option ng-reflect-value="Africa/Brazzaville" value="Africa/Brazzaville">Africa/Brazzaville
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Congo (Democratic Republic)" label="Congo (Democratic Republic)">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Kinshasa,Africa/Lubumbashi"
                    }-->
              <option ng-reflect-value="Africa/Kinshasa" value="Africa/Kinshasa">Africa/Kinshasa</option>
              <option ng-reflect-value="Africa/Lubumbashi" value="Africa/Lubumbashi">Africa/Lubumbashi
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Cook Islands" label="Cook Islands">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Rarotonga"
                    }-->
              <option ng-reflect-value="Pacific/Rarotonga" value="Pacific/Rarotonga">Pacific/Rarotonga
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Costa Rica" label="Costa Rica">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Costa_Rica"
                    }-->
              <option ng-reflect-value="America/Costa_Rica" value="America/Costa_Rica">America/Costa_Rica
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Cote D'Ivoire" label="Cote D'Ivoire">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Abidjan"
                    }-->
              <option ng-reflect-value="Africa/Abidjan" value="Africa/Abidjan">Africa/Abidjan</option>
            </optgroup>
            <optgroup ng-reflect-label="Croatia" label="Croatia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Zagreb"
                    }-->
              <option ng-reflect-value="Europe/Zagreb" value="Europe/Zagreb">Europe/Zagreb</option>
            </optgroup>
            <optgroup ng-reflect-label="Cuba" label="Cuba">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Havana"
                    }-->
              <option ng-reflect-value="America/Havana" value="America/Havana">America/Havana</option>
            </optgroup>
            <optgroup ng-reflect-label="Cyprus" label="Cyprus">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Nicosia"
                    }-->
              <option ng-reflect-value="Asia/Nicosia" value="Asia/Nicosia">Asia/Nicosia</option>
            </optgroup>
            <optgroup ng-reflect-label="Czech Republic" label="Czech Republic">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Prague"
                    }-->
              <option ng-reflect-value="Europe/Prague" value="Europe/Prague">Europe/Prague</option>
            </optgroup>
            <optgroup ng-reflect-label="Denmark" label="Denmark">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Copenhagen"
                    }-->
              <option ng-reflect-value="Europe/Copenhagen" value="Europe/Copenhagen">Europe/Copenhagen
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Djibouti" label="Djibouti">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Djibouti"
                    }-->
              <option ng-reflect-value="Africa/Djibouti" value="Africa/Djibouti">Africa/Djibouti</option>
            </optgroup>
            <optgroup ng-reflect-label="Dominica" label="Dominica">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Dominica"
                    }-->
              <option ng-reflect-value="America/Dominica" value="America/Dominica">America/Dominica
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Dominican Republic" label="Dominican Republic">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Santo_Domingo"
                    }-->
              <option ng-reflect-value="America/Santo_Domingo" value="America/Santo_Domingo">
                America/Santo_Domingo</option>
            </optgroup>
            <optgroup ng-reflect-label="Ecuador" label="Ecuador">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Guayaquil,Pacific/Galapagos"
                    }-->
              <option ng-reflect-value="America/Guayaquil" value="America/Guayaquil">America/Guayaquil
              </option>
              <option ng-reflect-value="Pacific/Galapagos" value="Pacific/Galapagos">Pacific/Galapagos
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Egypt" label="Egypt">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Cairo"
                    }-->
              <option ng-reflect-value="Africa/Cairo" value="Africa/Cairo">Africa/Cairo</option>
            </optgroup>
            <optgroup ng-reflect-label="El Salvador" label="El Salvador">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/El_Salvador"
                    }-->
              <option ng-reflect-value="America/El_Salvador" value="America/El_Salvador">
                America/El_Salvador</option>
            </optgroup>
            <optgroup ng-reflect-label="Equatorial Guinea" label="Equatorial Guinea">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Malabo"
                    }-->
              <option ng-reflect-value="Africa/Malabo" value="Africa/Malabo">Africa/Malabo</option>
            </optgroup>
            <optgroup ng-reflect-label="Eritrea" label="Eritrea">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Asmara"
                    }-->
              <option ng-reflect-value="Africa/Asmara" value="Africa/Asmara">Africa/Asmara</option>
            </optgroup>
            <optgroup ng-reflect-label="Estonia" label="Estonia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Tallinn"
                    }-->
              <option ng-reflect-value="Europe/Tallinn" value="Europe/Tallinn">Europe/Tallinn</option>
            </optgroup>
            <optgroup ng-reflect-label="Ethiopia" label="Ethiopia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Addis_Ababa"
                    }-->
              <option ng-reflect-value="Africa/Addis_Ababa" value="Africa/Addis_Ababa">Africa/Addis_Ababa
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Falkland Islands (Malvinas)" label="Falkland Islands (Malvinas)">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/Stanley"
                    }-->
              <option ng-reflect-value="Atlantic/Stanley" value="Atlantic/Stanley">Atlantic/Stanley
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Faroe Islands" label="Faroe Islands">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/Faroe"
                    }-->
              <option ng-reflect-value="Atlantic/Faroe" value="Atlantic/Faroe">Atlantic/Faroe</option>
            </optgroup>
            <optgroup ng-reflect-label="Fiji" label="Fiji">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Fiji"
                    }-->
              <option ng-reflect-value="Pacific/Fiji" value="Pacific/Fiji">Pacific/Fiji</option>
            </optgroup>
            <optgroup ng-reflect-label="Finland" label="Finland">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Helsinki"
                    }-->
              <option ng-reflect-value="Europe/Helsinki" value="Europe/Helsinki">Europe/Helsinki</option>
            </optgroup>
            <optgroup ng-reflect-label="France" label="France">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Paris"
                    }-->
              <option ng-reflect-value="Europe/Paris" value="Europe/Paris">Europe/Paris</option>
            </optgroup>
            <optgroup ng-reflect-label="French Guiana" label="French Guiana">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Cayenne"
                    }-->
              <option ng-reflect-value="America/Cayenne" value="America/Cayenne">America/Cayenne</option>
            </optgroup>
            <optgroup ng-reflect-label="French Polynesia" label="French Polynesia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Tahiti,Pacific/Marquesas,Pacific/Gambier"
                    }-->
              <option ng-reflect-value="Pacific/Tahiti" value="Pacific/Tahiti">Pacific/Tahiti</option>
              <option ng-reflect-value="Pacific/Marquesas" value="Pacific/Marquesas">Pacific/Marquesas
              </option>
              <option ng-reflect-value="Pacific/Gambier" value="Pacific/Gambier">Pacific/Gambier</option>
            </optgroup>
            <optgroup ng-reflect-label="French Southern Territories" label="French Southern Territories">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Kerguelen"
                    }-->
              <option ng-reflect-value="Indian/Kerguelen" value="Indian/Kerguelen">Indian/Kerguelen
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Gabon" label="Gabon">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Libreville"
                    }-->
              <option ng-reflect-value="Africa/Libreville" value="Africa/Libreville">Africa/Libreville
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Gambia" label="Gambia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Banjul"
                    }-->
              <option ng-reflect-value="Africa/Banjul" value="Africa/Banjul">Africa/Banjul</option>
            </optgroup>
            <optgroup ng-reflect-label="Georgia" label="Georgia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Tbilisi"
                    }-->
              <option ng-reflect-value="Asia/Tbilisi" value="Asia/Tbilisi">Asia/Tbilisi</option>
            </optgroup>
            <optgroup ng-reflect-label="Germany" label="Germany">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Berlin,Europe/Busingen"
                    }-->
              <option ng-reflect-value="Europe/Berlin" value="Europe/Berlin">Europe/Berlin</option>
              <option ng-reflect-value="Europe/Busingen" value="Europe/Busingen">Europe/Busingen</option>
            </optgroup>
            <optgroup ng-reflect-label="Ghana" label="Ghana">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Accra"
                    }-->
              <option ng-reflect-value="Africa/Accra" value="Africa/Accra">Africa/Accra</option>
            </optgroup>
            <optgroup ng-reflect-label="Gibraltar" label="Gibraltar">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Gibraltar"
                    }-->
              <option ng-reflect-value="Europe/Gibraltar" value="Europe/Gibraltar">Europe/Gibraltar
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Greece" label="Greece">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Athens"
                    }-->
              <option ng-reflect-value="Europe/Athens" value="Europe/Athens">Europe/Athens</option>
            </optgroup>
            <optgroup ng-reflect-label="Greenland" label="Greenland">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Godthab,America/Danmarkshavn,America/Scoresbysund,America/Thule"
                    }-->
              <option ng-reflect-value="America/Godthab" value="America/Godthab">America/Godthab</option>
              <option ng-reflect-value="America/Danmarkshavn" value="America/Danmarkshavn">
                America/Danmarkshavn</option>
              <option ng-reflect-value="America/Scoresbysund" value="America/Scoresbysund">
                America/Scoresbysund</option>
              <option ng-reflect-value="America/Thule" value="America/Thule">America/Thule</option>
            </optgroup>
            <optgroup ng-reflect-label="Grenada" label="Grenada">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Grenada"
                    }-->
              <option ng-reflect-value="America/Grenada" value="America/Grenada">America/Grenada</option>
            </optgroup>
            <optgroup ng-reflect-label="Guadeloupe" label="Guadeloupe">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Guadeloupe"
                    }-->
              <option ng-reflect-value="America/Guadeloupe" value="America/Guadeloupe">America/Guadeloupe
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Guam" label="Guam">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Guam"
                    }-->
              <option ng-reflect-value="Pacific/Guam" value="Pacific/Guam">Pacific/Guam</option>
            </optgroup>
            <optgroup ng-reflect-label="Guatemala" label="Guatemala">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Guatemala"
                    }-->
              <option ng-reflect-value="America/Guatemala" value="America/Guatemala">America/Guatemala
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Guernsey" label="Guernsey">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Guernsey"
                    }-->
              <option ng-reflect-value="Europe/Guernsey" value="Europe/Guernsey">Europe/Guernsey</option>
            </optgroup>
            <optgroup ng-reflect-label="Guinea" label="Guinea">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Conakry"
                    }-->
              <option ng-reflect-value="Africa/Conakry" value="Africa/Conakry">Africa/Conakry</option>
            </optgroup>
            <optgroup ng-reflect-label="Guinea-Bissau" label="Guinea-Bissau">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Bissau"
                    }-->
              <option ng-reflect-value="Africa/Bissau" value="Africa/Bissau">Africa/Bissau</option>
            </optgroup>
            <optgroup ng-reflect-label="Guyana" label="Guyana">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Guyana"
                    }-->
              <option ng-reflect-value="America/Guyana" value="America/Guyana">America/Guyana</option>
            </optgroup>
            <optgroup ng-reflect-label="Haiti" label="Haiti">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Port-au-Prince"
                    }-->
              <option ng-reflect-value="America/Port-au-Prince" value="America/Port-au-Prince">
                America/Port-au-Prince</option>
            </optgroup>
            <optgroup ng-reflect-label="Heard Island &amp; Mcdonald Islands"
              label="Heard Island &amp; Mcdonald Islands">
              <!--template bindings={
                    "ng-reflect-ng-for-of": ""
                    }-->
            </optgroup>
            <optgroup ng-reflect-label="Holy See (Vatican City State)" label="Holy See (Vatican City State)">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Vatican"
                    }-->
              <option ng-reflect-value="Europe/Vatican" value="Europe/Vatican">Europe/Vatican</option>
            </optgroup>
            <optgroup ng-reflect-label="Honduras" label="Honduras">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Tegucigalpa"
                    }-->
              <option ng-reflect-value="America/Tegucigalpa" value="America/Tegucigalpa">
                America/Tegucigalpa</option>
            </optgroup>
            <optgroup ng-reflect-label="Hong Kong" label="Hong Kong">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Hong_Kong"
                    }-->
              <option ng-reflect-value="Asia/Hong_Kong" value="Asia/Hong_Kong">Asia/Hong_Kong</option>
            </optgroup>
            <optgroup ng-reflect-label="Hungary" label="Hungary">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Budapest"
                    }-->
              <option ng-reflect-value="Europe/Budapest" value="Europe/Budapest">Europe/Budapest</option>
            </optgroup>
            <optgroup ng-reflect-label="Iceland" label="Iceland">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/Reykjavik"
                    }-->
              <option ng-reflect-value="Atlantic/Reykjavik" value="Atlantic/Reykjavik">Atlantic/Reykjavik
              </option>
            </optgroup>
            <optgroup ng-reflect-label="India" label="India">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Kolkata"
                    }-->
              <option ng-reflect-value="Asia/Kolkata" value="Asia/Kolkata">Asia/Kolkata</option>
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Calcutta"
                    }-->
              <option ng-reflect-value="Asia/Calcutta" value="Asia/Calcutta">Asia/Calcutta</option>
            </optgroup>
            <optgroup ng-reflect-label="Indonesia" label="Indonesia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/ Jakarta,Asia/ Pontianak,Asia/ Makassar,Asia/ Jayapura"
                    }-->
              <option ng-reflect-value="Asia/ Jakarta" value="Asia/ Jakarta">Asia/ Jakarta</option>
              <option ng-reflect-value="Asia/ Pontianak" value="Asia/ Pontianak">Asia/ Pontianak</option>
              <option ng-reflect-value="Asia/ Makassar" value="Asia/ Makassar">Asia/ Makassar</option>
              <option ng-reflect-value="Asia/ Jayapura" value="Asia/ Jayapura">Asia/ Jayapura</option>
            </optgroup>
            <optgroup ng-reflect-label="Iran (Islamic Republic Of)" label="Iran (Islamic Republic Of)">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Tehran"
                    }-->
              <option ng-reflect-value="Asia/Tehran" value="Asia/Tehran">Asia/Tehran</option>
            </optgroup>
            <optgroup ng-reflect-label="Iraq" label="Iraq">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Baghdad"
                    }-->
              <option ng-reflect-value="Asia/Baghdad" value="Asia/Baghdad">Asia/Baghdad</option>
            </optgroup>
            <optgroup ng-reflect-label="Ireland" label="Ireland">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Dublin"
                    }-->
              <option ng-reflect-value="Europe/Dublin" value="Europe/Dublin">Europe/Dublin</option>
            </optgroup>
            <optgroup ng-reflect-label="Isle Of Man" label="Isle Of Man">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Isle_of_Man"
                    }-->
              <option ng-reflect-value="Europe/Isle_of_Man" value="Europe/Isle_of_Man">Europe/Isle_of_Man
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Israel" label="Israel">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Jerusalem"
                    }-->
              <option ng-reflect-value="Asia/Jerusalem" value="Asia/Jerusalem">Asia/Jerusalem</option>
            </optgroup>
            <optgroup ng-reflect-label="Italy" label="Italy">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Rome"
                    }-->
              <option ng-reflect-value="Europe/Rome" value="Europe/Rome">Europe/Rome</option>
            </optgroup>
            <optgroup ng-reflect-label="Jamaica" label="Jamaica">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Jamaica"
                    }-->
              <option ng-reflect-value="America/Jamaica" value="America/Jamaica">America/Jamaica</option>
            </optgroup>
            <optgroup ng-reflect-label="Japan" label="Japan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Tokyo"
                    }-->
              <option ng-reflect-value="Asia/Tokyo" value="Asia/Tokyo">Asia/Tokyo</option>
            </optgroup>
            <optgroup ng-reflect-label="Jersey" label="Jersey">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Jersey"
                    }-->
              <option ng-reflect-value="Europe/Jersey" value="Europe/Jersey">Europe/Jersey</option>
            </optgroup>
            <optgroup ng-reflect-label="Jordan" label="Jordan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Amman"
                    }-->
              <option ng-reflect-value="Asia/Amman" value="Asia/Amman">Asia/Amman</option>
            </optgroup>
            <optgroup ng-reflect-label="Kazakhstan" label="Kazakhstan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Almaty,Asia/Qyzylorda,Asia/Aqtobe,Asia/Aqtau,Asia/Oral"
                    }-->
              <option ng-reflect-value="Asia/Almaty" value="Asia/Almaty">Asia/Almaty</option>
              <option ng-reflect-value="Asia/Qyzylorda" value="Asia/Qyzylorda">Asia/Qyzylorda</option>
              <option ng-reflect-value="Asia/Aqtobe" value="Asia/Aqtobe">Asia/Aqtobe</option>
              <option ng-reflect-value="Asia/Aqtau" value="Asia/Aqtau">Asia/Aqtau</option>
              <option ng-reflect-value="Asia/Oral" value="Asia/Oral">Asia/Oral</option>
            </optgroup>
            <optgroup ng-reflect-label="Kenya" label="Kenya">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Nairobi"
                    }-->
              <option ng-reflect-value="Africa/Nairobi" value="Africa/Nairobi">Africa/Nairobi</option>
            </optgroup>
            <optgroup ng-reflect-label="Kiribati" label="Kiribati">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Tarawa,Pacific/Enderbury,Pacific/Kiritimati"
                    }-->
              <option ng-reflect-value="Pacific/Tarawa" value="Pacific/Tarawa">Pacific/Tarawa</option>
              <option ng-reflect-value="Pacific/Enderbury" value="Pacific/Enderbury">Pacific/Enderbury
              </option>
              <option ng-reflect-value="Pacific/Kiritimati" value="Pacific/Kiritimati">Pacific/Kiritimati
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Korea" label="Korea">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Seoul"
                    }-->
              <option ng-reflect-value="Asia/Seoul" value="Asia/Seoul">Asia/Seoul</option>
            </optgroup>
            <optgroup ng-reflect-label="Kuwait" label="Kuwait">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Kuwait"
                    }-->
              <option ng-reflect-value="Asia/Kuwait" value="Asia/Kuwait">Asia/Kuwait</option>
            </optgroup>
            <optgroup ng-reflect-label="Kyrgyzstan" label="Kyrgyzstan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Bishkek"
                    }-->
              <option ng-reflect-value="Asia/Bishkek" value="Asia/Bishkek">Asia/Bishkek</option>
            </optgroup>
            <optgroup ng-reflect-label="Lao People's Democratic Republic" label="Lao People's Democratic Republic">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Vientiane"
                    }-->
              <option ng-reflect-value="Asia/Vientiane" value="Asia/Vientiane">Asia/Vientiane</option>
            </optgroup>
            <optgroup ng-reflect-label="Latvia" label="Latvia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Riga"
                    }-->
              <option ng-reflect-value="Europe/Riga" value="Europe/Riga">Europe/Riga</option>
            </optgroup>
            <optgroup ng-reflect-label="Lebanon" label="Lebanon">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Beirut"
                    }-->
              <option ng-reflect-value="Asia/Beirut" value="Asia/Beirut">Asia/Beirut</option>
            </optgroup>
            <optgroup ng-reflect-label="Lesotho" label="Lesotho">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Maseru"
                    }-->
              <option ng-reflect-value="Africa/Maseru" value="Africa/Maseru">Africa/Maseru</option>
            </optgroup>
            <optgroup ng-reflect-label="Liberia" label="Liberia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Monrovia"
                    }-->
              <option ng-reflect-value="Africa/Monrovia" value="Africa/Monrovia">Africa/Monrovia</option>
            </optgroup>
            <optgroup ng-reflect-label="Libyan Arab Jamahiriya" label="Libyan Arab Jamahiriya">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Tripoli"
                    }-->
              <option ng-reflect-value="Africa/Tripoli" value="Africa/Tripoli">Africa/Tripoli</option>
            </optgroup>
            <optgroup ng-reflect-label="Liechtenstein" label="Liechtenstein">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Vaduz"
                    }-->
              <option ng-reflect-value="Europe/Vaduz" value="Europe/Vaduz">Europe/Vaduz</option>
            </optgroup>
            <optgroup ng-reflect-label="Lithuania" label="Lithuania">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Vilnius"
                    }-->
              <option ng-reflect-value="Europe/Vilnius" value="Europe/Vilnius">Europe/Vilnius</option>
            </optgroup>
            <optgroup ng-reflect-label="Luxembourg" label="Luxembourg">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Luxembourg"
                    }-->
              <option ng-reflect-value="Europe/Luxembourg" value="Europe/Luxembourg">Europe/Luxembourg
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Macao" label="Macao">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Macau"
                    }-->
              <option ng-reflect-value="Asia/Macau" value="Asia/Macau">Asia/Macau</option>
            </optgroup>
            <optgroup ng-reflect-label="Macedonia" label="Macedonia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Skopje"
                    }-->
              <option ng-reflect-value="Europe/Skopje" value="Europe/Skopje">Europe/Skopje</option>
            </optgroup>
            <optgroup ng-reflect-label="Madagascar" label="Madagascar">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Antananarivo"
                    }-->
              <option ng-reflect-value="Indian/Antananarivo" value="Indian/Antananarivo">
                Indian/Antananarivo</option>
            </optgroup>
            <optgroup ng-reflect-label="Malawi" label="Malawi">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Blantyre"
                    }-->
              <option ng-reflect-value="Africa/Blantyre" value="Africa/Blantyre">Africa/Blantyre</option>
            </optgroup>
            <optgroup ng-reflect-label="Malaysia" label="Malaysia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Kuala_Lumpur,Asia/Kuching"
                    }-->
              <option ng-reflect-value="Asia/Kuala_Lumpur" value="Asia/Kuala_Lumpur">Asia/Kuala_Lumpur
              </option>
              <option ng-reflect-value="Asia/Kuching" value="Asia/Kuching">Asia/Kuching</option>
            </optgroup>
            <optgroup ng-reflect-label="Maldives" label="Maldives">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Maldives"
                    }-->
              <option ng-reflect-value="Indian/Maldives" value="Indian/Maldives">Indian/Maldives</option>
            </optgroup>
            <optgroup ng-reflect-label="Mali" label="Mali">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Bamako"
                    }-->
              <option ng-reflect-value="Africa/Bamako" value="Africa/Bamako">Africa/Bamako</option>
            </optgroup>
            <optgroup ng-reflect-label="Malta" label="Malta">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Malta"
                    }-->
              <option ng-reflect-value="Europe/Malta" value="Europe/Malta">Europe/Malta</option>
            </optgroup>
            <optgroup ng-reflect-label="Marshall Islands" label="Marshall Islands">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Majuro,Pacific/Kwajalein"
                    }-->
              <option ng-reflect-value="Pacific/Majuro" value="Pacific/Majuro">Pacific/Majuro</option>
              <option ng-reflect-value="Pacific/Kwajalein" value="Pacific/Kwajalein">Pacific/Kwajalein
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Martinique" label="Martinique">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Martinique"
                    }-->
              <option ng-reflect-value="America/Martinique" value="America/Martinique">America/Martinique
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Mauritania" label="Mauritania">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Nouakchott"
                    }-->
              <option ng-reflect-value="Africa/Nouakchott" value="Africa/Nouakchott">Africa/Nouakchott
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Mauritius" label="Mauritius">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Mauritius"
                    }-->
              <option ng-reflect-value="Indian/Mauritius" value="Indian/Mauritius">Indian/Mauritius
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Mayotte" label="Mayotte">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Mayotte"
                    }-->
              <option ng-reflect-value="Indian/Mayotte" value="Indian/Mayotte">Indian/Mayotte</option>
            </optgroup>
            <optgroup ng-reflect-label="Mexico" label="Mexico">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Mexico_City,America/Cancun,America/Merida,America/Monterrey,America/Matamoros,America/Mazatlan,America/Chihuahua,America/Ojinaga,America/Hermosillo,America/Tijuana,America/Santa_Isabel,America/Bahia_Banderas"
                    }-->
              <option ng-reflect-value="America/Mexico_City" value="America/Mexico_City">
                America/Mexico_City</option>
              <option ng-reflect-value="America/Cancun" value="America/Cancun">America/Cancun</option>
              <option ng-reflect-value="America/Merida" value="America/Merida">America/Merida</option>
              <option ng-reflect-value="America/Monterrey" value="America/Monterrey">America/Monterrey
              </option>
              <option ng-reflect-value="America/Matamoros" value="America/Matamoros">America/Matamoros
              </option>
              <option ng-reflect-value="America/Mazatlan" value="America/Mazatlan">America/Mazatlan
              </option>
              <option ng-reflect-value="America/Chihuahua" value="America/Chihuahua">America/Chihuahua
              </option>
              <option ng-reflect-value="America/Ojinaga" value="America/Ojinaga">America/Ojinaga</option>
              <option ng-reflect-value="America/Hermosillo" value="America/Hermosillo">America/Hermosillo
              </option>
              <option ng-reflect-value="America/Tijuana" value="America/Tijuana">America/Tijuana</option>
              <option ng-reflect-value="America/Santa_Isabel" value="America/Santa_Isabel">
                America/Santa_Isabel</option>
              <option ng-reflect-value="America/Bahia_Banderas" value="America/Bahia_Banderas">
                America/Bahia_Banderas</option>
            </optgroup>
            <optgroup ng-reflect-label="Micronesia (Federated States Of)" label="Micronesia (Federated States Of)">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Chuuk,Pacific/Pohnpei,Pacific/Kosrae"
                    }-->
              <option ng-reflect-value="Pacific/Chuuk" value="Pacific/Chuuk">Pacific/Chuuk</option>
              <option ng-reflect-value="Pacific/Pohnpei" value="Pacific/Pohnpei">Pacific/Pohnpei</option>
              <option ng-reflect-value="Pacific/Kosrae" value="Pacific/Kosrae">Pacific/Kosrae</option>
            </optgroup>
            <optgroup ng-reflect-label="Moldova" label="Moldova">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Chisinau"
                    }-->
              <option ng-reflect-value="Europe/Chisinau" value="Europe/Chisinau">Europe/Chisinau</option>
            </optgroup>
            <optgroup ng-reflect-label="Monaco" label="Monaco">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Monaco"
                    }-->
              <option ng-reflect-value="Europe/Monaco" value="Europe/Monaco">Europe/Monaco</option>
            </optgroup>
            <optgroup ng-reflect-label="Mongolia" label="Mongolia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Ulaanbaatar,Asia/Hovd,Asia/Choibalsan"
                    }-->
              <option ng-reflect-value="Asia/Ulaanbaatar" value="Asia/Ulaanbaatar">Asia/Ulaanbaatar
              </option>
              <option ng-reflect-value="Asia/Hovd" value="Asia/Hovd">Asia/Hovd</option>
              <option ng-reflect-value="Asia/Choibalsan" value="Asia/Choibalsan">Asia/Choibalsan</option>
            </optgroup>
            <optgroup ng-reflect-label="Montenegro" label="Montenegro">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Podgorica"
                    }-->
              <option ng-reflect-value="Europe/Podgorica" value="Europe/Podgorica">Europe/Podgorica
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Montserrat" label="Montserrat">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Montserrat"
                    }-->
              <option ng-reflect-value="America/Montserrat" value="America/Montserrat">America/Montserrat
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Morocco" label="Morocco">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Casablanca"
                    }-->
              <option ng-reflect-value="Africa/Casablanca" value="Africa/Casablanca">Africa/Casablanca
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Mozambique" label="Mozambique">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Maputo"
                    }-->
              <option ng-reflect-value="Africa/Maputo" value="Africa/Maputo">Africa/Maputo</option>
            </optgroup>
            <optgroup ng-reflect-label="Myanmar" label="Myanmar">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Rangoon"
                    }-->
              <option ng-reflect-value="Asia/Rangoon" value="Asia/Rangoon">Asia/Rangoon</option>
            </optgroup>
            <optgroup ng-reflect-label="Namibia" label="Namibia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Windhoek"
                    }-->
              <option ng-reflect-value="Africa/Windhoek" value="Africa/Windhoek">Africa/Windhoek</option>
            </optgroup>
            <optgroup ng-reflect-label="Nauru" label="Nauru">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Nauru"
                    }-->
              <option ng-reflect-value="Pacific/Nauru" value="Pacific/Nauru">Pacific/Nauru</option>
            </optgroup>
            <optgroup ng-reflect-label="Nepal" label="Nepal">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Kathmandu"
                    }-->
              <option ng-reflect-value="Asia/Kathmandu" value="Asia/Kathmandu">Asia/Kathmandu</option>
            </optgroup>
            <optgroup ng-reflect-label="Netherlands" label="Netherlands">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Amsterdam"
                    }-->
              <option ng-reflect-value="Europe/Amsterdam" value="Europe/Amsterdam">Europe/Amsterdam
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Netherlands Antilles" label="Netherlands Antilles">
              <!--template bindings={
                    "ng-reflect-ng-for-of": ""
                    }-->
            </optgroup>
            <optgroup ng-reflect-label="New Caledonia" label="New Caledonia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Noumea"
                    }-->
              <option ng-reflect-value="Pacific/Noumea" value="Pacific/Noumea">Pacific/Noumea</option>
            </optgroup>
            <optgroup ng-reflect-label="New Zealand" label="New Zealand">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Auckland,Pacific/Chatham"
                    }-->
              <option ng-reflect-value="Pacific/Auckland" value="Pacific/Auckland">Pacific/Auckland
              </option>
              <option ng-reflect-value="Pacific/Chatham" value="Pacific/Chatham">Pacific/Chatham</option>
            </optgroup>
            <optgroup ng-reflect-label="Nicaragua" label="Nicaragua">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Managua"
                    }-->
              <option ng-reflect-value="America/Managua" value="America/Managua">America/Managua</option>
            </optgroup>
            <optgroup ng-reflect-label="Niger" label="Niger">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Niamey"
                    }-->
              <option ng-reflect-value="Africa/Niamey" value="Africa/Niamey">Africa/Niamey</option>
            </optgroup>
            <optgroup ng-reflect-label="Nigeria" label="Nigeria">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Lagos"
                    }-->
              <option ng-reflect-value="Africa/Lagos" value="Africa/Lagos">Africa/Lagos</option>
            </optgroup>
            <optgroup ng-reflect-label="Niue" label="Niue">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Niue"
                    }-->
              <option ng-reflect-value="Pacific/Niue" value="Pacific/Niue">Pacific/Niue</option>
            </optgroup>
            <optgroup ng-reflect-label="Norfolk Island" label="Norfolk Island">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Norfolk"
                    }-->
              <option ng-reflect-value="Pacific/Norfolk" value="Pacific/Norfolk">Pacific/Norfolk</option>
            </optgroup>
            <optgroup ng-reflect-label="Northern Mariana Islands" label="Northern Mariana Islands">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Saipan"
                    }-->
              <option ng-reflect-value="Pacific/Saipan" value="Pacific/Saipan">Pacific/Saipan</option>
            </optgroup>
            <optgroup ng-reflect-label="Norway" label="Norway">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Oslo"
                    }-->
              <option ng-reflect-value="Europe/Oslo" value="Europe/Oslo">Europe/Oslo</option>
            </optgroup>
            <optgroup ng-reflect-label="Oman" label="Oman">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Muscat"
                    }-->
              <option ng-reflect-value="Asia/Muscat" value="Asia/Muscat">Asia/Muscat</option>
            </optgroup>
            <optgroup ng-reflect-label="Pakistan" label="Pakistan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Karachi"
                    }-->
              <option ng-reflect-value="Asia/Karachi" value="Asia/Karachi">Asia/Karachi</option>
            </optgroup>
            <optgroup ng-reflect-label="Palau" label="Palau">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Palau"
                    }-->
              <option ng-reflect-value="Pacific/Palau" value="Pacific/Palau">Pacific/Palau</option>
            </optgroup>
            <optgroup ng-reflect-label="Palestinian Territory (Occupied)" label="Palestinian Territory (Occupied)">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Gaza,Asia/Hebron"
                    }-->
              <option ng-reflect-value="Asia/Gaza" value="Asia/Gaza">Asia/Gaza</option>
              <option ng-reflect-value="Asia/Hebron" value="Asia/Hebron">Asia/Hebron</option>
            </optgroup>
            <optgroup ng-reflect-label="Panama" label="Panama">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Panama"
                    }-->
              <option ng-reflect-value="America/Panama" value="America/Panama">America/Panama</option>
            </optgroup>
            <optgroup ng-reflect-label="Papua New Guinea" label="Papua New Guinea">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Port_Moresby"
                    }-->
              <option ng-reflect-value="Pacific/Port_Moresby" value="Pacific/Port_Moresby">
                Pacific/Port_Moresby</option>
            </optgroup>
            <optgroup ng-reflect-label="Paraguay" label="Paraguay">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Asuncion"
                    }-->
              <option ng-reflect-value="America/Asuncion" value="America/Asuncion">America/Asuncion
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Peru" label="Peru">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Lima"
                    }-->
              <option ng-reflect-value="America/Lima" value="America/Lima">America/Lima</option>
            </optgroup>
            <optgroup ng-reflect-label="Philippines" label="Philippines">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Manila"
                    }-->
              <option ng-reflect-value="Asia/Manila" value="Asia/Manila">Asia/Manila</option>
            </optgroup>
            <optgroup ng-reflect-label="Pitcairn" label="Pitcairn">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Pitcairn"
                    }-->
              <option ng-reflect-value="Pacific/Pitcairn" value="Pacific/Pitcairn">Pacific/Pitcairn
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Poland" label="Poland">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Warsaw"
                    }-->
              <option ng-reflect-value="Europe/Warsaw" value="Europe/Warsaw">Europe/Warsaw</option>
            </optgroup>
            <optgroup ng-reflect-label="Portugal" label="Portugal">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Lisbon,Atlantic/Madeira,Atlantic/Azores"
                    }-->
              <option ng-reflect-value="Europe/Lisbon" value="Europe/Lisbon">Europe/Lisbon</option>
              <option ng-reflect-value="Atlantic/Madeira" value="Atlantic/Madeira">Atlantic/Madeira
              </option>
              <option ng-reflect-value="Atlantic/Azores" value="Atlantic/Azores">Atlantic/Azores</option>
            </optgroup>
            <optgroup ng-reflect-label="Puerto Rico" label="Puerto Rico">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Puerto_Rico"
                    }-->
              <option ng-reflect-value="America/Puerto_Rico" value="America/Puerto_Rico">
                America/Puerto_Rico</option>
            </optgroup>
            <optgroup ng-reflect-label="Qatar" label="Qatar">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Qatar"
                    }-->
              <option ng-reflect-value="Asia/Qatar" value="Asia/Qatar">Asia/Qatar</option>
            </optgroup>
            <optgroup ng-reflect-label="Reunion" label="Reunion">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Reunion"
                    }-->
              <option ng-reflect-value="Indian/Reunion" value="Indian/Reunion">Indian/Reunion</option>
            </optgroup>
            <optgroup ng-reflect-label="Romania" label="Romania">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Bucharest"
                    }-->
              <option ng-reflect-value="Europe/Bucharest" value="Europe/Bucharest">Europe/Bucharest
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Russian Federation" label="Russian Federation">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Kaliningrad,Europe/Moscow,Europe/Volgograd,Europe/Samara,Europe/Simferopol,Asia/Yekaterinburg,Asia/Omsk,Asia/Novosibirsk,Asia/Novokuznetsk,Asia/Krasnoyarsk,Asia/Irkutsk,Asia/Yakutsk,Asia/Khandyga,Asia/Vladivostok,Asia/Sakhalin,Asia/Ust-Nera,Asia/Magadan,Asia/Kamchatka,Asia/Anadyr"
                    }-->
              <option ng-reflect-value="Europe/Kaliningrad" value="Europe/Kaliningrad">Europe/Kaliningrad
              </option>
              <option ng-reflect-value="Europe/Moscow" value="Europe/Moscow">Europe/Moscow</option>
              <option ng-reflect-value="Europe/Volgograd" value="Europe/Volgograd">Europe/Volgograd
              </option>
              <option ng-reflect-value="Europe/Samara" value="Europe/Samara">Europe/Samara</option>
              <option ng-reflect-value="Europe/Simferopol" value="Europe/Simferopol">Europe/Simferopol
              </option>
              <option ng-reflect-value="Asia/Yekaterinburg" value="Asia/Yekaterinburg">Asia/Yekaterinburg
              </option>
              <option ng-reflect-value="Asia/Omsk" value="Asia/Omsk">Asia/Omsk</option>
              <option ng-reflect-value="Asia/Novosibirsk" value="Asia/Novosibirsk">Asia/Novosibirsk
              </option>
              <option ng-reflect-value="Asia/Novokuznetsk" value="Asia/Novokuznetsk">Asia/Novokuznetsk
              </option>
              <option ng-reflect-value="Asia/Krasnoyarsk" value="Asia/Krasnoyarsk">Asia/Krasnoyarsk
              </option>
              <option ng-reflect-value="Asia/Irkutsk" value="Asia/Irkutsk">Asia/Irkutsk</option>
              <option ng-reflect-value="Asia/Yakutsk" value="Asia/Yakutsk">Asia/Yakutsk</option>
              <option ng-reflect-value="Asia/Khandyga" value="Asia/Khandyga">Asia/Khandyga</option>
              <option ng-reflect-value="Asia/Vladivostok" value="Asia/Vladivostok">Asia/Vladivostok
              </option>
              <option ng-reflect-value="Asia/Sakhalin" value="Asia/Sakhalin">Asia/Sakhalin</option>
              <option ng-reflect-value="Asia/Ust-Nera" value="Asia/Ust-Nera">Asia/Ust-Nera</option>
              <option ng-reflect-value="Asia/Magadan" value="Asia/Magadan">Asia/Magadan</option>
              <option ng-reflect-value="Asia/Kamchatka" value="Asia/Kamchatka">Asia/Kamchatka</option>
              <option ng-reflect-value="Asia/Anadyr" value="Asia/Anadyr">Asia/Anadyr</option>
            </optgroup>
            <optgroup ng-reflect-label="Rwanda" label="Rwanda">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Kigali"
                    }-->
              <option ng-reflect-value="Africa/Kigali" value="Africa/Kigali">Africa/Kigali</option>
            </optgroup>
            <optgroup ng-reflect-label="Saint Barthelemy" label="Saint Barthelemy">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/St_Barthelemy"
                    }-->
              <option ng-reflect-value="America/St_Barthelemy" value="America/St_Barthelemy">
                America/St_Barthelemy</option>
            </optgroup>
            <optgroup ng-reflect-label="Saint Helena" label="Saint Helena">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/St_Helena"
                    }-->
              <option ng-reflect-value="Atlantic/St_Helena" value="Atlantic/St_Helena">Atlantic/St_Helena
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Saint Kitts And Nevis" label="Saint Kitts And Nevis">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/St_Kitts"
                    }-->
              <option ng-reflect-value="America/St_Kitts" value="America/St_Kitts">America/St_Kitts
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Saint Lucia" label="Saint Lucia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/St_Lucia"
                    }-->
              <option ng-reflect-value="America/St_Lucia" value="America/St_Lucia">America/St_Lucia
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Saint Martin" label="Saint Martin">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Marigot"
                    }-->
              <option ng-reflect-value="America/Marigot" value="America/Marigot">America/Marigot</option>
            </optgroup>
            <optgroup ng-reflect-label="Saint Pierre And Miquelon" label="Saint Pierre And Miquelon">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Miquelon"
                    }-->
              <option ng-reflect-value="America/Miquelon" value="America/Miquelon">America/Miquelon
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Saint Vincent And Grenadines" label="Saint Vincent And Grenadines">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/St_Vincent"
                    }-->
              <option ng-reflect-value="America/St_Vincent" value="America/St_Vincent">America/St_Vincent
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Samoa" label="Samoa">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Apia"
                    }-->
              <option ng-reflect-value="Pacific/Apia" value="Pacific/Apia">Pacific/Apia</option>
            </optgroup>
            <optgroup ng-reflect-label="San Marino" label="San Marino">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/San_Marino"
                    }-->
              <option ng-reflect-value="Europe/San_Marino" value="Europe/San_Marino">Europe/San_Marino
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Sao Tome And Principe" label="Sao Tome And Principe">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Sao_Tome"
                    }-->
              <option ng-reflect-value="Africa/Sao_Tome" value="Africa/Sao_Tome">Africa/Sao_Tome</option>
            </optgroup>
            <optgroup ng-reflect-label="Saudi Arabia" label="Saudi Arabia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Riyadh"
                    }-->
              <option ng-reflect-value="Asia/Riyadh" value="Asia/Riyadh">Asia/Riyadh</option>
            </optgroup>
            <optgroup ng-reflect-label="Senegal" label="Senegal">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Dakar"
                    }-->
              <option ng-reflect-value="Africa/Dakar" value="Africa/Dakar">Africa/Dakar</option>
            </optgroup>
            <optgroup ng-reflect-label="Serbia" label="Serbia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Belgrade"
                    }-->
              <option ng-reflect-value="Europe/Belgrade" value="Europe/Belgrade">Europe/Belgrade</option>
            </optgroup>
            <optgroup ng-reflect-label="Seychelles" label="Seychelles">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Mahe"
                    }-->
              <option ng-reflect-value="Indian/Mahe" value="Indian/Mahe">Indian/Mahe</option>
            </optgroup>
            <optgroup ng-reflect-label="Sierra Leone" label="Sierra Leone">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Freetown"
                    }-->
              <option ng-reflect-value="Africa/Freetown" value="Africa/Freetown">Africa/Freetown</option>
            </optgroup>
            <optgroup ng-reflect-label="Singapore" label="Singapore">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Singapore"
                    }-->
              <option ng-reflect-value="Asia/Singapore" value="Asia/Singapore">Asia/Singapore</option>
            </optgroup>
            <optgroup ng-reflect-label="Slovakia" label="Slovakia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Bratislava"
                    }-->
              <option ng-reflect-value="Europe/Bratislava" value="Europe/Bratislava">Europe/Bratislava
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Slovenia" label="Slovenia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Ljubljana"
                    }-->
              <option ng-reflect-value="Europe/Ljubljana" value="Europe/Ljubljana">Europe/Ljubljana
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Solomon Islands" label="Solomon Islands">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Guadalcanal"
                    }-->
              <option ng-reflect-value="Pacific/Guadalcanal" value="Pacific/Guadalcanal">
                Pacific/Guadalcanal</option>
            </optgroup>
            <optgroup ng-reflect-label="Somalia" label="Somalia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Mogadishu"
                    }-->
              <option ng-reflect-value="Africa/Mogadishu" value="Africa/Mogadishu">Africa/Mogadishu
              </option>
            </optgroup>
            <optgroup ng-reflect-label="South Sudan" label="South Sudan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Juba"
                    }-->
              <option ng-reflect-value="Africa/Juba" value="Africa/Juba">Africa/Juba</option>
            </optgroup>
            <optgroup ng-reflect-label="South Africa" label="South Africa">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Johannesburg"
                    }-->
              <option ng-reflect-value="Africa/Johannesburg" value="Africa/Johannesburg">
                Africa/Johannesburg</option>
            </optgroup>
            <optgroup ng-reflect-label="South Georgia And Sandwich Isl." label="South Georgia And Sandwich Isl.">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/South_Georgia"
                    }-->
              <option ng-reflect-value="Atlantic/South_Georgia" value="Atlantic/South_Georgia">
                Atlantic/South_Georgia</option>
            </optgroup>
            <optgroup ng-reflect-label="Spain" label="Spain">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Madrid,Africa/Ceuta,Atlantic/Canary"
                    }-->
              <option ng-reflect-value="Europe/Madrid" value="Europe/Madrid">Europe/Madrid</option>
              <option ng-reflect-value="Africa/Ceuta" value="Africa/Ceuta">Africa/Ceuta</option>
              <option ng-reflect-value="Atlantic/Canary" value="Atlantic/Canary">Atlantic/Canary</option>
            </optgroup>
            <optgroup ng-reflect-label="Sri Lanka" label="Sri Lanka">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Colombo"
                    }-->
              <option ng-reflect-value="Asia/Colombo" value="Asia/Colombo">Asia/Colombo</option>
            </optgroup>
            <optgroup ng-reflect-label="Sudan" label="Sudan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Khartoum"
                    }-->
              <option ng-reflect-value="Africa/Khartoum" value="Africa/Khartoum">Africa/Khartoum</option>
            </optgroup>
            <optgroup ng-reflect-label="Suriname" label="Suriname">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Paramaribo"
                    }-->
              <option ng-reflect-value="America/Paramaribo" value="America/Paramaribo">America/Paramaribo
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Svalbard And Jan Mayen" label="Svalbard And Jan Mayen">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Arctic/Longyearbyen"
                    }-->
              <option ng-reflect-value="Arctic/Longyearbyen" value="Arctic/Longyearbyen">
                Arctic/Longyearbyen</option>
            </optgroup>
            <optgroup ng-reflect-label="Swaziland" label="Swaziland">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Mbabane"
                    }-->
              <option ng-reflect-value="Africa/Mbabane" value="Africa/Mbabane">Africa/Mbabane</option>
            </optgroup>
            <optgroup ng-reflect-label="Sweden" label="Sweden">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Stockholm"
                    }-->
              <option ng-reflect-value="Europe/Stockholm" value="Europe/Stockholm">Europe/Stockholm
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Switzerland" label="Switzerland">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Zurich"
                    }-->
              <option ng-reflect-value="Europe/Zurich" value="Europe/Zurich">Europe/Zurich</option>
            </optgroup>
            <optgroup ng-reflect-label="Syrian Arab Republic" label="Syrian Arab Republic">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Damascus"
                    }-->
              <option ng-reflect-value="Asia/Damascus" value="Asia/Damascus">Asia/Damascus</option>
            </optgroup>
            <optgroup ng-reflect-label="Taiwan" label="Taiwan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Taipei"
                    }-->
              <option ng-reflect-value="Asia/Taipei" value="Asia/Taipei">Asia/Taipei</option>
            </optgroup>
            <optgroup ng-reflect-label="Tajikistan" label="Tajikistan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Dushanbe"
                    }-->
              <option ng-reflect-value="Asia/Dushanbe" value="Asia/Dushanbe">Asia/Dushanbe</option>
            </optgroup>
            <optgroup ng-reflect-label="Tanzania" label="Tanzania">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Dar_es_Salaam"
                    }-->
              <option ng-reflect-value="Africa/Dar_es_Salaam" value="Africa/Dar_es_Salaam">
                Africa/Dar_es_Salaam</option>
            </optgroup>
            <optgroup ng-reflect-label="Thailand" label="Thailand">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Bangkok"
                    }-->
              <option ng-reflect-value="Asia/Bangkok" value="Asia/Bangkok">Asia/Bangkok</option>
            </optgroup>
            <optgroup ng-reflect-label="Timor-Leste" label="Timor-Leste">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Dili"
                    }-->
              <option ng-reflect-value="Asia/Dili" value="Asia/Dili">Asia/Dili</option>
            </optgroup>
            <optgroup ng-reflect-label="Togo" label="Togo">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Lome"
                    }-->
              <option ng-reflect-value="Africa/Lome" value="Africa/Lome">Africa/Lome</option>
            </optgroup>
            <optgroup ng-reflect-label="Tokelau" label="Tokelau">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Fakaofo"
                    }-->
              <option ng-reflect-value="Pacific/Fakaofo" value="Pacific/Fakaofo">Pacific/Fakaofo</option>
            </optgroup>
            <optgroup ng-reflect-label="Tonga" label="Tonga">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Tongatapu"
                    }-->
              <option ng-reflect-value="Pacific/Tongatapu" value="Pacific/Tongatapu">Pacific/Tongatapu
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Trinidad And Tobago" label="Trinidad And Tobago">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Port_of_Spain"
                    }-->
              <option ng-reflect-value="America/Port_of_Spain" value="America/Port_of_Spain">
                America/Port_of_Spain</option>
            </optgroup>
            <optgroup ng-reflect-label="Tunisia" label="Tunisia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Tunis"
                    }-->
              <option ng-reflect-value="Africa/Tunis" value="Africa/Tunis">Africa/Tunis</option>
            </optgroup>
            <optgroup ng-reflect-label="Turkey" label="Turkey">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Istanbul"
                    }-->
              <option ng-reflect-value="Europe/Istanbul" value="Europe/Istanbul">Europe/Istanbul</option>
            </optgroup>
            <optgroup ng-reflect-label="Turkmenistan" label="Turkmenistan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Ashgabat"
                    }-->
              <option ng-reflect-value="Asia/Ashgabat" value="Asia/Ashgabat">Asia/Ashgabat</option>
            </optgroup>
            <optgroup ng-reflect-label="Turks And Caicos Islands" label="Turks And Caicos Islands">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Grand_Turk"
                    }-->
              <option ng-reflect-value="America/Grand_Turk" value="America/Grand_Turk">America/Grand_Turk
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Tuvalu" label="Tuvalu">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Funafuti"
                    }-->
              <option ng-reflect-value="Pacific/Funafuti" value="Pacific/Funafuti">Pacific/Funafuti
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Uganda" label="Uganda">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Kampala"
                    }-->
              <option ng-reflect-value="Africa/Kampala" value="Africa/Kampala">Africa/Kampala</option>
            </optgroup>
            <optgroup ng-reflect-label="Ukraine" label="Ukraine">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Kiev,Europe/Uzhgorod,Europe/Zaporozhye"
                    }-->
              <option ng-reflect-value="Europe/Kiev" value="Europe/Kiev">Europe/Kiev</option>
              <option ng-reflect-value="Europe/Uzhgorod" value="Europe/Uzhgorod">Europe/Uzhgorod</option>
              <option ng-reflect-value="Europe/Zaporozhye" value="Europe/Zaporozhye">Europe/Zaporozhye
              </option>
            </optgroup>
            <optgroup ng-reflect-label="United Arab Emirates" label="United Arab Emirates">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Dubai"
                    }-->
              <option ng-reflect-value="Asia/Dubai" value="Asia/Dubai">Asia/Dubai</option>
            </optgroup>
            <optgroup ng-reflect-label="United Kingdom (UK)" label="United Kingdom (UK)">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/London"
                    }-->
              <option ng-reflect-value="Europe/London" value="Europe/London">Europe/London</option>
            </optgroup>
            <optgroup ng-reflect-label="United States (USA)" label="United States (USA)">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/New_York,America/Detroit,America/Kentucky/Louisville,America/Kentucky/Monticello,America/Indiana/Indianapolis,America/Indiana/Vincennes,America/Indiana/Winamac,America/Indiana/Marengo,America/Indiana/Petersburg,America/Indiana/Vevay,America/Chicago,America/Indiana/Tell_City,America/Indiana/Knox,America/Menominee,America/North_Dakota/Center,America/North_Dakota/New_Salem,America/North_Dakota/Beulah,America/Denver,America/Boise,America/Phoenix,America/Los_Angeles,America/Anchorage,America/Juneau,America/Sitka,America/Yakutat,America/Nome,America/Adak,America/Metlakatla,Pacific/Honolulu"
                    }-->
              <option ng-reflect-value="America/New_York" value="America/New_York">America/New_York
              </option>
              <option ng-reflect-value="America/Detroit" value="America/Detroit">America/Detroit</option>
              <option ng-reflect-value="America/Kentucky/Louisville" value="America/Kentucky/Louisville">
                America/Kentucky/Louisville</option>
              <option ng-reflect-value="America/Kentucky/Monticello" value="America/Kentucky/Monticello">
                America/Kentucky/Monticello</option>
              <option ng-reflect-value="America/Indiana/Indianapolis" value="America/Indiana/Indianapolis">
                America/Indiana/Indianapolis</option>
              <option ng-reflect-value="America/Indiana/Vincennes" value="America/Indiana/Vincennes">
                America/Indiana/Vincennes</option>
              <option ng-reflect-value="America/Indiana/Winamac" value="America/Indiana/Winamac">
                America/Indiana/Winamac</option>
              <option ng-reflect-value="America/Indiana/Marengo" value="America/Indiana/Marengo">
                America/Indiana/Marengo</option>
              <option ng-reflect-value="America/Indiana/Petersburg" value="America/Indiana/Petersburg">
                America/Indiana/Petersburg</option>
              <option ng-reflect-value="America/Indiana/Vevay" value="America/Indiana/Vevay">
                America/Indiana/Vevay</option>
              <option ng-reflect-value="America/Chicago" value="America/Chicago">America/Chicago</option>
              <option ng-reflect-value="America/Indiana/Tell_City" value="America/Indiana/Tell_City">
                America/Indiana/Tell_City</option>
              <option ng-reflect-value="America/Indiana/Knox" value="America/Indiana/Knox">
                America/Indiana/Knox</option>
              <option ng-reflect-value="America/Menominee" value="America/Menominee">America/Menominee
              </option>
              <option ng-reflect-value="America/North_Dakota/Center" value="America/North_Dakota/Center">
                America/North_Dakota/Center</option>
              <option ng-reflect-value="America/North_Dakota/New_Salem" value="America/North_Dakota/New_Salem">
                America/North_Dakota/New_Salem</option>
              <option ng-reflect-value="America/North_Dakota/Beulah" value="America/North_Dakota/Beulah">
                America/North_Dakota/Beulah</option>
              <option ng-reflect-value="America/Denver" value="America/Denver">America/Denver</option>
              <option ng-reflect-value="America/Boise" value="America/Boise">America/Boise</option>
              <option ng-reflect-value="America/Phoenix" value="America/Phoenix">America/Phoenix</option>
              <option ng-reflect-value="America/Los_Angeles" value="America/Los_Angeles">
                America/Los_Angeles</option>
              <option ng-reflect-value="America/Anchorage" value="America/Anchorage">America/Anchorage
              </option>
              <option ng-reflect-value="America/Juneau" value="America/Juneau">America/Juneau</option>
              <option ng-reflect-value="America/Sitka" value="America/Sitka">America/Sitka</option>
              <option ng-reflect-value="America/Yakutat" value="America/Yakutat">America/Yakutat</option>
              <option ng-reflect-value="America/Nome" value="America/Nome">America/Nome</option>
              <option ng-reflect-value="America/Adak" value="America/Adak">America/Adak</option>
              <option ng-reflect-value="America/Metlakatla" value="America/Metlakatla">America/Metlakatla
              </option>
              <option ng-reflect-value="Pacific/Honolulu" value="Pacific/Honolulu">Pacific/Honolulu
              </option>
            </optgroup>
            <optgroup ng-reflect-label="United States Outlying Islands" label="United States Outlying Islands">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Johnston,Pacific/Midway,Pacific/Wake"
                    }-->
              <option ng-reflect-value="Pacific/Johnston" value="Pacific/Johnston">Pacific/Johnston
              </option>
              <option ng-reflect-value="Pacific/Midway" value="Pacific/Midway">Pacific/Midway</option>
              <option ng-reflect-value="Pacific/Wake" value="Pacific/Wake">Pacific/Wake</option>
            </optgroup>
            <optgroup ng-reflect-label="Uruguay" label="Uruguay">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Montevideo"
                    }-->
              <option ng-reflect-value="America/Montevideo" value="America/Montevideo">America/Montevideo
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Uzbekistan" label="Uzbekistan">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Samarkand,Asia/Tashkent"
                    }-->
              <option ng-reflect-value="Asia/Samarkand" value="Asia/Samarkand">Asia/Samarkand</option>
              <option ng-reflect-value="Asia/Tashkent" value="Asia/Tashkent">Asia/Tashkent</option>
            </optgroup>
            <optgroup ng-reflect-label="Vanuatu" label="Vanuatu">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Efate"
                    }-->
              <option ng-reflect-value="Pacific/Efate" value="Pacific/Efate">Pacific/Efate</option>
            </optgroup>
            <optgroup ng-reflect-label="Venezuela" label="Venezuela">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Caracas"
                    }-->
              <option ng-reflect-value="America/Caracas" value="America/Caracas">America/Caracas</option>
            </optgroup>
            <optgroup ng-reflect-label="Viet Nam" label="Viet Nam">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Ho_Chi_Minh"
                    }-->
              <option ng-reflect-value="Asia/Ho_Chi_Minh" value="Asia/Ho_Chi_Minh">Asia/Ho_Chi_Minh
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Virgin Islands (British)" label="Virgin Islands (British)">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Tortola"
                    }-->
              <option ng-reflect-value="America/Tortola" value="America/Tortola">America/Tortola</option>
            </optgroup>
            <optgroup ng-reflect-label="Virgin Islands (U.S.)" label="Virgin Islands (U.S.)">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/St_Thomas"
                    }-->
              <option ng-reflect-value="America/St_Thomas" value="America/St_Thomas">America/St_Thomas
              </option>
            </optgroup>
            <optgroup ng-reflect-label="Wallis And Futuna" label="Wallis And Futuna">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Wallis"
                    }-->
              <option ng-reflect-value="Pacific/Wallis" value="Pacific/Wallis">Pacific/Wallis</option>
            </optgroup>
            <optgroup ng-reflect-label="Western Sahara" label="Western Sahara">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/El_Aaiun"
                    }-->
              <option ng-reflect-value="Africa/El_Aaiun" value="Africa/El_Aaiun">Africa/El_Aaiun</option>
            </optgroup>
            <optgroup ng-reflect-label="Yemen" label="Yemen">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Aden"
                    }-->
              <option ng-reflect-value="Asia/Aden" value="Asia/Aden">Asia/Aden</option>
            </optgroup>
            <optgroup ng-reflect-label="Zambia" label="Zambia">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Lusaka"
                    }-->
              <option ng-reflect-value="Africa/Lusaka" value="Africa/Lusaka">Africa/Lusaka</option>
            </optgroup>
            <optgroup ng-reflect-label="Zimbabwe" label="Zimbabwe">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Harare"
                    }-->
              <option ng-reflect-value="Africa/Harare" value="Africa/Harare">Africa/Harare</option>
            </optgroup>
            <optgroup ng-reflect-label="Sint Maarten" label="Sint Maarten">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Lower_Princes"
                    }-->
              <option ng-reflect-value="America/Lower_Princes" value="America/Lower_Princes">
                America/Lower_Princes</option>
            </optgroup>
            <optgroup ng-reflect-label="Curacao" label="Curacao">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Curacao"
                    }-->
              <option ng-reflect-value="America/Curacao" value="America/Curacao">America/Curacao</option>
            </optgroup>
            <optgroup ng-reflect-label="Bonaire" label="Bonaire">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Kralendijk"
                    }-->
              <option ng-reflect-value="America/Kralendijk" value="America/Kralendijk">America/Kralendijk
              </option>
            </optgroup>
            <optgroup ng-reflect-label="North Korea" label="North Korea">
              <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Pyongyang"
                    }-->
              <option ng-reflect-value="Asia/Pyongyang" value="Asia/Pyongyang">Asia/Pyongyang</option>
            </optgroup>
          </select>
        </li>
      </ol>
      <div class="clr"></div>
    </div>
    <div class="clear"></div>
  </div>
  <div id="displayCreateCourse" class="boxs">
    <table cellspacing="0" cellpadding="0" class="PageBackGround" style="margin: 10px;width:50%">
      <tbody>
        <tr>
          <div class="signupstep1form createcourseform">
            <form name="form" enctype="multipart/form-data" method="post"
              (ngSubmit)="f.form.valid && navigateWithData('dashboard/previewcourse', createCourse)" #f="ngForm"
              novalidate>
              <table>
                <tbody>
                  <tr *ngIf="showFieldForEdit">
                    <td>
                      <div class="gwt-Label">* Category:</div>
                    </td>
                  </tr>

                  <tr *ngIf="showFieldForEdit">
                    <td align="left" style="vertical-align: top;">
                      <select [(ngModel)]="createCourse.categories[0].courseCategoryId" #courseCategoryId="ngModel"
                        required name="createCourse.categories[0].courseCategoryId" class="categoriesSelect"
                        style="width: 100%; margin-bottom: 0px;" [disabled]="disabledForEdit">
                        <option value="-1">Select Category</option>
                        <option [value]="cat.courseCategoryId" *ngFor="let cat of catogory">
                          {{cat.categoryFullText}}</option>
                      </select>
                      <div *ngIf="f.submitted && !courseCategoryId.valid" class="errorDiv" style="color: #f00">
                        Category is required
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" style="vertical-align: top;">
                      <div class="gwt-Label pastLabel">
                        Or Choose from your past sessions
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" style="vertical-align: top;">
                      <select class="gwt-ListBox expiredCoursesSelect" #courseID (change)="expireCourse(courseID.value)"
                        style="width: 100%;" [disabled]="disabledForEdit">
                        <option value="Select a course">Select a session</option>
                        <option [value]="pastcourse.courseId" *ngFor="let pastcourse of expcourse">
                          {{pastcourse.courseFullName}}
                        </option>
                      </select>
                    </td>
                  </tr>

                  <tr>
                    <td align="left" style="vertical-align: top;">
                      <div class="gwt-Label">
                        Add Co-host (optional)
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" style="vertical-align: top;" *ngIf="createCourse.cohostUser != null">
                      <div class="coHostWrap">
                        <div class="four columns profilevisitors cohostUser">
                          <div class="proleft">
                            <img
                              *ngIf="createCourse.cohostUser.imageURL != null && !createCourse.cohostUser.imageURL.includes('profilebig.png')"
                              width="60" height="60" border="0" src="{{createCourse.cohostUser.imageURL}}"
                              class="greyBorder profileImage">
                            <ngx-avatar
                              *ngIf="createCourse.cohostUser.imageURL == null || createCourse.cohostUser.imageURL.includes('profilebig.png')"
                              name="{{createCourse.cohostUser.userName}}" size="60">
                            </ngx-avatar>
                          </div>
                          <div class="">
                            <label>
                              <a href="javascript: void(0);" style="font-size: 14px;" class="styledtooltip"
                                [routerLink]="['../profile']"
                                [queryParams]="{ user: createCourse.cohostUser.userDetailsId }">
                                {{createCourse.cohostUser.userName}}
                                <span></span></a>
                            </label>
                            <br>
                            <div style="font-size: 11px;"
                              *ngIf="createCourse.cohostUser.userBaseType != null && createCourse.cohostUser.userBaseType != undefined"
                              class="profilecou gwt-Label profile-userType userype">
                              {{createCourse.cohostUser.userBaseType}},
                              <label
                                *ngIf="createCourse.cohostUser.educationalInstitution != null && createCourse.cohostUser.educationalInstitution != undefined">
                                {{createCourse.cohostUser.educationalInstitution}},
                                {{createCourse.cohostUser.campus}}</label>
                            </div>
                          </div>
                          <div class="clr"></div>
                        </div>
                        <span pTooltip="Delete Co-host" class="coHostSubWrap delete"
                          (click)="removeCohost((createCourse.coHosts != undefined && createCourse.coHosts[0] != undefined) ? createCourse.coHosts[0].userDetailsId : sessionCoHostId[0].userDetailsId)">
                          <i class="fa fa-trash"></i>
                        </span>
                      </div>
                    </td>
                    <td align="left" style="vertical-align: top;" *ngIf="createCourse.cohostUser == null">
                      <a href="javascript:;" (click)="addCoHost()" class="btncr"><i style="margin-right: 10px;"
                          class="fa fa-plus"></i>Add
                        Co-host</a>
                      <!-- <p-autoComplete field="username" name="sessionCoHostId" [multiple]="true" class="gwt-TextBox"
                        [suggestions]="userToresults" (completeMethod)="searchusers($event)"
                        (onSelect)="onSelectCoHost()" (onUnselect)="onUnselectCoHost()" [(ngModel)]="sessionCoHostId">
                      </p-autoComplete> -->
                    </td>
                  </tr>

                  <tr>
                    <td style="
                    width: 50%;
                    display: inline-block;
                ">
                      <div class="gwt-Label" style="margin-top: 15px;">
                        Session Image: (max size: 575 x 150)
                      </div>
                    </td>
                    <!-- <td style="
                    width: 50%;
                    display: inline-block;
                ">
                      <div class="gwt-Label" style="margin-top: 15px;">
                        Upload Document: (max size:5 MB)
                      </div>
                    </td> -->
                  </tr>
                  <tr>
                    <td align="left" style="vertical-align: top;" style="
                    width: 50%;
                    display: inline-block;
                ">
                      <!--<form target="FormPanel_createcourse_2" enctype="multipart/form-data" method="post">-->
                      <div class="upld-form-elements">
                        <input type="file" class="gwt-FileUpload" size="40" (change)="courseImageUpload($event)"
                          name="GWTMU-0990379387325141" accept="" />
                      </div>
                      <!--</form>-->
                    </td>
                    <!-- <td align="left" style="vertical-align: top;">
                      <table cellspacing="0" cellpadding="0" class="upld-status" style="display: none;">
                        <tbody>
                          <tr>
                            <td align="left" style="vertical-align: top;">
                              <div class="cancel"></div>
                            </td>
                            <td align="left" style="vertical-align: top;">
                              <div class="filename"></div>
                            </td>
                            <td align="left" style="vertical-align: top;">
                              <div class="status"></div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td> -->
                    <!-- <td align="left" style="vertical-align: top;" style="
                    width: 50%;
                    display: inline-block;
                ">
                      <div class="upld-form-elements">
                        <input #DocInput type="file" class="gwt-DocUpload" size="40" (change)="courseDocUpload($event)"
                          name="GWTMU-0990379387325140" accept="" />
                        <div *ngIf="maxUpload" class="errorDiv" style="color: #f00">
                          Max upload size: 5MB
                        </div>
                        <div *ngIf="invalidUpload" class="errorDiv" style="color: #f00">
                          Allowed File Types: pdf, ppt, images, zip, word, xls
                        </div>
                        <div class="gwt-Label" style="margin-top: 5px;">
                          File types: pdf, ppt, images, zip, word, xls
                        </div>
                        <div class="gwt-Label" style="margin-top: 2px;">
                          <b>Note: </b>For multiple files, zip and upload
                        </div>
                      </div>
                    </td> -->
                  </tr>
                  <tr *ngIf="previewurl != ''">
                    <td>
                      <img src="{{previewurl}}" class="featured-image"
                        style="width: auto; height: 150px; border-radius: 0;" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-Label" style="margin-top: 15px;">
                        Upload Document: (max size: 50MB) (Optional)
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td *ngIf="(createCourse.slideDeckFileNameOriginal == null || !isEdit) && createCourse.isNewCourse">
                      <p-fileUpload #DocInput showUploadButton="false" showCancelButton="false"
                        chooseLabel="Select Document" cancelLabel="Remove Document" customUpload="true"
                        (onRemove)="onRemoveFile($event); formDataDoc = null" (onClear)="onClearFile()"
                        (onSelect)="courseDocUpload($event)">
                      </p-fileUpload>
                      <div class="gwt-Label" style="margin-top: 2px;">
                        <b>Note: </b>For multiple files, zip and upload
                      </div>
                      <div class="gwt-Label" style="margin-top: 2px; color: inherit;">
                        <input type="checkbox" name="docCheckbox" #docCheckbox="ngModel"
                          [(ngModel)]="createCourse.docCheckbox" />
                        I agree that this document doesn't violate other's copyright or privacy rights.
                        <div *ngIf="formDataDoc != null && createCourse.docCheckbox != true" class="errorDiv"
                          style="color: #f00">
                          Please agree that this document doesn't violate other's copyright or privacy rights.
                        </div>
                      </div>
                    </td>
                    <td *ngIf="createCourse.slideDeckFileNameOriginal != null && isEdit">
                      <div class="gwt-Label" style="margin-top: 2px;">
                        <b>Uploaded Document: </b><a href="javascript:void(0)"
                          (click)="downloadDoc(createCourse.courseId, createCourse.slideDeckFileNameOriginal)">{{createCourse.slideDeckFileNameOriginal}}</a>
                      </div>
                    </td>
                    <td *ngIf="createCourse.slideDeckFileNameOriginal != null && !createCourse.isNewCourse">
                      <div class="gwt-Label" style="margin-top: 2px; position: relative;">
                        <b>Uploaded Document: </b><a href="javascript:void(0)">{{createCourse.slideDeckFileNameOriginal}}</a>
                          <span pTooltip="Remove Document" class="uploadWrap delete"
                          (click)="slideDeckFileName = null; 
                                  createCourse.isNewCourse = true; 
                                  createCourse.slideDeckFileNameOriginal = null; 
                                  createCourse.slideDeckFileName = null;
                                  editCourse.isNewCourse = true; 
                                  editCourse.slideDeckFileNameOriginal = null; 
                                  editCourse.slideDeckFileName = null;">
                          <i class="fa fa-trash"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-Label" style="margin-top: 15px;">
                        * Session Full Name:
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input type="text" [(ngModel)]="createCourse.courseFullName" name="createCourse.courseFullName"
                        #courseFullName="ngModel" required class="gwt-TextBox" maxlength="200"
                        style="margin-bottom: 0px;" />
                      <div *ngIf="f.submitted && !courseFullName.valid" class="errorDiv" style="color: #f00">
                        Session full name is required
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-Label"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-Label" style="margin-top: 15px;">
                        * Session Short Name:
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input type="text" [(ngModel)]="createCourse.courseShortName" name="createCourse.courseShortName"
                        #courseShortName="ngModel" required class="gwt-TextBox" maxlength="100"
                        style="margin-bottom: 0px;" />
                      <div *ngIf="f.submitted && !courseShortName.valid" class="errorDiv" style="color: #f00">
                        Session short name is required
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-Label"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-Label" style="margin-top: 15px;">
                        Session Video Preview URL (Optional):
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input type="text" #url="ngModel" [(ngModel)]="createCourse.url" name="createCourse.url"
                        pattern="https?://.+" class="gwt-TextBox" maxlength="500" style="margin-bottom: 0px;" />
                      <div *ngIf="(f.submitted && !url.valid) || !url.valid" class="errorDiv" style="color: #f00">
                        Url is Invalid
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-Label"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-Label" style="margin-top: 15px;">
                        * Session Summary:
                      </div>
                    </td>
                  </tr>
                  <tr class="editor">
                    <td>
                      <!-- <p-editor
													style="display: block;margin: 0;"
													[(ngModel)]="createCourse.courseSummary"
													#courseSummary="ngModel" required
													name="createCourse.courseSummary"
													[style]="{'height':'320px'}"></p-editor> -->
                      <ejs-richtexteditor [insertImageSettings]='insertImageSettings' style="display: block;margin: 0;"
                        #toolsRTE id="alltoolRTE" [(value)]="createCourse.courseSummary" [height]="400"
                        [(ngModel)]="createCourse.courseSummary" #courseSummary="ngModel" required
                        name="createCourse.courseSummary" [toolbarSettings]="tools">
                      </ejs-richtexteditor>
                      <div *ngIf="f.submitted && !courseSummary.valid" class="errorDiv" style="color: #f00">
                        Session Summary is required
                      </div>
                    </td>
                  </tr>
                  <!-- <tr>
                                    <td>
                                        <div class="gwt-HTML">
                                            <br> </div>
                                    </td>
                                </tr> -->
                  <tr>
                    <td>
                      <div class="gwt-HTML">
                        <br />
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="showFieldForEdit">
                    <td>
                      <div class="gwt-Label">Session Schedule:</div>
                    </td>
                  </tr>
                  <tr *ngIf="showFieldForEdit">
                    <td>
                      <table>
                        <tbody>
                          <tr *ngIf="showFieldForEdit">
                            <td align="left" style="vertical-align: top;width: 32%">
                              <p-calendar (onSelect)="dateSelected($event)" class="gwt-DateBox"
                                [(ngModel)]="createCourse.StartDate" [minDate]="minDate" [readonlyInput]="true"
                                name="StartDate" dateFormat="mm/dd/yy" #startDateCal="ngModel"
                                [disabled]="isCourseDateEntryDisabled"></p-calendar>
                              <div
                                *ngIf="f.submitted && (createCourse.StartDate==undefined || createCourse.StartDate=='')"
                                class="errorDiv" style="color: #f00">
                                Start Date required
                              </div>
                            </td>
                            <td align="left" style="vertical-align: top;width: 2%"></td>
                            <td align="left" style="vertical-align: top;width: 32%" class="starttimes">
                              <select [(ngModel)]="createCourse.startTime.timeId" name="createCourse.startTime.timeId"
                                (change)="startTime($event.target.value);" class="gwt-ListBox"
                                #startTimeSelect="ngModel" style="margin-bottom: 0px;"
                                [disabled]="isCourseDateEntryDisabled">
                                <option value="" [selected]="selectDefaultTime">Start Time</option>
                                <option [value]="timee.timeId" *ngFor="let timee of time">
                                  {{formatTime(timee)}}
                                </option>
                              </select>
                              <div *ngIf="f.submitted && (start==undefined || start=='')" class="errorDiv"
                                style="color: #f00">
                                Start Time required
                              </div>
                              <div *ngIf="(f.submitted && (wrongTime) || wrongTime)" class="errorDiv"
                                style="color: #f00">
                                Please select start time at least 1 hr after current time.
                              </div>
                            </td>
                            <td align="left" style="vertical-align: top;width: 2%"></td>
                            <!-- <td style="padding-left: 20px; vertical-align: top;" align="left">
																<div class="gwt-HTML">Duration (Hours)</div>
															</td> -->
                            <td align="left" style="vertical-align: top;width: 32%">
                              <select class="gwt-ListBox" [(ngModel)]="createCourse.endTime.timeId"
                                (change)="endTime($event.target.value);" name="createCourse.endTime.timeId"
                                #endTimeSelect="ngModel" style="margin-bottom: 0px;"
                                [disabled]="isCourseDateEntryDisabled">
                                <option value="" [selected]="selectDefaultTime">Duration (hrs)</option>
                                <option [value]="timee.timeId" *ngFor="let timee of durationArray">
                                  {{timee.durationDisplay}}</option>
                              </select>
                              <div *ngIf="f.submitted && (end==undefined || end=='')" class="errorDiv"
                                style="color: #f00">
                                End Time required
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr *ngIf="showFieldForEdit">
                    <td>
                      <table>
                        <tbody>
                          <tr *ngIf="showFieldForEdit">
                            <td align="left" style="vertical-align: top;width: 34%">
                              <span class="gwt-CheckBox">
                                <input [(ngModel)]="repeatcheckbox" name="repeatcheckbox" (change)="addprop($event)"
                                  type="checkbox" value="on" id="gwt-uid-1" tabindex="0" /><label
                                  for="gwt-uid-1">Repeat</label></span>
                            </td>
                            <td align="left" style="vertical-align: top;width: 66%">
                              <a class="gwt-Anchor" (click)="timezone = true" href="javascript:;"
                                style="float: left; margin-right:0.2em;">TimeZone
                              </a>
                              <div *ngIf="createCourse.timeZone" class="timeZoneLbl">
                                {{createCourse.timeZone}}
                              </div>
                            </td>
                          </tr>
                          <tr *ngIf="showFieldForEdit">
                            <td colspan="2" align="left" style="vertical-align: top;width: 100%">
                              <div class="gwt-Label">{{summary}}</div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-HTML">
                        <br />
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="disabledForEdit">
                    <td>
                      <div class="gwt-Label">Session Schedule (View only):</div>
                    </td>
                  </tr>
                  <tr *ngIf="disabledForEdit">
                    <td style="vertical-align: top;">
                      <div class="info1">
                        {{createCourse.courseScheduleSummaryShort}}
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="disabledForEdit">
                    <td style="vertical-align: top;">
                      <table class="timesep">
                        <tbody>
                          <tr>
                            <td align="center">
                              <div class="info2">
                                Effective
                                {{createCourse.displayStartDateOnlyConverted |
                                amDateFormat:'ll'}} Until
                                {{createCourse.displayStopDateOnlyConverted |
                                amDateFormat:'ll'}}
                              </div>
                            </td>
                            <td align="left" style="vertical-align: bottom;"></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr *ngIf="disabledForEdit">
                    <td style="vertical-align: top;">
                      <div class="info3">
                        From {{createCourse.startTimeConverted}} To
                        {{createCourse.endTimeConverted}}
                        ({{createCourse.timeZoneConverted}})
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="disabledForEdit">
                    <td *ngIf="createCourse.currentSchedule != null" style="vertical-align: top;">
                      <div class="info2">
                        {{createCourse.courseScheduleSummaryLong}}
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="showFieldForEdit">
                    <td>
                      <div class="gwt-Label" style="margin-top: 15px;">
                        * Session Fee:
                      </div>
                    </td>
                  </tr>
                  <tr *ngIf="showFieldForEdit">
                    <td>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <select style="margin-bottom: 0px;" class="gwt-ListBox"
                                (change)="courseFeechange($event.target.value)" #fee="ngModel" required
                                [(ngModel)]="createCourse.fee" name="createCourse.fee">
                                <option value="-1">Select Session Fee</option>
                                <option [value]="fee" *ngFor="let fee of coursefees">
                                  {{fee}}</option>
                              </select>
                              <div *ngIf="f.submitted && !fee.valid" class="errorDiv" style="color: #f00">
                                Fee is required
                              </div>
                            </td>
                            <!-- <td>
                              <div class="gwt-HTML">&nbsp;&nbsp;</div>
                            </td> -->
                            <td *ngIf="createCourse.fee == 'Paid'">
                              <small class="dollers">$</small><input type="text" [(ngModel)]="createCourse.cost"
                                #cost="ngModel" required name="createCourse.cost" class="dollersamount gwt-TextBox"
                                maxlength="12" style="padding-left: 52px;margin-bottom: 0px;" />
                              <div *ngIf="f.submitted && !cost.valid" class="errorDiv" style="color: #f00">
                                Cost is required
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr *ngIf="createCourse.fee == 'Paid'">
                    <td>
                      <div class="msgDiv">
                        You can add promo codes for this session under "Sessions -> My Sessions"
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-Label"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-Label" style="margin-top: 15px;">
                        * Expected No. of Participants:
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input pTooltip="For 100+ participants, premium membership required" type="text" #expectedAttendees="ngModel" required [(ngModel)]="createCourse.expectedAttendees" name="createCourse.expectedAttendees"
                        class="gwt-TextBox" style="margin-bottom: 0px;" pattern= "[0-9]+"/>
                      <div *ngIf="f.submitted && !expectedAttendees.valid" class="errorDiv" style="color: #f00">
                        Please enter whole number
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table>
                        <tbody>
                          <tr *ngIf="showFieldForEdit">
                            <td align="left" style="vertical-align: top;width: 50% !important;float: left;">
                              <span class="gwt-RadioButton"><input type="radio" #courseType="ngModel" required
                                  [(ngModel)]="createCourse.courseType" name="createCourse.courseType" value="Public"
                                  id="gwt-uid-2" tabindex="0" checked="" /><label for="gwt-uid-2">public</label></span>
                            </td>
                            <td align="left" style="vertical-align: top;width: 50% !important;float: left;">
                              <span class="gwt-RadioButton"><input type="radio" #courseType="ngModel" required
                                  [(ngModel)]="createCourse.courseType" name="createCourse.courseType" value="Private"
                                  id="gwt-uid-3" tabindex="0" (change)="privateTypeSelected()" /><label
                                  for="gwt-uid-3">private</label></span>
                            </td>

                            <td align="left" style="vertical-align: top;">
                              <div class="gwt-Label">
                                <div *ngIf="f.submitted && !courseType.valid" class="errorDiv" style="color: #f00">
                                  Session Type is required
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr style="margin-top: 15px;display: block;">
                            <td>
                              <table>
                                <tbody>
                                  <tr>
                                    <input type="checkbox" name="sponsorshipRequired" #sponsorshipRequired="ngModel"
                                      [(ngModel)]="createCourse.sponsorshipRequired" />
                                    I am looking for Sponsors for this session.
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr *ngIf="createCourse.sponsorshipRequired == '1'"
                            style="margin-top: 15px;display: block; padding: 10px;">
                            <td>
                              <table class="sponsoreTable" *ngFor="let sponsor of createCourse.sponsorshipLevels">
                                <tbody>
                                  <tr>
                                    <td class="pricing"
                                      [className]="sponsor.sponsorshipLevel == 'Silver' ? 'silverBanner pricing' : sponsor.sponsorshipLevel == 'Gold' ? 'goldBanner pricing' : 'platinumBanner pricing'">
                                      {{sponsor.sponsorshipLevel}} - ${{sponsor.amount}}
                                      <a class="educ-show-edit ico_edit sponsoreEdit"
                                        (click)="editSponsoreClick(sponsor)" href="javascript:void(0);">
                                        Edit</a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td class="pricingContent" [innerHtml]="sponsor.benefits | safeHtml">

                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr *ngIf="showFieldForEdit" class="clearfix" style="margin-top: 15px; display: block;">
                            <td align="left" style="vertical-align: top;width: 50% !important;float: left;">
                              <span class="gwt-RadioButton"><input style="display: inline-flex;width: 6%;" type="radio" #notifyPastAttendees="ngModel" required
                                  [(ngModel)]="createCourse.notifyPastAttendees" name="createCourse.notifyPastAttendees" [value]="true"
                                  id="gwt-uid-21" /><label style="display: inline-flex;width: 93%;" for="gwt-uid-21">Auto notify all participants from my all previous sessions</label></span>
                            </td>
                            <td align="left" style="vertical-align: top;width: 50% !important;float: left;">
                              <span class="gwt-RadioButton"><input style="display: inline-flex;width: 6%;" type="radio" #notifyPastAttendees="ngModel" required
                                  [(ngModel)]="createCourse.notifyPastAttendees" name="createCourse.notifyPastAttendees" [value]="false"
                                  id="gwt-uid-31" /><label style="display: inline-flex;width: 93%;"
                                  for="gwt-uid-31">I will invite manually from "My Session</label></span>
                            </td>

                            <td align="left" style="vertical-align: top;">
                              <div class="gwt-Label">
                                <div *ngIf="f.submitted && !notifyPastAttendees.valid" class="errorDiv" style="color: #f00">
                                  Notify Past Attendees  is required
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr style="margin-top: 15px;display: block;">
                            <td align="left" style="vertical-align: top;">
                              <a style="padding: 0 10px;" *ngIf="createCourse.registrationQuestionnaireId == 0 || createCourse.registrationQuestionnaireId == null" href="javascript:;" (click)="QuestionnaireEvent('add')" class="btncr"><i style="margin-right: 10px;"
                                  class="fa fa-plus"></i>Add Custom Questions</a>
                              <a style="padding: 0 10px;" *ngIf="createCourse.registrationQuestionnaireId > 0" href="javascript:;" (click)="QuestionnaireEvent('edit')" class="btncr"><i style="margin-right: 10px;"
                                  class="fa fa-pencil"></i>Edit Custom Questions</a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="3" [class.has-error]="tc.touched">
                              <br />
                              <br />
                              <input type="checkbox" name="tc" #tc="ngModel" required [(ngModel)]="createCourse.tc" />
                              I agree with
                              <a href="legal/termsandconditions.html" target="_blank">Terms & Conditions</a>
                              <div class="gwt-Label">
                                <div *ngIf="f.submitted && !tc.valid" class="errorDiv" style="color: #f00">
                                  Terms & Conditions is required
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-HTML">
                        <br />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table>
                        <tbody>
                          <tr *ngIf="showFieldForEdit">
                            <td>
                              <input id="submitBtn" type="submit" class="buttonStyle2" value="Preview Before Submit" />
                            </td>
                            <td align="left" style="vertical-align: top;">
                              <div class="gwt-HTML">&nbsp;&nbsp;</div>
                            </td>
                            <td>
                              <button type="button" [routerLink]="['/dashboard/home']"
                                class="buttonStyle2">Cancel</button>
                            </td>
                          </tr>
                          <tr *ngIf="!showFieldForEdit">
                            <td>
                              <input type="button" class="buttonStyle2" value="Save"
                                (click)="submitForEditingCourse()" />
                            </td>
                            <td align="left" style="vertical-align: top;">
                              <div class="gwt-HTML">&nbsp;&nbsp;</div>
                            </td>
                            <td>
                              <button type="button" [routerLink]="['/dashboard/courseaccount']" class="buttonStyle2">
                                Never Mind
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-HTML">
                        <br />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="gwt-HTML">
                        <br />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="gwt-DialogBox dialogBody" [style.display]="editDisplay(timezone)"
  style="left: 474px; top: 30%; display: none; position: fixed; overflow: visible;">
  <div class="">
    <table cellspacing="0" cellpadding="0" class="" style="width: 400px; height: 200px;">
      <tbody>
        <tr class="dialogTop">
          <td class="dialogTopLeft">
            <div class="dialogTopLeftInner"></div>
          </td>
          <td class="dialogTopCenter">
            <div class="dialogTopCenterInner">
              <div class="Caption">
                <h1>Time Zone</h1>
              </div>
            </div>
          </td>
          <td class="dialogTopRight">
            <div class="dialogTopRightInner"></div>
          </td>
        </tr>
        <tr class="dialogMiddle">
          <td class="dialogMiddleLeft">
            <div class="dialogMiddleLeftInner"></div>
          </td>
          <td class="dialogMiddleCenter">
            <div class="dialogMiddleCenterInner dialogContent">
              <table cellspacing="0" cellpadding="0" style="width: 300px;">
                <tbody>
                  <tr>
                    <td align="left" style="vertical-align: top;">
                      <select class="form-control" [(ngModel)]="createCourse.timeZone" class="gwt-ListBox"
                        style="width: 90%;" #t (change)="timeZonechange(t.value);">
                        <optgroup ng-reflect-label="Afghanistan" label="Afghanistan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Kabul"
                    }-->
                          <option ng-reflect-value="Asia/Kabul" value="Asia/Kabul">Asia/Kabul
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Aland Islands" label="Aland Islands">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Mariehamn"
                    }-->
                          <option ng-reflect-value="Europe/Mariehamn" value="Europe/Mariehamn">Europe/Mariehamn</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Albania" label="Albania">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Tirane"
                    }-->
                          <option ng-reflect-value="Europe/Tirane" value="Europe/Tirane">
                            Europe/Tirane</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Algeria" label="Algeria">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Algiers"
                    }-->
                          <option ng-reflect-value="Africa/Algiers" value="Africa/Algiers">
                            Africa/Algiers</option>
                        </optgroup>
                        <optgroup ng-reflect-label="American Samoa" label="American Samoa">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Pago_Pago"
                    }-->
                          <option ng-reflect-value="Pacific/Pago_Pago" value="Pacific/Pago_Pago">Pacific/Pago_Pago
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Andorra" label="Andorra">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Andorra"
                    }-->
                          <option ng-reflect-value="Europe/Andorra" value="Europe/Andorra">
                            Europe/Andorra</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Angola" label="Angola">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Luanda"
                    }-->
                          <option ng-reflect-value="Africa/Luanda" value="Africa/Luanda">
                            Africa/Luanda</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Anguilla" label="Anguilla">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Anguilla"
                    }-->
                          <option ng-reflect-value="America/Anguilla" value="America/Anguilla">America/Anguilla</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Antarctica" label="Antarctica">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Antarctica/McMurdo,Antarctica/Rothera,Antarctica/Palmer,Antarctica/Mawson,Antarctica/Davis,Antarctica/Casey,Antarctica/Vostok,Antarctica/DumontDUrville,Antarctica/Syowa,Antarctica/Troll"
                    }-->
                          <option ng-reflect-value="Antarctica/McMurdo" value="Antarctica/McMurdo">Antarctica/McMurdo
                          </option>
                          <option ng-reflect-value="Antarctica/Rothera" value="Antarctica/Rothera">Antarctica/Rothera
                          </option>
                          <option ng-reflect-value="Antarctica/Palmer" value="Antarctica/Palmer">Antarctica/Palmer
                          </option>
                          <option ng-reflect-value="Antarctica/Mawson" value="Antarctica/Mawson">Antarctica/Mawson
                          </option>
                          <option ng-reflect-value="Antarctica/Davis" value="Antarctica/Davis">Antarctica/Davis</option>
                          <option ng-reflect-value="Antarctica/Casey" value="Antarctica/Casey">Antarctica/Casey</option>
                          <option ng-reflect-value="Antarctica/Vostok" value="Antarctica/Vostok">Antarctica/Vostok
                          </option>
                          <option ng-reflect-value="Antarctica/DumontDUrville" value="Antarctica/DumontDUrville">
                            Antarctica/DumontDUrville
                          </option>
                          <option ng-reflect-value="Antarctica/Syowa" value="Antarctica/Syowa">Antarctica/Syowa</option>
                          <option ng-reflect-value="Antarctica/Troll" value="Antarctica/Troll">Antarctica/Troll</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Antigua And Barbuda" label="Antigua And Barbuda">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Antigua"
                    }-->
                          <option ng-reflect-value="America/Antigua" value="America/Antigua">
                            America/Antigua</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Argentina" label="Argentina">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Argentina/Buenos_Aires,America/Argentina/Cordoba,America/Argentina/Salta,America/Argentina/Jujuy,America/Argentina/Tucuman,America/Argentina/Catamarca,America/Argentina/La_Rioja,America/Argentina/San_Juan,America/Argentina/Mendoza,America/Argentina/San_Luis,America/Argentina/Rio_Gallegos,America/Argentina/Ushuaia"
                    }-->
                          <option ng-reflect-value="America/Argentina/Buenos_Aires"
                            value="America/Argentina/Buenos_Aires">
                            America/Argentina/Buenos_Aires</option>
                          <option ng-reflect-value="America/Argentina/Cordoba" value="America/Argentina/Cordoba">
                            America/Argentina/Cordoba
                          </option>
                          <option ng-reflect-value="America/Argentina/Salta" value="America/Argentina/Salta">
                            America/Argentina/Salta</option>
                          <option ng-reflect-value="America/Argentina/Jujuy" value="America/Argentina/Jujuy">
                            America/Argentina/Jujuy</option>
                          <option ng-reflect-value="America/Argentina/Tucuman" value="America/Argentina/Tucuman">
                            America/Argentina/Tucuman
                          </option>
                          <option ng-reflect-value="America/Argentina/Catamarca" value="America/Argentina/Catamarca">
                            America/Argentina/Catamarca
                          </option>
                          <option ng-reflect-value="America/Argentina/La_Rioja" value="America/Argentina/La_Rioja">
                            America/Argentina/La_Rioja
                          </option>
                          <option ng-reflect-value="America/Argentina/San_Juan" value="America/Argentina/San_Juan">
                            America/Argentina/San_Juan
                          </option>
                          <option ng-reflect-value="America/Argentina/Mendoza" value="America/Argentina/Mendoza">
                            America/Argentina/Mendoza
                          </option>
                          <option ng-reflect-value="America/Argentina/San_Luis" value="America/Argentina/San_Luis">
                            America/Argentina/San_Luis
                          </option>
                          <option ng-reflect-value="America/Argentina/Rio_Gallegos"
                            value="America/Argentina/Rio_Gallegos">
                            America/Argentina/Rio_Gallegos</option>
                          <option ng-reflect-value="America/Argentina/Ushuaia" value="America/Argentina/Ushuaia">
                            America/Argentina/Ushuaia
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Armenia" label="Armenia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Yerevan"
                    }-->
                          <option ng-reflect-value="Asia/Yerevan" value="Asia/Yerevan">
                            Asia/Yerevan</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Aruba" label="Aruba">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Aruba"
                    }-->
                          <option ng-reflect-value="America/Aruba" value="America/Aruba">
                            America/Aruba</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Australia" label="Australia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Australia/Lord_Howe,Antarctica/Macquarie,Australia/Hobart,Australia/Currie,Australia/Melbourne,Australia/Sydney,Australia/Broken_Hill,Australia/Brisbane,Australia/Lindeman,Australia/Adelaide,Australia/Darwin,Australia/Perth,Australia/Eucla"
                    }-->
                          <option ng-reflect-value="Australia/Lord_Howe" value="Australia/Lord_Howe">Australia/Lord_Howe
                          </option>
                          <option ng-reflect-value="Antarctica/Macquarie" value="Antarctica/Macquarie">
                            Antarctica/Macquarie</option>
                          <option ng-reflect-value="Australia/Hobart" value="Australia/Hobart">Australia/Hobart</option>
                          <option ng-reflect-value="Australia/Currie" value="Australia/Currie">Australia/Currie</option>
                          <option ng-reflect-value="Australia/Melbourne" value="Australia/Melbourne">Australia/Melbourne
                          </option>
                          <option ng-reflect-value="Australia/Sydney" value="Australia/Sydney">Australia/Sydney</option>
                          <option ng-reflect-value="Australia/Broken_Hill" value="Australia/Broken_Hill">
                            Australia/Broken_Hill</option>
                          <option ng-reflect-value="Australia/Brisbane" value="Australia/Brisbane">Australia/Brisbane
                          </option>
                          <option ng-reflect-value="Australia/Lindeman" value="Australia/Lindeman">Australia/Lindeman
                          </option>
                          <option ng-reflect-value="Australia/Adelaide" value="Australia/Adelaide">Australia/Adelaide
                          </option>
                          <option ng-reflect-value="Australia/Darwin" value="Australia/Darwin">Australia/Darwin</option>
                          <option ng-reflect-value="Australia/Perth" value="Australia/Perth">
                            Australia/Perth</option>
                          <option ng-reflect-value="Australia/Eucla" value="Australia/Eucla">
                            Australia/Eucla</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Austria" label="Austria">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Vienna"
                    }-->
                          <option ng-reflect-value="Europe/Vienna" value="Europe/Vienna">
                            Europe/Vienna</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Azerbaijan" label="Azerbaijan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Baku"
                    }-->
                          <option ng-reflect-value="Asia/Baku" value="Asia/Baku">Asia/Baku
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Bahamas" label="Bahamas">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Nassau"
                    }-->
                          <option ng-reflect-value="America/Nassau" value="America/Nassau">
                            America/Nassau</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Bahrain" label="Bahrain">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Bahrain"
                    }-->
                          <option ng-reflect-value="Asia/Bahrain" value="Asia/Bahrain">
                            Asia/Bahrain</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Bangladesh" label="Bangladesh">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Dhaka"
                    }-->
                          <option ng-reflect-value="Asia/Dhaka" value="Asia/Dhaka">Asia/Dhaka
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Barbados" label="Barbados">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Barbados"
                    }-->
                          <option ng-reflect-value="America/Barbados" value="America/Barbados">America/Barbados</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Belarus" label="Belarus">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Minsk"
                    }-->
                          <option ng-reflect-value="Europe/Minsk" value="Europe/Minsk">
                            Europe/Minsk</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Belgium" label="Belgium">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Brussels"
                    }-->
                          <option ng-reflect-value="Europe/Brussels" value="Europe/Brussels">
                            Europe/Brussels</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Belize" label="Belize">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Belize"
                    }-->
                          <option ng-reflect-value="America/Belize" value="America/Belize">
                            America/Belize</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Benin" label="Benin">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Porto-Novo"
                    }-->
                          <option ng-reflect-value="Africa/Porto-Novo" value="Africa/Porto-Novo">Africa/Porto-Novo
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Bermuda" label="Bermuda">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/Bermuda"
                    }-->
                          <option ng-reflect-value="Atlantic/Bermuda" value="Atlantic/Bermuda">Atlantic/Bermuda</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Bhutan" label="Bhutan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Thimphu"
                    }-->
                          <option ng-reflect-value="Asia/Thimphu" value="Asia/Thimphu">
                            Asia/Thimphu</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Bolivia" label="Bolivia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/La_Paz"
                    }-->
                          <option ng-reflect-value="America/La_Paz" value="America/La_Paz">
                            America/La_Paz</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Bosnia And Herzegovina" label="Bosnia And Herzegovina">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Sarajevo"
                    }-->
                          <option ng-reflect-value="Europe/Sarajevo" value="Europe/Sarajevo">
                            Europe/Sarajevo</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Botswana" label="Botswana">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Gaborone"
                    }-->
                          <option ng-reflect-value="Africa/Gaborone" value="Africa/Gaborone">
                            Africa/Gaborone</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Bouvet Island" label="Bouvet Island">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": ""
                    }-->
                        </optgroup>
                        <optgroup ng-reflect-label="Brazil" label="Brazil">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Noronha,America/Belem,America/Fortaleza,America/Recife,America/Araguaina,America/Maceio,America/Bahia,America/Sao_Paulo,America/Campo_Grande,America/Cuiaba,America/Santarem,America/Porto_Velho,America/Boa_Vista,America/Manaus,America/Eirunepe,America/Rio_Branco"
                    }-->
                          <option ng-reflect-value="America/Noronha" value="America/Noronha">
                            America/Noronha</option>
                          <option ng-reflect-value="America/Belem" value="America/Belem">
                            America/Belem</option>
                          <option ng-reflect-value="America/Fortaleza" value="America/Fortaleza">America/Fortaleza
                          </option>
                          <option ng-reflect-value="America/Recife" value="America/Recife">
                            America/Recife</option>
                          <option ng-reflect-value="America/Araguaina" value="America/Araguaina">America/Araguaina
                          </option>
                          <option ng-reflect-value="America/Maceio" value="America/Maceio">
                            America/Maceio</option>
                          <option ng-reflect-value="America/Bahia" value="America/Bahia">
                            America/Bahia</option>
                          <option ng-reflect-value="America/Sao_Paulo" value="America/Sao_Paulo">America/Sao_Paulo
                          </option>
                          <option ng-reflect-value="America/Campo_Grande" value="America/Campo_Grande">
                            America/Campo_Grande</option>
                          <option ng-reflect-value="America/Cuiaba" value="America/Cuiaba">
                            America/Cuiaba</option>
                          <option ng-reflect-value="America/Santarem" value="America/Santarem">America/Santarem</option>
                          <option ng-reflect-value="America/Porto_Velho" value="America/Porto_Velho">America/Porto_Velho
                          </option>
                          <option ng-reflect-value="America/Boa_Vista" value="America/Boa_Vista">America/Boa_Vista
                          </option>
                          <option ng-reflect-value="America/Manaus" value="America/Manaus">
                            America/Manaus</option>
                          <option ng-reflect-value="America/Eirunepe" value="America/Eirunepe">America/Eirunepe</option>
                          <option ng-reflect-value="America/Rio_Branco" value="America/Rio_Branco">America/Rio_Branco
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="British Indian Ocean Territory"
                          label="British Indian Ocean Territory">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Chagos"
                    }-->
                          <option ng-reflect-value="Indian/Chagos" value="Indian/Chagos">
                            Indian/Chagos</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Brunei Darussalam" label="Brunei Darussalam">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Brunei"
                    }-->
                          <option ng-reflect-value="Asia/Brunei" value="Asia/Brunei">
                            Asia/Brunei</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Bulgaria" label="Bulgaria">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Sofia"
                    }-->
                          <option ng-reflect-value="Europe/Sofia" value="Europe/Sofia">
                            Europe/Sofia</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Burkina Faso" label="Burkina Faso">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Ouagadougou"
                    }-->
                          <option ng-reflect-value="Africa/Ouagadougou" value="Africa/Ouagadougou">Africa/Ouagadougou
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Burundi" label="Burundi">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Bujumbura"
                    }-->
                          <option ng-reflect-value="Africa/Bujumbura" value="Africa/Bujumbura">Africa/Bujumbura</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Cambodia" label="Cambodia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Phnom_Penh"
                    }-->
                          <option ng-reflect-value="Asia/Phnom_Penh" value="Asia/Phnom_Penh">
                            Asia/Phnom_Penh</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Cameroon" label="Cameroon">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Douala"
                    }-->
                          <option ng-reflect-value="Africa/Douala" value="Africa/Douala">
                            Africa/Douala</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Canada" label="Canada">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/St_Johns,America/Halifax,America/Glace_Bay,America/Moncton,America/Goose_Bay,America/Blanc-Sablon,America/Toronto,America/Nipigon,America/Thunder_Bay,America/Iqaluit,America/Pangnirtung,America/Resolute,America/Atikokan,America/Rankin_Inlet,America/Winnipeg,America/Rainy_River,America/Regina,America/Swift_Current,America/Edmonton,America/Cambridge_Bay,America/Yellowknife,America/Inuvik,America/Creston,America/Dawson_Creek,America/Vancouver,America/Whitehorse,America/Dawson,America/Montreal"
                    }-->
                          <option ng-reflect-value="America/St_Johns" value="America/St_Johns">America/St_Johns</option>
                          <option ng-reflect-value="America/Halifax" value="America/Halifax">
                            America/Halifax</option>
                          <option ng-reflect-value="America/Glace_Bay" value="America/Glace_Bay">America/Glace_Bay
                          </option>
                          <option ng-reflect-value="America/Moncton" value="America/Moncton">
                            America/Moncton</option>
                          <option ng-reflect-value="America/Goose_Bay" value="America/Goose_Bay">America/Goose_Bay
                          </option>
                          <option ng-reflect-value="America/Blanc-Sablon" value="America/Blanc-Sablon">
                            America/Blanc-Sablon</option>
                          <option ng-reflect-value="America/Toronto" value="America/Toronto">
                            America/Toronto</option>
                          <option ng-reflect-value="America/Nipigon" value="America/Nipigon">
                            America/Nipigon</option>
                          <option ng-reflect-value="America/Thunder_Bay" value="America/Thunder_Bay">America/Thunder_Bay
                          </option>
                          <option ng-reflect-value="America/Iqaluit" value="America/Iqaluit">
                            America/Iqaluit</option>
                          <option ng-reflect-value="America/Pangnirtung" value="America/Pangnirtung">America/Pangnirtung
                          </option>
                          <option ng-reflect-value="America/Resolute" value="America/Resolute">America/Resolute</option>
                          <option ng-reflect-value="America/Atikokan" value="America/Atikokan">America/Atikokan</option>
                          <option ng-reflect-value="America/Rankin_Inlet" value="America/Rankin_Inlet">
                            America/Rankin_Inlet</option>
                          <option ng-reflect-value="America/Winnipeg" value="America/Winnipeg">America/Winnipeg</option>
                          <option ng-reflect-value="America/Rainy_River" value="America/Rainy_River">America/Rainy_River
                          </option>
                          <option ng-reflect-value="America/Regina" value="America/Regina">
                            America/Regina</option>
                          <option ng-reflect-value="America/Swift_Current" value="America/Swift_Current">
                            America/Swift_Current</option>
                          <option ng-reflect-value="America/Edmonton" value="America/Edmonton">America/Edmonton</option>
                          <option ng-reflect-value="America/Cambridge_Bay" value="America/Cambridge_Bay">
                            America/Cambridge_Bay</option>
                          <option ng-reflect-value="America/Yellowknife" value="America/Yellowknife">America/Yellowknife
                          </option>
                          <option ng-reflect-value="America/Inuvik" value="America/Inuvik">
                            America/Inuvik</option>
                          <option ng-reflect-value="America/Creston" value="America/Creston">
                            America/Creston</option>
                          <option ng-reflect-value="America/Dawson_Creek" value="America/Dawson_Creek">
                            America/Dawson_Creek</option>
                          <option ng-reflect-value="America/Vancouver" value="America/Vancouver">America/Vancouver
                          </option>
                          <option ng-reflect-value="America/Whitehorse" value="America/Whitehorse">America/Whitehorse
                          </option>
                          <option ng-reflect-value="America/Dawson" value="America/Dawson">
                            America/Dawson</option>
                          <option ng-reflect-value="America/Montreal" value="America/Montreal">America/Montreal</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Cape Verde" label="Cape Verde">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/Cape_Verde"
                    }-->
                          <option ng-reflect-value="Atlantic/Cape_Verde" value="Atlantic/Cape_Verde">Atlantic/Cape_Verde
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Cayman Islands" label="Cayman Islands">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Cayman"
                    }-->
                          <option ng-reflect-value="America/Cayman" value="America/Cayman">
                            America/Cayman</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Central African Republic" label="Central African Republic">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Bangui"
                    }-->
                          <option ng-reflect-value="Africa/Bangui" value="Africa/Bangui">
                            Africa/Bangui</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Chad" label="Chad">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Ndjamena"
                    }-->
                          <option ng-reflect-value="Africa/Ndjamena" value="Africa/Ndjamena">
                            Africa/Ndjamena</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Chile" label="Chile">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Santiago,Pacific/Easter"
                    }-->
                          <option ng-reflect-value="America/Santiago" value="America/Santiago">America/Santiago</option>
                          <option ng-reflect-value="Pacific/Easter" value="Pacific/Easter">
                            Pacific/Easter</option>
                        </optgroup>
                        <optgroup ng-reflect-label="China" label="China">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Shanghai,Asia/Harbin,Asia/Chongqing,Asia/Urumqi,Asia/Kashgar"
                    }-->
                          <option ng-reflect-value="Asia/Shanghai" value="Asia/Shanghai">
                            Asia/Shanghai</option>
                          <option ng-reflect-value="Asia/Harbin" value="Asia/Harbin">
                            Asia/Harbin</option>
                          <option ng-reflect-value="Asia/Chongqing" value="Asia/Chongqing">
                            Asia/Chongqing</option>
                          <option ng-reflect-value="Asia/Urumqi" value="Asia/Urumqi">
                            Asia/Urumqi</option>
                          <option ng-reflect-value="Asia/Kashgar" value="Asia/Kashgar">
                            Asia/Kashgar</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Christmas Island" label="Christmas Island">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Christmas"
                    }-->
                          <option ng-reflect-value="Indian/Christmas" value="Indian/Christmas">Indian/Christmas</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Cocos (Keeling) Islands" label="Cocos (Keeling) Islands">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Cocos"
                    }-->
                          <option ng-reflect-value="Indian/Cocos" value="Indian/Cocos">
                            Indian/Cocos</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Colombia" label="Colombia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Bogota"
                    }-->
                          <option ng-reflect-value="America/Bogota" value="America/Bogota">
                            America/Bogota</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Comoros" label="Comoros">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Comoro"
                    }-->
                          <option ng-reflect-value="Indian/Comoro" value="Indian/Comoro">
                            Indian/Comoro</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Congo" label="Congo">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Brazzaville"
                    }-->
                          <option ng-reflect-value="Africa/Brazzaville" value="Africa/Brazzaville">Africa/Brazzaville
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Congo (Democratic Republic)" label="Congo (Democratic Republic)">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Kinshasa,Africa/Lubumbashi"
                    }-->
                          <option ng-reflect-value="Africa/Kinshasa" value="Africa/Kinshasa">
                            Africa/Kinshasa</option>
                          <option ng-reflect-value="Africa/Lubumbashi" value="Africa/Lubumbashi">Africa/Lubumbashi
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Cook Islands" label="Cook Islands">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Rarotonga"
                    }-->
                          <option ng-reflect-value="Pacific/Rarotonga" value="Pacific/Rarotonga">Pacific/Rarotonga
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Costa Rica" label="Costa Rica">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Costa_Rica"
                    }-->
                          <option ng-reflect-value="America/Costa_Rica" value="America/Costa_Rica">America/Costa_Rica
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Cote D'Ivoire" label="Cote D'Ivoire">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Abidjan"
                    }-->
                          <option ng-reflect-value="Africa/Abidjan" value="Africa/Abidjan">
                            Africa/Abidjan</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Croatia" label="Croatia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Zagreb"
                    }-->
                          <option ng-reflect-value="Europe/Zagreb" value="Europe/Zagreb">
                            Europe/Zagreb</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Cuba" label="Cuba">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Havana"
                    }-->
                          <option ng-reflect-value="America/Havana" value="America/Havana">
                            America/Havana</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Cyprus" label="Cyprus">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Nicosia"
                    }-->
                          <option ng-reflect-value="Asia/Nicosia" value="Asia/Nicosia">
                            Asia/Nicosia</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Czech Republic" label="Czech Republic">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Prague"
                    }-->
                          <option ng-reflect-value="Europe/Prague" value="Europe/Prague">
                            Europe/Prague</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Denmark" label="Denmark">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Copenhagen"
                    }-->
                          <option ng-reflect-value="Europe/Copenhagen" value="Europe/Copenhagen">Europe/Copenhagen
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Djibouti" label="Djibouti">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Djibouti"
                    }-->
                          <option ng-reflect-value="Africa/Djibouti" value="Africa/Djibouti">
                            Africa/Djibouti</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Dominica" label="Dominica">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Dominica"
                    }-->
                          <option ng-reflect-value="America/Dominica" value="America/Dominica">America/Dominica</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Dominican Republic" label="Dominican Republic">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Santo_Domingo"
                    }-->
                          <option ng-reflect-value="America/Santo_Domingo" value="America/Santo_Domingo">
                            America/Santo_Domingo</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Ecuador" label="Ecuador">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Guayaquil,Pacific/Galapagos"
                    }-->
                          <option ng-reflect-value="America/Guayaquil" value="America/Guayaquil">America/Guayaquil
                          </option>
                          <option ng-reflect-value="Pacific/Galapagos" value="Pacific/Galapagos">Pacific/Galapagos
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Egypt" label="Egypt">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Cairo"
                    }-->
                          <option ng-reflect-value="Africa/Cairo" value="Africa/Cairo">
                            Africa/Cairo</option>
                        </optgroup>
                        <optgroup ng-reflect-label="El Salvador" label="El Salvador">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/El_Salvador"
                    }-->
                          <option ng-reflect-value="America/El_Salvador" value="America/El_Salvador">America/El_Salvador
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Equatorial Guinea" label="Equatorial Guinea">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Malabo"
                    }-->
                          <option ng-reflect-value="Africa/Malabo" value="Africa/Malabo">
                            Africa/Malabo</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Eritrea" label="Eritrea">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Asmara"
                    }-->
                          <option ng-reflect-value="Africa/Asmara" value="Africa/Asmara">
                            Africa/Asmara</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Estonia" label="Estonia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Tallinn"
                    }-->
                          <option ng-reflect-value="Europe/Tallinn" value="Europe/Tallinn">
                            Europe/Tallinn</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Ethiopia" label="Ethiopia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Addis_Ababa"
                    }-->
                          <option ng-reflect-value="Africa/Addis_Ababa" value="Africa/Addis_Ababa">Africa/Addis_Ababa
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Falkland Islands (Malvinas)" label="Falkland Islands (Malvinas)">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/Stanley"
                    }-->
                          <option ng-reflect-value="Atlantic/Stanley" value="Atlantic/Stanley">Atlantic/Stanley</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Faroe Islands" label="Faroe Islands">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/Faroe"
                    }-->
                          <option ng-reflect-value="Atlantic/Faroe" value="Atlantic/Faroe">
                            Atlantic/Faroe</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Fiji" label="Fiji">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Fiji"
                    }-->
                          <option ng-reflect-value="Pacific/Fiji" value="Pacific/Fiji">
                            Pacific/Fiji</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Finland" label="Finland">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Helsinki"
                    }-->
                          <option ng-reflect-value="Europe/Helsinki" value="Europe/Helsinki">
                            Europe/Helsinki</option>
                        </optgroup>
                        <optgroup ng-reflect-label="France" label="France">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Paris"
                    }-->
                          <option ng-reflect-value="Europe/Paris" value="Europe/Paris">
                            Europe/Paris</option>
                        </optgroup>
                        <optgroup ng-reflect-label="French Guiana" label="French Guiana">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Cayenne"
                    }-->
                          <option ng-reflect-value="America/Cayenne" value="America/Cayenne">
                            America/Cayenne</option>
                        </optgroup>
                        <optgroup ng-reflect-label="French Polynesia" label="French Polynesia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Tahiti,Pacific/Marquesas,Pacific/Gambier"
                    }-->
                          <option ng-reflect-value="Pacific/Tahiti" value="Pacific/Tahiti">
                            Pacific/Tahiti</option>
                          <option ng-reflect-value="Pacific/Marquesas" value="Pacific/Marquesas">Pacific/Marquesas
                          </option>
                          <option ng-reflect-value="Pacific/Gambier" value="Pacific/Gambier">
                            Pacific/Gambier</option>
                        </optgroup>
                        <optgroup ng-reflect-label="French Southern Territories" label="French Southern Territories">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Kerguelen"
                    }-->
                          <option ng-reflect-value="Indian/Kerguelen" value="Indian/Kerguelen">Indian/Kerguelen</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Gabon" label="Gabon">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Libreville"
                    }-->
                          <option ng-reflect-value="Africa/Libreville" value="Africa/Libreville">Africa/Libreville
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Gambia" label="Gambia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Banjul"
                    }-->
                          <option ng-reflect-value="Africa/Banjul" value="Africa/Banjul">
                            Africa/Banjul</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Georgia" label="Georgia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Tbilisi"
                    }-->
                          <option ng-reflect-value="Asia/Tbilisi" value="Asia/Tbilisi">
                            Asia/Tbilisi</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Germany" label="Germany">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Berlin,Europe/Busingen"
                    }-->
                          <option ng-reflect-value="Europe/Berlin" value="Europe/Berlin">
                            Europe/Berlin</option>
                          <option ng-reflect-value="Europe/Busingen" value="Europe/Busingen">
                            Europe/Busingen</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Ghana" label="Ghana">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Accra"
                    }-->
                          <option ng-reflect-value="Africa/Accra" value="Africa/Accra">
                            Africa/Accra</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Gibraltar" label="Gibraltar">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Gibraltar"
                    }-->
                          <option ng-reflect-value="Europe/Gibraltar" value="Europe/Gibraltar">Europe/Gibraltar</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Greece" label="Greece">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Athens"
                    }-->
                          <option ng-reflect-value="Europe/Athens" value="Europe/Athens">
                            Europe/Athens</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Greenland" label="Greenland">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Godthab,America/Danmarkshavn,America/Scoresbysund,America/Thule"
                    }-->
                          <option ng-reflect-value="America/Godthab" value="America/Godthab">
                            America/Godthab</option>
                          <option ng-reflect-value="America/Danmarkshavn" value="America/Danmarkshavn">
                            America/Danmarkshavn</option>
                          <option ng-reflect-value="America/Scoresbysund" value="America/Scoresbysund">
                            America/Scoresbysund</option>
                          <option ng-reflect-value="America/Thule" value="America/Thule">
                            America/Thule</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Grenada" label="Grenada">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Grenada"
                    }-->
                          <option ng-reflect-value="America/Grenada" value="America/Grenada">
                            America/Grenada</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Guadeloupe" label="Guadeloupe">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Guadeloupe"
                    }-->
                          <option ng-reflect-value="America/Guadeloupe" value="America/Guadeloupe">America/Guadeloupe
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Guam" label="Guam">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Guam"
                    }-->
                          <option ng-reflect-value="Pacific/Guam" value="Pacific/Guam">
                            Pacific/Guam</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Guatemala" label="Guatemala">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Guatemala"
                    }-->
                          <option ng-reflect-value="America/Guatemala" value="America/Guatemala">America/Guatemala
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Guernsey" label="Guernsey">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Guernsey"
                    }-->
                          <option ng-reflect-value="Europe/Guernsey" value="Europe/Guernsey">
                            Europe/Guernsey</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Guinea" label="Guinea">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Conakry"
                    }-->
                          <option ng-reflect-value="Africa/Conakry" value="Africa/Conakry">
                            Africa/Conakry</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Guinea-Bissau" label="Guinea-Bissau">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Bissau"
                    }-->
                          <option ng-reflect-value="Africa/Bissau" value="Africa/Bissau">
                            Africa/Bissau</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Guyana" label="Guyana">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Guyana"
                    }-->
                          <option ng-reflect-value="America/Guyana" value="America/Guyana">
                            America/Guyana</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Haiti" label="Haiti">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Port-au-Prince"
                    }-->
                          <option ng-reflect-value="America/Port-au-Prince" value="America/Port-au-Prince">
                            America/Port-au-Prince</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Heard Island &amp; Mcdonald Islands"
                          label="Heard Island &amp; Mcdonald Islands">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": ""
                    }-->
                        </optgroup>
                        <optgroup ng-reflect-label="Holy See (Vatican City State)"
                          label="Holy See (Vatican City State)">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Vatican"
                    }-->
                          <option ng-reflect-value="Europe/Vatican" value="Europe/Vatican">
                            Europe/Vatican</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Honduras" label="Honduras">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Tegucigalpa"
                    }-->
                          <option ng-reflect-value="America/Tegucigalpa" value="America/Tegucigalpa">America/Tegucigalpa
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Hong Kong" label="Hong Kong">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Hong_Kong"
                    }-->
                          <option ng-reflect-value="Asia/Hong_Kong" value="Asia/Hong_Kong">
                            Asia/Hong_Kong</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Hungary" label="Hungary">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Budapest"
                    }-->
                          <option ng-reflect-value="Europe/Budapest" value="Europe/Budapest">
                            Europe/Budapest</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Iceland" label="Iceland">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/Reykjavik"
                    }-->
                          <option ng-reflect-value="Atlantic/Reykjavik" value="Atlantic/Reykjavik">Atlantic/Reykjavik
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="India" label="India">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Kolkata"
                    }-->
                          <option ng-reflect-value="Asia/Kolkata" value="Asia/Kolkata">
                            Asia/Kolkata</option>
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Calcutta"
                    }-->
                          <option ng-reflect-value="Asia/Calcutta" value="Asia/Calcutta">
                            Asia/Calcutta</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Indonesia" label="Indonesia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/ Jakarta,Asia/ Pontianak,Asia/ Makassar,Asia/ Jayapura"
                    }-->
                          <option ng-reflect-value="Asia/ Jakarta" value="Asia/ Jakarta">Asia/ Jakarta</option>
                          <option ng-reflect-value="Asia/ Pontianak" value="Asia/ Pontianak">
                            Asia/ Pontianak</option>
                          <option ng-reflect-value="Asia/ Makassar" value="Asia/ Makassar">
                            Asia/ Makassar</option>
                          <option ng-reflect-value="Asia/ Jayapura" value="Asia/ Jayapura">
                            Asia/ Jayapura</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Iran (Islamic Republic Of)" label="Iran (Islamic Republic Of)">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Tehran"
                    }-->
                          <option ng-reflect-value="Asia/Tehran" value="Asia/Tehran">
                            Asia/Tehran</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Iraq" label="Iraq">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Baghdad"
                    }-->
                          <option ng-reflect-value="Asia/Baghdad" value="Asia/Baghdad">
                            Asia/Baghdad</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Ireland" label="Ireland">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Dublin"
                    }-->
                          <option ng-reflect-value="Europe/Dublin" value="Europe/Dublin">
                            Europe/Dublin</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Isle Of Man" label="Isle Of Man">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Isle_of_Man"
                    }-->
                          <option ng-reflect-value="Europe/Isle_of_Man" value="Europe/Isle_of_Man">Europe/Isle_of_Man
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Israel" label="Israel">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Jerusalem"
                    }-->
                          <option ng-reflect-value="Asia/Jerusalem" value="Asia/Jerusalem">
                            Asia/Jerusalem</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Italy" label="Italy">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Rome"
                    }-->
                          <option ng-reflect-value="Europe/Rome" value="Europe/Rome">
                            Europe/Rome</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Jamaica" label="Jamaica">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Jamaica"
                    }-->
                          <option ng-reflect-value="America/Jamaica" value="America/Jamaica">
                            America/Jamaica</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Japan" label="Japan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Tokyo"
                    }-->
                          <option ng-reflect-value="Asia/Tokyo" value="Asia/Tokyo">Asia/Tokyo
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Jersey" label="Jersey">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Jersey"
                    }-->
                          <option ng-reflect-value="Europe/Jersey" value="Europe/Jersey">
                            Europe/Jersey</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Jordan" label="Jordan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Amman"
                    }-->
                          <option ng-reflect-value="Asia/Amman" value="Asia/Amman">Asia/Amman
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Kazakhstan" label="Kazakhstan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Almaty,Asia/Qyzylorda,Asia/Aqtobe,Asia/Aqtau,Asia/Oral"
                    }-->
                          <option ng-reflect-value="Asia/Almaty" value="Asia/Almaty">
                            Asia/Almaty</option>
                          <option ng-reflect-value="Asia/Qyzylorda" value="Asia/Qyzylorda">
                            Asia/Qyzylorda</option>
                          <option ng-reflect-value="Asia/Aqtobe" value="Asia/Aqtobe">
                            Asia/Aqtobe</option>
                          <option ng-reflect-value="Asia/Aqtau" value="Asia/Aqtau">Asia/Aqtau
                          </option>
                          <option ng-reflect-value="Asia/Oral" value="Asia/Oral">Asia/Oral
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Kenya" label="Kenya">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Nairobi"
                    }-->
                          <option ng-reflect-value="Africa/Nairobi" value="Africa/Nairobi">
                            Africa/Nairobi</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Kiribati" label="Kiribati">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Tarawa,Pacific/Enderbury,Pacific/Kiritimati"
                    }-->
                          <option ng-reflect-value="Pacific/Tarawa" value="Pacific/Tarawa">
                            Pacific/Tarawa</option>
                          <option ng-reflect-value="Pacific/Enderbury" value="Pacific/Enderbury">Pacific/Enderbury
                          </option>
                          <option ng-reflect-value="Pacific/Kiritimati" value="Pacific/Kiritimati">Pacific/Kiritimati
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Korea" label="Korea">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Seoul"
                    }-->
                          <option ng-reflect-value="Asia/Seoul" value="Asia/Seoul">Asia/Seoul
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Kuwait" label="Kuwait">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Kuwait"
                    }-->
                          <option ng-reflect-value="Asia/Kuwait" value="Asia/Kuwait">
                            Asia/Kuwait</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Kyrgyzstan" label="Kyrgyzstan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Bishkek"
                    }-->
                          <option ng-reflect-value="Asia/Bishkek" value="Asia/Bishkek">
                            Asia/Bishkek</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Lao People's Democratic Republic"
                          label="Lao People's Democratic Republic">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Vientiane"
                    }-->
                          <option ng-reflect-value="Asia/Vientiane" value="Asia/Vientiane">
                            Asia/Vientiane</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Latvia" label="Latvia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Riga"
                    }-->
                          <option ng-reflect-value="Europe/Riga" value="Europe/Riga">
                            Europe/Riga</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Lebanon" label="Lebanon">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Beirut"
                    }-->
                          <option ng-reflect-value="Asia/Beirut" value="Asia/Beirut">
                            Asia/Beirut</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Lesotho" label="Lesotho">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Maseru"
                    }-->
                          <option ng-reflect-value="Africa/Maseru" value="Africa/Maseru">
                            Africa/Maseru</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Liberia" label="Liberia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Monrovia"
                    }-->
                          <option ng-reflect-value="Africa/Monrovia" value="Africa/Monrovia">
                            Africa/Monrovia</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Libyan Arab Jamahiriya" label="Libyan Arab Jamahiriya">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Tripoli"
                    }-->
                          <option ng-reflect-value="Africa/Tripoli" value="Africa/Tripoli">
                            Africa/Tripoli</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Liechtenstein" label="Liechtenstein">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Vaduz"
                    }-->
                          <option ng-reflect-value="Europe/Vaduz" value="Europe/Vaduz">
                            Europe/Vaduz</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Lithuania" label="Lithuania">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Vilnius"
                    }-->
                          <option ng-reflect-value="Europe/Vilnius" value="Europe/Vilnius">
                            Europe/Vilnius</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Luxembourg" label="Luxembourg">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Luxembourg"
                    }-->
                          <option ng-reflect-value="Europe/Luxembourg" value="Europe/Luxembourg">Europe/Luxembourg
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Macao" label="Macao">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Macau"
                    }-->
                          <option ng-reflect-value="Asia/Macau" value="Asia/Macau">Asia/Macau
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Macedonia" label="Macedonia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Skopje"
                    }-->
                          <option ng-reflect-value="Europe/Skopje" value="Europe/Skopje">
                            Europe/Skopje</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Madagascar" label="Madagascar">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Antananarivo"
                    }-->
                          <option ng-reflect-value="Indian/Antananarivo" value="Indian/Antananarivo">Indian/Antananarivo
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Malawi" label="Malawi">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Blantyre"
                    }-->
                          <option ng-reflect-value="Africa/Blantyre" value="Africa/Blantyre">
                            Africa/Blantyre</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Malaysia" label="Malaysia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Kuala_Lumpur,Asia/Kuching"
                    }-->
                          <option ng-reflect-value="Asia/Kuala_Lumpur" value="Asia/Kuala_Lumpur">Asia/Kuala_Lumpur
                          </option>
                          <option ng-reflect-value="Asia/Kuching" value="Asia/Kuching">
                            Asia/Kuching</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Maldives" label="Maldives">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Maldives"
                    }-->
                          <option ng-reflect-value="Indian/Maldives" value="Indian/Maldives">
                            Indian/Maldives</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Mali" label="Mali">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Bamako"
                    }-->
                          <option ng-reflect-value="Africa/Bamako" value="Africa/Bamako">
                            Africa/Bamako</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Malta" label="Malta">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Malta"
                    }-->
                          <option ng-reflect-value="Europe/Malta" value="Europe/Malta">
                            Europe/Malta</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Marshall Islands" label="Marshall Islands">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Majuro,Pacific/Kwajalein"
                    }-->
                          <option ng-reflect-value="Pacific/Majuro" value="Pacific/Majuro">
                            Pacific/Majuro</option>
                          <option ng-reflect-value="Pacific/Kwajalein" value="Pacific/Kwajalein">Pacific/Kwajalein
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Martinique" label="Martinique">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Martinique"
                    }-->
                          <option ng-reflect-value="America/Martinique" value="America/Martinique">America/Martinique
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Mauritania" label="Mauritania">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Nouakchott"
                    }-->
                          <option ng-reflect-value="Africa/Nouakchott" value="Africa/Nouakchott">Africa/Nouakchott
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Mauritius" label="Mauritius">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Mauritius"
                    }-->
                          <option ng-reflect-value="Indian/Mauritius" value="Indian/Mauritius">Indian/Mauritius</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Mayotte" label="Mayotte">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Mayotte"
                    }-->
                          <option ng-reflect-value="Indian/Mayotte" value="Indian/Mayotte">
                            Indian/Mayotte</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Mexico" label="Mexico">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Mexico_City,America/Cancun,America/Merida,America/Monterrey,America/Matamoros,America/Mazatlan,America/Chihuahua,America/Ojinaga,America/Hermosillo,America/Tijuana,America/Santa_Isabel,America/Bahia_Banderas"
                    }-->
                          <option ng-reflect-value="America/Mexico_City" value="America/Mexico_City">America/Mexico_City
                          </option>
                          <option ng-reflect-value="America/Cancun" value="America/Cancun">
                            America/Cancun</option>
                          <option ng-reflect-value="America/Merida" value="America/Merida">
                            America/Merida</option>
                          <option ng-reflect-value="America/Monterrey" value="America/Monterrey">America/Monterrey
                          </option>
                          <option ng-reflect-value="America/Matamoros" value="America/Matamoros">America/Matamoros
                          </option>
                          <option ng-reflect-value="America/Mazatlan" value="America/Mazatlan">America/Mazatlan</option>
                          <option ng-reflect-value="America/Chihuahua" value="America/Chihuahua">America/Chihuahua
                          </option>
                          <option ng-reflect-value="America/Ojinaga" value="America/Ojinaga">
                            America/Ojinaga</option>
                          <option ng-reflect-value="America/Hermosillo" value="America/Hermosillo">America/Hermosillo
                          </option>
                          <option ng-reflect-value="America/Tijuana" value="America/Tijuana">
                            America/Tijuana</option>
                          <option ng-reflect-value="America/Santa_Isabel" value="America/Santa_Isabel">
                            America/Santa_Isabel</option>
                          <option ng-reflect-value="America/Bahia_Banderas" value="America/Bahia_Banderas">
                            America/Bahia_Banderas</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Micronesia (Federated States Of)"
                          label="Micronesia (Federated States Of)">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Chuuk,Pacific/Pohnpei,Pacific/Kosrae"
                    }-->
                          <option ng-reflect-value="Pacific/Chuuk" value="Pacific/Chuuk">
                            Pacific/Chuuk</option>
                          <option ng-reflect-value="Pacific/Pohnpei" value="Pacific/Pohnpei">
                            Pacific/Pohnpei</option>
                          <option ng-reflect-value="Pacific/Kosrae" value="Pacific/Kosrae">
                            Pacific/Kosrae</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Moldova" label="Moldova">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Chisinau"
                    }-->
                          <option ng-reflect-value="Europe/Chisinau" value="Europe/Chisinau">
                            Europe/Chisinau</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Monaco" label="Monaco">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Monaco"
                    }-->
                          <option ng-reflect-value="Europe/Monaco" value="Europe/Monaco">
                            Europe/Monaco</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Mongolia" label="Mongolia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Ulaanbaatar,Asia/Hovd,Asia/Choibalsan"
                    }-->
                          <option ng-reflect-value="Asia/Ulaanbaatar" value="Asia/Ulaanbaatar">Asia/Ulaanbaatar</option>
                          <option ng-reflect-value="Asia/Hovd" value="Asia/Hovd">Asia/Hovd
                          </option>
                          <option ng-reflect-value="Asia/Choibalsan" value="Asia/Choibalsan">
                            Asia/Choibalsan</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Montenegro" label="Montenegro">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Podgorica"
                    }-->
                          <option ng-reflect-value="Europe/Podgorica" value="Europe/Podgorica">Europe/Podgorica</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Montserrat" label="Montserrat">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Montserrat"
                    }-->
                          <option ng-reflect-value="America/Montserrat" value="America/Montserrat">America/Montserrat
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Morocco" label="Morocco">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Casablanca"
                    }-->
                          <option ng-reflect-value="Africa/Casablanca" value="Africa/Casablanca">Africa/Casablanca
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Mozambique" label="Mozambique">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Maputo"
                    }-->
                          <option ng-reflect-value="Africa/Maputo" value="Africa/Maputo">
                            Africa/Maputo</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Myanmar" label="Myanmar">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Rangoon"
                    }-->
                          <option ng-reflect-value="Asia/Rangoon" value="Asia/Rangoon">
                            Asia/Rangoon</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Namibia" label="Namibia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Windhoek"
                    }-->
                          <option ng-reflect-value="Africa/Windhoek" value="Africa/Windhoek">
                            Africa/Windhoek</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Nauru" label="Nauru">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Nauru"
                    }-->
                          <option ng-reflect-value="Pacific/Nauru" value="Pacific/Nauru">
                            Pacific/Nauru</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Nepal" label="Nepal">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Kathmandu"
                    }-->
                          <option ng-reflect-value="Asia/Kathmandu" value="Asia/Kathmandu">
                            Asia/Kathmandu</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Netherlands" label="Netherlands">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Amsterdam"
                    }-->
                          <option ng-reflect-value="Europe/Amsterdam" value="Europe/Amsterdam">Europe/Amsterdam</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Netherlands Antilles" label="Netherlands Antilles">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": ""
                    }-->
                        </optgroup>
                        <optgroup ng-reflect-label="New Caledonia" label="New Caledonia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Noumea"
                    }-->
                          <option ng-reflect-value="Pacific/Noumea" value="Pacific/Noumea">
                            Pacific/Noumea</option>
                        </optgroup>
                        <optgroup ng-reflect-label="New Zealand" label="New Zealand">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Auckland,Pacific/Chatham"
                    }-->
                          <option ng-reflect-value="Pacific/Auckland" value="Pacific/Auckland">Pacific/Auckland</option>
                          <option ng-reflect-value="Pacific/Chatham" value="Pacific/Chatham">
                            Pacific/Chatham</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Nicaragua" label="Nicaragua">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Managua"
                    }-->
                          <option ng-reflect-value="America/Managua" value="America/Managua">
                            America/Managua</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Niger" label="Niger">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Niamey"
                    }-->
                          <option ng-reflect-value="Africa/Niamey" value="Africa/Niamey">
                            Africa/Niamey</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Nigeria" label="Nigeria">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Lagos"
                    }-->
                          <option ng-reflect-value="Africa/Lagos" value="Africa/Lagos">
                            Africa/Lagos</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Niue" label="Niue">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Niue"
                    }-->
                          <option ng-reflect-value="Pacific/Niue" value="Pacific/Niue">
                            Pacific/Niue</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Norfolk Island" label="Norfolk Island">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Norfolk"
                    }-->
                          <option ng-reflect-value="Pacific/Norfolk" value="Pacific/Norfolk">
                            Pacific/Norfolk</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Northern Mariana Islands" label="Northern Mariana Islands">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Saipan"
                    }-->
                          <option ng-reflect-value="Pacific/Saipan" value="Pacific/Saipan">
                            Pacific/Saipan</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Norway" label="Norway">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Oslo"
                    }-->
                          <option ng-reflect-value="Europe/Oslo" value="Europe/Oslo">
                            Europe/Oslo</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Oman" label="Oman">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Muscat"
                    }-->
                          <option ng-reflect-value="Asia/Muscat" value="Asia/Muscat">
                            Asia/Muscat</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Pakistan" label="Pakistan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Karachi"
                    }-->
                          <option ng-reflect-value="Asia/Karachi" value="Asia/Karachi">
                            Asia/Karachi</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Palau" label="Palau">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Palau"
                    }-->
                          <option ng-reflect-value="Pacific/Palau" value="Pacific/Palau">
                            Pacific/Palau</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Palestinian Territory (Occupied)"
                          label="Palestinian Territory (Occupied)">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Gaza,Asia/Hebron"
                    }-->
                          <option ng-reflect-value="Asia/Gaza" value="Asia/Gaza">Asia/Gaza
                          </option>
                          <option ng-reflect-value="Asia/Hebron" value="Asia/Hebron">
                            Asia/Hebron</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Panama" label="Panama">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Panama"
                    }-->
                          <option ng-reflect-value="America/Panama" value="America/Panama">
                            America/Panama</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Papua New Guinea" label="Papua New Guinea">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Port_Moresby"
                    }-->
                          <option ng-reflect-value="Pacific/Port_Moresby" value="Pacific/Port_Moresby">
                            Pacific/Port_Moresby</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Paraguay" label="Paraguay">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Asuncion"
                    }-->
                          <option ng-reflect-value="America/Asuncion" value="America/Asuncion">America/Asuncion</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Peru" label="Peru">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Lima"
                    }-->
                          <option ng-reflect-value="America/Lima" value="America/Lima">
                            America/Lima</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Philippines" label="Philippines">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Manila"
                    }-->
                          <option ng-reflect-value="Asia/Manila" value="Asia/Manila">
                            Asia/Manila</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Pitcairn" label="Pitcairn">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Pitcairn"
                    }-->
                          <option ng-reflect-value="Pacific/Pitcairn" value="Pacific/Pitcairn">Pacific/Pitcairn</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Poland" label="Poland">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Warsaw"
                    }-->
                          <option ng-reflect-value="Europe/Warsaw" value="Europe/Warsaw">
                            Europe/Warsaw</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Portugal" label="Portugal">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Lisbon,Atlantic/Madeira,Atlantic/Azores"
                    }-->
                          <option ng-reflect-value="Europe/Lisbon" value="Europe/Lisbon">
                            Europe/Lisbon</option>
                          <option ng-reflect-value="Atlantic/Madeira" value="Atlantic/Madeira">Atlantic/Madeira</option>
                          <option ng-reflect-value="Atlantic/Azores" value="Atlantic/Azores">
                            Atlantic/Azores</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Puerto Rico" label="Puerto Rico">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Puerto_Rico"
                    }-->
                          <option ng-reflect-value="America/Puerto_Rico" value="America/Puerto_Rico">America/Puerto_Rico
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Qatar" label="Qatar">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Qatar"
                    }-->
                          <option ng-reflect-value="Asia/Qatar" value="Asia/Qatar">Asia/Qatar
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Reunion" label="Reunion">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Reunion"
                    }-->
                          <option ng-reflect-value="Indian/Reunion" value="Indian/Reunion">
                            Indian/Reunion</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Romania" label="Romania">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Bucharest"
                    }-->
                          <option ng-reflect-value="Europe/Bucharest" value="Europe/Bucharest">Europe/Bucharest</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Russian Federation" label="Russian Federation">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Kaliningrad,Europe/Moscow,Europe/Volgograd,Europe/Samara,Europe/Simferopol,Asia/Yekaterinburg,Asia/Omsk,Asia/Novosibirsk,Asia/Novokuznetsk,Asia/Krasnoyarsk,Asia/Irkutsk,Asia/Yakutsk,Asia/Khandyga,Asia/Vladivostok,Asia/Sakhalin,Asia/Ust-Nera,Asia/Magadan,Asia/Kamchatka,Asia/Anadyr"
                    }-->
                          <option ng-reflect-value="Europe/Kaliningrad" value="Europe/Kaliningrad">Europe/Kaliningrad
                          </option>
                          <option ng-reflect-value="Europe/Moscow" value="Europe/Moscow">
                            Europe/Moscow</option>
                          <option ng-reflect-value="Europe/Volgograd" value="Europe/Volgograd">Europe/Volgograd</option>
                          <option ng-reflect-value="Europe/Samara" value="Europe/Samara">
                            Europe/Samara</option>
                          <option ng-reflect-value="Europe/Simferopol" value="Europe/Simferopol">Europe/Simferopol
                          </option>
                          <option ng-reflect-value="Asia/Yekaterinburg" value="Asia/Yekaterinburg">Asia/Yekaterinburg
                          </option>
                          <option ng-reflect-value="Asia/Omsk" value="Asia/Omsk">Asia/Omsk
                          </option>
                          <option ng-reflect-value="Asia/Novosibirsk" value="Asia/Novosibirsk">Asia/Novosibirsk</option>
                          <option ng-reflect-value="Asia/Novokuznetsk" value="Asia/Novokuznetsk">Asia/Novokuznetsk
                          </option>
                          <option ng-reflect-value="Asia/Krasnoyarsk" value="Asia/Krasnoyarsk">Asia/Krasnoyarsk</option>
                          <option ng-reflect-value="Asia/Irkutsk" value="Asia/Irkutsk">
                            Asia/Irkutsk</option>
                          <option ng-reflect-value="Asia/Yakutsk" value="Asia/Yakutsk">
                            Asia/Yakutsk</option>
                          <option ng-reflect-value="Asia/Khandyga" value="Asia/Khandyga">
                            Asia/Khandyga</option>
                          <option ng-reflect-value="Asia/Vladivostok" value="Asia/Vladivostok">Asia/Vladivostok</option>
                          <option ng-reflect-value="Asia/Sakhalin" value="Asia/Sakhalin">
                            Asia/Sakhalin</option>
                          <option ng-reflect-value="Asia/Ust-Nera" value="Asia/Ust-Nera">
                            Asia/Ust-Nera</option>
                          <option ng-reflect-value="Asia/Magadan" value="Asia/Magadan">
                            Asia/Magadan</option>
                          <option ng-reflect-value="Asia/Kamchatka" value="Asia/Kamchatka">
                            Asia/Kamchatka</option>
                          <option ng-reflect-value="Asia/Anadyr" value="Asia/Anadyr">
                            Asia/Anadyr</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Rwanda" label="Rwanda">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Kigali"
                    }-->
                          <option ng-reflect-value="Africa/Kigali" value="Africa/Kigali">
                            Africa/Kigali</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Saint Barthelemy" label="Saint Barthelemy">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/St_Barthelemy"
                    }-->
                          <option ng-reflect-value="America/St_Barthelemy" value="America/St_Barthelemy">
                            America/St_Barthelemy</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Saint Helena" label="Saint Helena">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/St_Helena"
                    }-->
                          <option ng-reflect-value="Atlantic/St_Helena" value="Atlantic/St_Helena">Atlantic/St_Helena
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Saint Kitts And Nevis" label="Saint Kitts And Nevis">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/St_Kitts"
                    }-->
                          <option ng-reflect-value="America/St_Kitts" value="America/St_Kitts">America/St_Kitts</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Saint Lucia" label="Saint Lucia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/St_Lucia"
                    }-->
                          <option ng-reflect-value="America/St_Lucia" value="America/St_Lucia">America/St_Lucia</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Saint Martin" label="Saint Martin">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Marigot"
                    }-->
                          <option ng-reflect-value="America/Marigot" value="America/Marigot">
                            America/Marigot</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Saint Pierre And Miquelon" label="Saint Pierre And Miquelon">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Miquelon"
                    }-->
                          <option ng-reflect-value="America/Miquelon" value="America/Miquelon">America/Miquelon</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Saint Vincent And Grenadines" label="Saint Vincent And Grenadines">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/St_Vincent"
                    }-->
                          <option ng-reflect-value="America/St_Vincent" value="America/St_Vincent">America/St_Vincent
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Samoa" label="Samoa">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Apia"
                    }-->
                          <option ng-reflect-value="Pacific/Apia" value="Pacific/Apia">
                            Pacific/Apia</option>
                        </optgroup>
                        <optgroup ng-reflect-label="San Marino" label="San Marino">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/San_Marino"
                    }-->
                          <option ng-reflect-value="Europe/San_Marino" value="Europe/San_Marino">Europe/San_Marino
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Sao Tome And Principe" label="Sao Tome And Principe">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Sao_Tome"
                    }-->
                          <option ng-reflect-value="Africa/Sao_Tome" value="Africa/Sao_Tome">
                            Africa/Sao_Tome</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Saudi Arabia" label="Saudi Arabia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Riyadh"
                    }-->
                          <option ng-reflect-value="Asia/Riyadh" value="Asia/Riyadh">
                            Asia/Riyadh</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Senegal" label="Senegal">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Dakar"
                    }-->
                          <option ng-reflect-value="Africa/Dakar" value="Africa/Dakar">
                            Africa/Dakar</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Serbia" label="Serbia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Belgrade"
                    }-->
                          <option ng-reflect-value="Europe/Belgrade" value="Europe/Belgrade">
                            Europe/Belgrade</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Seychelles" label="Seychelles">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Indian/Mahe"
                    }-->
                          <option ng-reflect-value="Indian/Mahe" value="Indian/Mahe">
                            Indian/Mahe</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Sierra Leone" label="Sierra Leone">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Freetown"
                    }-->
                          <option ng-reflect-value="Africa/Freetown" value="Africa/Freetown">
                            Africa/Freetown</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Singapore" label="Singapore">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Singapore"
                    }-->
                          <option ng-reflect-value="Asia/Singapore" value="Asia/Singapore">
                            Asia/Singapore</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Slovakia" label="Slovakia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Bratislava"
                    }-->
                          <option ng-reflect-value="Europe/Bratislava" value="Europe/Bratislava">Europe/Bratislava
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Slovenia" label="Slovenia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Ljubljana"
                    }-->
                          <option ng-reflect-value="Europe/Ljubljana" value="Europe/Ljubljana">Europe/Ljubljana</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Solomon Islands" label="Solomon Islands">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Guadalcanal"
                    }-->
                          <option ng-reflect-value="Pacific/Guadalcanal" value="Pacific/Guadalcanal">Pacific/Guadalcanal
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Somalia" label="Somalia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Mogadishu"
                    }-->
                          <option ng-reflect-value="Africa/Mogadishu" value="Africa/Mogadishu">Africa/Mogadishu</option>
                        </optgroup>
                        <optgroup ng-reflect-label="South Sudan" label="South Sudan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Juba"
                    }-->
                          <option ng-reflect-value="Africa/Juba" value="Africa/Juba">
                            Africa/Juba</option>
                        </optgroup>
                        <optgroup ng-reflect-label="South Africa" label="South Africa">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Johannesburg"
                    }-->
                          <option ng-reflect-value="Africa/Johannesburg" value="Africa/Johannesburg">Africa/Johannesburg
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="South Georgia And Sandwich Isl."
                          label="South Georgia And Sandwich Isl.">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Atlantic/South_Georgia"
                    }-->
                          <option ng-reflect-value="Atlantic/South_Georgia" value="Atlantic/South_Georgia">
                            Atlantic/South_Georgia</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Spain" label="Spain">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Madrid,Africa/Ceuta,Atlantic/Canary"
                    }-->
                          <option ng-reflect-value="Europe/Madrid" value="Europe/Madrid">
                            Europe/Madrid</option>
                          <option ng-reflect-value="Africa/Ceuta" value="Africa/Ceuta">
                            Africa/Ceuta</option>
                          <option ng-reflect-value="Atlantic/Canary" value="Atlantic/Canary">
                            Atlantic/Canary</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Sri Lanka" label="Sri Lanka">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Colombo"
                    }-->
                          <option ng-reflect-value="Asia/Colombo" value="Asia/Colombo">
                            Asia/Colombo</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Sudan" label="Sudan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Khartoum"
                    }-->
                          <option ng-reflect-value="Africa/Khartoum" value="Africa/Khartoum">
                            Africa/Khartoum</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Suriname" label="Suriname">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Paramaribo"
                    }-->
                          <option ng-reflect-value="America/Paramaribo" value="America/Paramaribo">America/Paramaribo
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Svalbard And Jan Mayen" label="Svalbard And Jan Mayen">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Arctic/Longyearbyen"
                    }-->
                          <option ng-reflect-value="Arctic/Longyearbyen" value="Arctic/Longyearbyen">Arctic/Longyearbyen
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Swaziland" label="Swaziland">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Mbabane"
                    }-->
                          <option ng-reflect-value="Africa/Mbabane" value="Africa/Mbabane">
                            Africa/Mbabane</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Sweden" label="Sweden">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Stockholm"
                    }-->
                          <option ng-reflect-value="Europe/Stockholm" value="Europe/Stockholm">Europe/Stockholm</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Switzerland" label="Switzerland">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Zurich"
                    }-->
                          <option ng-reflect-value="Europe/Zurich" value="Europe/Zurich">
                            Europe/Zurich</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Syrian Arab Republic" label="Syrian Arab Republic">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Damascus"
                    }-->
                          <option ng-reflect-value="Asia/Damascus" value="Asia/Damascus">
                            Asia/Damascus</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Taiwan" label="Taiwan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Taipei"
                    }-->
                          <option ng-reflect-value="Asia/Taipei" value="Asia/Taipei">
                            Asia/Taipei</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Tajikistan" label="Tajikistan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Dushanbe"
                    }-->
                          <option ng-reflect-value="Asia/Dushanbe" value="Asia/Dushanbe">
                            Asia/Dushanbe</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Tanzania" label="Tanzania">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Dar_es_Salaam"
                    }-->
                          <option ng-reflect-value="Africa/Dar_es_Salaam" value="Africa/Dar_es_Salaam">
                            Africa/Dar_es_Salaam</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Thailand" label="Thailand">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Bangkok"
                    }-->
                          <option ng-reflect-value="Asia/Bangkok" value="Asia/Bangkok">
                            Asia/Bangkok</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Timor-Leste" label="Timor-Leste">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Dili"
                    }-->
                          <option ng-reflect-value="Asia/Dili" value="Asia/Dili">Asia/Dili
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Togo" label="Togo">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Lome"
                    }-->
                          <option ng-reflect-value="Africa/Lome" value="Africa/Lome">
                            Africa/Lome</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Tokelau" label="Tokelau">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Fakaofo"
                    }-->
                          <option ng-reflect-value="Pacific/Fakaofo" value="Pacific/Fakaofo">
                            Pacific/Fakaofo</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Tonga" label="Tonga">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Tongatapu"
                    }-->
                          <option ng-reflect-value="Pacific/Tongatapu" value="Pacific/Tongatapu">Pacific/Tongatapu
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Trinidad And Tobago" label="Trinidad And Tobago">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Port_of_Spain"
                    }-->
                          <option ng-reflect-value="America/Port_of_Spain" value="America/Port_of_Spain">
                            America/Port_of_Spain</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Tunisia" label="Tunisia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Tunis"
                    }-->
                          <option ng-reflect-value="Africa/Tunis" value="Africa/Tunis">
                            Africa/Tunis</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Turkey" label="Turkey">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Istanbul"
                    }-->
                          <option ng-reflect-value="Europe/Istanbul" value="Europe/Istanbul">
                            Europe/Istanbul</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Turkmenistan" label="Turkmenistan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Ashgabat"
                    }-->
                          <option ng-reflect-value="Asia/Ashgabat" value="Asia/Ashgabat">
                            Asia/Ashgabat</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Turks And Caicos Islands" label="Turks And Caicos Islands">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Grand_Turk"
                    }-->
                          <option ng-reflect-value="America/Grand_Turk" value="America/Grand_Turk">America/Grand_Turk
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Tuvalu" label="Tuvalu">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Funafuti"
                    }-->
                          <option ng-reflect-value="Pacific/Funafuti" value="Pacific/Funafuti">Pacific/Funafuti</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Uganda" label="Uganda">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Kampala"
                    }-->
                          <option ng-reflect-value="Africa/Kampala" value="Africa/Kampala">
                            Africa/Kampala</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Ukraine" label="Ukraine">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/Kiev,Europe/Uzhgorod,Europe/Zaporozhye"
                    }-->
                          <option ng-reflect-value="Europe/Kiev" value="Europe/Kiev">
                            Europe/Kiev</option>
                          <option ng-reflect-value="Europe/Uzhgorod" value="Europe/Uzhgorod">
                            Europe/Uzhgorod</option>
                          <option ng-reflect-value="Europe/Zaporozhye" value="Europe/Zaporozhye">Europe/Zaporozhye
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="United Arab Emirates" label="United Arab Emirates">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Dubai"
                    }-->
                          <option ng-reflect-value="Asia/Dubai" value="Asia/Dubai">Asia/Dubai
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="United Kingdom (UK)" label="United Kingdom (UK)">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Europe/London"
                    }-->
                          <option ng-reflect-value="Europe/London" value="Europe/London">
                            Europe/London</option>
                        </optgroup>
                        <optgroup ng-reflect-label="United States (USA)" label="United States (USA)">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/New_York,America/Detroit,America/Kentucky/Louisville,America/Kentucky/Monticello,America/Indiana/Indianapolis,America/Indiana/Vincennes,America/Indiana/Winamac,America/Indiana/Marengo,America/Indiana/Petersburg,America/Indiana/Vevay,America/Chicago,America/Indiana/Tell_City,America/Indiana/Knox,America/Menominee,America/North_Dakota/Center,America/North_Dakota/New_Salem,America/North_Dakota/Beulah,America/Denver,America/Boise,America/Phoenix,America/Los_Angeles,America/Anchorage,America/Juneau,America/Sitka,America/Yakutat,America/Nome,America/Adak,America/Metlakatla,Pacific/Honolulu"
                    }-->
                          <option ng-reflect-value="America/New_York" value="America/New_York">America/New_York</option>
                          <option ng-reflect-value="America/Detroit" value="America/Detroit">
                            America/Detroit</option>
                          <option ng-reflect-value="America/Kentucky/Louisville" value="America/Kentucky/Louisville">
                            America/Kentucky/Louisville
                          </option>
                          <option ng-reflect-value="America/Kentucky/Monticello" value="America/Kentucky/Monticello">
                            America/Kentucky/Monticello
                          </option>
                          <option ng-reflect-value="America/Indiana/Indianapolis" value="America/Indiana/Indianapolis">
                            America/Indiana/Indianapolis</option>
                          <option ng-reflect-value="America/Indiana/Vincennes" value="America/Indiana/Vincennes">
                            America/Indiana/Vincennes
                          </option>
                          <option ng-reflect-value="America/Indiana/Winamac" value="America/Indiana/Winamac">
                            America/Indiana/Winamac</option>
                          <option ng-reflect-value="America/Indiana/Marengo" value="America/Indiana/Marengo">
                            America/Indiana/Marengo</option>
                          <option ng-reflect-value="America/Indiana/Petersburg" value="America/Indiana/Petersburg">
                            America/Indiana/Petersburg
                          </option>
                          <option ng-reflect-value="America/Indiana/Vevay" value="America/Indiana/Vevay">
                            America/Indiana/Vevay</option>
                          <option ng-reflect-value="America/Chicago" value="America/Chicago">
                            America/Chicago</option>
                          <option ng-reflect-value="America/Indiana/Tell_City" value="America/Indiana/Tell_City">
                            America/Indiana/Tell_City
                          </option>
                          <option ng-reflect-value="America/Indiana/Knox" value="America/Indiana/Knox">
                            America/Indiana/Knox</option>
                          <option ng-reflect-value="America/Menominee" value="America/Menominee">America/Menominee
                          </option>
                          <option ng-reflect-value="America/North_Dakota/Center" value="America/North_Dakota/Center">
                            America/North_Dakota/Center
                          </option>
                          <option ng-reflect-value="America/North_Dakota/New_Salem"
                            value="America/North_Dakota/New_Salem">
                            America/North_Dakota/New_Salem</option>
                          <option ng-reflect-value="America/North_Dakota/Beulah" value="America/North_Dakota/Beulah">
                            America/North_Dakota/Beulah
                          </option>
                          <option ng-reflect-value="America/Denver" value="America/Denver">
                            America/Denver</option>
                          <option ng-reflect-value="America/Boise" value="America/Boise">
                            America/Boise</option>
                          <option ng-reflect-value="America/Phoenix" value="America/Phoenix">
                            America/Phoenix</option>
                          <option ng-reflect-value="America/Los_Angeles" value="America/Los_Angeles">America/Los_Angeles
                          </option>
                          <option ng-reflect-value="America/Anchorage" value="America/Anchorage">America/Anchorage
                          </option>
                          <option ng-reflect-value="America/Juneau" value="America/Juneau">
                            America/Juneau</option>
                          <option ng-reflect-value="America/Sitka" value="America/Sitka">
                            America/Sitka</option>
                          <option ng-reflect-value="America/Yakutat" value="America/Yakutat">
                            America/Yakutat</option>
                          <option ng-reflect-value="America/Nome" value="America/Nome">
                            America/Nome</option>
                          <option ng-reflect-value="America/Adak" value="America/Adak">
                            America/Adak</option>
                          <option ng-reflect-value="America/Metlakatla" value="America/Metlakatla">America/Metlakatla
                          </option>
                          <option ng-reflect-value="Pacific/Honolulu" value="Pacific/Honolulu">Pacific/Honolulu</option>
                        </optgroup>
                        <optgroup ng-reflect-label="United States Outlying Islands"
                          label="United States Outlying Islands">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Johnston,Pacific/Midway,Pacific/Wake"
                    }-->
                          <option ng-reflect-value="Pacific/Johnston" value="Pacific/Johnston">Pacific/Johnston</option>
                          <option ng-reflect-value="Pacific/Midway" value="Pacific/Midway">
                            Pacific/Midway</option>
                          <option ng-reflect-value="Pacific/Wake" value="Pacific/Wake">
                            Pacific/Wake</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Uruguay" label="Uruguay">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Montevideo"
                    }-->
                          <option ng-reflect-value="America/Montevideo" value="America/Montevideo">America/Montevideo
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Uzbekistan" label="Uzbekistan">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Samarkand,Asia/Tashkent"
                    }-->
                          <option ng-reflect-value="Asia/Samarkand" value="Asia/Samarkand">
                            Asia/Samarkand</option>
                          <option ng-reflect-value="Asia/Tashkent" value="Asia/Tashkent">
                            Asia/Tashkent</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Vanuatu" label="Vanuatu">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Efate"
                    }-->
                          <option ng-reflect-value="Pacific/Efate" value="Pacific/Efate">
                            Pacific/Efate</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Venezuela" label="Venezuela">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Caracas"
                    }-->
                          <option ng-reflect-value="America/Caracas" value="America/Caracas">
                            America/Caracas</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Viet Nam" label="Viet Nam">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Ho_Chi_Minh"
                    }-->
                          <option ng-reflect-value="Asia/Ho_Chi_Minh" value="Asia/Ho_Chi_Minh">Asia/Ho_Chi_Minh</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Virgin Islands (British)" label="Virgin Islands (British)">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Tortola"
                    }-->
                          <option ng-reflect-value="America/Tortola" value="America/Tortola">
                            America/Tortola</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Virgin Islands (U.S.)" label="Virgin Islands (U.S.)">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/St_Thomas"
                    }-->
                          <option ng-reflect-value="America/St_Thomas" value="America/St_Thomas">America/St_Thomas
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Wallis And Futuna" label="Wallis And Futuna">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Pacific/Wallis"
                    }-->
                          <option ng-reflect-value="Pacific/Wallis" value="Pacific/Wallis">
                            Pacific/Wallis</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Western Sahara" label="Western Sahara">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/El_Aaiun"
                    }-->
                          <option ng-reflect-value="Africa/El_Aaiun" value="Africa/El_Aaiun">
                            Africa/El_Aaiun</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Yemen" label="Yemen">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Aden"
                    }-->
                          <option ng-reflect-value="Asia/Aden" value="Asia/Aden">Asia/Aden
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="Zambia" label="Zambia">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Lusaka"
                    }-->
                          <option ng-reflect-value="Africa/Lusaka" value="Africa/Lusaka">
                            Africa/Lusaka</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Zimbabwe" label="Zimbabwe">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Africa/Harare"
                    }-->
                          <option ng-reflect-value="Africa/Harare" value="Africa/Harare">
                            Africa/Harare</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Sint Maarten" label="Sint Maarten">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Lower_Princes"
                    }-->
                          <option ng-reflect-value="America/Lower_Princes" value="America/Lower_Princes">
                            America/Lower_Princes</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Curacao" label="Curacao">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Curacao"
                    }-->
                          <option ng-reflect-value="America/Curacao" value="America/Curacao">
                            America/Curacao</option>
                        </optgroup>
                        <optgroup ng-reflect-label="Bonaire" label="Bonaire">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "America/Kralendijk"
                    }-->
                          <option ng-reflect-value="America/Kralendijk" value="America/Kralendijk">America/Kralendijk
                          </option>
                        </optgroup>
                        <optgroup ng-reflect-label="North Korea" label="North Korea">
                          <!--template bindings={
                    "ng-reflect-ng-for-of": "Asia/Pyongyang"
                    }-->
                          <option ng-reflect-value="Asia/Pyongyang" value="Asia/Pyongyang">
                            Asia/Pyongyang</option>
                        </optgroup>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" style="vertical-align: top;">
                      <table style="width: 100%;">
                        <colgroup>
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td align="right" style="vertical-align: top;">
                              <button (click)="timezone = false;" type="button" class="gwt-Button">
                                Done
                              </button>
                            </td>
                            <td align="left" style="vertical-align: top;">
                              <button (click)="timezone = false;" type="button" class="gwt-Button">
                                Cancel
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
          <td class="dialogMiddleRight">
            <div class="dialogMiddleRightInner"></div>
          </td>
        </tr>
        <tr class="dialogBottom">
          <td class="dialogBottomLeft">
            <div class="dialogBottomLeftInner"></div>
          </td>
          <td class="dialogBottomCenter">
            <div class="dialogBottomCenterInner"></div>
          </td>
          <td class="dialogBottomRight">
            <div class="dialogBottomRightInner"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="gwt-DialogBox dialogBody" [style.display]="editDisplay(repeat)"
  style="z-index: 2; left: 410px; top: 10%; position: fixed; display: none; overflow: visible;">
  <div class="">
    <table cellspacing="0" cellpadding="0" class="">
      <tbody>
        <tr class="dialogTop">
          <td class="dialogTopLeft">
            <div class="dialogTopLeftInner"></div>
          </td>
          <td class="dialogTopCenter">
            <div class="dialogTopCenterInner">
              <div class="Caption">
                <h1>Repeat</h1>
              </div>
            </div>
          </td>
          <td class="dialogTopRight">
            <div class="dialogTopRightInner"></div>
          </td>
        </tr>
        <tr class="dialogMiddle">
          <td class="dialogMiddleLeft">
            <div class="dialogMiddleLeftInner"></div>
          </td>
          <td class="dialogMiddleCenter">
            <div class="dialogMiddleCenterInner dialogContent">
              <table cellspacing="0" cellpadding="0" style="width: 500px;">
                <tbody>
                  <tr>
                    <td align="left" style="vertical-align: top;">
                      <table style="width: 100%;">
                        <colgroup>
                          <col />
                        </colgroup>
                        <tbody>
                          <tr>
                            <td width="100%">
                              <table style="width: 100%;">
                                <colgroup>
                                  <col />
                                </colgroup>
                                <tbody>
                                  <tr>
                                    <td width="20%" align="right" style="vertical-align: top;">
                                      <div class="gwt-Label">Repeats:</div>
                                    </td>
                                    <td width="80%" align="left" style="vertical-align: top;">
                                      <select (change)="resetAll()"
                                        [(ngModel)]="createCourse.currentSchedule.repeattype" name="repeattype"
                                        class="gwt-ListBox">
                                        <option value="Daily">Daily</option>
                                        <option value="EveryWeekDay">Every weekday (Monday to
                                          Friday)</option>
                                        <option value="EveryMonWedFriday">Every Monday, Wednesday, and Friday
                                        </option>
                                        <option value="EveryTueThu">Every Tuesday and Thursday</option>
                                        <option value="Weekly">Weekly</option>
                                      </select>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr
                            *ngIf="createCourse.currentSchedule.repeattype == 'Daily' ||
                                                               createCourse.currentSchedule.repeattype == 'Monthly' ||
                                                               createCourse.currentSchedule.repeattype == 'Yearly' ||
                                                               createCourse.currentSchedule.repeattype == 'Weekly' ||
                                                               createCourse.currentSchedule.repeattype == 'EveryTueThu' ||
                                                               createCourse.currentSchedule.repeattype == 'EveryMonWedFriday' ||
                                                               createCourse.currentSchedule.repeattype == 'EveryWeekDay'">
                            <td width="100%">
                              <table style="width: 100%;">
                                <colgroup>
                                  <col />
                                </colgroup>
                                <tbody>
                                  <tr></tr>
                                  <tr>
                                    <td width="20%" align="right" style="vertical-align: top;">
                                      <div class="gwt-Label">Repeat every:</div>
                                    </td>
                                    <td width="10%" align="left" style="vertical-align: top;">
                                      <select [(ngModel)]="createCourse.currentSchedule.repeateEveryCounttemp"
                                        name="repeateEveryCounttemp" (change)="summaryGenerate()" class="gwt-ListBox">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                      </select>
                                    </td>
                                    <td align="left" style="vertical-align: top;"
                                      *ngIf="createCourse.currentSchedule.repeattype == 'Daily'">
                                      <div class="gwt-Label">days</div>
                                    </td>
                                    <td align="left" style="vertical-align: top;"
                                      *ngIf="createCourse.currentSchedule.repeattype == 'Weekly' ||
                                                                                                                             createCourse.currentSchedule.repeattype == 'EveryTueThu' ||
                                                                                                                             createCourse.currentSchedule.repeattype == 'EveryMonWedFriday' ||
                                                                                                                             createCourse.currentSchedule.repeattype == 'EveryWeekDay'">
                                      <div class="gwt-Label">weeks</div>
                                    </td>
                                    <td align="left" style="vertical-align: top;"
                                      *ngIf="createCourse.currentSchedule.repeattype == 'Monthly'">
                                      <div class="gwt-Label">months</div>
                                    </td>
                                    <td align="left" style="vertical-align: top;"
                                      *ngIf="createCourse.currentSchedule.repeattype == 'Yearly'">
                                      <div class="gwt-Label">years</div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr
                            *ngIf="createCourse.currentSchedule.repeattype == 'Weekly' || createCourse.currentSchedule.repeattype == 'Monthly'">
                            <td width="100%" *ngIf="createCourse.currentSchedule.repeattype == 'Weekly'">
                              <table style="width: 100%;">
                                <colgroup>
                                  <col />
                                </colgroup>
                                <tbody>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr>
                                    <td width="20%" align="right" style="vertical-align: top;">
                                      <div class="gwt-Label">Repeat by:</div>
                                    </td>
                                    <td>
                                      <span class="gwt-CheckBox"><input type="checkbox"
                                          [(ngModel)]="createCourse.currentSchedule.selectedDaysOfWeektempStr[0]"
                                          (change)="weekdatChange(0, 'Sun', $event)" name="selectedDaysOfWeektempStr[0]"
                                          [value]="Sun" id="gwt-uid-8" tabindex="0" /><label
                                          for="gwt-uid-8">S</label></span>
                                    </td>
                                    <td>
                                      <span class="gwt-CheckBox"><input type="checkbox"
                                          [(ngModel)]="createCourse.currentSchedule.selectedDaysOfWeektempStr[1]"
                                          (change)="weekdatChange(1, 'Mon', $event)" name="selectedDaysOfWeektempStr[1]"
                                          [value]="Mon" id="gwt-uid-9" tabindex="0" /><label
                                          for="gwt-uid-9">M</label></span>
                                    </td>
                                    <td>
                                      <span class="gwt-CheckBox"><input type="checkbox"
                                          [(ngModel)]="createCourse.currentSchedule.selectedDaysOfWeektempStr[2]"
                                          (change)="weekdatChange(2, 'Tue', $event)" name="selectedDaysOfWeektempStr[2]"
                                          [value]="Tue" id="gwt-uid-10" tabindex="0" /><label
                                          for="gwt-uid-10">T</label></span>
                                    </td>
                                    <td>
                                      <span class="gwt-CheckBox"><input type="checkbox"
                                          [(ngModel)]="createCourse.currentSchedule.selectedDaysOfWeektempStr[3]"
                                          (change)="weekdatChange(3, 'Wed', $event)" name="selectedDaysOfWeektempStr[3]"
                                          [value]="Wed" id="gwt-uid-11" tabindex="0" /><label
                                          for="gwt-uid-11">W</label></span>
                                    </td>
                                    <td>
                                      <span class="gwt-CheckBox"><input type="checkbox"
                                          [(ngModel)]="createCourse.currentSchedule.selectedDaysOfWeektempStr[4]"
                                          (change)="weekdatChange(4, 'Thu', $event)" name="selectedDaysOfWeektempStr[4]"
                                          [value]="Thu" id="gwt-uid-12" tabindex="0" checked="" /><label
                                          for="gwt-uid-12">T</label></span>
                                    </td>
                                    <td>
                                      <span class="gwt-CheckBox"><input type="checkbox"
                                          [(ngModel)]="createCourse.currentSchedule.selectedDaysOfWeektempStr[5]"
                                          (change)="weekdatChange(5, 'Fri', $event)" name="selectedDaysOfWeektempStr[5]"
                                          [value]="Fri" id="gwt-uid-13" tabindex="0" /><label
                                          for="gwt-uid-13">F</label></span>
                                    </td>
                                    <td>
                                      <span class="gwt-CheckBox"><input type="checkbox"
                                          [(ngModel)]="createCourse.currentSchedule.selectedDaysOfWeektempStr[6]"
                                          (change)="weekdatChange(6, 'Sat', $event)" name="selectedDaysOfWeektempStr[6]"
                                          [value]="Sat" id="gwt-uid-14" tabindex="0" /><label
                                          for="gwt-uid-14">S</label></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                            <td width="100%" *ngIf="createCourse.currentSchedule.repeattype == 'Monthly'">
                              <table style="width: 100%;">
                                <colgroup>
                                  <col />
                                </colgroup>
                                <tbody>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr>
                                    <td width="20%" align="right" style="vertical-align: top;">
                                      <div class="gwt-Label">Repeat by:</div>
                                    </td>
                                    <td width="30%" align="left" style="vertical-align: top;">
                                      <span class="gwt-RadioButton"><input type="radio"
                                          [(ngModel)]="createCourse.currentSchedule.monthlyRepeatTypeInputStr"
                                          (change)="summaryGenerate()" name="monthlyRepeatTypeInputStr"
                                          value="DayOfMonth" id="gwt-uid-6" tabindex="0" checked="" /><label
                                          for="gwt-uid-6">day of the month
                                        </label></span>
                                    </td>
                                    <td align="left" style="vertical-align: top;">
                                      <span class="gwt-RadioButton"><input type="radio"
                                          [(ngModel)]="createCourse.currentSchedule.monthlyRepeatTypeInputStr"
                                          (change)="summaryGenerate()" name="monthlyRepeatTypeInputStr"
                                          value="DayOfWeek" id="gwt-uid-7" tabindex="0" /><label for="gwt-uid-7">day of
                                          the week</label></span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr
                            *ngIf="createCourse.currentSchedule.repeattype == 'Daily' || createCourse.currentSchedule.repeattype == 'EveryWeekDay' || createCourse.currentSchedule.repeattype == 'EveryMonWedFriday' || createCourse.currentSchedule.repeattype == 'EveryTueThu' || createCourse.currentSchedule.repeattype == 'Weekly' || createCourse.currentSchedule.repeattype == 'Monthly' || createCourse.currentSchedule.repeattype == 'Yearly'">
                            <td width="100%">
                              <table style="width: 100%;">
                                <colgroup>
                                  <col />
                                </colgroup>
                                <tbody>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr>
                                    <td width="20%" align="right" style="vertical-align: top;">
                                      <div class="gwt-Label">Starts On:</div>
                                    </td>
                                    <td align="left" style="vertical-align: top;">
                                      <p-calendar class="gwt-DateBox"
                                        [(ngModel)]="createCourse.currentSchedule.csstartDate" [readonlyInput]="true"
                                        name="csstartDate" dateFormat="mm/dd/yy" [disabled]="true">
                                      </p-calendar>
                                      <!--<input type="text" [(ngModel)]="csstartDate" name="csstartDate" class="gwt-DateBox" disabled="">-->
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr
                            *ngIf="createCourse.currentSchedule.repeattype == 'Daily' || createCourse.currentSchedule.repeattype == 'EveryWeekDay' || createCourse.currentSchedule.repeattype == 'EveryMonWedFriday' || createCourse.currentSchedule.repeattype == 'EveryTueThu' || createCourse.currentSchedule.repeattype == 'Weekly' || createCourse.currentSchedule.repeattype == 'Monthly' || createCourse.currentSchedule.repeattype == 'Yearly'">
                            <td width="100%">
                              <table style="width: 100%;">
                                <colgroup>
                                  <col />
                                </colgroup>
                                <tbody>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr>
                                    <td width="20%" align="right" style="vertical-align: top;">
                                      <div class="gwt-Label">Ends:</div>
                                    </td>
                                    <td>
                                      <div class="gwt-Label"></div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr
                            *ngIf="createCourse.currentSchedule.repeattype == 'Daily' || createCourse.currentSchedule.repeattype == 'EveryWeekDay' || createCourse.currentSchedule.repeattype == 'EveryMonWedFriday' || createCourse.currentSchedule.repeattype == 'EveryTueThu' || createCourse.currentSchedule.repeattype == 'Weekly' || createCourse.currentSchedule.repeattype == 'Monthly' || createCourse.currentSchedule.repeattype == 'Yearly'">
                            <td width="100%">
                              <table style="width: 100%;">
                                <colgroup>
                                  <col />
                                </colgroup>
                                <tbody>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr>
                                    <td width="20%" align="right" style="vertical-align: top;">
                                      <div class="gwt-Label"></div>
                                    </td>
                                    <td width="80px" align="left" style="vertical-align: top;">
                                      <span class="gwt-RadioButton"><input type="radio"
                                          [(ngModel)]="createCourse.currentSchedule.endOfMeetingTypeInputStr"
                                          (change)="summaryGenerate()" name="endOfMeetingTypeInputStr" value="Occurence"
                                          id="gwt-uid-4" tabindex="0" /><label for="gwt-uid-4">After
                                        </label></span>
                                    </td>
                                    <td width="50px" align="left" style="vertical-align: top;">
                                      <input type="number"
                                        [disabled]="createCourse.currentSchedule.endOfMeetingTypeInputStr=='Occurence' ? null : true"
                                        (keyup)="summaryGenerate()"
                                        [(ngModel)]="createCourse.currentSchedule.csoccurence" name="csoccurence"
                                        class="gwt-TextBox" maxlength="3" />
                                    </td>
                                    <td align="left" style="vertical-align: top;">
                                      <div class="gwt-Label">occurences</div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr
                            *ngIf="createCourse.currentSchedule.repeattype == 'Daily' || createCourse.currentSchedule.repeattype == 'EveryWeekDay' || createCourse.currentSchedule.repeattype == 'EveryMonWedFriday' || createCourse.currentSchedule.repeattype == 'EveryTueThu' || createCourse.currentSchedule.repeattype == 'Weekly' || createCourse.currentSchedule.repeattype == 'Monthly' || createCourse.currentSchedule.repeattype == 'Yearly'">
                            <td width="100%">
                              <table style="width: 100%;">
                                <colgroup>
                                  <col />
                                </colgroup>
                                <tbody>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr>
                                    <td width="20%" align="right" style="vertical-align: top;">
                                      <div class="gwt-Label"></div>
                                    </td>
                                    <td width="80px" align="left" style="vertical-align: top;">
                                      <span class="gwt-RadioButton">
                                        <input type="radio"
                                          [(ngModel)]="createCourse.currentSchedule.endOfMeetingTypeInputStr"
                                          (change)="addpropOn($event)" name="endOfMeetingTypeInputStr" value="OnDate"
                                          id="gwt-uid-5" tabindex="0" />
                                        <label for="gwt-uid-5">On</label></span>
                                    </td>
                                    <td width="150px" align="left" style="vertical-align: top;">
                                      <p-calendar class="gwt-DateBox" (onSelect)="summaryGenerate()"
                                        [disabled]="createCourse.currentSchedule.endOfMeetingTypeInputStr=='OnDate' ? null : true"
                                        [(ngModel)]="createCourse.currentSchedule.csendDate" [readonlyInput]="true"
                                        name="csendDate" [minDate]="createCourse.EndDate" dateFormat="mm/dd/yy">
                                      </p-calendar>
                                    </td>
                                    <td align="left" style="vertical-align: top;">
                                      <div class="gwt-Label"></div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr
                            *ngIf="createCourse.currentSchedule.repeattype == 'Daily' || createCourse.currentSchedule.repeattype == 'EveryWeekDay' || createCourse.currentSchedule.repeattype == 'EveryMonWedFriday' || createCourse.currentSchedule.repeattype == 'EveryTueThu' || createCourse.currentSchedule.repeattype == 'Weekly' || createCourse.currentSchedule.repeattype == 'Monthly' || createCourse.currentSchedule.repeattype == 'Yearly'">
                            <td width="100%">
                              <table style="width: 100%;">
                                <colgroup>
                                  <col />
                                </colgroup>
                                <tbody>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr>
                                    <td width="20%" align="right" style="vertical-align: top;">
                                      <div class="gwt-Label">Summary:</div>
                                    </td>
                                    <td width="80%" align="left" style="vertical-align: top;">
                                      <div class="gwt-Label">{{summary}}</div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td width="100%" align="center" style="vertical-align: top;">
                              <table>
                                <colgroup>
                                  <col />
                                </colgroup>
                                <tbody>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr></tr>
                                  <tr>
                                    <td width="50%" align="right" style="vertical-align: top;">
                                      <button type="button" (click)="repeatDone()" class="gwt-Button">
                                        Done
                                      </button>
                                    </td>
                                    <td width="50%" align="left" style="vertical-align: top;">
                                      <button (click)="cancleRepeat()" type="button" class="gwt-Button">
                                        Cancel
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
          <td class="dialogMiddleRight">
            <div class="dialogMiddleRightInner"></div>
          </td>
        </tr>
        <tr class="dialogBottom">
          <td class="dialogBottomLeft">
            <div class="dialogBottomLeftInner"></div>
          </td>
          <td class="dialogBottomCenter">
            <div class="dialogBottomCenterInner"></div>
          </td>
          <td class="dialogBottomRight">
            <div class="dialogBottomRightInner"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<div class="editSponsoreOverlay" [style.display]="editDisplay(editSponsoreShow)"></div>
<div class="editSponsoreWrap" [style.display]="editDisplay(editSponsoreShow)">
  <div class="editSponsoreClose">

  </div>
  <div class="editSponsorePrice">
    <label>Price</label>
    <span class="dollerSign">$</span>
    <input type="text" [(ngModel)]="editSponsore.price" style="padding-left: 20px;" name="editSponsore.price"
      class="gwt-TextBox" maxlength="5" />
  </div>
  <div class="editSponsoreContent">
    <label>Benefits</label>
    <ejs-richtexteditor [height]="400" [(ngModel)]="editSponsore.content" #content="ngModel" name="editSponsore.content"
      [toolbarSettings]="basictools">
    </ejs-richtexteditor>
  </div>
  <div class="editSponsoreAction">
    <button type="button" class="buttonStyle2" (click)="editSponsoreSave()"
      style="width: 49%; margin-top: 10px;">Save</button>
    <button type="button" class="buttonStyle2" (click)="editSponsoreClose()"
      style="width: 49%; margin-top: 10px;">Cancel</button>
  </div>
</div>

<div class="addCoHostOverlay" [style.display]="editDisplay(addCoHostShow)"></div>
<div class="addCoHostWrap" [style.display]="editDisplay(addCoHostShow)">
  <div class="addCoHostClose">

  </div>
  <div class="addCoHost">
    <label>Co-Host</label>
    <p-autoComplete field="username" name="sessionCoHostId" [multiple]="true" [suggestions]="userToresults"
      (completeMethod)="searchusers($event)" (onSelect)="onSelectCoHost()" (onUnselect)="onUnselectCoHost()"
      [(ngModel)]="sessionCoHostId">
    </p-autoComplete>
  </div>
  <div class="addCoHostAction">
    <button type="button" class="buttonStyle2" (click)="addCoHostSave()"
      style="width: 49%; margin-top: 10px;">Add</button>
    <button type="button" class="buttonStyle2" (click)="addCoHostClose()"
      style="width: 49%; margin-top: 10px;">Cancel</button>
  </div>
</div>

<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
<uvsity-modal-popup [isModalDialogToBeDisplayed]="showErrorModalDialog" [messageTitle]="showErrorMessageTitle"
  [messages]="showErrorMessages" (uvsityModalPopupClosed)="closeErrorPopup()"
  [popupWidthPercentage]="showErrorPopupWidthInPercentage" [pleaseWait]="pleaseWait" [isSignUp]="isSignUp"
  [isValidationError]="isValidationError">
</uvsity-modal-popup>
<p-confirmDialog width="425"></p-confirmDialog>
<questionnaire-modal-popup [sessionId]="queSessionId" [objId]="createCourse.registrationQuestionnaireId" [isCreatingSession]="isSessionCreating" [isQuestionModalDialogToBeDisplayed]="questionnairePopup" (QuestionModalPopupClosed)="questionnaireClose($event)"></questionnaire-modal-popup>