<div class="questionnaireOverllay" *ngIf="isQuestionModalDialogToBeDisplayed"></div>
<div class="questionnairePopUpWrap" *ngIf="isQuestionModalDialogToBeDisplayed">
  <div class="questionnairePopUp">
      <div class="deleteWrap" *ngIf="objId > 0">
         <a class="deleteQuestionnaireBtn" href="javascript:void(0)" (click)="deleteQuestionnaire(objId)">Delete Questionnaire</a>
      </div>
     <form name="form" [formGroup]="questionnaireForm" novalidate (ngSubmit)="onSubmit()">
        <div class="questionnairePopUpForm">
            <div class="mainWrapper">
               <div class="descriptionWrap">
                  <div class="textInput descriptionDiv">
                     <label>Description:</label>
                     <input type="text" name="description" formControlName="description" class="form-control" required style="margin-bottom:0px;">
                        <div class="errorDiv" *ngIf="questionnaireForm.controls.description.errors && questionnaireForm.controls.description.touched">
                           <div [hidden]="!questionnaireForm.controls.description.errors.required">Description is required.</div>
                        </div>
                  </div>
               </div>
               <!-- <div class="selectInput col50">
                  <label>Questionnaire Type:</label>
                     <select name="questionnaireType" formControlName="questionnaireType" required>
                     <option [ngValue]="1">Session Registration</option>
                     <option [ngValue]="2">Session Review</option>
                     <option [ngValue]="3">Session Survey</option>
                     </select>
                     <div class="errorDiv" *ngIf="questionnaireForm.controls.questionnaireType.errors && questionnaireForm.controls.questionnaireType.touched">
                        <div [hidden]="!questionnaireForm.controls.questionnaireType.errors.required">Questionnaire Type is required.</div>
                     </div>
               </div> -->
            </div>
            <div class="addQuestionBtnWrap">
               <a pTooltip="Add New Question" class="addQuestionbtn" href="javascript:void(0)" (click)="addQuestion()"><i class="fa fa-plus-circle" aria-hidden="true"></i> Question</a>
            </div>
            <div class="ulWrapper" id="scrollDiv">
               <ul class="questionsUl" formArrayName="questions">
                  <li class="questionLi" *ngFor="let que of questions().controls; let queIndex = index">
                     <div [formGroupName]="queIndex">
                        <div class="textInput col50 questionDiv">
                           <label>Question:</label>
                           <input type="text" name="question" formControlName="question" required class="form-control" style="margin-bottom:0px;">
                           <div class="errorDiv" *ngIf="que.controls.question.errors && que.controls.question.touched">
                              <div [hidden]="!que.controls.question.errors.required">Question is required.</div>
                           </div>
                        </div>
                        
                        <div class="selectInput col50 answerTypeCodeDiv" id="answerTypeCodeDiv{{queIndex+1}}">
                           <label>Answer Type:</label>
                           <select name="answerTypeCode" required (change)="answerTypeCodeChange(queIndex, que.controls.answerTypeCode.value)" formControlName="answerTypeCode">
                              <option [ngValue]="1">Text</option>
                              <option [ngValue]="2">Radio</option>
                              <option [ngValue]="3">Checkbox</option>
                              <option [ngValue]="4">Dropdown</option>
                              <option [ngValue]="5">Multiselect</option>
                              <!-- <option [ngValue]="6">StarRating</option> -->
                              <option [ngValue]="7">DateTime</option>
                           </select>
                        </div>

                        <div class="maxLengthDiv" *ngIf="que.controls.answerTypeCode.value == 1">
                           <div class="textInput maxLength">
                                 <label>Max Length</label>
                                 <input type="Text" name="maxLength" formControlName="maxLength" required/>
                                 <div class="errorDiv" *ngIf="que.controls.maxLength.errors && que.controls.maxLength.touched">
                                    <div [hidden]="!que.controls.maxLength.errors.required">Max Length is required.</div>
                                 </div>
                           </div>
                        </div>

                        <div class="maxRatingDiv" *ngIf="que.controls.answerTypeCode.value == 6">
                           <div class="textInput maxRating">
                                 <label>Max Rating</label>
                                 <input type="Text" name="maxRating" formControlName="maxRating" required/>
                                 <div class="errorDiv" *ngIf="que.controls.maxRating.errors && que.controls.maxRating.touched">
                                    <div [hidden]="!que.controls.maxRating.errors.required">Max Rating is required.</div>
                                 </div>
                           </div>
                        </div>

                        <div class="optionsDivWrap" *ngIf="que.controls.answerTypeCode.value != 1 && que.controls.answerTypeCode.value != 6 && que.controls.answerTypeCode.value != 7" formArrayName="options">
                           <label>Options:</label>
                           <div class="textInput optionsDiv" *ngFor="let option of getOptions(queIndex).controls;
                                    let optionIndex = index">
                                 <div [formGroupName]="optionIndex">
                                    <input type="Text" name="optionName" formControlName="optionName" required/>
                                    <div class="errorDiv" *ngIf="option.controls.optionName.errors && option.controls.optionName.touched">
                                       <div [hidden]="!option.controls.optionName.errors.required">Option is required.</div>
                                    </div>
                                    <a pTooltip="Remove Option" class="removeBtn" href="javascript:void(0)" *ngIf="optionIndex > 0" (click)="removeOption(queIndex, optionIndex)"><i class="fa fa-times-circle" aria-hidden="true"></i></a>
                                 </div>
                           </div>
                           <!-- <div class="optionsbtnWrap" *ngIf="que.controls.answerTypeCode.value != 1 && que.controls.answerTypeCode.value != 6 && que.controls.answerTypeCode.value != 7"> -->
                           <a pTooltip="Add Option" class="addOptionbtn addOptionbtn{{queIndex}}" href="javascript:void(0)" (click)="addOption(queIndex)"><i class="fa fa-plus-circle" aria-hidden="true"></i> Option </a>
                           <!-- </div> -->
                        </div>

                         <div class="optionalWrap">
                           <div class="optional" pTooltip="Options Answer">
                              <p-checkbox formControlName="optional" binary="true" value="true" label="Optional"></p-checkbox>
                           </div>
                        </div> 

                     </div>
                     <a pTooltip="Remove Question" class="removeBtn" href="javascript:void(0)" *ngIf="queIndex > 0" (click)="removeQuestion(queIndex)"><i class="fa fa-minus-circle" aria-hidden="true"></i></a>
                  </li>
               </ul>
            </div>
            <div class="textInputButton col50 width495">
               <input type="submit" name="btnSubmit" id="btnSubmit" value="Save" class="buttonStyle">
            </div>
            
            <div class="textInputButton col50 width495" style="vertical-align: top;">
               <a (click)="handleModalPopupClose(false);" style="border-radius: 3px;padding: 5.5px; font-size: 12px;" class="buttonStyle">Cancel</a>
            </div>

        </div>
     </form>
  </div>
</div>