<div class="content_area pageblock" id="myAccountDiv">
  <div class="sixteen columns">
    <menu-component></menu-component>
    <div class="boxs">
      <div id="content-top">
        <ol id="breadcrumb" class="twelve columns alpha breadcrumb">
          <li class="home">
            <a href="javascript:void(0);" [routerLink]="['../home']">Home</a>
          </li>
          <li class="arrow">&nbsp;</li>
          <li>My Earning</li>
        </ol>
        <div class="clr"></div>
        <div class="clear"></div>
      </div>
    </div>
  </div>
  <div id="myEarningDetailDiv">
    <div class="siixteen columns addrmcl-content boxs clearfix" id="myEarningDetailDiv">
      <div class="paymentDetailsWrap">
        <div class="paymentDetailsLeft clearfix">
          <div class="BalanceWrap">
            <h4>Balance: </h4>
            <span>{{balanceCount | currency:'USD':true:'1.0-2'}}</span>
            <a href="javascript:void(0)" *ngIf="balanceCount > 0 && !payOutStatus" (click)="payout()"
              class="withdrawButton">Withdraw</a>
            <a href="javascript:void(0)" *ngIf="payOutStatus" class="withdrawButton orange">Payout in Progress</a>
          </div>
        </div>
        <div class="paymentDetailsRight">
          <div class="paymentDetailsUlWrap">
            <ul class="paymentDetailsUl">
              <li class="paymentDetailsLi">
                <h5>My Total Earning: </h5>
                <span>{{totalCount | currency:'USD':true:'1.0-2'}}</span>
              </li>
              <li class="paymentDetailsLi">
                <h5>From Sessions: </h5>
                <span>{{sessionCount | currency:'USD':true:'1.0-2'}}</span>
              </li>
              <li class="paymentDetailsLi">
                <h5>From Meetings: </h5>
                <span>{{meetingCount | currency:'USD':true:'1.0-2'}}</span>
              </li>
              <li class="paymentDetailsLi">
                <h5>From Sponsorships: </h5>
                <span>{{sponsorshipCount | currency:'USD':true:'1.0-2'}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="siixteen columns addrmcl-content boxs clearfix" id="myEarningTableDiv">
      <div class="TransactionFilterWrap">

      </div>
      <div class=TransactionsWrap>
        <p-dataTable styleClass="earningTable" #dt [value]="paymentallDetails" [rows]="10" [paginator]="true"
          [pageLinks]="3" [responsive]="false">
          <p-header>
            <div class="ui-helper-clearfix">
              <button type="button" pButton icon="fa-download" iconPos="left" label="Transactions to CSV"
                (click)="dt.exportCSV()" style="float:left; background: #0f8dbf;color: #fff;border: 0;"></button>
              <select class="filterDropdown" (change)="filterTransaction($event)">
                <option value="all">All</option>
                <option value="session">Sessions</option>
                <option value="meeting">Meetings</option>
                <option value="sponsorship">Sponsorships</option>
              </select>
            </div>
          </p-header>
          <p-column field="occurence" header="Date"></p-column>
          <p-column field="transactionSummary" header="Session/Meeting"></p-column>
          <p-column field="numberOfAttendees" header="No. of Attendee/Sponsors"></p-column>
          <p-column field="totalAmount" header="Total Amount Paid by user/s"></p-column>
          <p-column field="uvsityFees" header="Uvsity Processing Fee"></p-column>
          <p-column field="userAmount" header="Amount"></p-column>
        </p-dataTable>
      </div>
    </div>
  </div>

  <div class="clr"></div>

</div>
<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
<p-confirmDialog width="425"></p-confirmDialog>