<header class="header login" [style.min-height]="showBanner && closeBanner ? '110px' : '80px'">
  <div class="BannerWrap" *ngIf="showBanner && closeBanner">
    <div class="BannerSubWrap">
      <span class="BannerSpan">
        Sign Up today and get your free personal appointment app pre-configured with zoom.
      </span>
      <span class="closeBanner" (click)="closeBanner = false">x</span>
    </div>
  </div>
  <div class="overlayWrap" *ngIf="showOverlay"></div>
  <!-- <a href="#" class="pull-left"><img src="assets/images/logo.jpg" /> </a> -->
  <div class="main headermain">
    <a href="#" class="pull-left mainlogo" style="font-size: 30px;
  font-weight: 600;
  font-family: inherit;">uvsity<sub>.com</sub> </a>
    <div class="topnav" id="myTopnav">
      <a href="javascript:void(0)" *ngIf="routeUrl === '/login' || routeUrl === '/login/true' || routeUrl === '/'"
        (click)="GotoSection('sessions')" class="headerlivea">Live Sessions</a>
      <a href="javascript:void(0)" [routerLink]="['/login']"
        *ngIf="routeUrl != '/login' && routeUrl != '/login/true' && routeUrl != '/'" class="headerlivea">Live Sessions</a>
      <!-- <a href="javascript:void(0)" *ngIf="routeUrl === '/login' || routeUrl === '/login/true'"
        (click)="GotoSection('home')">Home</a>
      <a href="javascript:void(0)" [routerLink]="['/login']"
        *ngIf="routeUrl != '/login' && routeUrl != '/login/true'">Home</a> -->
      <!-- <a href="javascript:void(0)" (click)="GotoSection('howto')">How To</a> -->
      <!-- <a href="javascript:void(0)" (click)="GotoSection('courses')">Sessions</a> -->
      <a href="javascript:void(0)" *ngIf="routeUrl === '/login' || routeUrl === '/login/true' || routeUrl === '/'" 
        (click)="GotoSection('howto')" class="headerlivea">How it Works</a>
      <a href="javascript:void(0)" (click)="scrollToDiv('howto')"
      *ngIf="routeUrl != '/login' && routeUrl != '/login/true' && routeUrl != '/'" class="headerlivea">How it Works</a>
      <a href="javascript:void(0)" (click)="GotoSection('courses')" style="visibility: hidden;" class="headerlivea">Sessions</a>
      <a *ngIf="showBanner" class="aglogin" style="margin: 0;" href="javascript:void(0)">
        <button (click)="googleLogin()" class="loginBtn loginBtn--google">
          Continue with Google
        </button>
      </a>
      <a *ngIf="showBanner" class="aseparater" href="javascript:void(0)">
        <div class="orBrHeader">Or</div>
      </a>
      <a *ngIf="showBanner" class="alogin" style="margin: 0;" href="javascript:void(0)"><button
          class="btn loginBtnn headerLoginButton"
          (click)="loginOpen=true; showOverlay = true; forgetpass=false; signUp=false;">
          Login
        </button></a>
      <a *ngIf="!showBanner" [routerLink]="['/dashboard/home']" class="alogin goToAcc" style="margin: 0; float: right;"
        href="javascript:void(0)"><button class="btn loginBtnn headerLoginButton">
          Go to your account
        </button></a>
      <a href="javascript:void(0);" class="icon" onclick="mobileMenu()">
        <i class="fa fa-bars"></i>
      </a>
    </div>
    <!-- <div class="loginWrapper">
      <div class="socialLoginHeader">
        <button (click)="googleLogin()" class="loginBtn loginBtn--google">
          Continue with Google
        </button>
      </div>

      <div class="orBrHeader">Or</div>

      <button class="btn loginBtnn headerLoginButton"
        (click)="loginOpen=true; showOverlay = true; forgetpass=false; signUp=false;">
        Login
      </button>
    </div> -->
    <div class="error" style="display:none">
      You have been successfully logged out.
    </div>
  </div>
  <div id="errorDialog" title="Message" *ngIf="invalid">
    Please Enter Valid Username Password.
  </div>
  <div id="successDialog" title="Message" *ngIf="isAccountActivatedSuccessfully">
    You have successfully activated your account. Please login using your
    credentials.
  </div>
  <div id="successDialog" title="Message" *ngIf="isAccountAlreadyActivated">
    You have already activated your account earlier. Please use your credentials
    to login. Use "Forgot Password" to reset them in case you don't remember
    them.
  </div>

  <div id="successDialog" title="Message" *ngIf="isInvitationAcceptedSuccesfully">
    You have Successfully Accepted the Invitation Request !!
  </div>
  <div id="errorDialog" title="Message" *ngIf="isInvitationAcceptedUnsuccesfully">
    Unable to Accept the Invitation Request, Please try later !
  </div>

  <div id="successDialog" title="Message" *ngIf="isPayPalActivationSuccessful">
    {{paypalActivationMessage}}
  </div>
  <div id="errorDialog" title="Message" *ngIf="isPayPalActivationError">
    {{paypalActivationMessage}}
  </div>

  <div id="successDialog" title="Message" *ngIf="isPasswordChangedSuccessfully">
    {{passwordChangedSuccessfullyMessage}}
  </div>
  <div id="errorDialog" title="Message" *ngIf="isForgetPasswordLinkNotValid">
    {{forgetPasswordLinkNotValidMessage}}
  </div>
  <div id="successDialog" title="Message" *ngIf="isUnsubscribeSuccesfully">
    You have Successfully Unsubscribed the Uvsity Notifications !!
  </div>

  <uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
  <uvsity-modal-popup [isModalDialogToBeDisplayed]="showErrorModalDialog" [messageTitle]="showErrorMessageTitle"
    [messages]="showErrorMessages" (uvsityModalPopupClosed)="closeErrorPopup()"
    [popupWidthPercentage]="showErrorPopupWidthInPercentage" [pleaseWait]="pleaseWait" [isSignUp]="isSignUp"
    [isValidationError]="isValidationError">
  </uvsity-modal-popup>

  <div class="loginPopup-wrap" *ngIf="loginOpen">
    <span (click)="loginOpen = false; showOverlay = false;" class="closeLogin">X</span>
    <h2 *ngIf="!signUp">Log in</h2>
    <h2 *ngIf="signUp">Sign up now - It's free!</h2>
    <div class="socialLogin">
      <!-- <button (click)="Glogout()" style="display: none;">Sign in with Google</button> -->
      <!-- <button class="loginBtn loginBtn--facebook">
        Login with Facebook
      </button> -->

      <button (click)="googleLogin()" class="loginBtn loginBtn--google">
        Continue with Google
      </button>
    </div>

    <div class="orBr">Or</div>

    <div class="loginSection1" *ngIf="!forgetpass && !signUp">
      <div class="loginSection">
        <form (ngSubmit)="login()" #f="ngForm" novalidate id="frmLogon" name="frmLogon" class="newloginform">
          <input tabindex="1" class="textbox" [(ngModel)]="model.username" #username="ngModel" required type="text"
            name="username" id="emailId" size="28" maxlength="50" placeholder="Email Id" />
          <input #userPassword tabindex="2" class="pull-right" [(ngModel)]="model.password" #password="ngModel" required
            type="password" name="password" id="password" size="28" maxlength="20" placeholder="Password" />
          <input tabindex="3" type="submit" id="btnLogin" value="Login" class="btn loginBtnn" />
          <div class="forgetPasswordWrap">
            <div class="forgotpassword clearfix">
              <a class="forgotpassword pull-left" (click)="forgetpass = true; resendemail = false;">Forgot Password /
                Resend Email</a>&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="loginSection2" *ngIf="forgetpass">
      <div id="passwordContent" class="dropdownmenu" [style.display]="editDisplay(forgetpass)">
        <div class="eleven columns margintop create-topic-block forgot-password-block" id="forgotPasswordContentDiv">
          <div class="eight columns alpha posres">
            <input type="text" id="username" [(ngModel)]="forgotPassword.username"
              (keypress)="this.noEmailError = 'none';" #forgotPasswordEmail="ngModel"
              placeholder="Enter your email address" name="username" />
            <div [style.display]="getNoEmailError()" class="errorDiv">
              Please enter the Email Id
            </div>
          </div>

          <div class="eight columns alpha posres" style="width: 100% !important;">
            <re-captcha [(ngModel)]="forgotPasswordRecaptchaToken" name="captcha" required
              siteKey="6LcR5PwSAAAAAExCXrkpFPC4oT5oRtdf4uqhfLVG">
            </re-captcha>
            <br />
            <div [style.display]="getDisplayCaptchaError()" class="resendForgetCaptchaErrorDiv">
              Please select the Captcha as advised
            </div>
          </div>

          <br />

          <div class="two columns last">
            <input type="button" id="forgot-password-btn" name="forgot-password-btn"
              (click)="submitForgotPasswordResendEmail()" value="Submit" />
            <input style="margin-left: 10px;" type="button" id="forgot-password-cancel"
              (click)="cancelForgotPasswordResendEmail()" value="Cancel" />
          </div>
        </div>
        <div id="pleaseWaitDiv" class="eight columns password-reset-message margintop"
          style="margin-left: 0;display: none;">
          <img src="assets/images/pleaseWait.gif" width="15px" height="15px" />
        </div>
        <div class="eight columns password-reset-message margintop" style="margin-left: 0;display:none"
          id="forgotPasswordResponseDiv"></div>
      </div>
    </div>
    <div class="signUpSec" *ngIf="signUp">
      <signupPopup-component (SignupDone)="SignUpDone($event)"></signupPopup-component>
    </div>
    <hr />
    <div class="modalFooter">
      <div class="modalFooterLinks">
        <span class="loginLink" *ngIf="signUp">Back to
          <a href="javascript:void(0)" (click)="signUp=false; forgetpass=false;">Login</a></span>
        <span class="signupLink" *ngIf="!signUp">Not a member yet?
          <a href="javascript:void(0)" (click)="signUp=true; forgetpass=false;">Sign Up</a></span>
      </div>
      <div class="modalFooterpolicy">
        By clicking “Continue with Google” or “Sign up” or "Login", I confirm
        I’ve read and agree to uvsity's
        <a href="legal/termsandconditions.html" target="_blank">Terms of Use</a>, Privacy Policy.
      </div>
    </div>
  </div>
</header>