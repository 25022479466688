<div class="pageblock content_area relative">
    <div class="loader" [style.display]="editDisplay(loading)"><img src="assets/images/ajax-loader.gif" /></div>
    <div id="content-wrapper2">
        <div class="sixteen columns">
            <menu-component></menu-component>
            <div class="boxs">
                <div id="content-top">
                    <ol id="breadcrumb" class="twelve columns alpha breadcrumb">
                        <li class="home"><a href="#" [routerLink]="['../home']">Home</a> </li>
                        <li class="arrow">&nbsp;</li>
                        <li>Upgrade Account</li>
                    </ol>
                    <div class="clr"></div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <div class="sixteen columns boxs boxpad">
            <div style="display: none; margin: 0 12%;
            padding: 2px 0;
            font-style: italic;
            font-weight: 600;">For conducting sessions only</div>
            <div class="feature">{{currentPlan.effectivePlan}}</div>
            <table class="table upgrade table-bordered responsive responsive2">
                <tbody>
                    <tr>
                        <th class="feature">FEATURE</th>
                        <th class="feature" *ngFor="let fet of upgradePlansdetails.FEATURE">{{fet}}</th>
                    </tr>
                    <tr *ngFor="let fet of upgradePlansdetails.FeatureNames">
                        <td class="{{fet}}">
                            {{fet == "Uvsity fee*" ? "Uvsity fee (session)*" : fet == "Session Sponsorship fee*" ? "Sponsorship fee*" : fet == "Free 1hr Meetings" ? "Free Meetings (60 min)" : fet }}
                        </td>
                        <td class="{{fetname}}" *ngFor="let fetname of features[fet]">
                            <span
                                *ngIf="!fetname.objId">{{fetname === "999/month (max 999hrs)" || fetname == "999" ? 'Unlimited' : fetname}}</span>
                            <span
                                *ngIf="fetname.objId && fetname.membershipFeePerMonth != 0 && currentPlan.objId != fetname.objId">
                                <input type="radio" id="memfeeBasic" (change)="planSelect($event)" name="SelectPlan"
                                    value="{{fetname.payPalMonthlyId}}">&nbsp;&nbsp;Monthly:
                                ${{fetname.membershipFeePerMonth}} per month
                                <br><br>
                                <input type="radio" id="memfeeBasic" (change)="planSelect($event)" name="SelectPlan"
                                    value="{{fetname.payPalYearlyId}}">&nbsp;&nbsp;Yearly:
                                ${{fetname.membershipFeePerYear}} per year
                            </span>
                            <span
                                *ngIf="fetname.objId && fetname.membershipFeePerMonth != 0 && currentPlan.objId == fetname.objId">
                                <input type="radio" id="memfeeBasic"
                                    *ngIf="currentPlan.selectedPayPalId == currentPlan.payPalMonthlyId" checked disabled
                                    (change)="planSelect($event)" name="SelectPlan" value="{{fetname.payPalMonthlyId}}">
                                <input type="radio" id="memfeeBasic"
                                    *ngIf="currentPlan.selectedPayPalId != currentPlan.payPalMonthlyId" disabled
                                    (change)="planSelect($event)" name="SelectPlan" value="{{fetname.payPalMonthlyId}}">
                                &nbsp;&nbsp;Monthly: ${{fetname.membershipFeePerMonth}} per month
                                <br><br>
                                <input type="radio" id="memfeeBasic"
                                    *ngIf="currentPlan.selectedPayPalId == currentPlan.payPalYearlyId" checked disabled
                                    (change)="planSelect($event)" name="SelectPlan" value="{{fetname.payPalYearlyId}}">
                                <input type="radio" id="memfeeBasic"
                                    *ngIf="currentPlan.selectedPayPalId != currentPlan.payPalYearlyId" disabled
                                    (change)="planSelect($event)" name="SelectPlan" value="{{fetname.payPalYearlyId}}">
                                &nbsp;&nbsp;Yearly: ${{fetname.membershipFeePerYear}} per year
                            </span>
                            <span
                                *ngIf="fetname.objId && fetname.membershipFeePerMonth == 0 && currentPlan.objId != fetname.objId">
                                <!--<input type="radio" id="memfeeBasic" (change)="planSelect($event)" name="SelectPlan" value="{{fetname.payPalMonthlyId}}">Free-->
                                FREE
                                <br><br>
                                <!--<input type="radio" id="memfeeBasic" (change)="planSelect($event)" name="SelectPlan" value="{{fetname.payPalYearlyId}}">&nbsp;&nbsp;Yearly: Free	-->
                            </span>
                            <span
                                *ngIf="fetname.objId && fetname.membershipFeePerMonth == 0 && currentPlan.objId == fetname.objId">
                                <!--<input type="radio" id="memfeeBasic" *ngIf="currentPlan.selectedPayPalId == currentPlan.payPalMonthlyId" checked disabled (change)="planSelect($event)" name="SelectPlan" value="{{fetname.payPalMonthlyId}}">
                                <input type="radio" id="memfeeBasic" *ngIf="currentPlan.selectedPayPalId != currentPlan.payPalMonthlyId" disabled (change)="planSelect($event)" name="SelectPlan" value="{{fetname.payPalMonthlyId}}">-->
                                FREE
                                <br><br>
                                <!--<input type="radio" id="memfeeBasic" *ngIf="currentPlan.selectedPayPalId == currentPlan.payPalYearlyId" checked disabled (change)="planSelect($event)" name="SelectPlan" value="{{fetname.payPalYearlyId}}">
                                <input type="radio" id="memfeeBasic" *ngIf="currentPlan.selectedPayPalId != currentPlan.payPalYearlyId" disabled (change)="planSelect($event)" name="SelectPlan" value="{{fetname.payPalYearlyId}}">-->
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td class="feature">&nbsp;</td>
                        <td class="btntbl standard"
                            *ngFor="let upgradeplan of upgradePlansdetails.CurrentPlan; let i = index">
                            <span *ngIf="upgradeplan != null"
                                style="    background: #FFEB3B;color: #1406e2;padding: 6px 14px;font-weight: 600;border-radius: 20px;font-size: 12px;">Your
                                Current Plan*</span>
                            <span *ngIf="upgradeplan == null"><input class="button" (click)="upGrade(i)" type="button"
                                    value="Select Membership"></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div id="dim" class="dim" style="display: none; z-index:1;" [style.display]="editDisplay(popUp)"></div>
<div [style.display]="editDisplay(popUp)"
    class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
    tabindex="-1" role="dialog" aria-describedby="errorDialog" aria-labelledby="ui-id-1"
    style="position: fixed; height: auto; width: 300px; top: 18%; left: 42%; display: none; z-index:2;">
    <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"><span id="ui-id-1"
            class="ui-dialog-title">{{msgTitle}}</span><button
            class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
            role="button" aria-disabled="false" title="close" style="display: none;"><span
                class="ui-button-icon-primary ui-icon ui-icon-closethick"></span><span class="ui-button-text"
                (click)="popUp = false;">close</span></button></div>
    <div class="ui-dialog-content ui-widget-content"
        style="width: auto; min-height: 33px; max-height: none; height: auto;">
        <ul>
            <li>{{msgContent}}</li>
        </ul>
    </div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <div class="ui-dialog-buttonset"><button type="button"
                class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" (click)="popUp = false;"
                role="button" aria-disabled="false"><span class="ui-button-text">Close</span></button></div>
    </div>
    <div class="ui-resizable-handle ui-resizable-n" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-e" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-s" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-w" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-sw" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-ne" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-nw" style="z-index: 90;"></div>
</div>
<div class="container" [style.display]="editDisplay(basicPopup)"
    style="display: none; position: fixed;top: 0;left: 0;right: 0;bottom: 0;padding: 0;z-index: 2;">
    <div class="dim4" style="height: 100%;width: 100%;background: rgba(0, 0, 0, 0.5);">
        <!--<form method="post" action="#">-->
        <div class="ten columns invite-pop-up4 marginbottom"
            style="background: #fff;width: 50%;position: absolute;left: 25%;top: 15%;padding: 10px;">
            <div class="nine columns" style="font-size: 15px;margin-bottom: 25px;">
                <h3>Your current membership "{{currentPlan.planType}}" will remain effective till
                    {{currentPlan.billingCycleEndDate | amDateFormat:'ll'}} and will not be renewed for next billing
                    period." Are you sure you want to Cancel?</h3>
            </div>
            <div class="clear"></div>
            <div class="three columns alpha" style="width: 25%;display: inline-block;">
                <input type="submit" id="basicMem" (click)="BacisSelect()" name="basicMem" value="Yes" />
            </div>
            <div class="three columns last" style="width: 25%;display: inline-block;">
                <input type="button" id="cancel4" name="cancel4" (click)="basicPopup = false;" value="Cancel" />
            </div>
            <div class="clear"></div>
        </div>
        <!--</form>-->
    </div>
</div>