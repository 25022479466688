<div class="sixteen columns pageblock content_area">
    <div class="col-md-8 col_md_8">

        <menu-component></menu-component>

        <div class="row home_container">
            <div class="row">
                <div class="boxs">
                    <ol class="breadcrumb" id="breadcrumb">
                        <li class="home"><a href="javascript:void(0);" [routerLink]="['../home']"> Home
                            </a>
                        </li>
                        <li class="arrow">&nbsp;</li>
                        <li class="active">View Topic</li>
                    </ol>
                    <div class="clr"></div>
                </div>
            </div>
        </div>

        <form method="post" name="viewTopicForm" id="viewTopicForm">
            <input type="hidden" name="hdProfileId" id="hdProfileId">
            <input type="hidden" id="topicId" name="topicId" value="0">
            <input type="hidden" id="topicJoinId" name="topicJoinId" value="0">
            <input type="hidden" id="joinId" name="joinId" value="0">
            <input type="hidden" id="topicLeaveId" name="topicLeaveId" value="0">
            <div id="viewTopicMasterDiv">
                <div class="sixteen columns" style="margin-left: 0px;">
                    <div class="post-block inner-block profile-profile myconnections inbox viewtopic boxs boxpad">

                        <div class="uppercase profilename">
                            {{topicTO.topicName}}
                            <!--{{topicTO.topicName}}-->
                        </div>

                        <div class="topicowner">
                            <span class="gwt-Label profile-userBy userBy">by:</span>
                            <a href="javascript: void(0);" [routerLink]="['../profile']"
                                [queryParams]="{ user: topicTO.userDetailsSummaryTO.userDetailsId }"
                                class="fblue deco-none">
                                <span>{{topicTO.userDetailsSummaryTO.firstName}}&nbsp;{{topicTO.userDetailsSummaryTO.lastName}}</span>
                            </a>&nbsp;&nbsp; &nbsp;

                            <span class="gwt-Label profile-userBy userBy">website:</span>
                            <span><a class="fblue deco-none" href="#" target="Website"
                                    (click)="redirectClick(topicTO.topicWebsite)">{{topicTO.topicWebsite}}</a> </span>

                            <div *ngIf="topicDetail.isSubscriberLinkToBeDisplayed == true">

                                <a href="javascript: void(0);" class="fblue deco-none" [routerLink]="['../subscribers']"
                                    [queryParams]="{ topic: topicTO.topicDetailId }">
                                    {{topicTO.topicSubscribersCount}} Subscribers
                                </a>

                            </div>
                        </div>

                        <div class="title fgray">Discussions</div>

                        <div class="two columns inner-post-image">
                            <div class="featured-image">
                                <img *ngIf="topicTO.topicImageUrl != null && !topicTO.topicImageUrl.includes('profilebig.png')"
                                    id="profilePicImage_4" width="150" height="150" class="greyBorder profileImage"
                                    src="{{topicTO.topicImageUrl}}">
                                <ngx-avatar
                                    *ngIf="topicTO.topicImageUrl == null || topicTO.topicImageUrl.includes('profilebig.png')"
                                    name="{{topicTO.userDetailsSummaryTO.firstName}} {{topicTO.userDetailsSummaryTO.lastName}}"
                                    size="150"></ngx-avatar>
                            </div>
                        </div>
                        <div *ngIf="topicTO.topicDescriptionBeyondDisplayLength == false" id="descExpe_4"
                            class="topicsummary">
                            <div id="descExpeSpan_4" [innerHTML]="topicTO.topicDescription | safeHtml">
                            </div>
                        </div>
                        <div *ngIf="topicTO.topicDescriptionBeyondDisplayLength == true" id="descColl_4"
                            class="topicsummary" style="display:none" [style.display]="editDisplay(less)">
                            <span [innerHTML]="topicTO.shortTopicDescription | safeHtml"></span>
                            <a (click)="morecontent()" class="moreless" style="display:none; cursor:pointer;"
                                [style.display]="editDisplay(less)">More</a>
                        </div>
                        <div *ngIf="topicTO.topicDescriptionBeyondDisplayLength == true" id="descExpe_4"
                            class="topicsummary" style="display: none" style="display:none"
                            [style.display]="editDisplay(more)">
                            <span id="descExpeSpan_4" [innerHTML]="topicTO.topicDescription | safeHtml"></span>
                            <a (click)="lesscontent()" class="moreless" style="display:none; cursor:pointer;"
                                [style.display]="editDisplay(more)">Less</a>
                        </div>

                        <div class="clr"></div>
                        <div class="" id="topicButtonLikeDIV">
                            <div id="topicButtonDIV" class="view-topic-button">

                                <input type="button"
                                    *ngIf="topicDetail.actionToPerformOnTopic == 'CANNOT_JOIN_REQUEST_NOT_APPROVED'"
                                    class="btn-120 view-topics topic-button-width" value="Pending Approval">

                                <input type="button" *ngIf="topicDetail.actionToPerformOnTopic == 'REQUEST_TO_JOIN'"
                                    class="btn-120 view-topics topic-button-width"
                                    (click)="joinTopic(topicTO.topicDetailId)" value="Join Topic">

                                <input type="button" *ngIf="topicDetail.actionToPerformOnTopic == 'CLOSE'"
                                    class="btn-120 view-topics topic-button-width" (click)="closeTopic()"
                                    name="close-topic" value="Close Topic">

                                <input type="button"
                                    *ngIf="topicDetail.actionToPerformOnTopic == 'READY_TO_JOIN_REQUEST_ACCEPTED'"
                                    class="btn-120 view-topics topic-button-width" (click)="leaveTopic()"
                                    value="Leave Topic">
                                <!-- <input type="button" *ngIf="topicDetail.actionToPerformOnTopic == 'SUBSCRIBE' && topicDetail.isTopicViewerAlsoOwner == false" class="view-topics topic-button-width" (click)="leaveTopic(topicTO.topicDetailId)" name="leave-topic" value="Leave Topic"> -->

                            </div>

                            <div class="fifteen columns view-topic-icon" id="topicLikeDiv">
                                <input type="hidden" id="topicDetailId" name="topicId" value="4">
                                <a href="https://www.facebook.com/sharer/sharer.php?u={{facebookShareUrl}}"
                                    target="_blank"> <img src="assets/images/share/facebook.png"
                                        style="height:35px;position:relative;top:14px;" alt="Facebook"> <label>
                                        Share</label></a>
                                &nbsp;&nbsp;
                                <a *ngIf="topicDetail.isTopicAlreadyLikedByUser == false && topicDetail.isLikeTopicAllowed == true"
                                    (click)="likeTopic(topicTO.topicDetailId)" style="cursor:pointer">
                                    <img src="assets/images/yet-to-like.png" alt="Like" align="absmiddle"
                                        style="width:30px;height:30px;">
                                    {{topicTO.noOfLikes}}
                                    <label *ngIf="topicTO.noOfLikes <= 1"> Like</label>
                                    <label *ngIf="topicTO.noOfLikes > 1"> Likes</label>
                                </a>
                                <a *ngIf="topicDetail.isTopicAlreadyLikedByUser == true && topicDetail.isLikeTopicAllowed == true"
                                    (click)="unlikeTopic(topicTO.topicDetailId)" style="cursor:pointer">
                                    <img src="assets/images/liked.png" alt="Like" align="absmiddle"
                                        style="width:30px;height:30px;">
                                    {{topicTO.noOfLikes}}
                                    <label *ngIf="topicTO.noOfLikes <= 1"> Like</label>
                                    <label *ngIf="topicTO.noOfLikes > 1"> Likes</label>
                                </a>
                            </div>
                            <div class="clr"></div>

                        </div>
                        <div class="clr"></div>
                    </div>
                </div>

                <div id="topicCommentsDiv">

                    <div class="fifteen columns boxs boxpad addcommentbox"
                        *ngIf="topicDetail.isTopicCommentsAllowed == true">
                        <div class="two columns inner-post-image view-topic-comments">
                            <div class="featured-image">
                                <a href="#">
                                    <!-- <img width="150px" id="profilePicImage" class="greyBorder profileImage" src="{{user.profilePicName}}"> -->
                                    <img *ngIf="user.profilePicName != null && !user.profilePicName.includes('profilebig.png')"
                                        class="greyBorder profileImage" alt="" src="{{user.profilePicName}}"
                                        width="150px">
                                    <ngx-avatar
                                        *ngIf="user.profilePicName == null || user.profilePicName.includes('profilebig.png')"
                                        name="{{user.firstName}}"></ngx-avatar>
                                </a>
                            </div>
                        </div>
                        <div class="ten columns view-topic-comment-box marginbottom">
                            <div class="nine columns">
                                <textarea id="comment-box" [(ngModel)]="commentData.topicComment"
                                    name="commentData.topicComment" name="comment-box"></textarea>
                            </div>
                            <div class="clear"></div>
                            <div class="three columns">
                                <input type="button" (click)="addComment(topicTO.topicDetailId)"
                                    class="btn-120 view-topics" name="comment-submit" value="Add Comments">
                            </div>
                        </div>
                        <div class="clr"></div>
                    </div>

                    <div class="cmt-wrap" *ngFor="let topiccmt of topicCmt">
                        <div class="fifteen columns boxs boxpad topiccomments spamWrap">
                            <div class="two columns inner-post-image view-topic-comments">
                                <div class="featured-image">
                                    <a href="javascript: void(0);" class="fblue">
                                        <!-- <img width="150px" id="profilePicImage" class="greyBorder profileImage" src="{{topiccmt.userDetailsSummaryTO.profilePicName}}"> -->
                                        <img *ngIf="topiccmt.userDetailsSummaryTO.profilePicName != null && !topiccmt.userDetailsSummaryTO.profilePicName.includes('profilebig.png')"
                                            class="greyBorder profileImage" alt=""
                                            src="{{topiccmt.userDetailsSummaryTO.profilePicName}}">

                                        <ngx-avatar
                                            *ngIf="topiccmt.userDetailsSummaryTO.profilePicName == null || topiccmt.userDetailsSummaryTO.profilePicName.includes('profilebig.png')"
                                            name="{{topiccmt.userDetailsSummaryTO.firstName}}"></ngx-avatar>
                                    </a>
                                </div>
                            </div>
                            <div class="ten columns">
                                <div class="topiccommentlist">
                                    <div class="topic-name">
                                        <a href="javascript: void(0);" class="fblue" [routerLink]="['../profile']"
                                            [queryParams]="{ user: topiccmt.userDetailsSummaryTO.userDetailsId }">
                                            {{topiccmt.userDetailsSummaryTO.firstName}}</a>
                                    </div>
                                    <div class="topiccommentdesc">
                                        {{topiccmt.topicComment}}
                                        <label class="topiccommentdate settopright">
                                            {{topiccmt.createdOnDateInDDMmYYYFormat}},
                                            {{topiccmt.formattedCreatedDateForDisplay}}
                                        </label>
                                        <!-- <div class="rereplaylike" *ngIf="topicDetail.isLikeTopicAllowed == true"><img src="assets/images/like.png" alt="Like" align="absmiddle"> Like</div> -->

                                        <div class="icorply">
                                            <a *ngIf="topiccmt.userDetailsSummaryTO.userDetailsId != userId" pTooltip="Report spam" href="javascript: void(0);"
                                                (click)="reportSpam('comment', topiccmt.topicCommentId)"
                                                class="spamBtn deco-none"><i class="fa fa-flag"></i></a>
                                            <a href="javascript: void(0);"
                                                *ngIf="topicDetail.isReplyToTopicCOmmentsAllowed == true"
                                                (click)="topiccmt.newcomment = true;" class="fblue deco-none"><i
                                                    class="fa fa-reply"></i>Reply</a>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div class="clr"></div>
                        </div>
                        <div class="clr"></div>
                        <!-- START Child Topic Comment Div -->
                        <!-- <div *ngIf="topicCmt.topicCommentReplies!= ''"> -->
                        <div id="topicChildCommentDiv" class="topiccomments rereplay"
                            *ngFor="let topiccmtrply of topiccmt.topicCommentReplies">

                            <div class="clr"></div>

                            <div class="boxs boxpad rereplay spamWrap">
                                <!--child comment profile pic div  -->
                                <div class="two columns inner-post-image view-topic-comments">
                                    <div class="featured-image">
                                        <a href="javascript: void(0);" class="fblue deco-none">
                                            <!-- <img id="profilePicImage" class="greyBorder profileImage" src="{{topiccmtrply.userReplyingToComment.profilePicName}}"> -->
                                            <img *ngIf="topiccmtrply.userReplyingToComment.profilePicName != null && !topiccmtrply.userReplyingToComment.profilePicName.includes('profilebig.png')"
                                                class="greyBorder profileImage" alt=""
                                                src="{{topiccmtrply.userReplyingToComment.profilePicName}}">

                                            <ngx-avatar
                                                *ngIf="topiccmtrply.userReplyingToComment.profilePicName == null || topiccmtrply.userReplyingToComment.profilePicName.includes('profilebig.png')"
                                                name="{{topiccmtrply.userReplyingToComment.firstName}}"></ngx-avatar>
                                        </a>
                                    </div>
                                </div>
                                <!--end child comment profile pic div  -->
                                <div class=" ten columns view-topic-comment-box marginbottom">

                                    <div class="topiccommentdesc">
                                        <a href="javascript: void(0);" class="fblue deco-none"
                                            [routerLink]="['../profile']"
                                            [queryParams]="{ user: topiccmtrply.userReplyingToComment.userDetailsId }">{{topiccmtrply.userReplyingToComment.firstName}}
                                        </a>
                                        <div>{{topiccmtrply.topicCommentReply}}</div>
                                        <label class="topiccommentdate settopright">
                                            {{topiccmtrply.createdOnDateInDDMmYYYFormat}}

                                        </label>

                                        <div *ngIf="topiccmtrply.userReplyingToComment.userDetailsId != userId" class="icorply">
                                            <a href="javascript: void(0);" pTooltip="Report spam"
                                                (click)="reportSpam('reply', topiccmtrply.topicCommentReplyId)"
                                                class="spamBtn deco-none"><i class="fa fa-flag"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div class="clr"></div>
                            </div>
                            <div class="clr"></div>
                        </div>
                        <!-- </div> -->
                        <div id="topicChildCommentDiv" class="topiccomments rereplay" style="display:none"
                            [style.display]="editDisplay(topiccmt.newcomment)">
                            <div class="topiccomments boxs boxpad rereplay" id="topicChildCommentReplyDiv_3">
                                <div class="two columns inner-post-image view-topic-comments">
                                    <div class="featured-image">
                                        <a href="#">
                                            <!-- <img id="profilePicImage" class="greyBorder profileImage" src="{{user.profilePicName}}"> -->

                                            <img *ngIf="user.profilePicName != null && !user.profilePicName.includes('profilebig.png')"
                                                class="greyBorder profileImage" alt="" src="{{user.profilePicName}}"
                                                width="150px">

                                            <ngx-avatar
                                                *ngIf="user.profilePicName == null || user.profilePicName.includes('profilebig.png')"
                                                name="{{topiccmt.userDetailsSummaryTO.firstName}}"></ngx-avatar>
                                        </a>
                                    </div>
                                </div>
                                <div class=" ten columns view-topic-comment-box marginbottom">
                                    <div class="nine columns">
                                        <textarea id="child-comment-box_3"
                                            [(ngModel)]="innerCommentData.topicCommentReply"
                                            name="innerCommentData.topicCommentReply"></textarea>
                                    </div>
                                    <div class="clear"></div>
                                    <div class="three columns">
                                        <input type="button"
                                            (click)="addinnerComment(topiccmt.topicDetailId, topiccmt.topicCommentId)"
                                            class="btn-120 view-topics" name="comment-submit" value="Add Comments">
                                        <input id="cancel-viewtopic" (click)="topiccmt.newcomment = false;"
                                            class="btn-120" name="cancel-viewtopic" value="Cancel" type="button"> </div>
                                </div>
                                <div class="clr"></div>
                            </div>
                            <div class="clr"></div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    </div>

</div>

<p-confirmDialog id="registerCourseInitiate" width="425"></p-confirmDialog>