<div class="col-md-8 col_md_8 pageblock content_area">
  <menu-component></menu-component>
  <div class="row home_container">
    <div class="row">
      <div class="boxs">
        <ol class="breadcrumb" id="breadcrumb">
          <li class="home">
            <a href="javascript:void(0);" [routerLink]="['../home']">Home</a>
          </li>
          <li class="arrow">&nbsp;</li>
          <li class="active">View Profile</li>
        </ol>
        <div class="clr"></div>
      </div>
      <div class="row" id="printdiv">
        <div class="col-md-12 contant_div">
          <!-- Left side start -->
          <form name="frmProfile" id="frmProfile" method="post" enctype="multipart/form-data">
            <input type="hidden" id="hdUserId" name="hdUserId" value="13" />
            <div style="display: none;" id="addConnectionPleaseWaitDiv">
              <img src="assets/images/pleaseWait.gif" width="15px" height="15px" />
            </div>
            <span class="add-network-block" id="plsWaitImgCntnr_13"></span>
            <div class="row">
              <div class="boxs boxpad profileviewpage">
                <div class="linkedInImg">
                  <a *ngIf="viewProfile.linkedInProfile" target="_blank" [href]="addhttp(viewProfile.linkedInProfile)">
                      <img height="40" width="40" src="assets/images/linkedinLogo.png" />
                  </a>    
                </div>
                <div class="proleft">
                  <div class="col-md-2 profile col-sm-3">
                    <a href="javascript:void(0);">
                      <img
                        *ngIf="viewProfile.profilepicName != null && !viewProfile.profilepicName.includes('profilebig.png')"
                        width="150px" class="greyBorder profileImage" src="{{viewProfile.profilepicName}}" />
                      <!-- <img *ngIf="viewProfile.profilepicName == null" width="150" border="0" src="assets/images/profilebig.png" class="greyBorder profileImage"> -->
                      <ngx-avatar
                        *ngIf="viewProfile.profilepicName == null || viewProfile.profilepicName.includes('profilebig.png')"
                        name="{{viewProfile.firstName}} {{viewProfile.lastName}}" size="150">
                      </ngx-avatar>
                    </a>
                    <br />
                    <div class="printbtn">
                      <a href="javascript: void(0);" target="_blank" [queryParams]="{ user: viewProfile.userDetailsId }"
                        [routerLink]="['../../printprofile']"><img src="assets/images/print.png" /> Print Profile</a>
                    </div>
                    <div>
                      <span *ngIf="viewProfile.invitationAction != 'Invite'" class="add-network-block"
                        id="addToYourNetworkId_13">
                        <span class="innetlink" *ngIf="viewProfile.invitationAction == 'In My Network'">In Your
                          Network</span>
                        <a href="{{calendarMeetingUrl}}" *ngIf="viewProfile.invitationAction == 'In My Network'"
                          class="pageLink1 fa fa-calendar meetnetlink" target="_blank">
                          <p class="meetp">Schedule a meeting with me</p>
                        </a>
                        <div class="add-network-block" id="addToYourNetworkId_17"
                          *ngIf="viewProfile.invitationAction == 'Accept'">
                          <div class="profileact">
                            <span id="inNetworkStatusId_17" style="margin-right: 10px;"><a class="pageLink"
                                (click)="acceptInvite()" href="javascript: void(0);">Accept</a></span>
                            <a href="{{calendarMeetingUrl}}" *ngIf="viewProfile.invitationAction == 'Accept'"
                              class="pageLink1 fa fa-calendar meetnetlink" target="_blank">
                              <p class="meetp">Schedule a meeting with me</p>
                            </a>
                          </div>
                        </div>
                        <div class="add-network-block" id="addToYourNetworkId_22"
                          *ngIf="viewProfile.invitationAction == 'Waiting for Response'">
                          <div class="profileawa">
                            Awaiting Response
                            <a href="{{calendarMeetingUrl}}" class="pageLink1 fa fa-calendar meetnetlink"
                              target="_blank">
                              <p class="meetp">Schedule a meeting with me</p>
                            </a>
                          </div>
                        </div>
                        <div id="sendMessageInNetworkDiv_13" *ngIf="viewProfile.invitationAction == 'In My Network'"
                          class="getSendMessageIn">
                          <a href="javascript:void(0);" (click)="sendMsg = true;" class="pageLink">
                            Send Message</a>
                        </div>
                        <div class="clear"></div>
                      </span>
                      <span *ngIf="viewProfile.invitationAction == 'Invite'" class="add-network-block"
                        id="addToYourNetworkId_13">
                        <a href="javascript: void(0)" (click)="sendInvite()" class="pageLink1 addnetlink">Add to your
                          network</a>
                        <a href="{{calendarMeetingUrl}}" class="pageLink1 fa fa-calendar meetnetlink" target="_blank">
                          <p class="meetp">Schedule a meeting with me</p>
                        </a>
                      </span>
                      <div *ngIf="viewProfile.invitationAction == 'In My Network'" class="profilePageRecoNRateLinks">
                        <div class="profilerqs">
                          <span id="reqRecLinkDiv_13"
                            *ngIf="viewProfile.recommendationAction == 'SEND_RECOMMENDATION'"><a
                              class="request-recommendation" href="javascript: void(0);" (click)="reqforrcmd=true;"><img
                                border="0" width="12px" height="12px" src="assets/images/request.png"
                                style="margin-right: 2px;" />
                              Request for Recommendation</a></span>
                          <br />
                          <span *ngIf="viewProfile.ratingAction == 'RATING_ALREADY_SENT'"
                            id="rateProfessorLinkDiv_13"><a href="javascript: void(0);" class="pageLink1">You have
                              already rated this user</a></span>
                          <div *ngIf="viewProfile.ratingAction == 'SEND_RATING'">
                            <span id="rateProfessorLinkDiv_13">
                              <a href="javascript: void(0);" (click)="ratethis = true;" class="pageLink1">
                                <img border="0" width="12px" height="12px" src="assets/images/star.png"
                                  style="margin-right: 2px;" />
                                Rate this User
                              </a>
                            </span>
                            <div class="profilerqsbox">
                              <div id="rateProfessorDiv_13" class="common" style="display: none"
                                [style.display]="editDisplay(ratethis)">
                                <input type="radio" [(ngModel)]="rating.rating.ratingText" name="rating.ratingText"
                                  id="rateFair_13" value="FAIR" />
                                <label for="rateFair_13">Fair</label>&nbsp;
                                <input type="radio" [(ngModel)]="rating.rating.ratingText" name="rating.ratingText"
                                  id="rateGood_13" value="GOOD" />
                                <label for="rateGood_13">Good</label>&nbsp;
                                <input type="radio" [(ngModel)]="rating.rating.ratingText" name="rating.ratingText"
                                  id="rateBest_13" value="BEST" />
                                <label for="rateBest_13">Best</label>
                                <input type="button" name="btnRate" (click)="ratingUser()" id="btnRate" value="Rate"
                                  style="margin-top: 5px;" />
                                <input type="button" name="btnCancel" id="btnCancel" (click)="ratethis = false;"
                                  value="Cancel" style="margin-left: 5px; margin-top: 5px;" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="proright">
                  <input value="1" name="hdProfileId" id="hdProfileId" type="hidden" />
                  <input value="Student" name="hdCriteria" id="hdCriteria" type="hidden" />
                  <div class="profilename clearfix">
                    {{viewProfile.firstName}} {{viewProfile.middleName}}
                    {{viewProfile.lastName}}
                    <span *ngIf="viewProfile.noOfRatingStars"
                      style="font-size: 0px; color: #444444; vertical-align: sub;">
                      <img width="32" border="0" *ngFor="let rate of myRating" src="assets/images/star.png"
                        style="margin-right: 1px;" />
                    </span>
                  </div>
                  <div id="">
                    <div class="profileclg"
                      *ngIf="viewProfile.isUserSecondaryDataCapturedForUser == 'T' || anotheruser">
                      <label class=" profile-subname">
                        {{viewProfile.userType}}<span *ngIf="viewProfile.userProfileTypeAcademic == true">,
                          {{viewProfile.subject}}</span><span *ngIf="viewProfile.eduIns != null">,
                          {{viewProfile.eduIns}}, {{viewProfile.city}}</span>
                      </label>
                    </div>
                    <div class="profileclg" *ngIf="viewProfile.isUserSecondaryDataCapturedForUser == 'F' && !anotheruser">
                      <a href="javascript:void(0)" (click)="openStep2Popup = true">
                        <i>Add your title, Company/Institution, Location</i>
                      </a>
                    </div>
                    <div class="profiletools">
                      <label><a href="javascript: void(0);" [routerLink]="['../myconnections']" 
                        [queryParams]="{u: viewProfile.userDetailsId}">Connections:</a>
                      </label>
                      <a href="javascript: void(0);" [routerLink]="['../myconnections']"
                        [queryParams]="{ s: 'student', u: viewProfile.userDetailsId, i:'true', o:'true' }">
                        <span class="counter">
                          {{viewProfile.noOfStudentConnections}}</span>
                        Students,
                      </a>
                      <a href="javascript: void(0);" [routerLink]="['../myconnections']"
                        [queryParams]="{ s: 'professor', u: viewProfile.userDetailsId, i:'true', o:'true' }">
                        <span class="counter">
                          {{viewProfile.noOfProfessorConnections}}</span>
                        Professors,
                      </a>
                      <a href="javascript: void(0);" [routerLink]="['../myconnections']"
                        [queryParams]="{ s: 'alumni', u: viewProfile.userDetailsId, i:'true', o:'true' }">
                        <span class="counter">
                          {{viewProfile.noOfAlumniCOnnections}}</span>
                        Alumni
                      </a>
                    </div>
                    <div id="degreeDiv">
                      <label class="lblblue">Highest Education Level :</label>
                      <span id="degreeCourseDiv" class="contentarea">
                        {{viewProfile.degreeCourse}}
                      </span>
                    </div>
                  </div>
                  <div class="contentarea">
                    <div class="columns" *ngIf="viewProfile.pastEducations && viewProfile.pastEducations.length">
                      <label class="lblblue">Past Education:</label>
                      <div *ngFor="let pastEdu of viewProfile.pastEducations">
                        <div class="pstedu">
                          <strong>
                            {{pastEdu.degreeCourse}} from
                            {{pastEdu.educationInstitution}}
                          </strong>
                          , {{pastEdu.campus}}
                          <!--<i> {{pastEdu.educationStartDateForDisplay}} – {{pastEdu.educationEndDateForDisplay}} </i> </div>-->
                          <i *ngIf="pastEdu.isPresent == 'F'">
                            {{pastEdu.educationStartDateForDisplay}} –
                            {{pastEdu.educationEndDateForDisplay}}
                          </i>
                          <i *ngIf="pastEdu.isPresent == 'T'">
                            {{pastEdu.educationStartDateForDisplay}} – Present
                          </i>
                        </div>
                      </div>
                    </div>
                    <div class="columns" *ngIf="!viewProfile.pastEducations || !viewProfile.pastEducations.length">
                      <label class="lblblue">Past Education:</label>
                      No details entered
                    </div>
                  </div>
                </div>
                <div class="clr"></div>
              </div>
              <div class="post-block inner-block">
                <!-- About Me Block -->
                <div class="fifteen columns addrmcl-content2 boxs" id="aboutMeDiv">
                  <input id="aboutMeToken" type="hidden" value="" />
                  <div class="title fblue bglightgray">
                    <span class="drakbg"><i class="icon_about"></i>About <label>Me</label>:</span>
                  </div>
                  <div class="boxpad" *ngIf="!viewProfile.aboutMe">
                    No details entered
                  </div>
                  <div class="boxpad" *ngIf="viewProfile.aboutMe">
                    <pre style="font-family: 'Ubuntu', sans-serif; font-size:13px; color:#6d6d6d;">
{{viewProfile.aboutMe}}</pre>
                  </div>
                </div>
                <!-- About Me Block Ends -->
                <div class="clear"></div>
                <!-- Specialist Block -->
                <div class="fifteen columns addrmcl-content2 boxs " id="specialtiesDiv">
                  <input id="specialtiesToken" type="hidden" value="" />
                  <div class="title fblue bglightgray">
                    <span class="drakbg"><i class="icon_about"></i>Specialties/Skills :
                    </span>
                  </div>
                  <div id="view_taSpecialties1" class="common boxpad" style="width: 100%;"
                    *ngIf="!getSkills[0] && !usergetSkills[0]">
                    No details entered
                  </div>
                  <div id="view_taSpecialties2" class="common boxpad" style="width: 100%;" *ngIf="getSkills[0]">
                    
                    <span *ngFor="let getskill of getSkills; let i = index">{{(getskill.userSkillSetName != null && getskill.userSkillSetName !=undefined) ? getskill.userSkillSetName : getskill.skillSet.name}}<span
                        *ngIf="getSkills.length > 0 && i < getSkills.length - 1">,</span>&nbsp;</span>
                  </div>
                  <div id="view_taSpecialties3" class="common boxpad" style="width: 100%;" *ngIf="usergetSkills[0]">
                    <span *ngFor="let usergetSkill of usergetSkills"><a href="javascript:void(0);"
                        (click)="skillpopup = true; coursename = usergetSkill.skillName; userReqCourse.skillSet.skillSetId = usergetSkill.skillId;"
                        class="pageLink">{{usergetSkill.skillName}}</a>,&nbsp;</span>
                  </div>
                </div>
                <!-- Specialist Block Ends-->
                <div class="clear"></div>
                <div class="boxs">
                  <div class="fifteen columns addrmcl-content2">
                    <div class="title fblue bglightgray">
                      <span class="drakbg"><i class="icon_about"></i>
                        Project/Research/Work
                        <label>Experience</label>:
                      </span>
                    </div>

                    <div class="boxpad"
                      *ngIf="!viewProfile.projectResearchWorkExp || !viewProfile.projectResearchWorkExp.length">
                      No details entered
                    </div>
                    <!-- To Display No Details Entered when List is Empty -->
                    <div class="profile-work profile-hover boxpad"
                      *ngIf="viewProfile.projectResearchWorkExp && viewProfile.projectResearchWorkExp.length">
                      <div *ngFor="let workexp of viewProfile.projectResearchWorkExp">
                        <label class="lblblue">
                          {{workexp.projectResearchTitle}}
                        </label>
                        <br />
                        <label>
                          <span
                            *ngIf="workexp.educationInstitution != null">{{workexp.educationInstitution}}</span><span
                            *ngIf="workexp.campus != null && workexp.campus.length > 0">, {{workexp.campus}}</span>
                        </label>
                        <br />
                        <label class="lblblue2">
                          <i *ngIf="workexp.isPresent == 'F'">{{workexp.projResearchStartDateForDisplay}} –
                            {{workexp.projResearchEndDateForDisplay}}</i>
                          <i *ngIf="workexp.isPresent == 'T'">{{workexp.projResearchStartDateForDisplay}} –
                            Present</i>
                        </label>
                        <br />
                        <p>
                          <span class="exp-desc-txt-span" id="exp-desc-txt-span_1"
                            [innerHTML]="workexp.projectResearchDescription | safeHtml">
                            <!-- <pre style="font-family: 'Ubuntu', sans-serif; font-size:13px; color:#6d6d6d;">
{{workexp.projectResearchDescription}}</pre> -->
                          </span>
                        </p>
                        <br />
                      </div>
                    </div>
                    <div class="clr"></div>
                  </div>
                </div>
                <!--<div class="fifteen columns addrmcl-content2 boxs" id="booksIRecommendDiv">
                                    <input id="booksIRecommendToken" type="hidden" value="">
                                    <div class="title fblue bglightgray"><span class="drakbg"><i class="icon_about"></i>Books I <label>recommend</label>:</span> </div>
                                    <div class="boxpad" *ngIf="!viewProfile.userRecommendedBooks">No Courses recommended so far.</div>
                                    <div class="boxpad" *ngIf="viewProfile.userRecommendedBooks">{{viewProfile.booksIRecommend}}</div>
                                </div>
                                <div class="clear"></div>-->
                <div class="fifteen columns addrmcl-content2 boxs" id="coursesIRecommendDiv">
                  <input id="coursesIRecommendToken" type="hidden" value="" />
                  <div id="coursesIRecommendInnerDiv">
                    <div class="title fblue bglightgray">
                      <span class="drakbg">
                        <i class="icon_about"></i> Sessions I
                        <label>recommend</label>:</span>
                    </div>
                    <!-- For Displaying all the courses that are recommended by this user. -->
                    <div *ngIf="allcir != null">
                      <div class="ten columns topcoures" *ngFor="let courseir of allcir">
                        <div class="proleft printhide">
                          <!--<span *ngIf="courseir.course">-->
                          <img *ngIf="courseir.courseImageURL == null" class="greyBorder profileImage" alt=""
                            src="assets/images/default_course.png" height="60" width="60" />
                          <img *ngIf="courseir.courseImageURL != null" class="greyBorder profileImage" alt=""
                            src="{{courseir.courseImageURL}}" height="60" width="60" />
                          <!--</span>-->

                          <!--<span *ngIf="courseir.courseimageURL">
                                                            <img *ngIf="courseir.courseimageURL == null" class="greyBorder profileImage" alt="" src="assets/images/default_course.png" height="60" width="60">
                                                            <img *ngIf="courseir.courseimageURL != null" class="greyBorder profileImage" alt="" src="{{courseir.courseimageURL}}" height="60" width="60">
                                                        </span>-->
                        </div>

                        <div class="proright">
                          <div class="coursetitle coursetitle1">
                            <a style="font-weight: bold;" href="javascript:void(0)" [routerLink]="['../viewsession']"
                              [queryParams]="{ sessionId: courseir.courseId }">{{courseir.courseName}}</a>
                          </div>
                          <div class="label-bold">
                            <span class="gwt-Label profile-userBy userBy">by:</span>
                            <a href="javascript: void(0);" [routerLink]="['../profile']"
                              [queryParams]="{ user: courseir.courseCreator.userDetailsId }"
                              class="fblue deco-none"><span class="gwt-Anchor profile-name2 fblue deco-none topicowner">
                                {{courseir.courseCreator.firstName}}
                                {{courseir.courseCreator.lastName}}</span></a>,<span
                              *ngIf="courseir.courseCreator.isUserSecondaryDataCapturedForUser == 'T'"
                              class="gwt-Label profile-userType userype">{{courseir.courseCreator.userType}},&nbsp;{{courseir.courseCreator.educationalInstitution}},&nbsp;{{courseir.courseCreator.campus}}</span>
                          </div>
                        </div>

                        <div class="clr"></div>
                      </div>
                    </div>
                    <div class="boxpad" *ngIf="allcir == null">
                      No details entered
                    </div>
                    <div class="clr"></div>
                  </div>
                </div>
                <div class="clear"></div>
                <div id="myInterestsDiv" class="fifteen columns addrmcl-content2 boxs">
                  <input id="myInterestsToken" type="hidden" value="" />
                  <div class="title fblue bglightgray">
                    <span class="drakbg"><i class="icon_about"></i>My
                      <label>Interests</label>:</span>
                  </div>
                  <div class="boxpad" *ngIf="!viewProfile.myInterests">
                    No details entered
                  </div>
                  <div class="boxpad" *ngIf="viewProfile.myInterests">
                    <pre style="font-family: 'Ubuntu', sans-serif; font-size:13px; color:#6d6d6d;">
{{viewProfile.myInterests}}</pre>
                  </div>
                </div>
                <div id="recommendVisiDiv" class="fifteen columns addrmcl-content2 boxs">
                  <div class="uppercase sky_color title fblue bglightgray">
                    <span class="drakbg"><i class="icon_about"></i> Recommendations</span>
                  </div>
                  <div *ngIf="viewProfile.recommendationsReceived && viewProfile.recommendationsReceived.length">
                    <div class="userrcmd" *ngFor="let usrrcmd of viewProfile.recommendationsReceived">
                      <div class="clr"></div>
                      <div class="profile-recommend profile-hover boxpad">
                        <!--<div class="proleft"> <img border="0" [routerLink]="['../profile']" [queryParams]="{ user: usrrcmd.userDetailsId }" class="greyBorder profileImage" height="60" id="profilePicImage_4" width="60" src="{{usrrcmd.profilePicName}}"> </div>-->
                        <label><a class="pageLink lblblue" [routerLink]="['../profile']"
                            [queryParams]="{ user: usrrcmd.recommendationFromUserDetailsId }"
                            href="javascript: void(0);">{{usrrcmd.recommendationFromFirstName}}
                            {{usrrcmd.recommendationFromLastName}}</a>
                          <span> </span>
                        </label>
                        <br *ngIf="usrrcmd.isUserSecondaryDataCapturedForUser == 'T'">
                        <label *ngIf="usrrcmd.isUserSecondaryDataCapturedForUser == 'T'">
                          {{usrrcmd.recommendationFromUserType}},
                          {{usrrcmd.recommendationFromEducationalInstitution}}
                        </label>
                        <br *ngIf="usrrcmd.isUserSecondaryDataCapturedForUser == 'T'">
                        <label *ngIf="usrrcmd.isUserSecondaryDataCapturedForUser == 'T'">
                          {{usrrcmd.recommendationFromCampus}}, </label>
                        <br />
                        <div class="userAcceptedRecommendations">
                          <label class="write">
                            <p>
                              <i>{{usrrcmd.recommendation}}</i>
                            </p>
                          </label>
                        </div>
                      </div>
                      <div class="clr"></div>
                    </div>
                  </div>
                  <div class="boxpad"
                    *ngIf="!viewProfile.recommendationsReceived || !viewProfile.recommendationsReceived.length">
                    No Recommendations Received
                  </div>
                </div>
                <div class="clear"></div>
                <div class="dim" id="dimReco" name="dimReco" style="display:none"
                  [style.display]="editDisplay(reqforrcmd)">
                  <div id="invite-pop-up-reco" name="invite-pop-up-reco"
                    class="ten columns invite-pop-up marginbottom boxpad" style="display: none;"
                    [style.display]="editDisplay(reqforrcmd)">
                    <div class="popuptitle" id="requestForRecommend">
                      <h2>Request for Recommendation</h2>
                    </div>
                    <div class="popupclose" (click)="reqforrcmd=false">
                      <img alt="Close" src="assets/images/close-button.png" id="closeReco" />
                    </div>
                    <div class="eight columns" id="subjectDivReco">
                      <input type="text" value="" name="recommendationSubject" id="recommendationSubject"
                        placeholder="Subject" />
                    </div>
                    <div class="eight columns" id="CommentsDivReco">
                      <textarea id="recommendationComments" [(ngModel)]="reqRcmd.userRequestText"
                        name="reqRcmd.userRequestText" placeholder="Request"></textarea>
                    </div>
                    <div class="three columns" id="buttonDivReco">
                      <input type="button" (click)="rcmdReq()" value="Send" name="btnSend" id="btnSendReco"
                        class="pageLink1" />
                      <input type="hidden" id="hdUserIdReco" value="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="clear"></div>
            </div>
          </form>
        </div>
        <!-- START User Conducted Courses -->
        <form name="frmAuthorProfile" id="frmAuthorProfile" method="post">
          <div class="conductedCourseDiv boxs printhide" id="conductedCourseDiv">
            <div>
              <!--<div *ngIf="!coursebyMe.courses[0]">-->
              <div class="title fblue bglightgray">
                <span class="drakbg">
                  <i class="icon_about"></i> Sessions by me :</span>
              </div>
              <section class="section3 clearfix">
                <div class="">
                  <div *ngIf="$any(coursebyMe).courses && $any(coursebyMe).courses.length" style="padding-left: 25px;">
                    <div class="courses animated bounceIn"
                      style="cursor: pointer; float: none; display: inline-block; margin: 1% 1%; width: 30%;"
                      *ngFor="let coursebyme of coursebyMe.courses">
                      <a href="javascript:void(0);" [routerLink]="['../viewsession']"
                        [queryParams]="{ sessionId: coursebyme.courseId }">
                        <h1>
                          <img alt="" *ngIf="coursebyme.imageURL == null" src="assets/images/default_course.png"
                            height="142" width="266" />
                          <img alt="" *ngIf="coursebyme.imageURL != null" src="{{coursebyme.imageURL}}" height="142"
                            width="266" />
                        </h1>
                        <h3 title="Science of Face Transplant">
                          <a href="#">{{coursebyme.courseFullName}}</a>
                        </h3>
                        <div class="courseby">
                          <img
                            *ngIf="coursebyme.creator.profilePicName != null && !coursebyme.creator.profilePicName.includes('profilebig.png')"
                            width="40" height="40" border="0" src="{{coursebyme.creator.profilePicName}}"
                            class="greyBorder profileImage" />
                          <ngx-avatar
                            *ngIf="coursebyme.creator.profilePicName == null || coursebyme.creator.profilePicName.includes('profilebig.png')"
                            name="{{coursebyme.creator.firstName}} {{coursebyme.creator.lastName}}" size="40">
                          </ngx-avatar>
                        </div>
                        <a *ngIf="coursebyme.creator.isUserSecondaryDataCapturedForUser == 'T'" class="more"
                          href="javascript: void(0);">by {{coursebyme.creator.firstName}}
                          {{coursebyme.creator.lastName}},&nbsp;
                          <span>{{coursebyme.creator.userType}},&nbsp;{{coursebyme.creator.educationalInstitute}}</span>
                        </a>
                        <a *ngIf="coursebyme.creator.isUserSecondaryDataCapturedForUser == 'F'" class="more"
                          href="javascript: void(0);">by {{coursebyme.creator.firstName}}
                          {{coursebyme.creator.lastName}},&nbsp;
                        </a>
                        <label>Starts on {{coursebyme.displayStartDate}}</label>
                        <div
                          style="display: inline-block; margin-bottom: 10px; margin-right: 8px; margin-left: 38px;font-size: 13px;">
                          {{coursebyme.reviewCount}} Reviews
                        </div>
                        <!--<rating [readonly]="true" [(ngModel)]="coursebyme.avgReviewIntValue" name="coursebyme.avgReviewIntValue"></rating>-->
                        <ac-stars style="font-size: 11px;" class="average-reviev-counts" [starCount]="5"
                          [rating]="coursebyme.avgReviewIntValue" [readonly]="true">
                        </ac-stars>
                        <div style="display: inline-block; margin-right: 0px;font-size: 13px;">
                          {{coursebyme.avgReviewIntValue}}/5
                        </div>
                        <div class="clr"></div>
                        <div class="courseatte"
                          style="float: none;  display: inline-block; margin-left: 32px; margin-top: 0px;">
                          <span class="counter">{{coursebyme.numberOfAttendees}} </span>&nbsp;Attending
                        </div>
                        <label style="text-align: center; margin: 0 -19px 0 0; position: absolute; right: 10px; bottom: 24px; width: auto;
										            			padding: 0px 6px; background: url(assets/images/new_label.png) no-repeat;height: 20px; width: 56px;
										            			line-height: 20px;">
                          New
                        </label>
                        <div class="coursecost" *ngIf="coursebyme.cost">
                          ${{coursebyme.cost}}
                        </div>
                        <div class="coursecost" *ngIf="!coursebyme.cost">
                          Free
                        </div>
                        <div class="clr"></div>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              <!--</div>-->
              <div class="boxpad" *ngIf="$any(coursebyMe).courses == null || $any(coursebyMe).courses.length == 0">
                You haven't created any sessions so far.
              </div>
            </div>
          </div>
        </form>
        <!-- END User Conducted Courses -->
        <!-- START User Requested Courses Pop Up -->
        <form name="frmCourseRequest" id="frmCourseRequest" method="post">
          <div class="dim" style="display: none;" [style.display]="editDisplay(skillpopup)">
            <div class="ten columns invite-pop-up marginbottom boxpad" style="display: block;"
              [style.display]="editDisplay(skillpopup)">
              <div class="popuptitle" id="requestForCourse">
                <h2>Send Session Request</h2>
              </div>
              <div class="popupclose" (click)="skillpopup = false;">
                <img alt="Close" src="assets/images/close-button.png" id="closedPopUp" />
              </div>
              <input type="text" id="subject" value="Request {{viewProfile.firstName}} for course on {{coursename}}"
                name="subject" readonly="readonly" value="" />
              <a href="javascript:void(0);" class="pageLink1 btnstyle" (click)="sendCourseReq()">
                Send
              </a>
              <a href="javascript:void(0);" class="pageLink1 btnstyle" (click)="skillpopup = false;">
                Cancel
              </a>
            </div>
          </div>
        </form>
        <!-- START Response Pop Up -->
        <form name="frmCourseResponse" id="frmCourseResponse" method="post">
          <div class="dims" id="dims" style="display: none;">
            <div class="ten columns invite-pop-ups marginbottom boxpad" id="invite-pop-ups"
              style="display: block; width: 40%">
              <div class="popuptitle" id="requestForCourse">
                <h2>Session Request</h2>
              </div>
              <div class="popupclose">
                <img alt="Close" src="assets/images/close-button.png" id="closedPopUps" />
              </div>
              <div id="courseRequestResponse"></div>
              <a href="javascript:void(0);" class="pageLink1 btnstyle"> Ok </a>
            </div>
          </div>
        </form>
        <!-- END Response Pop Up -->
        <!-- END User Requested Courses Pop Up -->
        <!-- Left side end -->
        <div style="display: none;" [style.display]="editDisplay(sendMsg)" class="ui-widget-overlay ui-front"></div>
        <div style="display: none;" [style.display]="editDisplay(sendMsg)"
          class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
          tabindex="-1" role="dialog" aria-describedby="sendInNetworkInvite" aria-labelledby="ui-id-1"
          style="position: fixed; height: auto; width: 550px; top: 10% !important; left: 37%; display: block;">
          <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix">
            <span id="ui-id-1" class="ui-dialog-title">Send Message</span>
            <button (click)="sendMsg = false;" class=" ui-button ui-widget ui-state-default ui-corner-all
          ui-button-icon-only ui-dialog-titlebar-close" role="button" aria-disabled="false" title="close" style="line-height: 0;
          font-weight: 500;
          top: 15px;
          right: 10px; padding: 8px !important;">x</button>
          </div>
          <div class="ui-dialog-content ui-widget-content"
            style="width: auto; min-height: 33px; max-height: none; height: auto;" id="sendInNetworkInvite">
            <div class="seven columns last">
              To: &nbsp;
              <label id="userNameDisplay" style="color: #108dd5;  text-decoration: none;">{{viewProfile.firstName}}
                {{viewProfile.middleName}} {{(viewProfile.lastName != undefined) ? viewProfile.lastName.trim() : "" + (viewProfile.isUserSecondaryDataCapturedForUser == 'T' ? "," : "")}}{{viewProfile.isUserSecondaryDataCapturedForUser == 'T' ? " "+viewProfile.userType + (viewProfile.userProfileTypeAcademic == true && viewProfile.subject != null ? "," : "") : (viewProfile.userProfileTypeAcademic == true && viewProfile.subject != null ? "," : "")}}{{viewProfile.userProfileTypeAcademic == true && viewProfile.subject != null ? " "+viewProfile.subject + (viewProfile.eduIns != null ? "," : "") : (viewProfile.eduIns != null ? "," : "")}}{{viewProfile.eduIns != null ? " "+viewProfile.eduIns + (viewProfile.campus != null ? "," : "") : (viewProfile.campus != null ? "," : "")}}{{viewProfile.campus != null ? " "+viewProfile.campus : ""}}
              </label>
              <!-- <label *ngIf="viewProfile.isUserSecondaryDataCapturedForUser == 'T'" id=" userTypeInfo"
                class="gwt-Label profile-userType userype">{{viewProfile.userType}}
                <div *ngIf="viewProfile.userProfileTypeAcademic == true && viewProfile.subject != null">
                  , {{viewProfile.subject}}
                </div>
              </label>
              <label id="universityInfo" *ngIf="viewProfile.eduIns != null" class="gwt-Label profile-userType userype">{{viewProfile.eduIns}},
                {{viewProfile.campus}}</label> -->
            </div>
            <div class="clear"></div>
            <input type="hidden" id="userIdSend" value="22" />
            <div class="two columns alpha">
              <label>Subject:</label>
              <input type="text" id="subjectForNetwork" [(ngModel)]="msgData.messageSubject"
                name="msgData.messageSubject" />
            </div>
            <div class="clear"></div>
            <div class="seven columns last">
              <label>Message:</label>
              <textarea [(ngModel)]="msgData.messageBody" name="msgData.messageBody" id="messageForNetwork"></textarea>
            </div>
          </div>
          <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
            <div class="ui-dialog-buttonset">
              <button type="button" id="sendMessage" (click)="sendMessage()"
                class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" role="button"
                aria-disabled="false">
                <span class="ui-button-text">Send</span>
              </button>
              <button type="button" id="cancelSendMessage" (click)="sendMsg = false;"
                class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" role="button"
                aria-disabled="false">
                <span (click)="sendMsg = false;" class="ui-button-text">Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <signuptwo-modal-popup [countryId]="countryId" [city]="city" [isSignUpTwoModalDialogToBeDisplayed]="openStep2Popup"
    (signupTwoModalPopupClosed)="updateUser($event)"></signuptwo-modal-popup>

</div>
<div class="overLay" *ngIf="openStep2Popup"></div>