<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>

<div class="sixteen columns pageblock content_area">
    <div class="col-md-8 col_md_8" style="position:relative">
        <menu-component></menu-component>
        <div class="row home_container">
            <div class="row">
                <div class="boxs">
                    <ol class="breadcrumb" id="breadcrumb">
                        <li class="home"><a href="javascript:void(0);" [routerLink]="['../home']"> Home
						</a>
                        </li>
                        <li class="arrow">&nbsp;</li>
                        <li class="active">Create Topic</li>
                    </ol>
                    <div class="clr"></div>
                </div>
            </div>
        </div>

        <div class="signupstep1form createcourseform">

            <form name="form"  enctype="multipart/form-data" method="post" (ngSubmit)="f.form.valid && createTopicClick()" #f="ngForm" novalidate>
                <div class="boxs">
                    <div class="title fblue bglightgray">
                        <span class="drakbg"><i class="icon_about"></i>Logo:</span>
                    </div>
                    <div class="boxpad">
                        <div class="leftsection">
                            <input type="file" name="topicPic" (change)="topicImageUpload($event)" id="logoImage">
                            <!--<br>
                            <br>-->
                            <!--<div>
                                <input type="checkbox" name="topicLogoAgree" id="topicLogoAgree" value="topiclogoagree"> I acknowledge and agree that the logo/image I am uploading does not infringe upon any third party copyrights, trademarks, or other proprietary rights or otherwise violate the User Agreement.
                            </div>-->
                        </div>
                        <div class="clr"></div>
                    </div>
                </div>
                <div class="boxs">
                    <div class="title fblue bglightgray">
                        <span class="drakbg"><i class="icon_about"></i>* Topic Name:</span>
                    </div>
                    <div class="boxpad">
                        <div class="leftsection">
                            <input type="text" [(ngModel)]="createTopic.topicName" name="createTopic.topicName"
                            #topicName="ngModel" id="topicName" required placeholder="Enter Topic Name Here" style="margin-bottom:0px;">
                            <div *ngIf="f.submitted && !topicName.valid" class="errorDiv" style="">Topic Name is required</div>
                        </div>
                        <div class="charcount"><span id="charCount_topicName">200</span>chars left</div>
                        <div class="clr"></div>
                    </div>
                </div>
                <div class="boxs">
                    <div class="title fblue bglightgray">
                        <span class="drakbg"><i class="icon_about"></i>* Topic Type:</span>
                    </div>
                    <div class="boxpad">
                        <div class="leftsection">
                            <select [(ngModel)]="createTopic.inputTopicType" #inputTopicType="ngModel" required name="createTopic.inputTopicType"
                              id="topicType" style="margin-bottom:0px;">
                                <option value="">Select Topic Type</option>

                                <option value="{{topictype.inputTopicType}}" *ngFor="let topictype of topicTypes">{{topictype.topicType}}</option>
                                <!-- <option value="Alumni Topic">Alumni Topic</option>
								<option value="Corporate Topic">Corporate Topic</option> -->
                            </select>
                            <div *ngIf="f.submitted && !inputTopicType.valid" class="errorDiv" style="">Topic Type is required</div>
                        </div>
                        <div class="clr"></div>
                    </div>
                </div>
                <div class="boxs">
                    <div class="title fblue bglightgray">
                        <span class="drakbg"><i class="icon_about"></i>* Summary:</span>
                    </div>
                    <div class="boxpad">
                        <div class="leftsection">
                            <label>Enter a brief description about your topic and its purpose. Your summary about this topic will appear in the topics Directory.</label>
                            <textarea [(ngModel)]="createTopic.topicSummary"  #topicSummary="ngModel" required name="createTopic.topicSummary"
                              id="topicSummary" placeholder="Enter Topic Summary Here" style="margin-bottom:0px;"></textarea>
                            <div *ngIf="f.submitted && !topicSummary.valid" class="errorDiv" style="">Topic Summary is required</div>
                        </div>
                        <div class="clr"></div>
                        <div class="charcount"><span id="charCount_topicSummary">500</span>chars left</div>
                        <div class="clr"></div>
                    </div>
                </div>
                <div class="boxs">
                    <div class="title fblue bglightgray">
                        <span class="drakbg"><i class="icon_about"></i>* Description:</span>
                    </div>
                    <div class="boxpad">
                        <div class="leftsection">
                            <label>Your full description of this topic will appear on your topic pages.</label>

                            <p-editor [(ngModel)]="createTopic.topicDescription" #topicDescription="ngModel"
                              required name="createTopic.topicDescription" [style]="{'height':'320px'}">

                            </p-editor>
                            <!--<textarea [(ngModel)]="createTopic.topicDescription" name="createTopic.topicDescription"></textarea>-->
                            <div *ngIf="f.submitted && !topicDescription.valid" class="errorDiv" style="">Topic Description is required</div>
                        </div>
                        <div class="clr"></div>
                        <div class="clr"></div>
                        <div class="charcount"><span id="charCount_topicDescription">1000</span>chars left</div>

                    </div>
                </div>

                <div class="boxs">
                    <div class="title fblue bglightgray">
                        <span class="drakbg"><i class="icon_about"></i>Website:</span>
                    </div>
                    <div class="boxpad">
                        <div class="leftsection">
                            <input type="text" [(ngModel)]="createTopic.topicWebsite" name="createTopic.topicWebsite" id="topicWebsite" placeholder="Enter Website / URL for the Other Information">
                        </div>
                        <div class="charcount"><span id="charCount_topicWebsite">100</span>chars left</div>
                        <div class="clr"></div>
                    </div>
                </div>
                <div class="boxs">
                    <div class="title fblue bglightgray">
                        <span class="drakbg"><i class="icon_about"></i>* Permission Type:</span>
                    </div>
                    <div class="boxpad">
                        <div class="leftsection">
                        	<span *ngFor="let permissions of topicPermissions">
                            <input type="radio" checked="checked" [(ngModel)]="createTopic.inputTopicPermission"  #inputTopicPermission="ngModel"
                              required name="createTopic.inputTopicPermission" value="{{permissions.inputTopicPermission}}" style="margin-bottom:0px;">{{permissions.topicPermissionType}}
                            <br>
                            </span>
                        </div>
                        <div class="clr"></div>
                    </div>
                    <!--<div *ngIf="f.submitted && !inputTopicPermission.valid" class="errorDiv" style="">Permission Type is required</div>-->
                </div>

                <div class="boxs">
                    <div class="title fblue bglightgray">
                        <span class="drakbg"><i class="icon_about"></i>* Agreement:</span>
                    </div>
                    <div class="boxpad">
                        <div class="leftsection">
                            <input type="checkbox" [(ngModel)]="createTopic.topicAgreement"  #topicAgreement="ngModel" required name="createTopic.topicAgreement" id="topicAgreement" value="true" class="topiccheckbox">I agree with <a href="termsandconditions.html"  target="_blank" >Terms & Conditions</a>
                            <div *ngIf="f.submitted && !topicAgreement.valid" class="errorDiv" style="">Please confirm Terms of Service</div>
                        </div>
                        <div class="clr"></div>
                    </div>
                </div>
                <div class="boxs">
                    <div class="boxpad">
                        <!-- <input type="submit" value="Create Topic"> &nbsp; -->
                        <input type="submit" class="btn-120" value="Create Topic">
                        <input type="reset" class="btn-120" value="Cancel">
                    </div>
                </div>
            </form>

        </div>
    </div>
</div>
