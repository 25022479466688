<div class="sixteen columns pageblock content_area">
    <div class="col-md-8 col_md_8">
        <menu-component></menu-component>
        <div class="row home_container">
            <div class="row">
                <div class="boxs">
                    <ol class="breadcrumb" id="breadcrumb">
                        <li class="home"><a href="javascript:void(0);" [routerLink]="['../home']"> Home
                        </a>
                        </li>
                        <li class="arrow">&nbsp;</li>
                        <li class="active">My Topics</li>
                    </ol>
                    <div class="clr"></div>
                </div>
            </div>
        </div>
        <div class="">
            <div class="title fblue bglightgray"><span class="drakbg"><i class="icon_about"></i>Topics created <label> by You</label></span>
            </div>
            <div id="searchResultsResponseDiv" *ngIf="mytopics != null && mytopics.length > 0">
                <div class="boxs boxpad" *ngFor="let topic of mytopics">
                    <div class="topiclist nobor">
                    <div class="msgconimg"><img id="profilePicImage_15" height="100" width="100" class="greyBorder profileImage" src="{{topic.topicImageUrl}}"></div>
                    <div class="msgcondesc">
                    <div class="topicname topic-name">
                        <a href="javascript:void(0);" [routerLink]="['../viewtopic']" [queryParams]="{ topic: topic.topicDetailId }"> {{topic.topicName}}</a>
                    </div>
                    <div class="topicdesc">{{topic.topicSummary}}</div>
                    </div>
                        <div class="clr"></div>
                    </div>
                </div>
                <div id="loadMoreResults1" (click)="lodeMoremyTopic()" class="load-more-hide">{{loadmoreText1}}</div>
            </div>
            <div class="common errorDiv boxs boxpad" *ngIf="mytopics == null || mytopics.length == 0" style="width: 99%; margin-top: 10px;"> No records found </div>
        </div>
        <div class="">
            <div class="title fblue bglightgray"><span class="drakbg"><i class="icon_about"></i>Topics Joined <label> by You</label></span>
            </div>
            <div id="searchResultsResponseDiv" *ngIf="myjointopics != null && myjointopics.length > 0">
                <div class="boxs boxpad" *ngFor="let jointopic of myjointopics">
                    <div class="topiclist nobor">
                        <div class="msgconimg">
                            <img id="profilePicImage_4" class="greyBorder profileImage" src="{{jointopic.topicImageUrl}}">
                        </div>
                        <div class="msgcondesc">
                            <div class="topicname topic-name">
                                <a href="javascript:void(0);" [routerLink]="['../viewtopic']" [queryParams]="{ topic: jointopic.topicDetailId }">{{jointopic.topicName}}</a>
                            </div>
                            <div class="topicdesc">{{jointopic.topicSummary}}</div>
                        </div>
                        <div class="clr"></div>
                    </div>
                </div>
                <div id="loadMoreResults" (click)="lodeMorejoinTopic()" class="load-more-hide">{{loadmoreText}}</div>
            </div>
            <div class="common errorDiv boxs boxpad" *ngIf="myjointopics == null || myjointopics.length == 0" style="width: 99%; margin-top: 10px;"> No records found </div>
        </div>
    </div>
</div>
<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
