<div class="googleContactCard boxs boxpad">
    <div class="inner-post-image conprofilepic">
        <img *ngIf="contact.profileImageURL != ''" width="100" height="100" border="0" id="profile" src="{{contact.profileImageURL}}">
        <!-- <img *ngIf="contact.profileImageURL == ''" width="100" height="100" border="0" id="profile" src="assets/images/user_default.png"> -->
          <ngx-avatar *ngIf="contact.profileImageURL == null || contact.profileImageURL.includes('profilebig.png')" name="{{contact.displayName}}"></ngx-avatar>
    </div>
    <div class="inner-post-desc">
        <div class="profile-name"> {{contact.displayName}} </div>
        <div class="profilecou gwt-Label profile-userType userype"> {{contact.emailId}} </div>
        <span class="add-network-block" id="plsWaitImgCntnr_0"></span>
        <div class="add-network-block" id="addToYourNetworkId_0" [style.display]="contact.isEmailIdPresent==true?'block':'none'">
            <input type="checkbox" [(ngModel)]="contact.isEmailNeededToBeSent" name="contact.isEmailNeededToBeSent" id="check_0" value="true">
            <label>Send Email Invite to this Contact.</label>
        </div>
    </div>
</div>
