<div class="resetPasswordWrapper">
    <div class="modalHeader">
        <h3>{{title}}</h3>
    </div>
    <div class="closeModal" (click)="closePopup()">
        <span>x</span>
    </div>
    <hr>
    <div class="resetPasswordFormWrap" *ngIf="showForm">
        <form (ngSubmit)="f.form.valid && resetPassword()" #f="ngForm" novalidate name="resetForm" class="resetPasswordForm">
            <div class="inputWrap">
            <input style="margin-bottom: 0;" tabindex="1" class="textbox" required type="password" [(ngModel)]="model.newPassword" #password="ngModel" name="model.newPassword" placeholder="Password" />
            <div *ngIf="(f.submitted && !password.valid) || (!password.valid && password.touched)"
                class="errorDiv">Password is required</div>
            </div>
            <ng2-password-strength-bar [passwordToCheck]="model.newPassword"
                    [barLabel]="barLabel">
            </ng2-password-strength-bar>
            <div class="inputWrap">
            <input tabindex="2" class="textbox" required type="password" [(ngModel)]="model.confirmNewPassword" #confirmPassword="ngModel" name="model.confirmNewPassword" placeholder="Confirm Password" />
            <div *ngIf="(f.submitted && password.value != confirmPassword.value) || (password.value != confirmPassword.value && confirmPassword.touched)"
                class="errorDiv margintopbot">Password do not match.</div>
            <div *ngIf="(f.submitted && password.value == confirmPassword.value) || (password.value == confirmPassword.value && confirmPassword.touched)"
                class="errorDiv successDiv">Password matched.</div>
            <div *ngIf="(f.submitted && !confirmPassword.valid) || (!confirmPassword.valid && confirmPassword.touched)"
                class="errorDiv margintopbot">Please re-type the password here</div>
            </div>
            <input tabindex="3" type="submit" value="Reset Password" class="btn loginReset" />
        </form>
    </div>
    <div class="showMsg" *ngIf="!showForm">
        <span>
            {{Msg}}
        </span>
        <hr>
        <button class="btn loginReset" (click)="clickLogin()">Login</button>
    </div>
</div>
<div class="resetOverLay"></div>
<uvsity-modal-popup [isModalDialogToBeDisplayed]="showErrorModalDialog" [messageTitle]="showErrorMessageTitle"
    [messages]="showErrorMessages" (uvsityModalPopupClosed)="closeErrorPopup()"
    [popupWidthPercentage]="showErrorPopupWidthInPercentage" [isSignUp] = "isSignUp" [isValidationError]="isValidationError">
</uvsity-modal-popup>
<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
