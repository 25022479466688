<html>

<head>
    <style>
        .printhide {
            display: none;
        }

        a {
            text-decoration: none;
            color: #0f8dbf;
        }

        .boxs {
            border-bottom: #ddd 1px solid;
            margin-bottom: 10px;
        }

        .printbtn {
            display: none;
        }

        .clr {
            clear: both;
        }

        .proleft {
            width: 160px;
            float: left;
        }

        .profilepic {
            text-align: center;
            padding: 22px 0px;
        }

        .proright {
            float: left;
            width: 410px;
        }

        .profiledesc {
            padding-left: 30px;
        }

        .profilepart1 .profiledesc {
            padding-left: 0px;
        }

        .profilename {
            color: #0f8dbf;
            font-size: 30px;
            padding: 0px 0px 10px 0px;
        }

        .profilename a {
            color: #0f8dbf;
            text-decoration: none;
        }

        .profileclg {
            color: #444444;
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 0px;
        }

        .profiletools label a,
        .lblblue {
            color: #0f8dbf;
            text-decoration: none;
        }

        .profiletools a {
            color: #444444;
            text-decoration: none;
        }

        .profiletools a .counter {
            background: #f0f0f0;
            padding: 2px 7px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            margin: 0px 5px;
        }

        .profilepart2 {
            background-image: url(../../images/profilesap.jpg);
            background-repeat: repeat-y;
            background-position: 160px 0px;
        }

        .profilepart2complete {
            color: #0f8dbf;
            font-size: 14px;
            font-weight: bold;
            padding: 10px;
        }

        .profilestatus {
            padding: 15px 22px;
        }

        .profilepart2 .profiledesc {
            padding-top: 15px;
            padding-bottom: 15px;
            padding-right: 15px;
        }

        .profiledetails {
            color: #626262;
            font-size: 13px;
            line-height: 20px;
        }

        .profiledetails div {
            margin-bottom: 20px;
        }

        .profiledetails a {
            color: #0f8dbf;
            text-decoration: none;
        }

        .centertxt {
            position: relative;
            background: url(../../images/profilestutes.jpg) no-repeat;
            width: 120px;
        }

        .percent {
            position: absolute;
            top: 45px;
            left: 40px;
            color: #0f8dbf;
            font-size: 20px;
        }

        .percent:after {
            content: "%"
        }

        .pstedu {
            font-size: 12px;
            line-height: 18px;
            color: #666;
        }

        .title {
            font-size: 18px;
            padding-bottom: 30px;
        }

        .fblue {
            color: #0f8dbf;
        }

        .fgray {
            color: #444444;
        }

        .bglightgray {
            margin-bottom: 10px;
            padding-bottom: 0px;
        }

        .drakbg {
            display: inline-block;
            font-size: 13px;
            font-weight: bold;
            padding: 0px;
        }

        .icon_about {
            display: none;
        }

        .profilevisitors .proright {
            position: relative;
        }

        .addlink {
            position: absolute;
            top: 0px;
            right: 0px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            padding: 4px 5px 0;
            border: 1px solid #DDDDDD;
        }

        .profile-subname.searchresultsbox .profilerqsbox .common {
            display: none;
            left: 100%;
            position: absolute;
            top: -10px;
            width: 250px;
            z-index: 1;
            background: #ffffff;
            border: 1px solid #dddddd;
            padding: 10px;
            -moz-box-shadow: 1px 1px 3px 0px #AAAAAA;
            -webkit-box-shadow: 1px 1px 3px 0px #AAAAAA;
            box-shadow: 1px 1px 3px 0px #AAAAAA;
        }

        .searchresultsbox .profilerqsbox .common:after,
        .searchresultsbox .profilerqsbox .common:before {
            right: 100%;
            top: 50%;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        .searchresultsbox .profilerqsbox .common:after {
            border-color: rgba(255, 255, 255, 0);
            border-right-color: #ffffff;
            border-width: 10px;
            margin-top: -10px;
        }

        .searchresultsbox .profilerqsbox .common:before {
            border-color: rgba(221, 221, 221, 0);
            border-right-color: #dddddd;
            border-width: 11px;
            margin-top: -11px;
        }

        .boxpad {
            padding: 0px;
            line-height: 20px;
        }

        body {
            background: #f7f6f6;
            font-family: Ubuntu, sans-serif;
            font-size: 13px;
        }

        p {
            margin: 0px;
        }

        #coursesIRecommendDiv .topcoures {
            margin: 10px 0;
        }

        #myInterestsDiv {
            clear: both;
        }

        #coursesIRecommendDiv .topcoures .profile-name2 {
            display: inline-block;
        }

        #print {
            position: absolute;
            top: 20px;
            right: 50px;
            z-index: 99999;
        }
    </style>
</head>

<body>
    <div class="col-md-12 contant_div" style="position: relative;">
        <!-- Left side start -->
        <form name="frmProfile" id="frmProfile" method="post" enctype="multipart/form-data">
            <input type="hidden" id="hdUserId" name="hdUserId" value="14">
            <div style="display: none;" id="addConnectionPleaseWaitDiv"></div> <span class="add-network-block"
                id="plsWaitImgCntnr_14"></span>
            <div class="row">
                <div class="boxs boxpad profileviewpage">
                    <div class="proleft">
                        <div class="col-md-2 profile col-sm-3">
                            <a href="javascript:void(0);"><img width="150px" id="profilePicImage"
                                    class="greyBorder profileImage" src="{{viewProfile.profilepicName}}"> </a>
                            <br>
                            <div class="printbtn">
                            </div> <span class="add-network-block" id="addToYourNetworkId_14">






                            </span>
                            <div class="profilePageRecoNRateLinks">
                                <div class="profilerqs">
                                    <div class="profilerqsbox"> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="proright">
                        <input value="1" name="hdProfileId" id="hdProfileId" type="hidden">
                        <input value="Student" name="hdCriteria" id="hdCriteria" type="hidden">
                        <div class="profilename"> {{viewProfile.firstName}} {{viewProfile.middleName}}
                            {{viewProfile.lastName}} </div>
                        <div id="">
                            <div class="profileclg">
                                <label class="profile-subname"> {{viewProfile.userType}}, {{viewProfile.subject}},
                                    {{viewProfile.eduIns}}, {{viewProfile.campus}}</label>
                            </div>
                            <div class="profiletools">
                                <label><a href="javascript: void(0);">Connections:</a> </label> <a
                                    href="javascript: void(0);"><span
                                        class="counter">{{viewProfile.noOfStudentConnections}}</span>Students</a> ,
                                <span class="counter">{{viewProfile.noOfProfessorConnections}}</span> Professors , <a
                                    href="javascript: void(0);"><span
                                        class="counter">{{viewProfile.noOfAlumniCOnnections}}</span> Alumni</a> </div>
                            <div id="degreeDiv">
                                <label class="lblblue">Highest Education Level :</label> <span id="degreeCourseDiv"
                                    class="contentarea">{{viewProfile.degreeCourse}}</span> </div>
                        </div>
                        <div class="contentarea">
                            <div class="columns">
                                <label class="lblblue">Past Education:</label>
                                <div *ngFor="let pastEdu of viewProfile.pastEducations">
                                    <div class="pstedu"> <strong> {{pastEdu.degreeCourse}} from
                                            {{pastEdu.educationInstitution}} </strong> , {{pastEdu.campus}} <i>
                                            {{pastEdu.educationStartDateForDisplay}} –
                                            {{pastEdu.educationEndDateForDisplay}} </i> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clr"></div>
                </div>
                <div class="post-block inner-block">
                    <!-- About Me Block -->
                    <div class="fifteen columns addrmcl-content2 boxs" id="aboutMeDiv">
                        <input id="aboutMeToken" type="hidden" value="">
                        <div class="title fblue bglightgray"><span class="drakbg"><i class="icon_about"></i>About
                                <label>Me</label>:</span> </div>
                        <div class="boxpad" *ngIf="!viewProfile.aboutMe">No details entered</div>
                        <div class="boxpad" *ngIf="viewProfile.aboutMe">{{viewProfile.aboutMe}}</div>
                    </div>
                    <!-- About Me Block Ends -->
                    <div class="clear"></div>
                    <!-- Specialist Block -->
                    <div class="fifteen columns addrmcl-content2 boxs " id="specialtiesDiv">
                        <input id="specialtiesToken" type="hidden" value="">
                        <div class="title fblue bglightgray"> <span class="drakbg"><i class="icon_about"></i>
                                Specialties/Skills : </span> </div>
                        <div id="view_taSpecialties" class="common boxpad" style="width: 100%;"
                            *ngIf="!getSkills && !usergetSkills">No details entered
                        </div>
                        <div id="view_taSpecialties" class="common boxpad" style="width: 100%;" *ngIf="getSkills"><span
                                *ngFor="let getskill of getSkills">{{getskill.userSkillSetName}},&nbsp;</span>
                        </div>
                        <div id="view_taSpecialties" class="common boxpad" style="width: 100%;" *ngIf="usergetSkills">
                            <span *ngFor="let usergetSkill of usergetSkills"><a href="javascript:void(0);"
                                    (click)="skillpopup = true;" class="pageLink"
                                    title="Request to conduct a course on {{usergetSkill}}">{{usergetSkill}}</a>,&nbsp;</span>
                        </div>
                    </div>
                    <!-- Specialist Block Ends-->
                    <div class="clear"></div>
                    <div class="boxs">
                        <div class="fifteen columns addrmcl-content2">
                            <div class="title fblue bglightgray">
                                <span class="drakbg"><i class="icon_about"></i>
                                    Project/Research/Work
                                    <label>Experience</label>:
                                </span>
                            </div>

                            <div class="boxpad" *ngIf="!viewProfile.projectResearchWorkExp">No details entered</div>
                            <!-- To Display No Details Entered when List is Empty -->
                            <div class="profile-work profile-hover boxpad" *ngIf="viewProfile.projectResearchWorkExp">
                                <div *ngFor="let workexp of viewProfile.projectResearchWorkExp">
                                    <label class="lblblue">
                                        {{workexp.projectResearchTitle}}
                                    </label>
                                    <br>
                                    <label>
                                        <a>
                                            {{workexp.educationInstitution}},
                                            {{workexp.campus}},
                                        </a>
                                    </label>
                                    <br>
                                    <label class="lblblue2">
                                        {{workexp.projResearchStartDateForDisplay}} –
                                        {{workexp.projResearchEndDateForDisplay}}
                                    </label>
                                    <br>
                                    <p>
                                        <span class="exp-desc-txt-span"
                                            id="exp-desc-txt-span_1">{{workexp.projectResearchDescription}}</span>
                                    </p>
                                    <br>
                                </div>
                            </div>
                            <div class="clr"></div>
                        </div>
                    </div>
                    <div class="fifteen columns addrmcl-content2 boxs" id="booksIRecommendDiv">
                        <input id="booksIRecommendToken" type="hidden" value="">
                        <div class="title fblue bglightgray"><span class="drakbg"><i class="icon_about"></i>Books I
                                <label>recommend</label>:</span> </div>
                        <div class="boxpad" *ngIf="!viewProfile.userRecommendedBooks">No Sessions recommended so far.
                        </div>
                        <div class="boxpad" *ngIf="viewProfile.userRecommendedBooks">{{viewProfile.booksIRecommend}}
                        </div>
                    </div>
                    <div class="clear"></div>
                    <div class="fifteen columns addrmcl-content2 boxs" id="coursesIRecommendDiv">
                        <input id="coursesIRecommendToken" type="hidden" value="">
                        <div id="coursesIRecommendInnerDiv">

                            <style type="text/css">
                                span.stars,
                                span.stars span {
                                    display: block;
                                    background: url(assets/images/stars_small.png) 0 -17px repeat-x;
                                    width: 80px;
                                    height: 16px;
                                }

                                span.stars span {
                                    background-position: 0 0;
                                }

                                .disabled {
                                    pointer-events: none;
                                    cursor: default;
                                }

                                .enabled {
                                    text-decoration: none;
                                    color: #0f8dbf;
                                    display: inline;
                                }

                                a.enabled:HOVER {
                                    color: blue;
                                    text-decoration: underline;
                                }

                                table#courseData tr td {
                                    padding: 10px;
                                    border: #EEEEEE 1px none;
                                }

                                .autocomplete {
                                    width: 410px;
                                }
                            </style>

                            <div class="title fblue bglightgray"> <span class="drakbg"> <i class="icon_about"></i>
                                    Sessions I <label>recommend</label>:</span> </div>
                            <!-- For Displaying all the courses that are recommended by this user. -->
                            <div class="boxpad" *ngIf="!viewProfile.userRecommendedCourses">No Sessions recommended so
                                far.</div>
                            <div class="boxpad" *ngIf="viewProfile.userRecommendedCourses">
                                {{viewProfile.coursesIRecommend}}</div>
                            <input type="hidden" name="hdProfileId" id="hdProfileId">
                            <div class="clr"></div>
                        </div>
                    </div>
                    <div class="clear"></div>
                    <div id="myInterestsDiv" class="fifteen columns addrmcl-content2 boxs">
                        <input id="myInterestsToken" type="hidden" value="">
                        <div class="title fblue bglightgray"><span class="drakbg"><i class="icon_about"></i>My
                                <label>Interests</label>:</span> </div>
                        <div class="boxpad" *ngIf="!viewProfile.myInterests">No details entered</div>
                        <div class="boxpad" *ngIf="viewProfile.myInterests">{{viewProfile.myInterests}}</div>
                    </div>

                    <div class="clear"></div>
                    <div class="dim" id="dimReco" name="dimReco" style="display:none">
                        <div id="invite-pop-up-reco" name="invite-pop-up-reco"
                            class="ten columns invite-pop-up marginbottom boxpad" style="display: none;">
                            <div class="popuptitle" id="requestForRecommend">
                                <h2>Request for Recommendation</h2>
                            </div>
                            <div class="popupclose"> <img alt="Close" src="images/close-button.png" id="closeReco">
                            </div>
                            <div class="eight columns" id="subjectDivReco">
                                <input type="text" value="" name="recommendationSubject" id="recommendationSubject"
                                    placeholder="Subject"> </div>
                            <div class="eight columns" id="CommentsDivReco">
                                <textarea name="recommendationComments" id="recommendationComments"
                                    placeholder="Request"></textarea>
                            </div>
                            <div class="three columns" id="buttonDivReco">
                                <input type="button" value="Send" name="btnSend" id="btnSendReco" class="pageLink1">
                                <input type="hidden" id="hdUserIdReco" value=""> </div>
                        </div>
                    </div>
                </div>
                <div id="recommendVisiDiv" class="fifteen columns addrmcl-content2 boxs">

                    <div class="uppercase sky_color title fblue bglightgray">
                        <span class="drakbg"><i class="icon_about"></i> Recommendations</span>
                    </div>
                    <div class="userrcmd" *ngFor="let usrrcmd of userRcmd">
                        <div class="clr"></div>
                        <div class="profile-recommend profile-hover boxpad">
                            <label><a class="pageLink lblblue"
                                    href="javascript: void(0);">{{usrrcmd.recommendationResponse.responseFrom.firstName}}
                                    {{usrrcmd.recommendationResponse.responseFrom.lastName}}</a> <span> </span>
                            </label>
                            <br
                                *ngIf="usrrcmd.recommendationResponse.responseFrom.isUserSecondaryDataCapturedForUser == 'T'">
                            <label>

                                {{usrrcmd.recommendationResponse.responseFrom.userBaseType}} ,
                                {{usrrcmd.recommendationResponse.responseFrom.educationalInstitution}}

                            </label>
                            <br
                                *ngIf="usrrcmd.recommendationResponse.responseFrom.isUserSecondaryDataCapturedForUser == 'T'">
                            <label
                                *ngIf="usrrcmd.recommendationResponse.responseFrom.isUserSecondaryDataCapturedForUser == 'T'">
                                {{usrrcmd.recommendationResponse.responseFrom.campus}}, </label>
                            <br>
                            <div class="userAcceptedRecommendations">
                                <label class="write">
                                    <p>
                                        <i>{{usrrcmd.recommendationResponse.recommendation.recommendation}}</i>
                                    </p>
                                </label>
                            </div>
                        </div>
                        <div class="clr"></div>
                    </div>
                </div>
                <div class="clear"></div>
            </div>
        </form>
    </div>
    <!-- START User Conducted Courses -->
    <form name="frmAuthorProfile" id="frmAuthorProfile" method="post">
        <div class="conductedCourseDiv boxs printhide" id="conductedCourseDiv">
            <style type="text/css">
                span.stars,
                span.stars span {
                    display: block;
                    background: url(images/stars_small.png) 0 -17px repeat-x;
                    width: 80px;
                    height: 16px;
                }

                span.stars span {
                    background-position: 0 0;
                }

                table.courseConduct tr td {
                    border: none !important;
                }

                .courseSummary {
                    padding: 0 10px;
                }
            </style>

            <div>
                <div class="title fblue bglightgray"> <span class="drakbg"> <i class="icon_about"></i> Sessions by me
                        :</span> </div>
                <div class="coursebymedata" *ngFor="let coursebyme of $any(coursebyMe).courses">
                    <br>
                    <table border="0" class="courseConduct">
                        <tbody>
                            <tr>
                                <td width="80%"> <a class="pageLink" href="#">{{coursebyme.courseFullName}}</a> </td>
                                <td width="8%" style="color: #0f8dbf;"> 0 Reviews</td>
                                <td width="2%" style="color: #7f7f7f;"> 0/5 </td>
                                <td width="10%"> <span
                                        style="font-size: 0px; color: #444444; vertical-align: sub;"></span> </td>
                            </tr>
                            <tr width="15%" style="padding: 0">
                                <td id="courseID_28"> <span style="word-wrap: break-word;"
                                        [innerHTML]="coursebyme.courseSummary"></span>
                                    <div class="padd" id="more_28"> <a href="#" class="pageLink">More</a> </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                </div>
            </div>
        </div>
    </form>
    <!-- END User Conducted Courses -->
</body>

</html>