<div class="pageblock content_area">
    <div id="content-wrapper2">
        <div class="sixteen columns">
            <menu-component></menu-component>
            <div class="boxs">
                <div id="content-top">
                    <ol id="breadcrumb" class="twelve columns alpha breadcrumb">
                        <li class="home"><a href="#" [routerLink]="['../home']">Home</a> </li>
                        <li class="arrow">&nbsp;</li>
                        <li>Payout</li>
                    </ol>
                    <div class="clr"></div>
                    <div class="clear"></div>
                </div>
            </div>


            <!---->
        </div>
        <div class="sixteen columns boxs boxpad relative">

            <div class="searchbox">

                <input type="text" name="searchUser" [(ngModel)]="searchtext" class="form-control"
                    placeholder="Firstname, Lastname, Paypal ID" autocomplete="off">
            </div>
            <br /><br />
            <br />
            <table>
                <tbody style="text-align:center">
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Paypal ID</th>
                        <th>Total Earnings</th>
                        <th>Current Balance</th>
                        <th>Total No. of Withdraws</th>
                        <th>Last Payment Amount</th>
                        <th>Last Payment Date</th>
                        <th>Admin Action</th>

                    </tr>
                    <tr *ngFor="let record of records | filterName : searchtext">
                        <td style="text-align:left">{{record.tutorName}}</td>
                        <td style="text-align:left">{{record.tutorLastName}}</td>
                        <td style="text-align:left">{{record.paypalId}}</td>
                        <td>{{record.paidAmt}}</td>
                        <td>{{record.requestedAmt}}</td>
                        <td>{{record.noOfWithdraw}}</td>
                        <td>{{record.lastPaymentamt}}</td>
                        <td>{{record.lastPaymentDate}}</td>
                        <td>
                            <!-- <a (click)="makePayment(record.payoutId)" href="javascript:;">Pay</a> -->
                            <a (click)="makePaypalPayment(record.payoutId)" href="javascript:;">Manual Pay</a>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="manualPayOverlay" [style.display]="editDisplay(manualPayShow)"></div>
<div class="manualPayWrap" [style.display]="editDisplay(manualPayShow)">
    <div class="manualPayClose">
        <h3>Manual Pay</h3>
    </div>
    <form name="form" method="post" (ngSubmit)="f.form.valid && savePaypalId()" #f="ngForm" novalidate>
        <div class="manualPay">
            <label>Paypal TXN ID*</label>
            <input type="text" [(ngModel)]="payPalTxnId" name="payPalTxnId" #TxnId="ngModel" required
                class="gwt-TextBox" maxlength="200" style="margin-bottom: 0px;" />
            <div *ngIf="f.submitted && !TxnId.valid" class="errorDiv" style="color: #f00">
                PayPal TXN ID is required
            </div>
        </div>
        <div class="manualPayAction">
            <input type="submit" class="buttonStyle2" value="Submit" style="width: 49%; margin-top: 10px;" />
            <button type="button" class="buttonStyle2" (click)="manualPayClose()"
                style="width: 49%; margin-top: 10px;">Cancel</button>
        </div>
    </form>
</div>
<div class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
    tabindex="-1" role="dialog" aria-describedby="errorDialog" aria-labelledby="ui-id-1"
    style="position: fixed; height: auto; width: 300px; top: auto; left: 801px; display: none;">
    <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"><span id="ui-id-1"
            class="ui-dialog-title">{{msgTitle}}</span><button
            class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
            role="button" aria-disabled="false" title="close" style="display: none;"><span
                class="ui-button-icon-primary ui-icon ui-icon-closethick"></span><span class="ui-button-text"
                (click)="popUp = false;">close</span></button></div>
    <div class="ui-dialog-content ui-widget-content"
        style="width: auto; min-height: 33px; max-height: none; height: auto;">
        <ul>
            <li>{{msgContent}}</li>
        </ul>
    </div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <div class="ui-dialog-buttonset"><button type="button"
                class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" (click)="popUp = false;"
                role="button" aria-disabled="false"><span class="ui-button-text">Close</span></button></div>
    </div>
    <div class="ui-resizable-handle ui-resizable-n" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-e" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-s" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-w" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-sw" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-ne" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-nw" style="z-index: 90;"></div>
</div>
<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>