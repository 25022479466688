<div class="sixteen columns pageblock content_area">
	<div class="col-md-8 col_md_8">
		<menu-component></menu-component>
		<div class="row home_container">
			<div class="row">
				<div class="boxs">
					<ol class="breadcrumb" id="breadcrumb">
						<li class="home"><a href="javascript:void(0);"> Home
							</a> </li>
						<li class="arrow">&nbsp;</li>
						<li class="active">Topic Subscribers</li>
					</ol>
					<div class="clr"></div>
				</div>
			</div>
		</div>
		<form method="post" name="topicSubscribersForm" id="topicSubscribersForm">
			<input type="hidden" name="hdProfileId" id="hdProfileId">
			<input type="hidden" id="topicId" name="topicId" value="0">

			<div class="dim" style="display: none;" [style.display]="editDisplay(showRequestReco)">
				<div class="ten columns invite-pop-up marginbottom boxpad" style="display: none;"
					[style.display]="editDisplay(showRequestReco)">
					<div class="popuptitle" id="requestForRecommend">
						<h2>Request for Recommendation</h2>
					</div>
					<div class="popupclose" (click)="showRequestReco = false;"> <img alt="Close"
							src="assets/images/close-button.png" id="close"> </div>
					<div class="eight columns" id="subjectDiv">
						<input type="text" value="" name="recommendationSubject" id="recommendationSubject"
							placeholder="Subject"> </div>
					<div class="eight columns" id="CommentsDiv">
						<textarea [(ngModel)]="reqRcmd.userRequestText" name="reqRcmd.userRequestText"
							id="recommendationComments" placeholder="Request"></textarea>
					</div>
					<div class="three columns" id="buttonDiv">
						<input type="button" value="Send" (click)="rcmdReq()" name="btnSend" id="btnSend"
							class="pageLink1">
						<input type="hidden" id="hdUserId" value=""> </div>
				</div>
			</div>

			<div class="post-block inner-block profile-profile myconnections inbox viewtopic boxs boxpad">
				<div class="uppercase profilename"> <a href="javascript:void(0);" class="fblue deco-none">
						{{topicTO.topicName}}
					</a> </div>
				<div class="topicowner"> <span class="gwt-Label profile-userBy userBy">by:</span>
					<a href="javascript: void(0);" class="fblue deco-none">
						<span>{{topicTO.userDetailsSummaryTO.firstName}}&nbsp;{{topicTO.userDetailsSummaryTO.lastName}}</span>
					</a>&nbsp;&nbsp;
					<span class="gwt-Label profile-userBy userBy">website:</span> <span>{{topicTO.topicWebsite}}</span>
				</div>
				<div class="clr"></div>
			</div>
			<div class="" id="topicSubscriberDIV">
				<div class="fourteen title boxs boxpad">
					<div class="eight columns alpha subinfo1">
						<label>Topic Subscribers</label>
					</div>
					<div class="clr"></div>
				</div>
				<div class="topiccomments show-more-hide" id="subscriberDIV_21" style="display: block;"
					*ngFor="let subs of allTopicsSubs">
					<div class="boxs boxpad">
						<!-- <div class="eight columns alpha subinfo1">-->
						<div class="two columns inner-post-image float-attendees">
							<div class="featured-image">
								<a href="javascript: void(0);" class="fblue deco-none">
									<!-- <img class="greyBorder profileImage" id="profilePicImage" src="{{subs.userDetailsSummaryTO.profilePicName}}"> -->
									<img *ngIf="subs.userDetailsSummaryTO.profilePicName != null && !subs.userDetailsSummaryTO.profilePicName.includes('profilebig.png')"
										class="greyBorder profileImage" alt=""
										src="{{subs.userDetailsSummaryTO.profilePicName}}" width="100" height="100">

									<ngx-avatar
										*ngIf="subs.userDetailsSummaryTO.profilePicName == null || subs.userDetailsSummaryTO.profilePicName.includes('profilebig.png')"
										name="{{subs.userDetailsSummaryTO.firstName}} {{subs.userDetailsSummaryTO.lastName}}"
										size="100"></ngx-avatar>
								</a>
							</div>
						</div>
						<div class="four profile-hover block-attendees">
							<label class="profile-name profilename">
								<a href="javascript: void(0);" [routerLink]="['../profile']"
									[queryParams]="{ user: subs.userDetailsSummaryTO.userDetailsId }"
									class="fblue deco-none">
									{{subs.userDetailsSummaryTO.firstName}} {{subs.userDetailsSummaryTO.lastName}}
								</a>
								<span style="font-size: 0px; color: #444444; vertical-align: sub;">
								</span>
							</label>
							<br>
							<div *ngIf="subs.userDetailsSummaryTO.isUserSecondaryDataCapturedForUser == 'T'"
								class="profilecou gwt-Label profile-userType userype">
								{{subs.userDetailsSummaryTO.userBaseType}}
								<label> , {{subs.userDetailsSummaryTO.educationalInstitution}},
									{{subs.userDetailsSummaryTO.campus}} </label>
							</div>
							<span class="add-network-block" id="plsWaitImgCntnr_21"></span>
							<!-- Start Network Status -->

							<div>
								<div *ngIf="subs.userDetailsSummaryTO.invitationAction != 'Invite'"
									class="profilePageRecoNRateLinks searchresultsbox" id="addToYourNetworkId_13">
									<span class="innetlink"
										*ngIf="subs.userDetailsSummaryTO.invitationAction == 'In My Network'">In Your
										Network</span>
									<div class="add-network-block" id="addToYourNetworkId_17"
										*ngIf="subs.userDetailsSummaryTO.invitationAction == 'Accept'"
										style="margin-left: 0;width: 15%;">
										<div class="profileact">
											<span id="inNetworkStatusId_17" style="margin-right: 10px;"><a class=""
													style="color: #6d6d6d;" href="javascript:void(0);"
													(click)="acceptInvite(subs.invitationRequestId)">Accept</a></span>
										</div>
									</div>
									<div class="add-network-block" id="addToYourNetworkId_23"
										style="margin-left: 0;width: 25%;"
										*ngIf="subs.userDetailsSummaryTO.invitationAction == 'Waiting for Response'">
										<div class="profileawa">
											Awaiting Response
										</div>
									</div>
									<div class="clear"></div>
								</div>
								<div *ngIf="subs.userDetailsSummaryTO.invitationAction == 'In My Network'"
									class="profilePageRecoNRateLinks">
									<a href="javascript:void(0);" (click)="sendMessg(subs.userDetailsSummaryTO);"
										class="pageLink"> Send Message</a>
									<div class="clear"></div>
								</div>
								<div *ngIf="subs.userDetailsSummaryTO.invitationAction == 'Invite'"
									class="profilePageRecoNRateLinks" id="addToYourNetworkId_13">
									<a href="javascript: void(0)"
										(click)="sendInvite(subs.userDetailsSummaryTO.userDetailsId)"
										class="pageLink1 addnetlink">Add to your network</a>
								</div>
								<div *ngIf="subs.userDetailsSummaryTO.invitationAction == 'In My Network'"
									class="profilePageRecoNRateLinks">
									<div class="profilerqs">
										<a class="request-recommendation" href="javascript: void(0);"
											(click)="showRequestReco = true;reqRcmd.requestTo.userDetailsId = subs.userDetailsSummaryTO.userDetailsId">
											<img border="0" width="12px" height="12px" src="assets/images/request.png"
												style="margin-right: 2px;"> Request for Recommendation</a>
										<br>
										<span *ngIf="subs.userDetailsSummaryTO.ratingAction == 'RATING_ALREADY_SENT'"
											id="rateProfessorLinkDiv_13">
											<a href="javascript: void(0);" class="pageLink1">You have already rated this
												user</a></span>
										<div *ngIf="subs.userDetailsSummaryTO.ratingAction == 'SEND_RATING'">
											<span id="rateProfessorLinkDiv_13">
												<a href="javascript: void(0);" (click)="subs.ratebox = true;"
													class="pageLink1">
													<img border="0" width="12px" height="12px"
														src="assets/images/star.png" style="margin-right: 2px;">
													Rate this User
												</a>
											</span>
											<div class="profilerqsbox">
												<div id="rateProfessorDiv_13" class="common" style="display: none"
													[style.display]="editDisplay(subs.ratebox)">
													<input type="radio"
														(click)="rating.responseTo.userDetailsId = subs.userDetailsSummaryTO.userDetailsId"
														[(ngModel)]="rating.rating.ratingText"
														name="{{subs.userDetailsSummaryTO.userDetailsId}}"
														id="rateFair_13" value="FAIR">
													<label for="rateFair_13">Fair</label>&nbsp;
													<input type="radio"
														(click)="rating.responseTo.userDetailsId = subs.userDetailsSummaryTO.userDetailsId"
														[(ngModel)]="rating.rating.ratingText"
														name="{{subs.userDetailsSummaryTO.userDetailsId}}"
														id="rateGood_13" value="GOOD">
													<label for="rateGood_13">Good</label>&nbsp;
													<input type="radio"
														(click)="rating.responseTo.userDetailsId = subs.userDetailsSummaryTO.userDetailsId"
														[(ngModel)]="rating.rating.ratingText"
														name="{{subs.userDetailsSummaryTO.userDetailsId}}"
														id="rateBest_13" value="BEST">
													<label for="rateBest_13">Best</label>
													<input type="button" name="btnRate"
														(click)="ratingUser(subs); subs.ratebox = false;" id="btnRate"
														value="Rate" style="margin-top: 5px;">
													<input type="button" name="btnCancel" id="btnCancel"
														(click)="subs.ratebox = false;" value="Cancel"
														style="margin-left: 5px; margin-top: 5px;">
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- End Network Status -->
						</div>
						<div class="clr"></div>
						<!-- </div>-->
						<div class="five columns last topicattr">
							<label class="topiccommentdate settopright"> </label>
							<br>
							<label>
								<div class="three columns last topicattr icorply">
									<input *ngIf="removeSubs" type="button" (click)="remSubs(subs.topicSubscriberId)"
										class="view-topics" name="comment-submit" value="Remove Subscriber">
								</div>
							</label>
						</div>
						<div class="clr"></div>
					</div>
				</div>
			</div>
		</form>
		<div id="load-more-hide" class="load-more-hide marginbottom margintop" style="display: none;">Load More</div>
		<div class="clear"></div>
		<div style="display: none;" [style.display]="editDisplay(sendMsg)" class="ui-widget-overlay ui-front"></div>
		<div style="display: none;" [style.display]="editDisplay(sendMsg)"
			class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
			tabindex="-1" role="dialog" aria-describedby="sendInNetworkInvite" aria-labelledby="ui-id-1"
			style="position: absolute; height: auto; width: 600px; top: 250px; left: 651px;">
			<div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"><span id="ui-id-1"
					class="ui-dialog-title">&nbsp;</span>
				<button
					class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
					role="button" aria-disabled="false" title="close" style="display: none;"><span
						class="ui-button-icon-primary ui-icon ui-icon-closethick"></span><span
						class="ui-button-text">close</span> </button>
			</div>
			<div class="ui-dialog-content ui-widget-content"
				style="width: auto; min-height: 33px; max-height: none; height: auto;" id="sendInNetworkInvite">
				<div class="seven columns last"> To: &nbsp;
					<label id="userNameDisplay"
						style="color: #108dd5;  text-decoration: none;">{{viewProfile.firstName}}
						{{viewProfile.middleName}} {{viewProfile.lastName}}</label>
					<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
					<label id="userTypeInfo" class="gwt-Label profile-userType userype"
						*ngIf="viewProfile.isUserSecondaryDataCapturedForUser == 'T'">{{viewProfile.userBaseType}}
					</label>
					<label id="universityInfo" class="gwt-Label profile-userType userype"
						*ngIf="viewProfile.isUserSecondaryDataCapturedForUser == 'T'">,
						{{viewProfile.educationalInstitution}}, {{viewProfile.campus}}</label>
				</div>
				<div class="clear"></div>
				<input type="hidden" id="userIdSend" value="22">
				<div class="two columns alpha">
					<label>Subject:</label>
					<input type="text" id="subjectForNetwork" [(ngModel)]="msgData.messageSubject"
						name="msgData.messageSubject"> </div>
				<div class="clear"></div>
				<div class="seven columns last">
					<label>Message:</label>
					<textarea [(ngModel)]="msgData.messageBody" name="msgData.messageBody"
						id="messageForNetwork"></textarea>
				</div>
			</div>
			<div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
				<div class="ui-dialog-buttonset">
					<button type="button" id="sendMessage" (click)="sendMessage()"
						class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" role="button"
						aria-disabled="false"><span class="ui-button-text">Send</span> </button>
					<button type="button" id="cancelSendMessage"
						class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" role="button"
						aria-disabled="false"><span (click)="sendMsg = false;" class="ui-button-text">Cancel</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>