<div class="sixteen columns pageblock content_area">
    <div id="content-top">
        <menu-component></menu-component>
        <div class="boxs">
            <ol id="breadcrumb" class="ten columns alpha breadcrumb">
                <li class="home"><a href="#" [routerLink]="['../home']">Home</a> </li>
                <li class="arrow">&nbsp;</li>
                <li>CouSessionsrses: My Sessions</li>

            </ol>
            <div class="clr"></div>
        </div>
        <div class="clr"></div>
    </div>
    <!-- <table align="left">
    <tr> -->
    <div id="displayMyCourses" class="boxs">
        <table cellspacing="0" cellpadding="0" style="width: 600px;">
            <tbody>
                <tr>
                    <td align="left" style="vertical-align: top; padding: 10px;">
                        <table>
                            <colgroup>
                                <col>
                            </colgroup>
                            <tbody>
                                <tr class="custom-tr">
                                    <td class="custom-td1" style="vertical-align: top;">
                                        <div class="gwt-Label">To:*</div>
                                    </td>
                                    <td class="custom-td2" style="vertical-align: top;">
                                        <label for="gwt-uid-1">All Attendees</label>
                                    </td>
                                </tr>

                                <tr class="custom-tr">
                                    <td class="custom-td1" style="vertical-align: top;">
                                        <div class="gwt-Label">Message Body:*</div>
                                    </td>
                                    <td class="custom-td2" style="vertical-align: top;">
                                        <textarea class="gwt-TextArea" [(ngModel)]="invitedata.message"
                                            name="invitedata.message" style="width: 100%; height: 100px;"></textarea>
                                    </td>
                                </tr>
                                <tr class="custom-tr">
                                    <td class="custom-td1" style="vertical-align: top;">

                                    </td>
                                    <td class="custom-td2" style="vertical-align: top;">
                                        <button type="button" (click)="submit()" class="buttonStyle2">Notify</button>
                                        <button type="button" [routerLink]="['/dashboard/courseaccount']"
                                            class="buttonStyle2">Cancel</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- </tr>
</table> -->
</div>
<div [style.display]="editDisplay(popUp)"
    class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
    tabindex="-1" role="dialog" aria-describedby="errorDialog" aria-labelledby="ui-id-1"
    style="position: fixed; height: auto; width: 300px; top: auto; left: 801px; display: none;">
    <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"><span id="ui-id-1"
            class="ui-dialog-title">{{msgTitle}}</span><button
            class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
            role="button" aria-disabled="false" title="close" style="display: none;"><span
                class="ui-button-icon-primary ui-icon ui-icon-closethick"></span><span class="ui-button-text"
                (click)="popUp = false;">close</span></button></div>
    <div class="ui-dialog-content ui-widget-content"
        style="width: auto; min-height: 33px; max-height: none; height: auto;">
        <ul>
            <li>{{msgContent}}</li>
        </ul>
    </div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <div class="ui-dialog-buttonset"><button type="button"
                class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" (click)="popUp = false;"
                role="button" aria-disabled="false"><span class="ui-button-text">Close</span></button></div>
    </div>
    <div class="ui-resizable-handle ui-resizable-n" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-e" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-s" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-w" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-sw" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-ne" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-nw" style="z-index: 90;"></div>
</div>