<header-component></header-component>
<div class="col-md-8 col_md_8 pageblock content_area">
    <div class="contant_div">
        <div id="searchResultsDiv" class="myconnections">

            <form name="searchResultsForm" id="searchResultsForm" method="post">
                <input type="hidden" name="hdProfileId" id="hdProfileId">
                <input type="hidden" name="hdCriteria" id="hdCriteria">
                <div id="searchResultsResponseDiv">
                    <div class="searchresultsbox boxs boxpad last" style="height:620px;"
                        *ngFor="let connection of searchUserRes">
                        <div class="inner-post-image conprofilepic">
                            <img width="100" height="100" border="0" id="profile" src="{{connection.profilePicName}}">
                        </div>
                        <div class="inner-post-desc">
                            <div class="profile-name">
                                {{connection.firstName}} {{connection.lastName}}
                            </div>
                            <span *ngIf="connection.starRating"
                                style="font-size: 0px; color: #444444; vertical-align: sub;">
                                <img width="32" border="0" *ngFor="let rate of connection.myRating"
                                    src="assets/images/star.png" style="margin-right: 1px;">
                            </span>
                            <div *ngIf="connection.isUserSecondaryDataCapturedForUser == 'T'"
                                class="profilecou gwt-Label profile-userType userype"
                                style="padding-bottom:10px;height:auto;"> {{connection.userTypeFullDescription}},
                                {{connection.educationalInstitutionFullName}},
                                {{connection.educationalInstitutionCampus}}</div>
                            <div class="profilecnt">
                                <label>Connections</label>
                                <span class="counter">{{connection.studentsCount}}</span> Students
                                <span class="counter">{{connection.professorsCount}}</span> Professors
                                <span class="counter">{{connection.alumniCount}}</span> Alumni
                            </div>

                            <div class="proflecnt"
                                style="padding-top:12px; color:#0f8dbf; padding-bottom: 5px;font-size:12px;"
                                *ngIf="connection.projectResearchWorkExperiencesAvailable == true">
                                <label>Project Research / Work Experiences</label>
                            </div>
                            <div class="profileuni gwt-Label profile-userType userype"
                                style="height:auto;padding-bottom:2px;"
                                *ngFor="let projResExp of connection.projectResearchWorkExperiences">
                                {{projResExp}}
                            </div>

                            <div class="proflecnt"
                                style="padding-top:10px; color:#0f8dbf; padding-bottom: 5px;font-size:12px;"
                                *ngIf="connection.pastEducationsAvailable == true">
                                <label>Past Educations</label>
                            </div>
                            <div class="profileuni gwt-Label profile-userType userype"
                                style="height:auto;padding-bottom:2px;"
                                *ngFor="let pastEducation of connection.pastEducations">
                                {{pastEducation}}
                            </div>
                            <br>
                            <div class="profileuni gwt-Label profile-userType userype"
                                style="width:98%; height:80px; overflow:auto; padding-top:8px;"
                                *ngIf="connection.aboutMe != null">
                                {{connection.aboutMe}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="clr"></div>
            </form>
        </div>
    </div>

    <section class="section3 clearfix">
        <div class="main homepagesearch" style="padding: 50px 0px;">
            <h2> Search for People: </h2>
            <form name="frmGrowNetwork" method="post" role="search">
                <input type="hidden" name="hdCriteria" id="hdCriteria">
                <div style="margin-left: 0px; margin-top: 20px;" class="twelve columns">
                    <div class="four columns alpha">
                        <input type="text" name="searchFirstName" [(ngModel)]="searchfname" id="searchFirstName"
                            size="18" maxlength="50" autocomplete="off" placeholder="First Name">
                    </div>
                    <div class="four columns">
                        <input type="text" name="searchLastName" [(ngModel)]="searchlname" id="searchLastName" size="18"
                            maxlength="50" autocomplete="off" placeholder="Last Name">
                    </div>
                    <div class="three columns last">
                        <input type="submit" name="btnSearch" id="btnSearch" [routerLink]="['/public/search']"
                            [queryParams]="{ fname: searchfname, lname: searchlname }" value="Search"
                            class="buttonStyle">
                    </div>
                    <div class="clr"></div>
                </div>
                <div class="clr"></div>
            </form>
        </div>
    </section>

</div>
<footer class="footer clearfix">
    <div class="main">
        <ul>
            <li class="title">Company</li>
            <li><a href="#">About</a></li>
            <li><a href="#">Careers</a></li>
            <li><a href="#">Press</a></li>
            <li><a href="#">Contact</a></li>
            <li><a href="#">Blog</a></li>
        </ul>

        <ul>
            <li class="title">Community</li>
            <li><a href="#">Membership</a></li>
            <li><a href="#">Organizations</a></li>
            <li><a href="#">Referrals</a></li>
            <li><a href="#">Guidelines</a></li>
            <li><a href="#">Scholarships</a></li>

        </ul>

        <ul>
            <li class="title">Teaching</li>
            <li><a href="#">Become a Teacher</a></li>
            <li><a href="#">Teaching Handbook</a></li>

        </ul>

        <ul>
            <li class="title">Connect</li>
            <li><a href="#">Google+</a></li>
            <li><a href="#">Twitter</a></li>
            <li><a href="#">Facebook</a></li>
            <li><a href="#">Blog</a></li>
        </ul>

        <div class="copy clearfix">© All Rights Reserved.</div>
    </div>

</footer>
<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
<uvsity-modal-popup [isModalDialogToBeDisplayed]="showErrorModalDialog" [messageTitle]="showErrorMessageTitle"
    [messages]="showErrorMessages" (uvsityModalPopupClosed)="closeErrorPopup()"
    [popupWidthPercentage]="showErrorPopupWidthInPercentage" [isValidationError]="isValidationError">
</uvsity-modal-popup>