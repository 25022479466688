<div class="pageblock content_area">
    <div class="left_part">
        <div class="container" style="display: none;">
            <div class="dim">
                <div class="six columns pop-up boxpad" style="display: none;">
                    <div class="five columns alpha popuptitle">
                        <h2>Do you really want to refund?</h2>
                    </div>
                    <div class="one last popupclose"> <img id="close" src="assets/images/close-button.png" alt="Close">
                    </div>
                    <form id="approveForm" name="approveForm" action="displayAdminRefund.do" method="post">
                        <div class="two columns" style="margin-bottom: 10px;">
                            <label class="lblblue"></label>
                            <label id="lblDtls" class="lblblue">test</label>
                        </div>
                        <div class="two columns alpha">
                            <input type="submit" id="submit-approve" value="Refund">&nbsp;&nbsp;
                            <input type="button" id="cancel" name="cancel" value="Cancel"> </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Pop Up -->
        <menu-component></menu-component>
        <div class="boxs">
            <div id="content-top">
                <ol id="breadcrumb" class="twelve columns alpha breadcrumb">
                    <li class="home"><a href="home.do">Home</a> </li>
                    <li class="arrow">&nbsp;</li>
                    <li>Session Refund</li>
                </ol>
                <div class="clr"></div>
                <div class="clear"></div>
            </div>
        </div>
        <div class="sixteen columns boxs">
            <table id="rt1" class="rt cf">
                <thead class="cf">
                    <tr>
                        <th>Attendee</th>
                        <th>Session</th>
                        <th>Amount Paid</th>
                        <th>Schedule</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                    <tr *ngFor="let refund of users">
                        <td>{{refund.users[0].name}}({{refund.users[0].emailId}})</td>
                        <td><a href="javascript:void(0)" [routerLink]="['../viewsession']"
                                [queryParams]="{ sessionId: refund.courseId }">{{refund.courseFullName}}</a></td>
                        <td>${{refund.cost}}.0</td>
                        <td>{{refund.displayStartDate}}({{refund.timeZone}})</td>
                        <td></td>
                        <td><a href="javascript:void(0)"
                                (click)="refundClick(refund.courseId, refund.users[0].userDetailsId)">Refund</a></td>
                    </tr>
                </thead>
                <tbody> </tbody>
            </table>
        </div>
        <div class="clear"></div>
        <div class="space"></div>
    </div>
    <div class="right_part">
        <div class="sixteen columns boxs boxpad grow_your_network refineresults">
            <form id="searchForm" name="searchForm" action="displayAdminRefund.do" method="post">
                <div class="filterwrap">
                    <div class="title fblue pad15"> Filter Criteria: </div>
                    <div class="clear"></div>
                    <div class="three columns alpha">
                        <input type="text" id="fname" [(ngModel)]="search.fname" name="search.fname" value=""
                            class="formelement form-group" placeholder="First Name"> </div>
                    <div class="clear"></div>
                    <div class="nine columns last">
                        <!-- <label for="course">Course:</label><br/> -->
                        <input type="text" id="lname" [(ngModel)]="search.lname" name="search.lname" value=""
                            class="formelement form-group" placeholder="Last Name"> </div>
                    <div class="clear"></div>
                    <div class="three columns alpha">
                        <!-- <label for="first-name">First Name:</label><br/> -->
                        <input type="text" id="keyword" [(ngModel)]="search.courseName" name="search.courseName"
                            value="" class="formelement form-group" placeholder="Session Name"> </div>
                    <div class="three columns last">
                        <label style="color: #ffffff;">Hidden:</label>
                        <br>
                        <input type="submit" (click)="refundSearch();" id="filter-search" name="filter-search"
                            value="Go"> </div>
                    <input type="hidden" id="command" name="command" value="find">
                    <div class="clear"></div>
                    <div class="clear"></div>
                </div>
            </form>
        </div>
    </div>
    <div class="clr"></div>
</div>
<div [style.display]="editDisplay(popUp)"
    class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
    tabindex="-1" role="dialog" aria-describedby="errorDialog" aria-labelledby="ui-id-1"
    style="position: fixed; height: auto; width: 300px; top: auto; left: 801px; display: none;">
    <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"><span id="ui-id-1"
            class="ui-dialog-title">{{msgTitle}}</span><button
            class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
            role="button" aria-disabled="false" title="close" style="display: none;"><span
                class="ui-button-icon-primary ui-icon ui-icon-closethick"></span><span class="ui-button-text"
                (click)="popUp = false;">close</span></button></div>
    <div class="ui-dialog-content ui-widget-content"
        style="width: auto; min-height: 33px; max-height: none; height: auto;">
        <ul>
            <li>{{msgContent}}</li>
        </ul>
    </div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <div class="ui-dialog-buttonset"><button type="button"
                class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" (click)="popUp = false;"
                role="button" aria-disabled="false"><span class="ui-button-text">Close</span></button></div>
    </div>
    <div class="ui-resizable-handle ui-resizable-n" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-e" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-s" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-w" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-sw" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-ne" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-nw" style="z-index: 90;"></div>
</div>