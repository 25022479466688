<div class="sixteen columns pageblock content_area">
    <menu-component></menu-component>
    <div class="clr"></div>
    <div class="">
        <div id="content-wrapper2">
            <div class="main-body" style="margin-top: 10px;">
                <div class="sixteen columns">
                    <div id="content-top" class="boxs">
                        <ol id="breadcrumb" class="ten columns alpha breadcrumb">
                            <li class="home"><a href="#" [routerLink]="['../home']">Home</a> </li>
                            <li class="arrow">&nbsp;</li>
                            <li class="active">Topics: Topics List</li>
                        </ol>
                        <div class="clr"></div>
                    </div>
                </div>
                <!--Topic list  -->
                <form method="post" name="topicOwnerForm" id="topicOwnerForm">
                    <div id="uvsitySearchPane" class="left_part">
                        <div id="searchResultsResponseDiv">
                            <div id="topiclistdiv"
                                class="post-block inner-block profile-profile myconnections topicslists">
                                <div class="boxs boxpad" *ngFor="let topic of topiclist">
                                    <div class="showmore" style="display: block;">
                                        <div class="two columns inner-post-image msgconimg">
                                            <div class="featured-image">
                                                <img *ngIf="topic.topic.topicImageUrl != null && !topic.topic.topicImageUrl.includes('profilebig.png')"
                                                    class="greyBorder profileImage" alt=""
                                                    src="{{topic.topic.topicImageUrl}}" height="60" width="60">

                                                <ngx-avatar
                                                    *ngIf="topic.topic.topicImageUrl == null || topic.topic.topicImageUrl.includes('profilebig.png')"
                                                    name="{{topic.topic.userDetailsSummaryTO.firstName}} {{topic.topic.userDetailsSummaryTO.lastName}}"
                                                    size="60"></ngx-avatar>
                                            </div>
                                        </div>
                                        <div class="seven columns profile-hover alpha msgcondesc">
                                            <label class="topic-name"> <a [routerLink]="['../viewtopic']"
                                                    [queryParams]="{ topic: topic.topic.topicDetailId }"
                                                    href="javascript:void(0);">{{topic.topic.topicName}} </a> </label>
                                            <div class=" topicowner"><span
                                                    class="gwt-Label profile-userBy userBy">by:</span>
                                                <a href="javascript: void(0);" [routerLink]="['../profile']"
                                                    [queryParams]="{ user: topic.topic.userDetailsSummaryTO.userDetailsId }"
                                                    class="fblue deco-none">
                                                    <span>{{topic.topic.userDetailsSummaryTO.firstName}}&nbsp;{{topic.topic.userDetailsSummaryTO.lastName}}</span>
                                                </a> &nbsp;&nbsp;<span
                                                    *ngIf="topic.topic.userDetailsSummaryTO.isUserSecondaryDataCapturedForUser == 'T'"></span>
                                                User Type: <span
                                                    *ngIf="topic.topic.userDetailsSummaryTO.isUserSecondaryDataCapturedForUser == 'T'">{{topic.topic.userDetailsSummaryTO.userBaseType}}</span>&nbsp;
                                                <span class="gwt-Label profile-userBy userBy">website:</span> <span><a
                                                        class="fblue deco-none" href="{{topic.topic.topicWebsite}}"
                                                        target="_blank">{{topic.topic.topicWebsite}}</a></span> </div>
                                            <div class="topicdesc">{{topic.topic.topicSummary}}</div>
                                            <div>
                                                <!-- Topic Type -->Topic Type:
                                                <span>{{topic.topic.topicTypeTO.topicType}}</span>
                                                <br>
                                                <!-- Topic Permission -->Topic Permission :
                                                <span>{{topic.topic.topicPermissionTO.topicPermissionType}}</span>
                                                <br>
                                                <label class="label-bold fblue"
                                                    style="font-weight: bold; color:#0f0f0f">Active:</label>
                                                <label> {{topic.topic.topicCommentsCount}} discussions this month·
                                                    <br> <a href="javascript: void(0);"
                                                        [routerLink]="['../subscribers']"
                                                        [queryParams]="{ topic: topic.topic.topicDetailId }"
                                                        class="fblue deco-none">
                                                        {{topic.topic.topicSubscribersCount}} Members
                                                    </a> </label>
                                            </div>
                                            <div class="list type2 fblue"
                                                *ngIf="topic.isTopicOwnerInUsersFriendList == true"> In Your Network
                                            </div>
                                            <div class="two columns last icorply">
                                                <!--  <form method="post" action="#"> -->
                                                <input type="button" [routerLink]="['../viewtopic']"
                                                    [queryParams]="{ topic: topic.topic.topicDetailId }"
                                                    class="view-topics" name="view-topics" value="View">
                                                <!--   </form> -->
                                            </div>
                                        </div>
                                        <div class="clr"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="clr"></div>
                        <div id="loadMoreResults" (click)="loadMoreTopicList()" class="load-more-hide">Load More</div>
                        <div id="pleaseWaitDiv" style="display:none"> <img src="assets/images/pleaseWait.gif"
                                width="30%" height="30%"> </div>
                    </div>
                </form>
                <div class="right_part">
                    <div class="boxs boxpad">
                        <div class="widget">
                            <form action="" name="searchTopicForm">
                                <div id="searchwrapper2">
                                    <div class="topicsearchbox">
                                        <input type="text" class="searchbox2" id="searchtext"
                                            [(ngModel)]="topicsearch.topicName" name="topicsearch.topicName"> </div>
                                </div>
                                <div class="fblue">Filter Criteria:</div>
                                <ul class="topiclistsort">
                                    <li>
                                        <input type="checkbox" id="allcategories" value="all categories"> All Categories
                                    </li>
                                    <li style="margin-left: 30px;">
                                        <input type="checkbox" class="topicclass" id="topicType_1"
                                            [(ngModel)]="topicsearch.isFilterByAlumniRequired"
                                            name="topicsearch.isFilterByAlumniRequired" value="true"> Alumni </li>
                                    <li style="margin-left: 30px;">
                                        <input type="checkbox" class="topicclass" id="topicType_2"
                                            [(ngModel)]="topicsearch.isFilterByCorporateRequired"
                                            name="topicsearch.isFilterByCorporateRequired" value="true"> Corporate </li>
                                    <li>
                                        <input type="checkbox" id="topicslike"
                                            [(ngModel)]="topicsearch.isFilterByInterestedTopicsRequired"
                                            name="topicsearch.isFilterByInterestedTopicsRequired" value="true"> Topics
                                        you may like<sup>{{topicDashboard.noOfTopicsYouMightBeInterestedIn}}</sup> </li>
                                    <li>
                                        <input type="checkbox" id="opentopics"
                                            [(ngModel)]="topicsearch.isFilterByOPenTOpicRequried"
                                            name="topicsearch.isFilterByOPenTOpicRequried" value="true"> Open
                                        Topics<sup>{{topicDashboard.noOfPublicTopics}}</sup> </li>
                                    <li>
                                        <input type="checkbox" id="membersonly"
                                            [(ngModel)]="topicsearch.isFilterByPrivateTopicRequried"
                                            name="topicsearch.isFilterByPrivateTopicRequried" value="true"> Members
                                        Only<sup>{{topicDashboard.noOfPrivateTopics}}</sup> </li>
                                </ul>
                                <input type="button" class="view-topics" (click)="searchTopic()" value="Search Topic">
                            </form>
                        </div>
                    </div>
                </div>
                <div class="clr"></div>
            </div>
        </div>
    </div>
</div>
<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>