<div class="pageblock content_area">
	<div class="left_part">
		<menu-component></menu-component>
		<div id="content-top">
			<div class="boxs">
				<ol id="breadcrumb" class="twelve columns alpha breadcrumb">
					<li class="home"><a href="javascript:void(0);" [routerLink]="['../home']">Home</a> </li>
					<li class="arrow">&nbsp;</li>
					<li>Sessions: Attendees</li>
				</ol>
				<div class="clr"></div>
			</div>
		</div>
		<div class="dim" style="display: none;" [style.display]="editDisplay(showRequestReco)">
			<div class="ten columns invite-pop-up marginbottom boxpad" style="display: none;"
				[style.display]="editDisplay(showRequestReco)">
				<div class="popuptitle" id="requestForRecommend">
					<h2>Request for Recommendation</h2>
				</div>
				<div class="popupclose" (click)="showRequestReco = false;"> <img alt="Close"
						src="assets/images/close-button.png" id="close"> </div>
				<div class="eight columns" id="subjectDiv">
					<input type="text" value="" name="recommendationSubject" id="recommendationSubject"
						placeholder="Subject"> </div>
				<div class="eight columns" id="CommentsDiv">
					<textarea [(ngModel)]="reqRcmd.userRequestText" name="reqRcmd.userRequestText"
						id="recommendationComments" placeholder="Request"></textarea>
				</div>
				<div class="three columns" id="buttonDiv">
					<input type="button" value="Send" (click)="rcmdReq()" name="btnSend" id="btnSend" class="pageLink1">
					<input type="hidden" id="hdUserId" value=""> </div>
			</div>
		</div>
		<!-- Course Creater Info Start -->
		<div class="post-block inner-block profile-profile myconnections inbox viewtopic boxs boxpad">
			<div class="uppercase profilename"> <a href="javascript:void(0)" [routerLink]="['../viewsession']"
					[queryParams]="{ sessionId: courseDetail.courseId }" class="fblue deco-none">
					{{courseDetail.courseFullName}}
				</a> </div>
			<div class="topicowner"> <span class="gwt-Label profile-userBy userBy">by:</span>
				<a href="javascript: void(0);" [routerLink]="['../profile']"
					[queryParams]="{ user: creator.userDetailsId }" class="fblue deco-none">
					<span>{{creator.name}} </span> </a>
				<span
					*ngIf="creator.isUserSecondaryDataCapturedForUser != undefined && creator.isUserSecondaryDataCapturedForUser != 'F'"
					class="gwt-Label profile-userType userype"> ,
					{{creator.userType}}
					<label>, {{creator.educationalInstitute}}, {{creator.city}}</label>
				</span> </div>
			<div class="clr"></div>
			<a pTooltip="Download all responses" class="queAllDownload" *ngIf="queId > 0 && invitecourse" href="javascript:void(0)" (click)="downloadQuestionnaireRes()"><i class="fa fa-download" aria-hidden="true"></i>All Questionnaire CSV</a>
		</div>
		<!-- Course Creater Info End -->
		<div class="fifteen columns post-block inner-block profile-profile myconnections inbox">
			<div class="fourteen title boxs boxpad">
				<div class="eight columns alpha courseinfo">
					<label>Attendee</label>
				</div>
				<div class="five columns last courseatttitle">
					<label>Status</label>
				</div>
				<div class="clr"></div>
			</div>
			<div class="clear"></div>
			<div class="boxs boxpad" *ngFor="let user of users">
				<div class="eight columns alpha courseinfo">
					<div class="two columns inner-post-image float-attendees">
						<div class="featured-image">
							<a href="javascript: void(0);" [routerLink]="['../profile']"
								[queryParams]="{ user: user.userDetailsId }">
								<!-- <img src="{{user.profilePicName}}" alt="" width="50" class="greyBorder profileImage">  -->

								<img *ngIf="user.profilePicName != null && !user.profilePicName.includes('profilebig.png')"
									class="greyBorder profileImage" alt="" src="{{user.profilePicName}}" width="50"
									height="50">

								<ngx-avatar
									*ngIf="user.profilePicName == null || user.profilePicName.includes('profilebig.png')"
									name="{{user.name}}" size="50"></ngx-avatar>
							</a>
						</div>
					</div>
					<div class="four profile-hover block-attendees">
						<label class="profile-name profilename"> <a href="javascript: void(0);"
								[routerLink]="['../profile']" [queryParams]="{ user: user.userDetailsId }"
								class="fblue deco-none"> {{(user.firstName != null) ? user.firstName : ""}} {{(user.lastName != null) ? user.lastName : ""}} </a> </label>
						<br>
						<div *ngIf="user.isUserSecondaryDataCapturedForUser != undefined && user.isUserSecondaryDataCapturedForUser != 'F'"
							class="profilecou gwt-Label profile-userType userype"> {{user.userType}}
							<label>, {{user.educationalInstitute}}, {{user.city}} </label>
						</div>
						<!-- Start Network Status --><span class="add-network-block" id="plsWaitImgCntnr_21"></span>
						<div>
							<div *ngIf="user.connectionStatus != 'INVITE'"
								class="profilePageRecoNRateLinks searchresultsbox" style="width: 50%"
								id="addToYourNetworkId_13">
								<span class="innetlink" *ngIf="user.connectionStatus == 'IN_MY_NETWORK'">In Your
									Network</span>
								<div class="add-network-block" id="addToYourNetworkId_17"
									*ngIf="user.connectionStatus == 'ACCEPT'">
									<div class="profileact">
										<span id="inNetworkStatusId_17" style="margin-right: 10px;"><a class=""
												(click)="acceptInvite(user.invitationRequestId)"
												href="javascript: void(0);">Accept</a></span>
									</div>
								</div>
								<div class="add-network-block" id="addToYourNetworkId_22"
									*ngIf="user.connectionStatus == 'WAITING_FOR_RESPONSE'" style="margin-left: 0;">
									<div class="profileawa" style="margin: 0; border: 0">
										Awaiting Response
									</div>
								</div>
								<div class="clear"></div>
							</div>
							<div *ngIf="user.connectionStatus == 'IN_MY_NETWORK'" class="profilePageRecoNRateLinks">
								<a href="javascript:void(0);" (click)="sendMessg(user);" class="pageLink"> Send
									Message</a>
								<div class="clear"></div>
							</div>
							<div *ngIf="user.connectionStatus == 'INVITE'" class="profilePageRecoNRateLinks"
								id="addToYourNetworkId_13">
								<a href="javascript: void(0)" (click)="sendInvite(user.userDetailsId)"
									class="pageLink1 addnetlink">Add to your network</a>
							</div>
							<div *ngIf="user.connectionStatus == 'IN_MY_NETWORK'" class="profilePageRecoNRateLinks">
								<div class="profilerqs">
									<span id="reqRecLinkDiv_13"><a class="request-recommendation"
											href="javascript: void(0);"
											(click)="showRequestReco = true;reqRcmd.requestTo.userDetailsId = user.userDetailsId">
											<img border="0" width="12px" height="12px" src="assets/images/request.png"
												style="margin-right: 2px;"> Request for Recommendation</a></span>
									<span *ngIf="user.ratingStatus == 'RATING_ALREADY_SENT'"
										id="rateProfessorLinkDiv_13"><a href="javascript: void(0);"
											class="pageLink1">You have already rated this user</a></span>
									<div *ngIf="user.ratingStatus == 'SEND_RATING'">
										<span id="rateProfessorLinkDiv_13">
											<a href="javascript: void(0);" (click)="user.ratethis = true;"
												class="pageLink1">
												<img border="0" width="12px" height="12px" src="assets/images/star.png"
													style="margin-right: 2px;">
												Rate this User
											</a>
										</span>
										<div class="profilerqsbox">
											<div id="rateProfessorDiv_13" class="common" style="display: none"
												[style.display]="editDisplay(user.ratethis)">
												<input type="radio"
													(click)="rating.responseTo.userDetailsId = user.userDetailsId"
													[(ngModel)]="rating.rating.ratingText"
													name="rating.ratingText_{{user.userDetailsId}}" id="rateFair_13"
													value="FAIR">
												<label for="rateFair_13">Fair</label>&nbsp;
												<input type="radio"
													(click)="rating.responseTo.userDetailsId = user.userDetailsId"
													[(ngModel)]="rating.rating.ratingText"
													name="rating.ratingText_{{user.userDetailsId}}" id="rateGood_13"
													value="GOOD">
												<label for="rateGood_13">Good</label>&nbsp;
												<input type="radio"
													(click)="rating.responseTo.userDetailsId = user.userDetailsId"
													[(ngModel)]="rating.rating.ratingText"
													name="rating.ratingText_{{user.userDetailsId}}" id="rateBest_13"
													value="BEST">
												<label for="rateBest_13">Best</label>
												<input type="button" name="btnRate"
													(click)="user.ratethis = false;ratingUser(user)" id="btnRate"
													value="Rate" style="margin-top: 5px;">
												<input type="button" name="btnCancel" id="btnCancel"
													(click)="user.ratethis = false;" value="Cancel"
													style="margin-left: 5px; margin-top: 5px;">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!-- End Network Status -->
						<div class="clr"></div>
					</div>
					<div class="clr"></div>
				</div>
				<div class="five columns last courseatt">
					<label></label>
					<br>
					<div id="inviteForNewCourseDiv_62" *ngIf="invitecourse">
						<a class="getPopup" style="text-decoration: none; color: #0F8DBF" (click)="inviteUser(user)"
							href="javascript:void(0);" id="userName_Sujoy  Acharya_62_sujoy.acharya@gmail.com_21">Invite
							for New Session</a>
					</div>
				</div>
				<div pTooltip="View response" *ngIf="queId > 0 && invitecourse" class="five columns questionnaireAnswer">
					<a class="queBtn" [routerLink]="['../answers', user.userDetailsId, queId]" href="javascript:void(0);">Questionnaire Response</a>
				</div>
				<div class="clr"></div>
			</div>
		</div>
	</div>
	<div class="right_part">
		<div class="seven columns input-append search-div boxs boxpad">
			<input type="hidden" name="courseId" value="62">
			<input type="hidden" name="command" value="search">
			<input type="text" [(ngModel)]="searchData.searchText" name="searchData.searchText" id="searchText"
				class="span2" value="" placeholder="firstname, lastname">
			<br>
			<input type="submit" id="mySubmit" (click)="searchClick()" name="mySubmit" value="Search" width="20px">
		</div>
	</div>
	<div class="clr"></div>
</div>
<div style="display: none;" [style.display]="editDisplay(sendMsg)" class="ui-widget-overlay ui-front"></div>
<div style="display: none;" [style.display]="editDisplay(sendMsg)"
	class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
	tabindex="-1" role="dialog" aria-describedby="sendInNetworkInvite" aria-labelledby="ui-id-1"
	style="position: fixed; height: auto; width: 600px; top: 25% !important; left: 35% !important; display: block;">
	<div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"><span id="ui-id-1"
			class="ui-dialog-title">Send Message</span>
		<button class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
			role="button" aria-disabled="false" title="close" style="display: none;"><span
				class="ui-button-icon-primary ui-icon ui-icon-closethick"></span><span
				class="ui-button-text">close</span> </button>
	</div>
	<div class="ui-dialog-content ui-widget-content"
		style="width: auto; min-height: 33px; max-height: none; height: auto;" id="sendInNetworkInvite">
		<div class="seven columns last"> To: &nbsp;
			<label id="userNameDisplay" style="color: #108dd5;  text-decoration: none;">{{viewProfile.firstName}}
				{{viewProfile.middleName}} {{viewProfile.lastName}}</label>
			<br> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			<label id="userTypeInfo" class="gwt-Label profile-userType userype">
				{{viewProfile.userType}}
			</label>
			<label id="universityInfo" class="gwt-Label profile-userType userype">,
				{{viewProfile.educationalInstitute}}, {{viewProfile.campus}}</label>
		</div>
		<div class="clear"></div>
		<input type="hidden" id="userIdSend" value="22">
		<div class="two columns alpha">
			<label>Subject:</label>
			<input type="text" id="subjectForNetwork" [(ngModel)]="msgData.messageSubject"
				name="msgData.messageSubject"> </div>
		<div class="clear"></div>
		<div class="seven columns last">
			<label>Message:</label>
			<textarea [(ngModel)]="msgData.messageBody" name="msgData.messageBody" id="messageForNetwork"></textarea>
		</div>
	</div>
	<div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
		<div class="ui-dialog-buttonset">
			<button type="button" id="sendMessage" (click)="sendMessage()"
				class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" role="button"
				aria-disabled="false"><span class="ui-button-text">Send</span> </button>
			<button type="button" id="cancelSendMessage"
				class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" role="button"
				aria-disabled="false"><span (click)="sendMsg = false;" class="ui-button-text">Cancel</span> </button>
		</div>
	</div>
</div>

<div [style.display]="editDisplay(inviteCourse)"
	class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
	tabindex="-1" role="dialog" aria-describedby="errorDialog" aria-labelledby="ui-id-1"
	style="position: fixed; height: auto; width: 455px; top: 20%!important; left: 801px; display: none;">
	<div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"><span id="ui-id-1"
			class="ui-dialog-title">Invite</span>
		<button (click)="inviteCourse = false;" class=" ui-button ui-widget ui-state-default ui-corner-all
			ui-button-icon-only ui-dialog-titlebar-close" role="button" aria-disabled="false" title="close" style="line-height: 0;
			font-weight: 500;
			top: 15px;
			right: 10px;">x</button>
	</div>
	<div class="ten columns invite-pop-upc marginbottom boxpad">
		<div class="popupclose"> <img alt="Close" src="assets/images/close-button.png" id="close"> </div>
		<div class="nine columns alpha">
			<label id="courseName"></label>
			<br>
			<label><a id="profileView" href="javascript:void(0);" onclick=""><span id="createdBy"></span></a> </label>
		</div>
		<div class="clear"></div>
		<div class="clear"></div>
		<div class="seven columns last">
			<label style="display: inline-block; width: 50px;">To:</label>
			<input type="text" style="width: 260px;" [(ngModel)]="invitee" name="invitee" id="invitee">
			<input type="hidden" id="userId" value="105"> </div>
		<div class="two columns alpha">
			<label>Session:</label>
			<select id="courses" [(ngModel)]="inviteData.courseId" name="inviteData.courseName" style="width: 260px;">
				<option [value]="course.courseId" *ngFor="let course of allcourses">{{course.courseFullName}}</option>
			</select>
		</div>
		<div class="clear"></div>
		<div class="seven columns last">
			<label>Message:</label>
			<textarea name="message" [(ngModel)]="inviteData.message" name="inviteData.message" id="message"></textarea>
		</div>

		<div _ngcontent-c11="" class="ui-dialog-buttonset">
			<button _ngcontent-c11="" aria-disabled="false"
				class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" id="sendInvitation"
				(click)="sendInviteCourse()" role="button" type="button">
				<span _ngcontent-c11="" class="ui-button-text">Send</span>
			</button>
			<button _ngcontent-c11="" aria-disabled="false"
				class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
				(click)="inviteCourse = false" id="cancelz" role="button" type="button">
				<span _ngcontent-c11="" class="ui-button-text">Cancel</span>
			</button>
		</div>

		<div class="clear"></div>
	</div>
</div>
<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>