<div class="container pageblock content_area">
			<div class="sixteen columns">
				<div class="col-md-8 col_md_8">

	<menu-component></menu-component>


					<div class="row home_container">
						<div class="row">
							<div class="boxs">
								<ol class="breadcrumb" id="breadcrumb">
									<li class="home"><a href="javascript:void(0);" [routerLink]="['../home']">Home</a></li>
									<li class="arrow">&nbsp;</li>
									<li class="active">My Connections: Add Connections</li>

								</ol>
								<div class="clr"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="clear"></div>

			<div class="sixteen columns">
				<div class="twelve columns add-connections">
					<div class="two columns alpha">
						<div class="addconnleft">
							<div class="two columns">
								<div class="add-connections-social gmail">
									<a (click)="gmailClick()" href="javascript:void(0);"> <img src="assets/images/social/gmail.png" id="gmail" alt="Gmail"><span>Gmail</span>
									</a>
								</div>
							</div>
							<div class="clr"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
  <uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
