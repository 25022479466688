<header-component></header-component>
<div class="sixteen columns pageblock content_area">
    <div class="col-md-8 col_md_8">
        <form method="post" name="viewTopicForm" id="viewTopicForm">
            <input type="hidden" name="hdProfileId" id="hdProfileId">
            <input type="hidden" id="topicId" name="topicId" value="0">
            <input type="hidden" id="topicJoinId" name="topicJoinId" value="0">
            <input type="hidden" id="joinId" name="joinId" value="0">
            <input type="hidden" id="topicLeaveId" name="topicLeaveId" value="0">
            <div id="viewTopicMasterDiv">
                <div class="sixteen columns" style="margin-left: 0px;">
                    <div class="post-block inner-block profile-profile myconnections inbox viewtopic boxs boxpad">

                        <div class="uppercase profilename">
                            {{topicTO.topicName}}
                             <!--{{topicTO.topicName}}-->
                        </div>

                        <div class="topicowner">
                            <span class="gwt-Label profile-userBy userBy">by:</span>
                            <a href="javascript: void(0);" [routerLink]="['../profile']" [queryParams]="{ user: topicTO.userDetailsSummaryTO.userDetailsId }" class="fblue deco-none">
                                <span>{{topicTO.userDetailsSummaryTO.firstName}}&nbsp;{{topicTO.userDetailsSummaryTO.lastName}}</span>
                            </a>&nbsp;&nbsp; &nbsp;

                            <span class="gwt-Label profile-userBy userBy">website:</span>
                            <span><a class="fblue deco-none" href="http://maocom" target="_blank" (click)="redirectClick(topicTO.topicWebsite)">{{topicTO.topicWebsite}}</a> </span>

                            <div *ngIf="topicDetail.isSubscriberLinkToBeDisplayed == true">

                                <a href="javascript: void(0);" class="fblue deco-none" [routerLink]="['../subscribers']" [queryParams]="{ topic: topicTO.topicDetailId }">
                                            {{topicTO.topicSubscribersCount}} Subscribers
                                        </a>

                            </div>
                        </div>

                        <div class="title fgray">Discussions</div>

                        <div class="two columns inner-post-image">
                            <div class="featured-image">
                                <img id="profilePicImage_4" width="150" height="150" class="greyBorder profileImage" src="{{topicTO.topicImageUrl}}">
                            </div>
                        </div>
                        <div *ngIf="topicTO.topicDescriptionBeyondDisplayLength == false" id="descExpe_4" class="topicsummary">
                        	<div id="descExpeSpan_4" [innerHTML]="topicTO.topicDescription">
                            </div>
                        </div>
                        <div *ngIf="topicTO.topicDescriptionBeyondDisplayLength == true" id="descColl_4" class="topicsummary" style="display:none" [style.display]="editDisplay(less)">
                            <span [innerHTML]="topicTO.shortTopicDescription"></span>
                            <a (click)="morecontent()" class="moreless" style="display:none; cursor:pointer;" [style.display]="editDisplay(less)">More</a>
                        </div>
                        <div *ngIf="topicTO.topicDescriptionBeyondDisplayLength == true" id="descExpe_4" class="topicsummary" style="display: none" style="display:none" [style.display]="editDisplay(more)">
                            <span id="descExpeSpan_4" [innerHTML]="topicTO.topicDescription"></span>
                            <a (click)="lesscontent()" class="moreless" style="display:none; cursor:pointer;" [style.display]="editDisplay(more)" >Less</a>
                        </div>

                        <div class="clr"></div>
                        <div class="" id="topicButtonLikeDIV">
                            <div class="fifteen columns view-topic-icon" id="topicLikeDiv">                                                                
                                &nbsp;&nbsp;
                                <a style="cursor:pointer"><img src="assets/images/like.png" alt="Like" align="absmiddle"> {{topicTO.noOfLikes}}
                                    <label> Likes</label>
                                </a>
                            </div>
                            <div class="clr"></div>

                        </div>
                        <div class="clr"></div>
                    </div>
                </div>

                <div id="topicCommentsDiv">

                    <div class="fifteen columns boxs boxpad addcommentbox" *ngIf="topicDetail.isTopicCommentsAllowed == true">
                        <div class="two columns inner-post-image view-topic-comments">
                            <div class="featured-image">
                                <a href="#"><img width="150px" id="profilePicImage" class="greyBorder profileImage" src="{{user.profilePicName}}">
                                </a>
                            </div>
                        </div>
                        <div class="clr"></div>
                    </div>

                    <div class="cmt-wrap" *ngFor="let topiccmt of topicCmt">
                        <div class="fifteen columns boxs boxpad topiccomments">
                            <div class="two columns inner-post-image view-topic-comments">
                                <div class="featured-image">
                                    <a href="javascript: void(0);" class="fblue">
                                        <img width="150px" id="profilePicImage" class="greyBorder profileImage" src="{{topiccmt.userDetailsSummaryTO.profilePicName}}">
                                    </a>
                                </div>
                            </div>
                            <div class="ten columns">
                                <div class="topiccommentlist">
                                    <div class="topic-name">
                                        <a href="javascript: void(0);" class="fblue" [routerLink]="['../profile']" [queryParams]="{ user: topiccmt.userDetailsSummaryTO.userDetailsId }">
                                        {{topiccmt.userDetailsSummaryTO.firstName}}</a>
                                    </div>
                                    <div class="topiccommentdesc">
                                        {{topiccmt.topicComment}}
                                        <label class="topiccommentdate settopright">
                                            {{topiccmt.createdOnDateInDDMmYYYFormat}},  {{topiccmt.formattedCreatedDateForDisplay}}
                                        </label>
                                        <!-- <div class="rereplaylike" *ngIf="topicDetail.isLikeTopicAllowed == true"><img src="assets/images/like.png" alt="Like" align="absmiddle"> Like</div> -->

                                        <div class="icorply" *ngIf="topicDetail.isReplyToTopicCOmmentsAllowed == true">
                                            <a href="javascript: void(0);" (click)="topiccmt.newcomment = true;" class="fblue deco-none">Reply</a>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div class="clr"></div>
                        </div>
                        <div class="clr"></div>
                        <!-- START Child Topic Comment Div -->
                        <!-- <div *ngIf="topicCmt.topicCommentReplies!= ''"> -->
                            <div id="topicChildCommentDiv" class="topiccomments rereplay" *ngFor="let topiccmtrply of topiccmt.topicCommentReplies">

                                <div class="clr"></div>

                                    <div class="boxs boxpad rereplay">
                                        <!--child comment profile pic div  -->
                                        <div class="two columns inner-post-image view-topic-comments">
                                            <div class="featured-image">
                                                <a href="javascript: void(0);" class="fblue deco-none">
                                                    <img id="profilePicImage" class="greyBorder profileImage" src="{{topiccmtrply.userReplyingToComment.profilePicName}}">
                                                </a>
                                            </div>
                                        </div>
                                        <!--end child comment profile pic div  -->
                                        <div class=" ten columns view-topic-comment-box marginbottom">

                                            <div class="topiccommentdesc">
                                                <a href="javascript: void(0);" class="fblue deco-none" [routerLink]="['../profile']" [queryParams]="{ user: topiccmtrply.userReplyingToComment.userDetailsId }">
                                                                {{topiccmtrply.userReplyingToComment.firstName}}
                                                            </a>
                                                <div>{{topiccmtrply.topicCommentReply}}</div>
                                                <label class="topiccommentdate settopright">
                                                    {{topiccmtrply.createdOnDateInDDMmYYYFormat}}

                                                </label>
                                            </div>
                                        </div>
                                        <div class="clr"></div>
                                    </div>
                                    <div class="clr"></div>
                            </div>
                        <!-- </div> -->
                        <div id="topicChildCommentDiv" class="topiccomments rereplay" style="display:none" [style.display]="editDisplay(topiccmt.newcomment)">
                            <div class="topiccomments boxs boxpad rereplay" id="topicChildCommentReplyDiv_3">
                                <div class="two columns inner-post-image view-topic-comments">
                                    <div class="featured-image">
                                        <a href="#"><img id="profilePicImage" class="greyBorder profileImage" src="{{user.profilePicName}}"> </a>
                                    </div>
                                </div>
                                <div class=" ten columns view-topic-comment-box marginbottom">
                                    <div class="nine columns">
                                        <textarea id="child-comment-box_3" [(ngModel)]="innerCommentData.topicCommentReply" name="innerCommentData.topicCommentReply"></textarea>
                                    </div>
                                    <div class="clear"></div>
                                    <div class="three columns">
                                        <input type="button" (click)="addinnerComment(topiccmt.topicDetailId, topiccmt.topicCommentId)" class="btn-120 view-topics" name="comment-submit" value="Add Comments">
                                        <input id="cancel-viewtopic" (click)="topiccmt.newcomment = false;" class="btn-120" name="cancel-viewtopic" value="Cancel" type="button"> </div>
                                </div>
                                <div class="clr"></div>
                            </div>
                            <div class="clr"></div>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    </div>

</div>
