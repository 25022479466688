<div class="pageblock content_area">
    <div class="left_part">
        <div class="container" style="display: none;" [style.display]="editDisplay(approveClick)">
            <div class="dim">
                <div class="six columns pop-up boxpad" style="display: none;"
                    [style.display]="editDisplay(approveClick)">
                    <div class="five columns alpha popuptitle">
                        <h2>Approve</h2>
                    </div>
                    <div class="one last popupclose" (click)="rejectClick = false;"> <img id="close"
                            src="/assets/images/close-button.png" alt="Close"> </div>
                    <!--<form id="approveForm" name="approveForm" action="displayAdmin.do" method="post">-->
                    <div class="two columns" style="margin-bottom: 10px;">
                        <input type="radio" [(ngModel)]="approve_data.approvaltype" name="approve_data.approvaltype"
                            value="0" checked="checked">
                        <label class="lblblue">Once</label> &nbsp;
                        <input type="radio" [(ngModel)]="approve_data.approvaltype" name="approve_data.approvaltype"
                            value="1">
                        <label class="lblblue">Auto</label>
                    </div>
                    <div class="two columns alpha">
                        <input type="button" (click)="courseApprove()" id="submit-approve"
                            value="{{abtnText}}">&nbsp;&nbsp;
                        <input type="button" id="cancel" (click)="approveClick = false;" name="cancel" value="Cancel">
                    </div>
                    <!--</form>-->
                </div>
            </div>
        </div>
        <!-- Pop Up -->
        <div class="container" style="display: none;" [style.display]="editDisplay(rejectClick)">
            <div class="dim2">
                <div class="six columns pop-up2 marginbottom boxpad" style="display: none;"
                    [style.display]="editDisplay(rejectClick)">
                    <div class="five columns alpha popuptitle">
                        <h2>Reject</h2>
                    </div>
                    <div class="one last popupclose" (click)="rejectClick = false;"> <img id="close2"
                            src="/assets/images/close-button.png" alt="Close"> </div>
                    <!--<form id="rejectForm" name="rejectForm" action="displayAdmin.do" method="post">-->
                    <div class="five columns marginbottom" style="margin-bottom: 10px;">
                        <label>Are you sure, you want to Reject?</label>
                    </div>
                    <div class="two columns alpha">
                        <input type="button" id="submit-reject" (click)="courseReject()" name="submit-reject"
                            value="{{rbtnText}}">&nbsp;&nbsp;
                        <input type="button" id="cancel2" (click)="rejectClick = false;" name="cancel2" value="Cancel">
                    </div>
                    <!--</form>-->
                </div>
            </div>
        </div>
        <menu-component></menu-component>
        <div class="boxs">
            <div id="content-top">
                <ol id="breadcrumb" class="twelve columns alpha breadcrumb">
                    <li class="home"><a href="home.do">Home</a> </li>
                    <li class="arrow">&nbsp;</li>
                    <li>Session Admin</li>
                </ol>
                <div class="clr"></div>
                <div class="clear"></div>
            </div>
        </div>
        <div class="sixteen columns boxs">
            <table id="rt1" class="rt cf">
                <thead class="cf">
                    <tr>
                        <th>Date</th>
                        <th>Tutor</th>
                        <th>Session</th>
                        <th>Price/User</th>
                        <th class="flag">Flag</th>
                        <th>Approve/Reject</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="showmore" *ngFor="let course of courselist">
                        <td>{{course.displayStartDate}}</td>
                        <td><a [routerLink]="['../profile']"
                                [queryParams]="{ user: course.createdByUser }">{{course.createdByUserName}}</a></td>
                        <td><a [routerLink]="['../viewsession']" [queryParams]="{ sessionId: course.courseId }">
                                {{course.courseFullName}}</a></td>
                        <td *ngIf="course.cost">${{course.cost}}</td>
                        <td *ngIf="!course.cost">Free</td>
                        <td class="greenflag">{{course.severity}}</td>
                        <td><a href="javascript:void(0)" class="fapprove52"
                                (click)="approvePop(course.courseId)">Approve</a> &nbsp; <a href="javascript:void(0)"
                                class="freject52" (click)="rejectPop(course.courseId)">Reject</a></td>
                        <td>{{course.courseStatus}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="clear"></div>
        <div class="space"></div>
    </div>
    <div class="right_part">
        <div class="sixteen columns boxs boxpad grow_your_network refineresults">
            <form id="searchForm" name="searchForm" action="displayAdmin.do" method="post">
                <div class="filterwrap">
                    <div class="title fblue pad15"> Filter
                        <label>Criteria</label>: </div>
                    <div class="clear"></div>
                    <div class="three columns alpha">
                        <label for="filter-date"></label>
                        <input type="text" id="date" [(ngModel)]="searchdata.date" name="searchdata.date" value=""
                            class="formelement form-group" placeholder="Date"> </div>
                    <div class="three columns inptselect">
                        <!-- <label for="fstatus">Status:</label><br/> -->
                        <label for="fstatus">Status:</label>
                        <select [(ngModel)]="searchdata.status" name="searchdata.status" id="fstatus">
                            <!-- <option value="Status">Status</option> -->
                            <option value="2">Submitted</option>
                            <option value="3">Approved</option>
                            <option value="4">Rejected</option>
                            <option value="5">Expired</option>
                        </select>
                    </div>
                    <div class="nine columns last">
                        <!-- <label for="course">Course:</label><br/> -->
                        <input type="text" id="keyword" [(ngModel)]="searchdata.keyword" name="searchdata.keyword"
                            value="" class="formelement form-group" placeholder="Session"> </div>
                    <div class="clear"></div>
                    <div class="three columns alpha">
                        <!-- <label for="first-name">First Name:</label><br/> -->
                        <input type="text" id="fname" [(ngModel)]="searchdata.fname" name="searchdata.fname" value=""
                            class="formelement form-group" placeholder="First Name"> </div>
                    <div class="three columns">
                        <!-- <label for="last-name">Last Name:</label><br/> -->
                        <input type="text" id="lname" [(ngModel)]="searchdata.lname" name="searchdata.lname" value=""
                            class="formelement form-group" placeholder="Last Name"> </div>
                    <div class="three columns last">
                        <!-- <label for="last-name" style="color: #ffffff;">Hidden:</label><br/> -->
                        <input type="submit" id="filter-search" (click)="courseSearch()" value="Go"
                            placeholder="Hidden"> </div>
                    <div class="clear"></div>
                </div>
            </form>
        </div>
    </div>
    <div class="clr"></div>
</div>
<div [style.display]="editDisplay(popUp)"
    class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
    tabindex="-1" role="dialog" aria-describedby="errorDialog" aria-labelledby="ui-id-1"
    style="position: fixed; height: auto; width: 300px; top: auto; left: 801px; display: none;">
    <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"><span id="ui-id-1"
            class="ui-dialog-title">{{msgTitle}}</span><button
            class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
            role="button" aria-disabled="false" title="close" style="display: none;"><span
                class="ui-button-icon-primary ui-icon ui-icon-closethick"></span><span class="ui-button-text"
                (click)="popUp = false;">close</span></button></div>
    <div class="ui-dialog-content ui-widget-content"
        style="width: auto; min-height: 33px; max-height: none; height: auto;">
        <ul>
            <li>{{msgContent}}</li>
        </ul>
    </div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <div class="ui-dialog-buttonset"><button type="button"
                class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" (click)="popUp = false;"
                role="button" aria-disabled="false"><span class="ui-button-text">Close</span></button></div>
    </div>
    <div class="ui-resizable-handle ui-resizable-n" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-e" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-s" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-w" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-sw" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-ne" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-nw" style="z-index: 90;"></div>
</div>