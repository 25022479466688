<div class="section1">
    <div class="signupstep1">
        <div class="signupstep1form newloginform">
            <form name="form" (ngSubmit)="f.form.valid && register()" #f="ngForm" novalidate>
                <div class="posres">
                    <input type="text" class="textbox" name="model.firstName" [(ngModel)]="model.firstName"
                        #firstName="ngModel" required id="firstName" size="28" maxlength="25" autocomplete="off"
                        value="" placeholder="First Name">
                    <div *ngIf="(f.submitted && !firstName.valid) || (!firstName.valid && firstName.touched)"
                        class="errorDiv">First Name is required</div>
                </div>
                <div class="posres">
                    <input type="text" class="textbox" name="model.lastName" [(ngModel)]="model.lastName"
                        #lastName="ngModel" required id="lastName" size="28" maxlength="25" autocomplete="off" value=""
                        placeholder="Last Name">
                    <div *ngIf="(f.submitted && !lastName.valid) || (!lastName.valid && lastName.touched)"
                        class="errorDiv">Last Name is required</div>
                </div>
                <div class="posres">
                    <input type="email" class="textbox" name="model.emailId" [(ngModel)]="model.emailId"
                        #emailId="ngModel" required id="emailId1" size="28" maxlength="50" autocomplete="off" value=""
                        placeholder="Email Id" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                    <div *ngIf="(f.submitted && !emailId.valid) || (!emailId.valid && emailId.touched)"
                        class="errorDiv">Please enter an emailId of your choice.</div>
                    <div *ngIf="(f.submitted && emailId.errors) && (f.submitted && emailId.errors.pattern)">
                        Email is invalid
                    </div>
                </div>

                <div class="clear"></div>
                <div class="posres">
                    <input type="password" class="textbox" name="model.userCredential.plainTextPassword"
                        [(ngModel)]="model.userCredential.plainTextPassword" #password="ngModel" required id="pass"
                        minlength="8" autocomplete="off" placeholder="Password">
                    <div *ngIf="(f.submitted && !password.valid) || (!password.valid && password.touched)"
                        class="errorDiv">Password is required</div>
                </div>
                <ng2-password-strength-bar [passwordToCheck]="model.userCredential.plainTextPassword"
                    [barLabel]="barLabel">
                </ng2-password-strength-bar>
                <div class="posres">
                    <input type="password" class="textbox" [(ngModel)]="cplainTextPassword" name="model.confirmPassword"
                        #confirmPassword="ngModel" required id="confirmPassword" minlength="8" autocomplete="off"
                        placeholder="Confirm Password">
                    <div *ngIf="(f.submitted && password.value != confirmPassword.value) || (password.value != confirmPassword.value && confirmPassword.touched)"
                        class="errorDiv">Password do not match.</div>
                    <div *ngIf="(f.submitted && password.value == confirmPassword.value) || (password.value == confirmPassword.value && confirmPassword.touched)"
                        class="errorDiv successDiv">Password matched.</div>
                    <div *ngIf="(f.submitted && !confirmPassword.valid) || (!confirmPassword.valid && confirmPassword.touched)"
                        class="errorDiv">Please re-type the password here</div>
                </div>


                <!-- Hide END -->
                <div class="posres">
                    <input type="checkbox" id="termsAndConditions" name="termsconditions" required
                        [(ngModel)]="termsconditions" #tc="ngModel">
                    <label for="termsAndConditions">I agree to <a href="legal/termsandconditions.html"
                            target="_blank">Terms and Conditions</a></label>
                    <div *ngIf="(f.submitted && !tc.valid) || (!tc.valid && tc.touched)" class="errorDiv">Please read
                        and accept the Terms and Conditions</div>
                </div>
                <br>

                <div class="posres">
                    <re-captcha [(ngModel)]="model.recaptchaToken" name="model.recaptchaToken" required
                        siteKey="6LcR5PwSAAAAAExCXrkpFPC4oT5oRtdf4uqhfLVG"></re-captcha>
                    <div *ngIf="(f.submitted && model.recaptchaToken == '') || (f.submitted && model.recaptchaToken == null)"
                        class="resendForgetCaptchaErrorDiv">Please select the Captcha as advised</div>
                </div>
                <br>

                <input type="submit" class="btn" name="btnSubmit" id="btnSubmit" value="Submit">
            </form>
        </div>
    </div>
</div>
<div class="popupOverlay" *ngIf="showErrorModalDialog"></div>
<uvsity-modal-popup [isModalDialogToBeDisplayed]="showErrorModalDialog" [messageTitle]="showErrorMessageTitle"
    [messages]="showErrorMessages" (uvsityModalPopupClosed)="closeErrorPopup()"
    [popupWidthPercentage]="showErrorPopupWidthInPercentage" [isSignUp]="isSignUp" [isValidationError]="isValidationError">
</uvsity-modal-popup>