<header>
  <div class="header">
    <div class="logo"></div>
    <div class="pageblock">

      <ul id="filter2" class="filters usermenu">
        <li class="parent">

        </li>
      </ul>
      <div class="clr"></div>
    </div>
  </div>

  <div id="errorDialog" title="Error !!!"></div>
</header>

<!-- END Header -->

<div id="content-wrapper2 newloginform">

  <form name="form" (ngSubmit)="f.form.valid && register2()" #f="ngForm" novalidate autocomplete="not-off">
    <div class="clear"></div>
    <div class="sixteen columns marginbottom registration login-box">
      <h2 class="uppercase">Registration <span>Step - II</span> </h2>
      <div class="boxs boxpad">

        <div class="four columns alpha inptselect posres">

          <select name="userAddresses.countryTO.countryId" (change)="onChange(countryTO.value)"
            [(ngModel)]="userAddresses.countryTO.countryId" #countryTO="ngModel" required id="country">
            <option [value]="null">Select Country</option>
            <option [value]="country.countryId" *ngFor="let country of countries">{{country.countryFullName}}</option>
          </select>
          <div *ngIf="f.submitted && !countryTO.valid" class="errorDiv">Please select the Country</div>
        </div>
        <div class="clear"></div>

        <div class="four columns alpha posres">
          <input type="text" name="userAddresses.city" [(ngModel)]="userAddresses.city" #city="ngModel" id="city"
            maxlength="200" value="" required placeholder="City*" minLength=2 style="{'margin-top':'35px'}">
          <div *ngIf="f.submitted && !city.valid" class="errorDiv">Please enter a value for the City.</div>
        </div>
        <div class="clear"></div>

        <!-- START User Sub Category DIV -->
        <div class="eight columns alpha posres" id="userSubDiv" style="display:block;">
          <p-autoComplete name="userSubType.userSubTypeMaster" [suggestions]="usertyperesults"
            (completeMethod)="searchusertype($event)" [(ngModel)]="userSubType.userSubTypeMaster" required
            #userSubTypeMaster="ngModel" id="userSubTypeMaster" size="28" maxlength="200"
            placeholder="eg. Professor/Student/Alumni/Software Engnr.. *" [inputStyle]="{'margin-top':'5px'}" value=""
            minLength=2 delay=300>
          </p-autoComplete>
          <div *ngIf="f.submitted && !userSubTypeMaster.valid" class="errorDiv">Please select the type of user that
            describes you best from the choices available.</div>
        </div>
        <div class="clear"></div>
        <!-- END User Sub Category DIV -->

        <div *ngIf="isCorporateUser == false">
          <div class="eight columns alpha posres" id="subjectDiv" style="display:block;">
            <input type="text" name="userEducation.userEducationSubject"
              [(ngModel)]="userEducation.userEducationSubject" #userEducationSubject="ngModel" id="subject" size="28"
              required maxlength="50" placeholder="Subject (Computers , Electronics, Physics)*" value=""
              style="margin-top: 35px; margin-bottom:0px;">
            <div *ngIf="!isCorporateUser && f.submitted && !userEducationSubject.valid" class="errorDiv">
              Please enter the area of your specialization.
            </div>
          </div>
          <div class="clear"></div>
        </div>

        <div class="eight columns alpha posres" *ngIf="isCorporateUser == false">
          <p-autoComplete name="userEducation.userEducationEducationInstitution" [suggestions]="eduInstresults"
            (completeMethod)="searcheduinst($event)" required
            [(ngModel)]="userEducation.userEducationEducationInstitution" #userEducationEducationInstitution="ngModel"
            id="educationInstitution" class="span2" size="28" maxlength="100" value=""
            placeholder="University/School/College*" [inputStyle]="{'margin-top':'35px'}" minLength=2 delay=50>
          </p-autoComplete>
          <div *ngIf="!isCorporateUser && f.submitted && !userEducationEducationInstitution.valid" class="errorDiv">
            Please chose your University.
          </div>
        </div>
        <div class="eight columns alpha posres" *ngIf="isCorporateUser == true">
          <p-autoComplete name="userEducation.userEducationEducationInstitution" [suggestions]="eduInstresults"
            (completeMethod)="searcheduinst($event)" required
            [(ngModel)]="userEducation.userEducationEducationInstitution" #organization="ngModel"
            id="educationInstitution" class="span2" size="28" maxlength="100" value="" placeholder="Organization*"
            [inputStyle]="{'margin-top':'35px'}" minLength=2 delay=50>
          </p-autoComplete>
          <div *ngIf="isCorporateUser && f.submitted && !organization.valid" class="errorDiv">
            Please chose your Organization.
          </div>
        </div>
        <div class="clear"></div>

        <div class="eight columns alpha posres" *ngIf="isCorporateUser == false">
          <input type="text" name="userEducation.userEducationCampus" [(ngModel)]="userEducation.userEducationCampus"
            #userEducationCampus="ngModel" id="campus" size="28" maxlength="50" value=""
            placeholder="Campus. (Optional)" style="margin-top: 35px; margin-bottom:0px;">
        </div>
        <div class="clear"></div>

        <div *ngIf="isCorporateUser == false">
          <div class="eight columns alpha posres" id="degreeCourseDiv">
            <input type="text" name="userEducation.userEducationDegreeCourse"
              [(ngModel)]="userEducation.userEducationDegreeCourse" #userEducationDegreeCourse="ngModel"
              id="degreeCourse" size="28" required maxlength="50" value="" placeholder="Master, Bachelor, Diploma etc*"
              style="margin-top: 25px; margin-bottom:0px;">
            <div *ngIf="!isCorporateUser && f.submitted && !userEducationDegreeCourse.valid" class="errorDiv">Please
              enter your Degree</div>
          </div>
          <div class="clear"></div>
        </div>

        <div class="eight columns alpha posres" *ngIf="isCorporateUser == true">
          <div class="three columns alpha inptselect form-group block_left" style="margin-top: 20px;">
            <label>From Month</label><br>
            <p-dropdown id="startDateFromMonth" [options]="frommonths" name="workExpStartDateStartMonth" tabindex="5"
              [(ngModel)]="workExpStartDateStartMonth">
            </p-dropdown>
            <input type="text" id="fromyear" [(ngModel)]="fromYear" name="fromYear" placeholder="From Year" value=""
              style="position:absolute;margin-left: 10px;width: 30%;">
          </div>
        </div>
        <div class="clear" *ngIf="isCorporateUser == true"></div>
        <div class="eight columns alpha posres" id="toSelectIdPast" *ngIf="isCorporateUser == true"
          [hidden]="isPresent ">
          <div class="three columns alpha inptselect form-group block_left" style="margin-top: 20px;">
            <label>To Month</label><br>
            <p-dropdown id="toDateFromMonth" [options]="tomonths" name="workExpEndDateStartMonth" tabindex="6"
              [(ngModel)]="workExpEndDateStartMonth">
            </p-dropdown>
            <input type="text" id="toYear" [(ngModel)]="toYear" name="toYear" placeholder="To Year" value=""
              style="position: absolute;width: 30%;margin-left: 10px;">

          </div>
        </div>
        <div class="eight columns alpha posres" style="margin-top: 20px;">
          <input type="checkbox" id="presentPast" [(ngModel)]="isPresent" name="isPresent" value="true"
            style="position: relative;margin-left: 0%;">
          <span style="position: absolute;margin-left: 17px;">Present</span>
        </div>

        <div class="clear" *ngIf="isCorporateUser == true"></div>

        <div class="four columns alpha" *ngIf="isCorporateUser == false">
          <input type="submit" name="btnSubmit" id="btnSubmit" value="Save" class="buttonStyle"
            style="margin-top: 25px;">
        </div>
        <div class="four columns alpha" *ngIf="isCorporateUser == true">
          <input type="submit" name="btnSubmit" id="btnSubmit" value="Save" class="buttonStyle"
            style="margin-top: 5px;">
        </div>

      </div>
    </div>

    <div class="clear"></div>
  </form>
</div>

<!-- BEGIN Footer -->
<div id="footer-wrapper" class="footer-part pageblock" style="display: none;">
  <footer class="boxs">
    <div class="footer-menu footer-left">
      <ul>
        <li class="first"><a href="faq.html" target="_blank">Help Center</a></li>
      </ul>
    </div>
    <div class="footer-menu footer-right">
      <ul>
        <li class="first"><a href="javascript:void(0)">UVSiTY Corporation © 2022</a></li>
        <li><a href="useragreement.html" target="_blank">User Agreement</a></li>
        <li><a href="privacypolicy.html" target="_blank">Privacy Policy</a></li>
        <li><a href="contactus.html" target="_blank">Contact Us</a></li>
      </ul>
    </div>
    <div class="clear"></div>

  </footer>
</div>
<!-- END Footer -->
<div id="AutocompleteContainter_acdb7" style="position: absolute; z-index: 9999; top: 412.5px; left: 603px;">
  <div class="autocomplete-w1">
    <div class="autocomplete" id="Autocomplete_acdb7" style="display: none; width: 300px; max-height: 300px;"></div>
  </div>
</div>
<div id="AutocompleteContainter_31ae4" style="position: absolute; z-index: 9999; top: 544.5px; left: 603px;">
  <div class="autocomplete-w1">
    <div class="autocomplete" id="Autocomplete_31ae4" style="display: none; width: 300px; max-height: 300px;">

    </div>
  </div>
</div>
<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>
<uvsity-modal-popup [isModalDialogToBeDisplayed]="showErrorModalDialog" [messageTitle]="showErrorMessageTitle"
  [messages]="showErrorMessages" (uvsityModalPopupClosed)="closeErrorPopup()"
  [popupWidthPercentage]="showErrorPopupWidthInPercentage" [isValidationError]="isValidationError">
</uvsity-modal-popup>
