<div class="step2PopUpWrap" *ngIf="isSignUpTwoModalDialogToBeDisplayed">
  <div class="step2PopUp">
     <form name="form" (ngSubmit)="f.form.valid && register2(f)" #f="ngForm" novalidate autocomplete="not-off">
        <div class="step2PopUpForm">

           <!-- START User Sub Category DIV -->
           <div class="autoCompleteInput col50">
              <p-autoComplete name="userSubType.userSubTypeMaster" [suggestions]="usertyperesults"
                 (completeMethod)="searchusertype($event)" [(ngModel)]="userSubType.userSubTypeMaster" required
                 #userSubTypeMaster="ngModel" id="userSubTypeMaster" size="28" maxlength="200"
                 placeholder="Software engg./Consulatnt/Student/Professor *" value="" minLength=2 delay=300>
              </p-autoComplete>
              <div *ngIf="f.submitted && !userSubTypeMaster.valid" class="errorDiv">Please select the type of
                 user that describes you best from the choices available.</div>
           </div>
           <!-- END User Sub Category DIV -->

           <div class="col50" *ngIf="isCorporateUser == false">
              <div class="textInput" id="subjectDiv">
                 <input type="text" name="userEducation.userEducationSubject"
                    [(ngModel)]="userEducation.userEducationSubject" #userEducationSubject="ngModel" id="subject"
                    size="28" required maxlength="50" placeholder="Subject (Computers , Electronics, Physics)*"
                    value="" style="margin-bottom:0px;">
                 <div *ngIf="!isCorporateUser && f.submitted && !userEducationSubject.valid" class="errorDiv">
                    Please enter the area of your specialization.
                 </div>
              </div>
           </div>

           <div class="textInput col50" *ngIf="isCorporateUser == false">
              <p-autoComplete name="userEducation.userEducationEducationInstitution" [suggestions]="eduInstresults"
                 (completeMethod)="searcheduinst($event)" required
                 [(ngModel)]="userEducation.userEducationEducationInstitution"
                 #userEducationEducationInstitution="ngModel" id="educationInstitution" class="span2" size="28"
                 maxlength="100" value="" placeholder="University/School/College*" minLength=2 delay=50>
              </p-autoComplete>
              <div *ngIf="!isCorporateUser && f.submitted && !userEducationEducationInstitution.valid"
                 class="errorDiv">
                 Please chose your University.
              </div>
           </div>
           <div class="textInput col50" *ngIf="isCorporateUser == true">
              <p-autoComplete name="userEducation.userEducationEducationInstitution" [suggestions]="eduInstresults"
                 (completeMethod)="searcheduinst($event)" required
                 [(ngModel)]="userEducation.userEducationEducationInstitution" #organization="ngModel"
                 id="educationInstitution" class="span2" size="28" maxlength="100" value=""
                 placeholder="Organization*" minLength=2 delay=50>
              </p-autoComplete>
              <div *ngIf="isCorporateUser && f.submitted && !organization.valid" class="errorDiv">
                 Please chose your Organization.
              </div>
           </div>

           <div class="col50" *ngIf="isCorporateUser == false">
              <div class="textInput">
                 <input type="text" name="userEducation.userEducationDegreeCourse"
                    [(ngModel)]="userEducation.userEducationDegreeCourse" #userEducationDegreeCourse="ngModel"
                    id="degreeCourse" size="28" required maxlength="50" value=""
                    placeholder="Master, Bachelor, Diploma etc*">
                 <div *ngIf="!isCorporateUser && f.submitted && !userEducationDegreeCourse.valid" class="errorDiv">
                    Please enter your Degree</div>
              </div>
           </div>

           <div class="dropdownInput col50" *ngIf="isCorporateUser == true">
              <div class="three columns alpha inptselect form-group block_left">
                 <label>From Month</label><br>

                 <div class="monthWrap">

                    <input type="text" id="startDateFromMonth" required readonly
                       (click)="fromMonthCal = true; toMonthCal = false;" name="workExpStartDateStartMonthLabel"
                       placeholder="Select Month" [(ngModel)]="workExpStartDateStartMonthLabel">
                    <ul class="monthUl" *ngIf="fromMonthCal">
                       <li class="monthLi" *ngFor="let m of frommonths" (click)="clickLi(m, 'from');">{{m.label}}
                       </li>
                    </ul>

                 </div>
                 <input type="text" id="fromyear" [(ngModel)]="fromYear" name="fromYear" placeholder="From Year"
                    value="" style="position:absolute;margin-left: 0;width: 23%;">
              </div>
           </div>
           <div class="dropdownInput col50" id="toSelectIdPast" *ngIf="isCorporateUser == true && !isPresent"
              [hidden]="isPresent ">
              <div class="three columns alpha inptselect form-group block_left">
                 <label>To Month</label><br>
                 <div class="monthWrap">

                    <input type="text" id="toDateFromMonth" required readonly
                       (click)="fromMonthCal = false; toMonthCal = true;" name="workExpEndDateStartMonthLabel"
                       placeholder="Select Month" [(ngModel)]="workExpEndDateStartMonthLabel">
                    <ul class="monthUl" *ngIf="toMonthCal">
                       <li class="monthLi" *ngFor="let m of tomonths" (click)="clickLi(m, 'to');">{{m.label}}
                       </li>
                    </ul>

                 </div>
                 <!-- <p-dropdown id="toDateFromMonth" [options]="tomonths" name="workExpEndDateStartMonth" tabindex="6"
                    [(ngModel)]="workExpEndDateStartMonth">
                 </p-dropdown> -->
                 <input type="text" id="toYear" [(ngModel)]="toYear" name="toYear" placeholder="To Year" value=""
                    style="position: absolute;width: 23%;">

              </div>
           </div>
           <div class="textInputcheckbox" *ngIf="isCorporateUser == true">
              <input type="checkbox" id="presentPast" [(ngModel)]="isPresent" name="isPresent" value="true"
                 style="position: relative;margin-left: 0%;">
              <span style="position: absolute;margin-left: 17px;">Present</span>
           </div>

           <div class="selectInput col50">
              <select name="userAddresses.countryTO.countryId" (change)="onChange(countryTO.value)"
                 [(ngModel)]="userAddresses.countryTO.countryId" #countryTO="ngModel" required id="country">
                 <option [value]="null">Select Country</option>
                 <option [value]="country.countryId" *ngFor="let country of countries">
                    {{country.countryFullName}}</option>
              </select>
              <div *ngIf="f.submitted && !countryTO.valid" class="errorDiv">Please select the Country</div>
           </div>
           <div class="textInput col50">
              <input type="text" name="userAddresses.city" [(ngModel)]="userAddresses.city" #city="ngModel"
                 id="city" maxlength="200" value="" required placeholder="City*" minLength=2>
              <div *ngIf="f.submitted && !city.valid" class="errorDiv">Please enter a value for the City.</div>
           </div>

           <div class="clear" *ngIf="isCorporateUser == true"></div>

           <div class="textInputButton col50" *ngIf="isCorporateUser == false">
              <input type="submit" name="btnSubmit" id="btnSubmit" value="Save" class="buttonStyle">
           </div>
           <div class="textInputButton col50" *ngIf="isCorporateUser == true">
              <input type="submit" name="btnSubmit" id="btnSubmit" value="Save" class="buttonStyle">
           </div>
           <div class="textInputButton col50" style="vertical-align: top;">
              <a (click)="handleModalPopupClose(false); f.submitted = false" style="border-radius: 2px;padding: 8px;" class="buttonStyle">Cancel</a>
           </div>

        </div>
     </form>
  </div>
</div>