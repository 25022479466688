<div class="pageblock content_area">
    <div class="sixteen columns">
        <div class="col-md-8 col_md_8">
            <menu-component></menu-component>
            <div class="row home_container">
                <div class="row">
                    <div class="boxs">
                        <ol class="breadcrumb" id="breadcrumb">
                            <li class="home"><a href="javascript:void(0);" [routerLink]="['../home']">Home</a> </li>
                            <li class="arrow">&nbsp;</li>
                            <li class="active"> Messages </li>
                        </ol>
                        <div class="clr"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clear"></div>
    <!-- Left Section -->
    <div class="left_section">
        <div class="boxs">
            <aside class="four columns">
                <form>
                    <div class="widget" style="margin-top: 0px;">
                        <ul class="toggle maillinklist">
                            <li class="compose" [ngClass]="{'active': composemail === true}"><a class="compose"
                                    (click)="trashLi = false; sent = false; inbox = false; composemail = true; msgdetail = false;">Compose
                                    Message</a>
                                <div>
                                    <ul class="archives" style="display: none;"
                                        [style.display]="editDisplay(composemail)">
                                        <li><a class="pageLink4"
                                                (click)="msgsent = false; rcmdreceived = false; writearcmd = false;
                                          reqforrate = false; reqreceived = false; msgreceived = false; reqrecmd = false; mymsg = false;
                                          newmsg = true; reqsent = false; rcmdreqsent = false; msgdetail = false; trashh = false;"
                                                href="javascript: void(0);">New Message</a> </li>
                                        <li><a class="pageLink4"
                                                (click)="msgsent = false; rcmdreceived = false; writearcmd = false;
                                          reqforrate = false; reqreceived = false; msgreceived = false; reqrecmd = true; mymsg = false;
                                          newmsg = false; reqsent = false; rcmdreqsent = false; msgdetail = false; trashh = false;"
                                                href="javascript: void(0);">Request for Recommendation</a> </li>
                                        <li><a class="pageLink4"
                                                (click)="msgsent = false; rcmdreceived = false; writearcmd = false;
                                          reqforrate = true; reqreceived = false; msgreceived = false; reqrecmd = false; mymsg = false;
                                          newmsg = false; reqsent = false; rcmdreqsent = false; msgdetail = false;trashh = false;"
                                                href="javascript: void(0);">Request for Rating</a> </li>
                                        <li><a class="pageLink4" (click)="msgsent = false; rcmdreceived = false; writearcmd = true;
                                           reqforrate = false; reqreceived = false; msgreceived = false; reqrecmd = false;
                                           mymsg = false; newmsg = false; reqsent = false; rcmdreqsent = false; msgdetail = false;
                                           trashh = false;" href="javascript: void(0);">Write a Recommendation</a>
                                        </li>
                                    </ul>
                                    <div class="clear"></div>
                                </div>
                            </li>
                            <li class="inbox" [ngClass]="{'active': inbox === true}"><a class="inbox"
                                    (click)="trashLi = false; sent = false; composemail = false; inbox = true;">Inbox
                                    <sup id="totalNoOfNewNotificationsBubbleCount"
                                        *ngIf="isTotalNoOfNewNotificationsBubbleRequired == true">{{totalNoOfNewNotifications}}</sup>
                                </a>
                                <div>
                                    <ul class="archives" style="display: block;" [style.display]="editDisplay(inbox)">
                                        <li><a class="pageLink4" href="javascript: void(0);" (click)="msgsent = false; rcmdreceived = false;
                                          writearcmd = false; msgRcv(); reqreceived = false; reqforrate = false; msgreceived = true;
                                          reqrecmd = false; mymsg = false; newmsg = false; reqsent = false; rcmdreqsent = false;
                                          msgdetail = false;trashh = false;"> Messages Received
                                                <sup *ngIf="isTotalNoOfNewMessagesReceivedBubbleRequired == true"
                                                    id="newMessagesReceivedBubbleCount">
                                                    {{totalNoOfNewMessagesReceived}}
                                                </sup>
                                            </a> </li>
                                        <li><a class="pageLink4" href="javascript: void(0);" (click)="msgsent = false; rcmdreceived = false;
                                              reqRcv(); writearcmd = false; reqreceived = true; reqforrate = false; msgreceived = false;
                                              reqrecmd = false; mymsg = false; newmsg = false; reqsent = false; rcmdreqsent = false;
                                              msgdetail = false;trashh = false;"> Requests Received <sup
                                                    *ngIf="isTotalNoOfNewRequestsReceivedBubbleRequired == true"
                                                    id="requestsReceivedBubbleCount">{{totalNoOfNewRequestsReceived}}</sup>
                                            </a> </li>
                                        <li><a class="pageLink4" href="javascript: void(0);" (click)="msgsent = false; rcmdreceived = true;
                                                       rcmdRcv(); writearcmd = false; reqforrate = false; reqreceived = false; msgreceived = false;
                                                       reqrecmd = false; mymsg = false; newmsg = false; reqsent = false; rcmdreqsent = false;
                                                       msgdetail = false;trashh = false;">
                                                Recommendations Received <sup
                                                    *ngIf="isTotalNoOfNewRecommendationsReceivedBubbleRequired == true"
                                                    id="recommendationsReceivedBubbleCount">{{totalNoOfNewRecommendationsReceived}}</sup>
                                            </a> </li>
                                    </ul>
                                    <div class="clear"></div>
                                </div>
                            </li>
                            <li class="sent" [ngClass]="{'active': sent === true}"><a class="sent"
                                    (click)="trashLi = false; inbox = false; composemail = false; sent = true;">Sent</a>
                                <div>
                                    <ul class="archives" style="display: none;" [style.display]="editDisplay(sent)">
                                        <li><a href="javascript: void(0);" (click)="msgsent = true; messageSent(); rcmdreceived = false;
                                          writearcmd = false; reqreceived = false; reqforrate = false; msgreceived = false; reqrecmd = false;
                                          mymsg = false; newmsg = false; reqsent = false; rcmdreqsent = false; msgdetail = false;
                                          trashh = false;">Message Sent</a> </li>
                                        <li><a href="javascript: void(0);" (click)="msgsent = false; rcmdreceived = false; requestsent();
                                          writearcmd = false; reqforrate = false; reqreceived = false; msgreceived = false; reqrecmd = false;
                                          mymsg = false; newmsg = false; reqsent = true; rcmdreqsent = false; msgdetail = false;
                                          trashh = false;">Request Sent</a> </li>
                                        <li><a class="pageLink4" href="javascript: void(0);" (click)="msgsent = false; rcmdreceived = false;
                                          rcmdrespsent(); writearcmd = false; reqforrate = false; reqreceived = false; msgreceived = false;
                                          reqrecmd = false; mymsg = false; newmsg = false; reqsent = false; rcmdreqsent = true; msgdetail = false;
                                          trashh = false;">
                                                Recommendation Sent</a> </li>
                                    </ul>
                                    <div class="clear"></div>
                                </div>
                            </li>
                            <li class="trashLi" [ngClass]="{'active': trashLi === true}"><a class="trashLi"
                                    (click)="sent = false; inbox = false; composemail = false; trashLi = true; msgdetail = false;">Trash</a>
                                <div>
                                    <ul class="archives" style="display: none;" [style.display]="editDisplay(trashLi)">
                                        <li><a href="javascript: void(0);" (click)="trash(); msgsent = false; rcmdreceived = false; writearcmd = false; reqforrate = false;
                                          reqreceived = false; msgreceived = false; reqrecmd = false; mymsg = false; newmsg = false; trashh = true;
                                          reqsent = false; rcmdreqsent = false; msgdetail = false;">Trash</a> </li>
                                    </ul>
                                    <div class="clear"></div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </form>
            </aside>
        </div>
    </div>
    <!-- Content -->
    <!--  ADD EXTRA DIV-->
    <!-- ------------------------------------------------------------------------------------------------- -->
    <div id="inboxCommonPage relative">
        <div class="loader" [style.display]="editDisplay(composeloading)"><img src="assets/images/ajax-loader.gif" />
        </div>
        <div class="right_section" id="ngnewmessage" style="display:none" [style.display]="editDisplay(newmsg)">
            <div class="twelve columns shad-left boxs">
                <div class="boxpad">
                    <input type="hidden" name="hdCurrPage" id="hdCurrPage" value="New Message">
                    <div class="common errorDiv" id="serverSideErrorsDiv"></div>
                    <div id="selectedUserTemplate" style="display: none;">
                        <div class="selectedUserName">#userName#</div>
                        <div class="selectedClose"> X</div>
                    </div>
                    <div class="eleven columns post-block inner-block profile-profile myconnections inbox"
                        style="display: none;" id="responseMessage"></div>
                    <div class="eleven columns post-block inner-block profile-profile myconnections inbox" id="message">
                        <form #frmsendMsg="ngForm">
                            <div>
                                <label for="requestTo" class="lblblue">To</label>
                            </div>
                            <div class="ten columns" id="requestToOuterDiv"
                                style="border: 1px solid #0F8DBF; margin-bottom: 15px;">
                                <div id="selected_requestTo" style="margin: 0 5px;">
                                    <div class="clr"></div>
                                </div>
                                <div class="selectedOuter"
                                    style="padding: 0px; border: 0px none; background-color: #FFFFFF; margin: 0px;">
                                    <!--<input id="requestTo" type="text" [(ngModel)]="compose.messageto" name="compose.messageto" maxlength="100" autocomplete="off" style="border: none; margin: 0px; outline: 0px;" size="1">-->
                                    <p-autoComplete field="userName" name="userTo.messageto" [multiple]="true"
                                        [suggestions]="userToresults" (completeMethod)="searchusers($event)"
                                        [(ngModel)]="userTo.messageto" required minLength=2 delay=200>

                                    </p-autoComplete>
                                </div>
                                <div class="clr"></div>
                            </div>
                            <div class="clr"></div>
                            <div class="clr"></div>
                            <div class="ten columns">
                                <label for="subject" class="lblblue">Subject</label>
                                <br>
                                <input type="text" [(ngModel)]="compose.messageSubject" name="compose.messageSubject"
                                    id="subject" size="50" maxlength="100" autocomplete="off"> </div>
                            <div class="clear"></div>
                            <div id="subject">
                                <div class="ten columns">
                                    <label for="message" class="lblblue">Message</label>
                                    <textarea [(ngModel)]="compose.messageBody" name="compose.messageBody" id="message"
                                        rows="4" cols="80"></textarea>
                                </div>
                                <div id="update_message" class="ten columns" style="width: 92%; text-align: right;">
                                    <span id="charCount_message"></span>&nbsp; chars left </div>
                                <div class="clear"></div>
                                <div class="three columns last">
                                    <input type="button" (click)="sendMessage(frmsendMsg.form)" id="btnSend"
                                        class="btn-120" value="Send"> <a href="javascript: void(0);"
                                        (click)="trashLi = false; sent = false; composemail = false; inbox = true; msgsent = false; rcmdreceived = false; writearcmd = false; msgRcv(); reqreceived = false; reqforrate = false; msgreceived = true; reqrecmd = false; mymsg = false; newmsg = false; reqsent = false; rcmdreqsent = false; msgdetail = false;trashh = false;"
                                        class="pageLink4 btnstyle btn-120">Cancel</a> </div>
                            </div>
                            <div class="clear"></div>
                        </form>
                        <div class="clr"></div>
                    </div>
                </div>
                <div class="clr"></div>
            </div>
        </div>
        <div class="right_section" id="reqrecmd" style="display:none" [style.display]="editDisplay(reqrecmd)">
            <div class="shad-left boxs newloginform">
                <form id="frmRequestRecommendation" (ngSubmit)="sendRcmdReq(frmReqRcm.form);"
                    name="frmRequestRecommendation" #frmReqRcm="ngForm" novalidate>
                    <div class="boxpad">
                        <div id="selectedUserTemplate" style="display: none;">
                            <div class="selectedUserName">#userName#</div>
                            <div class="selectedClose">X</div>
                        </div>
                        <div class="twelve columns shad-left">
                            <div class="eleven columns post-block inner-block profile-profile myconnections inbox"
                                style="display: none;" id="responseMessage"></div>
                            <div class="eleven columns post-block inner-block profile-profile myconnections inbox"
                                id="message">
                                <div class="ten columns">
                                    <label for="requestTo">Request To</label>
                                    <div class="ten columns" id="requestToOuterDiv"
                                        style="border: 1px solid #0F8DBF; margin-bottom: 15px;">
                                        <div id="selected_requestTo" style="margin: 0 5px;">
                                            <div class="clr"></div>
                                        </div>
                                        <div class="selectedOuter"
                                            style="padding: 0px; border: 0px none; background-color: #FFFFFF; margin: 0px;">
                                            <p-autoComplete field="username" name="userTo.rcmdreqto"
                                                [suggestions]="userToresults" required minLength=2 delay=200
                                                (completeMethod)="searchusers($event)" [(ngModel)]="userTo.rcmdreqto"
                                                field="userName" #reqRcm="ngModel">
                                            </p-autoComplete>
                                        </div>
                                        <div *ngIf="!reqRcm.valid && !reqRcm.pristine" class="errorDiv">
                                            Please select the recipient to whom you want to send the request to
                                        </div>
                                        <div class="clr"></div>
                                    </div>
                                </div>
                                <div class="clear"></div>
                                <div class="clr"></div>
                                <div class="ten columns">
                                    <label for="subject">Subject </label>
                                    <input type="text" name="subject" id="subject" name="rcmdreqSend.Subject"
                                        #reqRcmSub="ngModel" [(ngModel)]="rcmdreqSend.Subject" size="50" maxlength="100"
                                        autocomplete="off" style="margin-bottom:0px;padding: 5px;" required
                                        value="Requesting your recommendation">
                                    <div *ngIf="(!reqRcmSub.valid && !reqRcmSub.pristine)" class="errorDiv">
                                        Please enter the subject.
                                    </div>
                                </div>
                                <div class="clear"></div>
                                <div class="ten columns">
                                    <label for="requestComments">Request</label>
                                    <textarea [(ngModel)]="rcmdreqSend.userRequestText"
                                        name="rcmdreqSend.userRequestText" required #reqRcmText="ngModel"
                                        id="requestComments" style="margin-bottom:0px;" rows="4" cols="80">
                                      Hi, Please write few lines about me that I could include on uvsity profile. Thanks,
                                    </textarea>
                                    <div id="update_requestComments" class="common"
                                        style="width: 100%; text-align: right;">
                                        <div *ngIf="(!reqRcmText.valid && !reqRcmText.pristine)" class="errorDiv"
                                            style="text-align: left;">
                                            Please add a few yourds to your request, for your recipient.
                                        </div>
                                        <span id="charCount_requestComments"></span>&nbsp; chars left
                                    </div>
                                </div>
                                <div class="clear"></div>
                                <div class="three columns alpha marginbottom">
                                    <input type="submit" [disabled]="!frmReqRcm.form.valid" id="btnSend" value="Send"
                                        class="buttonStyle btn-120">
                                    <a href="javascript: void(0);"
                                        (click)="frmReqRcm.form.reset();trashLi = false; sent = false; composemail = false; inbox = true; msgsent = false; rcmdreceived = false; writearcmd = false; msgRcv(); reqreceived = false; reqforrate = false; msgreceived = true; reqrecmd = false; mymsg = false; newmsg = false; reqsent = false; rcmdreqsent = false; msgdetail = false;trashh = false;"
                                        class="btn-120 pageLink4 btnstyle">
                                        Cancel
                                    </a>
                                </div>
                                <div class="clear"></div>
                                <div class="clr"></div>
                            </div>
                        </div>
                    </div>
                    <div class="clr"></div>
                </form>
                <div class="clr"></div>
            </div>
        </div>
        <div class="right_section" id="reqforrate" style="display:none" [style.display]="editDisplay(reqforrate)">
            <div class="shad-left boxs newloginform">
                <form name="frmRequestRating" (ngSubmit)="sendRateReq(frmReqRating.form)" method="post"
                    #frmReqRating="ngForm" novalidate>
                    <div class="boxpad">
                        <div id="selectedUserTemplate" style="display: none;">
                            <div class="selectedUserName">#userName#</div>
                            <div class="selectedClose"> X</div>
                        </div>
                        <div class="eleven columns post-block inner-block profile-profile myconnections inbox"
                            id="responseMessage">
                            <div class="eleven columns post-block inner-block profile-profile myconnections inbox"
                                id="message">
                                <div>
                                    <label class="lblblue" for="requestTo">Request To</label>
                                </div>
                                <div style="border: 1px solid #0F8DBF; margin-bottom: 15px; display: none;">
                                    <div style="float: left; font-size: 14px; margin: 10px;">
                                        <input type="radio" name="network" (click)="reqforrateSpecific = false;"
                                            id="yournetwork" value="yournetwork" checked="checked">
                                        <label style="margin-left: 5px;">Your Network</label>
                                    </div>
                                    <div style="float: left; font-size: 14px; margin: 10px;">
                                        <input type="radio" name="network" (click)="reqforrateSpecific = true;"
                                            id="specificuser" value="specificuser">
                                        <label style="margin-left: 5px;">Specific Users</label>
                                    </div>
                                    <div class="clr"></div>
                                </div>
                                <div class="ten columns" id="requestToOuterDiv"
                                    style="border: 1px solid #0F8DBF; margin-bottom: 15px;">
                                    <div id="selected_requestTo" style="margin: 0 5px;">
                                        <div class="clr"></div>
                                    </div>
                                    <div class="selectedOuter"
                                        style="padding: 0px; border: 0px none; background-color: #FFFFFF; margin: 0px;">
                                        <p-autoComplete field="userName" name="userTo.ratereqto"
                                            [suggestions]="userToresults" required minLength=2 delay=200
                                            (completeMethod)="searchusers($event)" [(ngModel)]="userTo.ratereqto"
                                            #reqRating="ngModel">
                                        </p-autoComplete>
                                    </div>
                                    <div *ngIf="!reqRating.valid && !reqRating.pristine" class="errorDiv">
                                        Please select the recipient to whom you want to send the request to
                                    </div>
                                    <div class="clr"></div>
                                </div>
                                <div class="clr"></div>
                                <div class="ten columns">
                                    <label for="requestComments" class="lblblue">Request</label>
                                    <textarea [(ngModel)]="ratereqSend.userRequestText" #reqRatingText="ngModel"
                                        required name="ratereqSend.userRequestText" id="requestComments" rows="4"
                                        cols="80" style="margin-bottom:0px;">
                                      Hi, Please rate my experience. Thanks,
                                    </textarea>
                                    <div id="update_requestComments" class="common"
                                        style="width: 100%; text-align: right; display: none;">
                                        <span id="charCount_requestComments"></span>&nbsp; chars left
                                    </div>
                                    <div *ngIf="(!reqRatingText.valid && !reqRatingText.pristine)" class="errorDiv"
                                        style="text-align: left;">
                                        Please add a few words to your request, for your recipient.
                                    </div>
                                </div>
                                <div class="clear" style="margin-bottom:2%;"></div>
                                <div class="three columns alpha marginbottom">
                                    <input type="submit" [disabled]="!frmReqRating.form.valid" id="btnSend" value="Send"
                                        class="buttonStyle btn-120">
                                    <a href="javascript: void(0);"
                                        (click)="frmReqRating.form.reset(); trashLi = false; sent = false; composemail = false; inbox = true; msgsent = false; rcmdreceived = false; writearcmd = false; msgRcv(); reqreceived = false; reqforrate = false; msgreceived = true; reqrecmd = false; mymsg = false; newmsg = false; reqsent = false; rcmdreqsent = false; msgdetail = false;trashh = false;"
                                        class="btn-120 pageLink4 btnstyle">
                                        Cancel
                                    </a>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                    <div class="clr"></div>
                </form>
            </div>
        </div>
        <div class="right_section" id="writearcmd" style="display:none" [style.display]="editDisplay(writearcmd)">
            <div class="boxs">
                <div class="boxpad newloginform">
                    <form id="frmWriteRecommendation" name="frmWriteRecommendation"
                        (ngSubmit)="writeRcmdSend(frmRspRcm.form)" #frmRspRcm="ngForm" novalidate method="post">
                        <input type="hidden" name="hdCurrPage" id="hdCurrPage" value="Write a Recommendation">
                        <div id="selectedUserTemplate" style="display: none;">
                            <div class="selectedUserName">#userName#</div>
                            <div class="selectedClose">X</div>
                        </div>
                        <div class="twelve columns shad-left">
                            <div class="eleven columns post-block inner-block profile-profile myconnections inbox"
                                style="display: none;" id="responseMessage"></div>
                            <div class="eleven columns post-block inner-block profile-profile myconnections inbox"
                                id="message">
                                <div class="ten columns">
                                    <label for="requestTo">Recommendation For</label>
                                    <div class="ten columns" id="requestToOuterDiv"
                                        style="border: 1px solid #0F8DBF; margin-bottom: 15px;">
                                        <div id="selected_requestTo" style="margin: 0 5px;">
                                            <div class="clr"></div>
                                        </div>
                                        <div class="selectedOuter"
                                            style="padding: 0px; border: 0px none; background-color: #FFFFFF; margin: 0px;">
                                            <p-autoComplete field="userName" name="userTo.writeRcmdto"
                                                [suggestions]="userToresults" #rspRcm="ngModel"
                                                (completeMethod)="searchusers($event)" required
                                                [(ngModel)]="userTo.writeRcmdto" minLength=2 delay=200>
                                            </p-autoComplete>
                                        </div>
                                        <div *ngIf="!rspRcm.valid && !rspRcm.pristine" class="errorDiv">
                                            Please select the recipient to whom you want to send the request to
                                        </div>
                                        <div class="clr"></div>
                                    </div>
                                </div>
                                <div class="clear"></div>
                                <div class="ten columns">
                                    <label for="recommendation">Recommendation</label>
                                    <textarea [(ngModel)]="writeRcmd.recommendation.recommendation"
                                        name="ratereqSend.userRequestText" id="recommendation" rows="4" cols="80"
                                        #rspRcmText="ngModel" required style="margin-bottom:0px;">
                                    </textarea>
                                    <div id="update_recommendation" class="common"
                                        style="width: 100%; text-align: right; display: none;">
                                        <span id="charCount_recommendation">
                                        </span>&nbsp; chars left
                                    </div>
                                    <div *ngIf="(!rspRcmText.valid && !rspRcmText.pristine)" class="errorDiv"
                                        style="text-align: left;">
                                        Please add your recommendation.
                                    </div>
                                </div>
                                <div class="clear" style="margin-bottom:2%;"></div>
                                <div class="three columns alpha marginbottom">
                                    <input type="submit" [disabled]="!frmRspRcm.form.valid" id="btnSend" value="Send"
                                        class="buttonStyle btn-120">
                                    <a href="javascript: void(0);"
                                        (click)="frmRspRcm.form.reset();trashLi = false; sent = false; composemail = false; inbox = true; msgsent = false; rcmdreceived = false; writearcmd = false; msgRcv(); reqreceived = false; reqforrate = false; msgreceived = true; reqrecmd = false; mymsg = false; newmsg = false; reqsent = false; rcmdreqsent = false; msgdetail = false;trashh = false;"
                                        class="btn-120 pageLink4 btnstyle">
                                        Cancel
                                    </a>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="clr"></div>
            </div>
        </div>
        <div class="right_section" id="msgreceived" style="display:none" [style.display]="editDisplay(msgreceived)">
            <div class="eleven columns shad-left common ">
                <div class="eleven columns post-block inner-block profile-profile myconnections inbox">
                    <div>
                        <div class="eleven columns title boxs boxpad inboxtitle">
                            <div class="nine columns alpha">
                                <label>From</label>
                            </div>
                            <div class="two columns last">
                                <label>Date</label>
                            </div>
                            <div class="clr"></div>
                        </div>
                        <form name="frmMessagesReceived" method="post">
                            <div class="common" id="inboxRequestsDiv">
                                <div id="searchResultsResponseDiv">
                                    <!-- Message List -->
                                    <div *ngFor="let allmssg of allMsg">
                                        <div id="inboxRow_62" class="boxs boxpad"
                                            *ngIf="allmssg.isElementDeleted == false">
                                            <div class="nine columns alpha">
                                                <div class="two inner-post-image float-attendees inboximg">
                                                    <div class="featured-image">
                                                        <img *ngIf="allmssg.messageConversation.senderProfilePicName != null && !allmssg.messageConversation.senderProfilePicName.includes('profilebig.png')"
                                                            class="greyBorder profileImage" alt=""
                                                            src="{{allmssg.messageConversation.senderProfilePicName}}"
                                                            width="60" height="60">
                                                        <ngx-avatar
                                                            *ngIf="allmssg.messageConversation.senderProfilePicName == null || allmssg.messageConversation.senderProfilePicName.includes('profilebig.png')"
                                                            name="{{allmssg.messageConversation.senderFirstName}}"
                                                            size="60"></ngx-avatar>
                                                    </div>
                                                </div>
                                                <div class="four profile-hover block-attendees inboxdesc">
                                                    <label
                                                        class="profile-name">{{allmssg.messageConversation.messageSenders}}
                                                        ({{allmssg.messageConversation.noOfMessagesInConversation}})</label>
                                                    <br>
                                                    <label>
                                                        <strong>{{allmssg.messageConversation.latestMessageInConversation.messageSubject}}</strong>
                                                    </label>
                                                    <br>
                                                    <br>
                                                    <div (click)="msgClick(allmssg.userMessageContainerId)"
                                                        style="cursor: pointer;">
                                                        <span>{{allmssg.truncatedMessage}}</span> </div>
                                                    <br>
                                                    <label class="padd ico_delete"><a class="pageLink"
                                                            style="font-size: 11px;" href="javascript: void(0);"
                                                            (click)="deleteFn('msgRec', allmssg.userMessageContainerId, allmssg);">Delete</a>
                                                    </label> <span id="errorDiv_62" style="margin-right: 10px;"
                                                        class="errorDiv"></span>
                                                </div>
                                                <div class="clr"></div>
                                                <div class="two columns last inboxdate">
                                                    <label>{{allmssg.messageConversation.latestMessageInConversation.formattedCreatedDateForDisplay}}</label>
                                                </div>
                                                <div class="clear"></div>
                                                <div class="big_separator"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="eleven columns alpha">
                                    <div id="navigationLinksRow" class="navigationLinksRow"> </div>
                                </div>
                                <div id="loadMoreResults" (click)="inboxAllMsgLoad()" class="load-more-hide">Load More
                                </div>
                            </div>
                            <div class="clr"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="right_section" id="msgdetail" style="display:none" [style.display]="editDisplay(msgdetail)">
            <div class="common boxs boxpad">
                <div style="float:left" *ngIf="msgSub != null"><strong class="lblblue msgsub">Subject:
                    </strong>{{msgSub}}</div>
                <div style="float:right" *ngIf="navigatingFromSentItems == false">
                    <input type="button" name="btnBack" id="btnBack"
                        (click)="msgdetailsingle = []; msgdetail = false; msgreceived = true;backToMessages();"
                        value="Back to Messages" class="buttonStyle btn-120">
                </div>
                <div style="float:right" *ngIf="navigatingFromSentItems == true">
                    <input type="button" name="btnBack" id="btnBack"
                        (click)="msgdetailsingle = []; msgdetail = false; msgsent = true;" value="Back to Sent Messages"
                        class="buttonStyle">
                </div>
                <div class="clr"></div>
            </div>
            <div class="mainContentsInner" style="    margin-left: 35px;" id="messagesListDiv"
                *ngFor="let msgd of msgdetailsingle">
                <div class="common" id="inboxRequestsDiv">
                    <div class="common" id="messageConversationsDiv">
                        <div class="boxs boxpad spamWrap">
                            <div class="show-reply icorply" id="showReply_46">
                                <a *ngIf="msgd.senderUserId != userDetailsId" href="javascript: void(0);" pTooltip="Report spam" (click)="reportSpam(msgd.id)"
                                    class="spamBtn deco-none"><i class="fa fa-flag"></i></a>
                                <a href="javascript:void(0)" style="cursor: pointer; display: none;"
                                    [style.display]="editDisplayInline(msgd.reply)"
                                    (click)="msgd.replybox = true;  msgd.reply = false;"><i
                                        class="fa fa-reply"></i>Reply</a>
                            </div>
                            <div class="messageConversationRow" id="inboxRow_46">
                                <div class="common">
                                    <div class="showMe msgconimg">

                                        <!-- <img width="60" height="60" border="0" src="{{msgd.senderProfilePicName}}" class="greyBorder profileImage"> -->

                                        <img *ngIf="msgd.senderProfilePicName != null && !msgd.senderProfilePicName.includes('profilebig.png')"
                                            class="greyBorder profileImage" alt="" src="{{msgd.senderProfilePicName}}"
                                            width="60" height="60">

                                        <ngx-avatar
                                            *ngIf="msgd.senderProfilePicName == null || msgd.senderProfilePicName.includes('profilebig.png')"
                                            name="{{msgd.sendername}}" size="60"></ngx-avatar>

                                    </div>
                                    <div class="showMe msgcondesc">
                                        <div class="common inboxdesc">
                                            <div class="profile-name profilename">
                                                <a [routerLink]="['../profile']"
                                                    [queryParams]="{ user: msgd.senderUserId }"
                                                    href="javascript: void(0);" class="pageLink5">
                                                    {{msgd.sendername}}
                                                </a>
                                                <br>
                                                <span style="font-size:14px;color:#404040">To :</span>
                                                <span style="font-size:12px; color:#8c8c8c;"
                                                    *ngFor="let recipientName of msgd.userNamesInTo">{{recipientName}}
                                                </span>
                                            </div>
                                            <br />
                                            <pre style="font-family: 'Ubuntu', sans-serif; font-size:13px; color:#6d6d6d;"
                                                [innerHTML]="msgd.messageBody | safeHtml"></pre>
                                        </div>
                                    </div>
                                    <div class="inboxdate"> {{msgd.formattedCreatedDateForDisplay}} </div>
                                </div>
                            </div>
                            <div class="clr"></div>
                            <div class="msgconrplbox" [style.display]="editDisplay(msgd.replybox)"
                                style="display: none;">
                                <div class="common" style="margin-top: 10px;">
                                    <textarea rows="2" cols="80" id="replyMessage_46" [(ngModel)]="messageBody"
                                        name="messageBody"></textarea>
                                    <input type="button" name="btnReply" id="btnReply" (click)="replyToMesg(msgd)"
                                        value="Reply" class="buttonStyle btn-120"> <a href="javascript: void(0);"
                                        (click)="msgd.replybox = false; msgd.reply = true;"
                                        class="pageLink4 btnstyle btn-120 buttonStyle btn-120">Cancel</a>
                                    &nbsp;&nbsp;&nbsp;
                                    <div id="update_replyMessage" class="common charcount" style="display: none;"> <span
                                            id="charCount_replyMessage"></span>&nbsp; chars left </div>
                                    <div class="clr"></div>
                                </div>
                            </div>
                            <div class="clr"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="right_section" id="reqreceived" style="display:none" [style.display]="editDisplay(reqreceived)">
            <div class="eleven columns shad-left common ">
                <div class="eleven columns post-block inner-block profile-profile myconnections inbox">
                    <div>
                        <div class="eleven columns title boxs boxpad inboxtitle">
                            <div class="nine columns alpha">
                                <label>From</label>
                            </div>
                            <div class="two columns last">
                                <label>Date</label>
                            </div>
                            <div class="clr"></div>
                        </div>
                        <form>
                            <!--  <input id="hdCurrPage" type="hidden" value="Messages" name="hdCurrPage"> -->
                            <div class="common" id="inboxRequestsDiv">
                                <!-- <div class="common errorDiv"> No requests found </div> -->
                                <div *ngFor="let reqRcvData of reqRecv">
                                    <div class="showmore boxs boxpad" *ngIf="reqRcvData.isElementDeleted == false"
                                        id="inboxRow_48">
                                        <div class="seven columns alpha fromlbl">
                                            <!-- Profile Picture -->
                                            <div class="two inner-post-image float-attendees inboximg">
                                                <div class="featured-image ">

                                                    <!-- <img width="60" height="60" border="0" src="{{reqRcvData.requestFrom.profilePicName}}" class="greyBorder profileImage"> -->

                                                    <img *ngIf="reqRcvData.requestFrom.profilePicName != null && !reqRcvData.requestFrom.profilePicName.includes('profilebig.png')"
                                                        class="greyBorder profileImage" alt=""
                                                        src="{{reqRcvData.requestFrom.profilePicName}}" width="60"
                                                        height="60">

                                                    <ngx-avatar
                                                        *ngIf="reqRcvData.requestFrom.profilePicName == null || reqRcvData.requestFrom.profilePicName.includes('profilebig.png')"
                                                        name="{{reqRcvData.requestFrom.firstName}} {{reqRcvData.requestFrom.lastName}}"
                                                        size="60"></ngx-avatar>

                                                </div>
                                            </div>
                                            <!-- First Name and Last Name -->
                                            <div class="five profile-hover block-attendees inboxdesc">
                                                <label class="profile-name"> <a [routerLink]="['../profile']"
                                                        [queryParams]="{ user: reqRcvData.requestFrom.userDetailsId }"
                                                        href="javascript: void(0);">
                                                        {{reqRcvData.requestFrom.firstName}}
                                                        {{reqRcvData.requestFrom.lastName}}
                                                    </a> </label>
                                                <br>
                                                <!-- User Type -->
                                                <label
                                                    *ngIf="reqRcvData.requestFrom.isUserSecondaryDataCapturedForUser == 'T'"
                                                    class="gwt-Label profile-userType userype">
                                                    {{reqRcvData.requestFrom.userBaseType}} </label>
                                                <br
                                                    *ngIf="reqRcvData.requestFrom.isUserSecondaryDataCapturedForUser == 'T'">
                                                <label
                                                    *ngIf="reqRcvData.requestFrom.isUserSecondaryDataCapturedForUser == 'T'"
                                                    class="gwt-Label profile-userType userype">
                                                    {{reqRcvData.requestFrom.educationalInstitution}} ,
                                                    {{reqRcvData.requestFrom.campus}} , </label>
                                                <!-- User Type -->
                                                <!-- Request Subject, this field is available on all types of Request -->
                                                <div class="" *ngIf="reqRcvData.userRequestSubject != null">
                                                    <strong>Subject: </strong> {{reqRcvData.userRequestSubject}} </div>
                                                <!-- Request Body, this field will be available only of the Request actually has a Request Body. Invitation Requests,
                                  Rating Requests do not have a body, so in such cases this UI element will not be displayed -->
                                                <!-- If the request has a request body then display it -->
                                                <div id="comments_48" class="lessContent" style="display: none"
                                                    [style.display]="editDisplay(reqRcvData.more)">
                                                    <!-- Request Body -->
                                                    <!-- The links for More and Less. They only appear if the Request Body is more than 50 characters -->
                                                    <br> <strong>
                                                        Request:
                                                    </strong> {{reqRcvData.userRequestText}} </div>
                                                <div class="padd" id="more_48"
                                                    [style.display]="editDisplay(reqRcvData.less)">
                                                    <a href="javascript: void(0);"
                                                        (click)="reqRcvData.more = true; reqRcvData.less = false;">
                                                        <strong>More</strong> </a>
                                                </div>
                                                <div style="display: none;" id="less_48"
                                                    [style.display]="editDisplay(reqRcvData.more)"> <a
                                                        (click)="reqRcvData.more = false; reqRcvData.less = true;"
                                                        href="javascript: void(0);" class="pageLink">
                                                        Less
                                                    </a> </div>
                                                <!-- Accept / Reject buttons, or write recommendation link if Action pending, else Accepted / Rejected Label -->
                                                <!-- If any Action needs to be taken. In this case, write a recommendation. -->
                                                <span id="acceptRejectReportSpam_39"
                                                    *ngIf="reqRcvData.userRequestType == 'TOPIC_JOIN_REQUEST'">
                                                    <input type="button" *ngIf="reqRcvData.requestStatus == 'ACCEPT'"
                                                        style="width: 120px; color: #fff ! important; cursor: auto;"
                                                        class="view-topics recorecaccept" name="recommendation_sent"
                                                        value="Accepted">
                                                    <input type="button" *ngIf="reqRcvData.requestStatus == 'REJECT'"
                                                        style="width: 120px; color: #fff ! important; cursor: auto;"
                                                        class="view-topics recorecaccept" name="recommendation_sent"
                                                        value="Rejected">
                                                    <input type="button" *ngIf="reqRcvData.requestStatus == 'PENDING'"
                                                        (click)="topicreqAccept(reqRcvData.userRequestId)"
                                                        style="width: 120px; color: #fff ! important; cursor: auto;"
                                                        class="view-topics recorecaccept" value="Accept">
                                                    <label class="padd"></label>
                                                    <input type="button" *ngIf="reqRcvData.requestStatus == 'PENDING'"
                                                        (click)="topicreqReject(reqRcvData.userRequestId)"
                                                        style="background-color: #aa4040; color: #fff !important; width: 120px; cursor: auto;"
                                                        class="view-topics recorecaccept" name="pending-topic"
                                                        value="Reject">
                                                </span>
                                                <span id="acceptRejectReportSpam_39"
                                                    *ngIf="reqRcvData.userRequestType == 'INVITATION_REQUEST'">
                                                    <input type="button" *ngIf="reqRcvData.requestStatus == 'Accepted'"
                                                        style="width: 120px; color: #fff ! important; cursor: auto;"
                                                        class="view-topics recorecaccept" name="recommendation_sent"
                                                        value="Accepted">
                                                    <input type="button" *ngIf="reqRcvData.requestStatus == 'Rejected'"
                                                        style="width: 120px; color: #fff ! important; cursor: auto;"
                                                        class="view-topics recorecaccept" name="recommendation_sent"
                                                        value="Rejected">
                                                    <input type="button"
                                                        *ngIf="reqRcvData.requestStatus == 'Waiting For Response'"
                                                        (click)="reqAccept(reqRcvData.userRequestId)"
                                                        style="width: 120px; color: #fff ! important; cursor: auto;"
                                                        class="view-topics recorecaccept" value="Accept">
                                                    <label class="padd"></label>
                                                    <input type="button"
                                                        *ngIf="reqRcvData.requestStatus == 'Waiting For Response'"
                                                        (click)="reqReject(reqRcvData.userRequestId)"
                                                        style="background-color: #aa4040; color: #fff !important; width: 120px; cursor: auto;"
                                                        class="view-topics recorecaccept" name="pending-topic"
                                                        value="Reject">
                                                </span>
                                                <span id="writeRecommendationLinkDiv_61"
                                                    *ngIf="reqRcvData.userRequestType == 'RECOMMENDATION_REQUEST'">
                                                    <a *ngIf="reqRcvData.requestStatus == 'Recommendation Response Not Sent Yet'"
                                                        href="javascript: void(0);" class="pageLink"
                                                        style="margin-right: 10px;" (click)="reqRcvData.show = true;">
                                                        <img border="0" height="12px" src="assets/images/pencil.png"
                                                            style="margin-right: 2px;">
                                                        Write Recommendation
                                                    </a>
                                                    <div class="common" id="recommendation_41" style="display: block;"
                                                        [style.display]="editDisplay(reqRcvData.show)">
                                                        <div id="writeRecommendationDiv_41" class="common">
                                                            <textarea
                                                                [(ngModel)]="reqwriteRcmd.recommendation.recommendation"
                                                                name="reqwriteRcmd.recommendation.recommendation"
                                                                id="writeRecommendation_41"
                                                                name="writeRecommendation_41" cols="60"
                                                                rows="4"></textarea>
                                                        </div>

                                                        <input type="button" name="btnSubmit"
                                                            (click)="reqrecrcmd(reqRcvData, reqRcvData.requestFrom.userDetailsId)"
                                                            id="btnSubmit" value="Submit" class="buttonStyle btn-120"
                                                            style="float: none;">

                                                        <input type="button" name="btnCancel"
                                                            (click)="reqRcvData.show = false;" id="btnCancel"
                                                            value="Cancel" class="buttonStyle btn-120"
                                                            style="float: none; margin-left: 10px;">

                                                        <div id="update_writeRecommendation_41"
                                                            class="common charcount">
                                                            <span
                                                                id="charCount_writeRecommendation_41">1000</span>&nbsp;
                                                            chars left
                                                        </div>
                                                    </div>
                                                </span>
                                                <span id="writeRecommendationLinkDiv_61"
                                                    *ngIf="reqRcvData.userRequestType == 'RATING_REQUEST'">
                                                    <span id="rateProfessorLinkDiv_13"><a href="javascript: void(0);"
                                                            (click)="reqRcvData.ratebox = true;" class="pageLink1"><img
                                                                border="0" width="12px" height="12px"
                                                                src="assets/images/star.png" style="margin-right: 2px;">
                                                            Rate this User</a></span>
                                                    <div class="profilerqsbox">
                                                        <div id="rateProfessorDiv_13" class="common"
                                                            style="display: none"
                                                            [style.display]="editDisplay(reqRcvData.ratebox)">
                                                            <input type="radio" [(ngModel)]="rating.rating.ratingText"
                                                                name="rating.ratingText" id="rateFair_13" value="FAIR">
                                                            <label for="rateFair_13">Fair</label>&nbsp;
                                                            <input type="radio" [(ngModel)]="rating.rating.ratingText"
                                                                name="rating.ratingText" id="rateGood_13" value="GOOD">
                                                            <label for="rateGood_13">Good</label>&nbsp;
                                                            <input type="radio" [(ngModel)]="rating.rating.ratingText"
                                                                name="rating.ratingText" id="rateBest_13" value="BEST">
                                                            <label for="rateBest_13">Best</label>
                                                            <input type="button" name="btnRate"
                                                                (click)="ratingUser(reqRcvData.requestFrom.userDetailsId); reqRcvData.ratebox = false;"
                                                                id="btnRate" value="Rate"
                                                                style="width: 25%;display: inherit;">
                                                            <input type="button" name="btnCancel" id="btnCancel"
                                                                (click)="reqRcvData.ratebox = false;" value="Cancel"
                                                                style="width: 25%;display: inherit; margin-top: 5px;">
                                                        </div>
                                                    </div>
                                                </span>
                                                <label class="padd ico_delete">
                                                    <span id="deleteInboxRequest_48">
                                                        <a class="pageLink"
                                                            (click)="deleteFn('reqRec', reqRcvData.userRequestId, reqRcvData);"
                                                            href="javascript: void(0);">
                                                            Delete
                                                        </a>
                                                    </span>
                                                </label>
                                                <span id="errorDiv_48" style="margin-right: 10px;"
                                                    class="errorDiv"></span>
                                                <div class="nine columns alpha"></div>
                                            </div>
                                            <div class="clr"></div>
                                        </div>
                                        <!-- Right hand top corner, Type of Request -->
                                        <div class="two columns last inboxrecomm">
                                            <label *ngIf="reqRcvData.userRequestType == 'RECOMMENDATION_REQUEST'">
                                                Recommendation </label>
                                            <label *ngIf="reqRcvData.userRequestType == 'INVITATION_REQUEST'">
                                                Connection Request </label>
                                            <label *ngIf="reqRcvData.userRequestType == 'CREATE_COURSE_REQUEST'">
                                                Session Request </label>
                                            <label *ngIf="reqRcvData.userRequestType == 'TOPIC_JOIN_REQUEST'"> Topic
                                                Request </label>
                                            <label *ngIf="reqRcvData.userRequestType == 'RATING_REQUEST'"> Rating
                                                Request </label>
                                        </div>
                                        <div class="two columns last inboxdate">
                                            <label>{{reqRcvData.formattedCreatedDateForDisplay}}</label>
                                        </div>
                                        <div class="clear"></div>
                                        <div class="clr"></div>
                                    </div>
                                </div>
                                <div id="loadMoreResults" (click)="reqRcvMore()" class="load-more-hide">Load More</div>
                            </div>
                            <div class="clr"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="right_section" style="display:none" [style.display]="editDisplay(rcmdreceived)">
            <div class="twelve columns shad-left">
                <div class="eleven columns post-block inner-block profile-profile myconnections inbox">
                    <div class="eleven columns alpha">
                        <!-- Data content -->
                        <div class="eleven columns title nonmobileview inboxtitle boxs boxpad">
                            <div class="nine columns alpha">
                                <label>From</label>
                            </div>
                            <div class="two columns last">
                                <label>Date</label>
                            </div>
                            <div class="clr"></div>
                        </div>
                        <div id="searchResultsResponseDiv">
                            <div *ngFor="let rcmdRcvData of rcmdRecv">
                                <div class="showmore boxs boxpad" id="inboxRow_24"
                                    *ngIf="rcmdRcvData.isElementDeleted == false">
                                    <div class="nine columns alpha recommrqst">
                                        <!-- Profile Pic Image -->
                                        <div class="two inner-post-image float-attendees">
                                            <div class="featured-image">
                                                <!-- <img width="60" height="60" border="0" src="{{rcmdRcvData.responseFrom.profilePicName}}" class="greyBorder profileImage"> -->

                                                <img *ngIf="rcmdRcvData.responseFrom.profilePicName != null && !rcmdRcvData.responseFrom.profilePicName.includes('profilebig.png')"
                                                    class="greyBorder profileImage" alt=""
                                                    src="{{rcmdRcvData.responseFrom.profilePicName}}" width="60"
                                                    height="60">

                                                <ngx-avatar
                                                    *ngIf="rcmdRcvData.responseFrom.profilePicName == null || rcmdRcvData.responseFrom.profilePicName.includes('profilebig.png')"
                                                    name="{{rcmdRcvData.responseFrom.firstName}}  {{rcmdRcvData.responseFrom.lastName}}"
                                                    size="60"></ngx-avatar>


                                            </div>
                                        </div>
                                        <div class="seven profile-hover block-attendees">
                                            <!-- First and Last Names -->
                                            <label class="profile-name profilename"> <a href="javascript: void(0);"
                                                    [routerLink]="['../profile']"
                                                    [queryParams]="{ user: rcmdRcvData.responseFrom.userDetailsId }"
                                                    class="profileLink"> {{rcmdRcvData.responseFrom.firstName}}
                                                    {{rcmdRcvData.responseFrom.middleName}}
                                                    {{rcmdRcvData.responseFrom.lastName}} </a> </label>
                                            <br>
                                            <!-- User type -->
                                            <div *ngIf="rcmdRcvData.responseFrom.isUserSecondaryDataCapturedForUser != 'F'"
                                                class="common lineHeight16 gwt-Label profile-userType userype">
                                                <span
                                                    *ngIf="rcmdRcvData.responseFrom.userBaseType == 'Student'">Pursuing
                                                    {{rcmdRcvData.responseFrom.userEducationDegreeCourse}} in
                                                    {{rcmdRcvData.responseFrom.userEducationSubject}}
                                                </span>
                                                <span
                                                    *ngIf="rcmdRcvData.responseFrom.userBaseType == 'Alumni'">Alumni</span>
                                            </div>
                                            <!-- University/Campus City -->
                                            <label
                                                *ngIf="rcmdRcvData.responseFrom.isUserSecondaryDataCapturedForUser == 'T'"
                                                class="gwt-Label profile-userType userype">
                                                {{rcmdRcvData.responseFrom.educationalInstitution}} ,
                                                {{rcmdRcvData.responseFrom.campus}} , </label>
                                            <br
                                                *ngIf="rcmdRcvData.responseFrom.isUserSecondaryDataCapturedForUser == 'T'">
                                            <!-- Response Subject -->
                                            <div class=""> <strong>Subject: </strong> Recommendation received from
                                                {{rcmdRcvData.responseFrom.firstName}}
                                                {{rcmdRcvData.responseFrom.middleName}}
                                                {{rcmdRcvData.responseFrom.lastName}} </div>
                                            <br>
                                            <!-- Minimized Response Body -->
                                            <div id="comments_minimized_24" class="lessContent"
                                                [style.display]="editDisplay(rcmdRcvData.less)">
                                                <strong>Request</strong>:{{ rcmdRcvData.recommendation.recommendation | slice:0:40 }}
                                                ..........
                                            </div>
                                            <!-- Full Response Body -->
                                            <div id="comments_maximized_24" class="lessContent" style="display: none"
                                                [style.display]="editDisplay(rcmdRcvData.more)">
                                                <strong>Request</strong>:{{ rcmdRcvData.recommendation.recommendation }}
                                            </div>
                                            <div id="more_24" [style.display]="editDisplay(rcmdRcvData.less)">
                                                <a href="javascript: void(0);" class="pageLink"
                                                    (click)="rcmdRcvData.more = true; rcmdRcvData.less = false;">More</a>
                                            </div>

                                            <div style="display: none;" id="less_24"
                                                [style.display]="editDisplay(rcmdRcvData.more)">
                                                <a href="javascript: void(0);"
                                                    (click)="rcmdRcvData.more = false; rcmdRcvData.less = true;"
                                                    class="pageLink">Less</a>
                                            </div>
                                            <!-- Response Status -->
                                            <span id="acceptRejectReportSpam_32"
                                                *ngIf="rcmdRcvData.actionTaken == null">

                                                <input type="button" id="acceptRecommendation_32"
                                                    style="width: 80px; color: #fff ! important;"
                                                    class="pageLink recorecaccept btn-120"
                                                    (click)="rcmdAccept(rcmdRcvData.userResponseId)" value="Accept">

                                                <input type="button" (click)="rcmdReject(rcmdRcvData.userResponseId)"
                                                    id="rejectRecommendation_32"
                                                    style="background-color: #aa4040; color: #fff !important; width: 80px;"
                                                    class="pageLink btn-120 recorecaccept" value="Reject">

                                            </span>
                                            <span id="acceptRejectReportSpam_32"
                                                *ngIf="rcmdRcvData.actionTaken != null">
                                                <input type="button"
                                                    *ngIf="rcmdRcvData.actionTaken.actionTaken == 'Accepted'"
                                                    id="acceptRecommendation_32"
                                                    style="width: 80px; color: #fff ! important;"
                                                    class="btn-120 pageLink recorecaccept" value="Accepted">
                                            </span>
                                            <span id="acceptRejectReportSpam_32"
                                                *ngIf="rcmdRcvData.actionTaken != null">
                                                <input type="button"
                                                    *ngIf="rcmdRcvData.actionTaken.actionTaken == 'Rejected'"
                                                    id="rejectRecommendation_32"
                                                    style="background-color: #aa4040; color: #fff !important; width: 80px;"
                                                    class="btn-120 pageLink recorecaccept" value="Rejected">
                                            </span>
                                            <!-- Delete The Recommendation Response -->
                                            <label class="padd ico_delete" id="deleteInboxRequest_24"><a
                                                    class="btn-120 pageLink" href="javascript: void(0);"
                                                    (click)="deleteFn('rcmdRec', rcmdRcvData.userResponseId, rcmdRcvData);">Delete</a>
                                            </label>
                                            <label class="padd" id="errorDiv_24"> </label>
                                        </div>
                                        <div class="clr"></div>
                                    </div>
                                    <div class="two columns last inboxdate">
                                        <label>{{rcmdRcvData.formattedCreatedDateForDisplay}}</label>
                                    </div>
                                    <div class="clear"></div>
                                    <div class="big_separator"></div>
                                </div>
                            </div>
                        </div>
                        <div class="clr"></div>
                        <div id="loadMoreResults" (click)="rcmdRcvLoadMore()" class="load-more-hide">Load More</div>
                        <!-- Data content Ends-->
                    </div>
                </div>
            </div>
        </div>
        <div class="right_section" [style.display]="editDisplay(msgsent)">
            <div class="eleven columns shad-left common ">
                <div class="eleven columns post-block inner-block profile-profile myconnections inbox">
                    <div>
                        <div class="eleven columns title boxs boxpad inboxtitle">
                            <div class="nine columns alpha">
                                <label>To</label>
                            </div>
                            <div class="two columns last">
                                <label>Date</label>
                            </div>
                            <div class="clr"></div>
                        </div>
                        <form name="frmMessagesSent" method="post">
                            <div class="common" id="inboxRequestsDiv">
                                <div id="searchResultsResponseDiv">
                                    <!-- Message List -->
                                    <div *ngFor="let msgSentData of msgSent">
                                        <div id="inboxRow_53" class="boxs boxpad "
                                            *ngIf="msgSentData.isElementDeleted == false">
                                            <div class="nine columns alpha">
                                                <div class="four profile-hover block-attendees inboxdesc">
                                                    <label class="profile-name" style="font-size:18px;color:#404040">
                                                        {{msgSentData.sentTousernames}}
                                                    </label>
                                                    <label class="profile-name" style="font-size:16px; color:#8c8c8c;">
                                                        {{msgSentData.messageConversation.noOfMessagesInConversation}}
                                                    </label>
                                                    <br>
                                                    <label>
                                                        {{msgSentData.messageConversation.latestMessageSentByUser.messageSubject}}
                                                    </label>
                                                    <br>
                                                    <br>
                                                    <div (click)="msgClickSentItems(msgSentData.userMessageContainerId)"
                                                        style="cursor: pointer;">
                                                        <span>{{msgSentData.truncatedMessage}}</span>
                                                    </div>
                                                    <br>
                                                    <label class="padd ico_delete">
                                                        <a class="pageLink" style="font-size: 11px;"
                                                            href="javascript: void(0);"
                                                            (click)="deleteFn('msgRec', msgSentData.userMessageContainerId, msgSentData);">
                                                            Delete
                                                        </a>
                                                    </label>
                                                    <span id="errorDiv_62" style="margin-right: 10px;"
                                                        class="errorDiv"></span>
                                                </div>
                                                <div class="clr"></div>
                                                <div class="clr"></div>
                                                <div class="two columns last inboxdate">
                                                    <label>{{msgSentData.messageConversation.latestMessageSentByUser.formattedCreatedDateForDisplay}}</label>
                                                </div>
                                                <div class="clear"></div>
                                                <div class="big_separator"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Message List -->
                                </div>
                                <div class="eleven columns alpha">
                                    <div id="navigationLinksRow" class="navigationLinksRow"> </div>
                                </div>
                                <div id="loadMoreResults" (click)="messageSentLoadMore()" class="load-more-hide">Load
                                    More</div>
                            </div>
                            <div class="clr"></div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="right_section" id="reqsent" style="display:none" [style.display]="editDisplay(reqsent)">
            <div class="twelve columns shad-left">
                <div class="eleven columns post-block inner-block profile-profile myconnections inbox">
                    <div class="eleven columns title nonmobileview boxs boxpad inboxtitle">
                        <div class="nine columns alpha">
                            <label>To</label>
                        </div>
                        <div class="two columns last">
                            <label>Date</label>
                        </div>
                        <div class="clr"></div>
                    </div>
                    <div class="clear"></div>
                    <div id="searchResultsResponseDiv">
                        <div *ngFor="let reqsentdata of reqSent">
                            <div class="showmore boxs boxpad " id="inboxRow_48"
                                *ngIf="reqsentdata.isElementDeleted == false">
                                <div class="nine columns alpha recommrqst">
                                    <div class="two inner-post-image float-attendees ">
                                        <div class="featured-image">

                                            <!-- <img width="60" height="60" border="0" src="{{reqsentdata.requestTo.profilePicName}}" class="greyBorder profileImage"> -->
                                            <img *ngIf="reqsentdata.requestTo.profilePicName != null && !reqsentdata.requestTo.profilePicName.includes('profilebig.png')"
                                                class="greyBorder profileImage" alt=""
                                                src="{{reqsentdata.requestTo.profilePicName}}" width="60" height="60">

                                            <ngx-avatar
                                                *ngIf="reqsentdata.requestTo.profilePicName == null || reqsentdata.requestTo.profilePicName.includes('profilebig.png')"
                                                name="{{reqsentdata.requestTo.firstName}}  {{reqsentdata.requestTo.lastName}}"
                                                size="60"></ngx-avatar>
                                        </div>
                                    </div>
                                    <div class="seven profile-hover block-attendees">
                                        <label class="profile-name profilename"> <a href="javascript: void(0);">
                                                {{reqsentdata.requestTo.firstName}} {{reqsentdata.requestTo.lastName}}
                                            </a> </label>
                                        <br>
                                        <!-- User Type -->
                                        <label *ngIf="reqsentdata.requestTo.isUserSecondaryDataCapturedForUser == 'T'"
                                            class="gwt-Label profile-userType userype">
                                            {{reqsentdata.requestTo.userBaseType}} </label>
                                        <br *ngIf="reqsentdata.requestTo.isUserSecondaryDataCapturedForUser == 'T'">
                                        <label *ngIf="reqsentdata.requestTo.isUserSecondaryDataCapturedForUser == 'T'"
                                            class="gwt-Label profile-userType userype">
                                            {{reqsentdata.requestTo.educationalInstitution}} ,
                                            {{reqsentdata.requestTo.campus}} , </label>
                                        <!-- User Type -->
                                        <!-- Request Subject, this field is available on all types of Request -->
                                        <div class="" *ngIf="reqsentdata.userRequestSubject != null"> <strong>Subject:
                                            </strong> {{reqsentdata.userRequestSubject}} </div>
                                        <!-- Request Body, this field will be available only of the Request actually has a Request Body. Invitation Requests,
                                  Rating Requests do not have a body, so in such cases this UI element will not be displayed -->
                                        <!-- If the request has a request body then display it -->
                                        <div id="comments_48" class="lessContent" style="display: none">
                                            <!-- Request Body -->
                                            <!-- The links for More and Less. They only appear if the Request Body is more than 50 characters -->
                                            <br> <strong>
                                                Request:
                                            </strong> <span [innerHTML]="reqsentdata.userRequestText"></span> </div>
                                        <div class="padd" id="more_48">
                                            <a href="javascript: void(0);" class="pageLink"> <strong>More</strong> </a>
                                        </div>
                                        <div style="display: none;" id="less_48"> <a href="javascript: void(0);"
                                                class="pageLink">
                                                Less
                                            </a> </div>
                                        <br>
                                        <label class="padd ico_delete"> <span id="deleteInboxRequest_48">
                                                <a class="pageLink" href="javascript: void(0);"
                                                    (click)="deleteFn('reqSent', reqsentdata.userRequestId, reqsentdata);">Delete
                                                </a>
                                            </span> </label>
                                        <label class="padd"> <span id="errorDiv_48" class="errorDiv"></span> </label>
                                    </div>
                                </div>
                                <div class="clr"></div>
                                <div class="two columns last inboxrecomm">
                                    <label>{{reqsentdata.userRequestType}}</label>
                                </div>
                                <div class="two columns last inboxdate">
                                    <label>{{reqsentdata.formattedCreatedDateForDisplay}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="loadMoreResults" (click)="requestsentLoadMore()" class="load-more-hide">Load More</div>
                </div>
            </div>
        </div>
        <div class="right_section" id="rcmdreqsent" style="display:none" [style.display]="editDisplay(rcmdreqsent)">
            <div class="twelve columns shad-left">
                <div class="eleven columns post-block inner-block profile-profile myconnections inbox">
                    <div class="dashedBorderBottom"></div>
                    <div class="common boxs boxpad inboxtitle">
                        <div class="nine"> To </div>
                        <div class="two"> Date </div>
                        <div class="clr"></div>
                    </div>
                    <div id="searchResultsResponseDiv">
                        <div *ngFor="let rcmdRequsetSent of rcmdreqSent">
                            <div class="boxs boxpad " id="inboxRow_24"
                                *ngIf="rcmdRequsetSent.isElementDeleted == false">
                                <div class="common">
                                    <div class="inboximg">
                                        <!-- <img width="60" height="60" border="0" src="{{rcmdRequsetSent.responseTo.profilePicName}}" class="greyBorder profileImage"> -->
                                        <img *ngIf="rcmdRequsetSent.responseTo.profilePicName != null && !rcmdRequsetSent.responseTo.profilePicName.includes('profilebig.png')"
                                            class="greyBorder profileImage" alt=""
                                            src="{{rcmdRequsetSent.responseTo.profilePicName}}" width="60" height="60">

                                        <ngx-avatar
                                            *ngIf="rcmdRequsetSent.responseTo.profilePicName == null || !rcmdRequsetSent.responseTo.profilePicName.includes('profilebig.png')"
                                            name="{{rcmdRequsetSent.responseTo.firstName}} {{rcmdRequsetSent.responseTo.lastName}}"
                                            size="60"></ngx-avatar>

                                    </div>
                                    <div class="inboxdesc">
                                        <label class="common profile-name">
                                            <a href="javascript: void(0);" [routerLink]="['../profile']"
                                                [queryParams]="{ user: rcmdRequsetSent.responseTo.userDetailsId }"
                                                class="profileLink"> {{rcmdRequsetSent.responseTo.firstName}}
                                                {{rcmdRequsetSent.responseTo.middleName}}
                                                {{rcmdRequsetSent.responseTo.lastName}} </a>
                                        </label>
                                        <div *ngIf="rcmdRequsetSent.responseTo.isUserSecondaryDataCapturedForUser == 'T'"
                                            class="common lineHeight16 gwt-Label profile-userType userype">
                                            <span
                                                *ngIf="rcmdRequsetSent.responseTo.userBaseType == 'Student'">Pursuing</span>
                                            {{rcmdRequsetSent.responseTo.userEducationDegreeCourse}} in
                                            {{rcmdRequsetSent.responseTo.userEducationSubject}}
                                        </div>
                                        <div *ngIf="rcmdRequsetSent.responseTo.isUserSecondaryDataCapturedForUser == 'T'"
                                            class="common lineHeight16 gwt-Label profile-userType userype">
                                            {{rcmdRequsetSent.responseTo.educationalInstitution}} ,
                                            {{rcmdRequsetSent.responseTo.campus}} ,
                                        </div>
                                        <div class="inboxdate"> {{rcmdRequsetSent.formattedCreatedDateForDisplay}}
                                        </div>
                                        <div class="recommendationSentComment">

                                            <div id="comments_minimized_24" class="lessContent"
                                                [style.display]="editDisplay(rcmdRequsetSent.less)">
                                                <strong>Recommendation</strong>:{{ rcmdRequsetSent.recommendation.recommendation | slice:0:40 }}
                                                ..........
                                            </div>

                                            <div id="comments_maximized_24" class="lessContent" style="display: none"
                                                [style.display]="editDisplay(rcmdRequsetSent.more)">
                                                <strong>Recommendation</strong>:{{ rcmdRequsetSent.recommendation.recommendation }}
                                            </div>

                                            <div id="more_24" [style.display]="editDisplay(rcmdRequsetSent.less)">
                                                <a href="javascript: void(0);" class="pageLink"
                                                    (click)="rcmdRequsetSent.more = true; rcmdRequsetSent.less = false;">More</a>
                                            </div>

                                            <div style="display: none;" id="less_24"
                                                [style.display]="editDisplay(rcmdRequsetSent.more)">
                                                <a href="javascript: void(0);"
                                                    (click)="rcmdRequsetSent.more = false; rcmdRequsetSent.less = true;"
                                                    class="pageLink">Less</a>
                                            </div>

                                        </div>
                                        <span id="deleteInboxRequest_24" style="margin-right: 10px;">
                                            <a class="pageLink ico_delete" href="javascript: void(0);"
                                                (click)="deleteFn('rcmdSent', rcmdRequsetSent.userResponseId, rcmdRequsetSent);">Delete</a>
                                        </span>
                                        <span id="errorDiv_24" style="margin-right: 10px;" class="errorDiv"></span>
                                    </div>
                                    <div class="clr"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="loadMoreResults" (click)="rcmdrespsentLoadMore()" class="load-more-hide">Load More</div>
                </div>
            </div>
        </div>
        <div class="right_section" id="trash" style="display:none" [style.display]="editDisplay(trashh)">
            <div class="eleven columns shad-left common ">
                <div class="eleven columns post-block inner-block profile-profile myconnections inbox">
                    <div>
                        <div class="eleven columns title boxs boxpad inboxtitle">
                            <div class="nine columns alpha">
                                <label>From</label>
                            </div>
                            <div class="two columns last">
                                <label>Date</label>
                            </div>
                            <div class="clr"></div>
                        </div>
                        <div *ngFor="let trash of trashData">
                            <div class="common" id="trashedEntriesDiv" *ngIf="trash.isElementDeleted == false">
                                <input type="hidden" name="hdCurrPage" id="hdCurrPage" value="trashEntries">
                                <div id="inboxRow_1" class="boxs boxpad">
                                    <div class="nine columns alpha">
                                        <!-- Sender Profile Pic Block -->
                                        <div class="two inner-post-image float-attendees inboximg">
                                            <div class="featured-image">
                                                <!-- <img width="60" height="60" border="0" src="{{trash.imageUrl}}" class="greyBorder profileImage"> -->

                                                <img *ngIf="trash.imageUrl != null && !trash.imageUrl"
                                                    class="greyBorder profileImage" alt="" src="{{trash.imageUrl}}"
                                                    width="60" height="60">

                                                <ngx-avatar
                                                    *ngIf="trash.imageUrl == null || trash.imageUrl.includes('profilebig.png')"
                                                    name="{{trash.firstName}} {{trash.lastName}}" size="60">
                                                </ngx-avatar>

                                            </div>
                                        </div>
                                        <div class="four profile-hover block-attendees inboxdesc">
                                            <!-- Sender Profile Pic and Name -->
                                            <label class="profile-name"> <a [routerLink]="['../profile']"
                                                    [queryParams]="{ user: trash.userId }"
                                                    href="javascript: void(0);">{{trash.firstName}} {{trash.lastName}}
                                                </a> </label>
                                            <br>
                                            <br>
                                            <label> {{trash.subject}} </label>
                                            <br>
                                            <label class="padd ico_delete"> <a class="pageLink" style="font-size: 11px;"
                                                    href="javascript: void(0);"
                                                    (click)="deleteFn('trash', trash.deletedItemContainerId, trash);">
                                                    Delete
                                                </a> </label> <span id="restoreInboxRequest_1"
                                                style="margin-right: 10px;">
                                                <input type="button" id="restoreTrashEntry_1"
                                                    style="width: 80px; color: #fff ! important;"
                                                    class="pageLink recorecaccept"
                                                    (click)="restoreFn(trash.deletedItemContainerId );" value="Restore">
                                            </span> <span id="errorDiv_1" style="margin-right: 10px;"
                                                class="errorDiv"></span> </div>
                                        <div class="clr"></div>
                                        <!-- Right hand top corner, Type of Request -->
                                        <div class="two columns last inboxrecomm">
                                            <label> {{trash.containerType}} </label>
                                        </div>
                                        <div class="two columns last inboxdate">
                                            <label>{{trash.formattedCreatedDateForDisplay}}</label>
                                        </div>
                                        <!-- Formatting Divs -->
                                        <div class="clear"></div>
                                        <div class="big_separator"></div>
                                    </div>
                                </div>
                                <div class="eleven columns alpha"> </div>
                            </div>
                        </div>
                        <div id="load-more-hide" (click)="trashLoad()" class="load-more-hide marginbottom margintop">
                            Load More</div>
                        <div class="clr"></div>
                    </div>
                </div>
            </div>
        </div>
        <!--EnD Div  -->
    </div>
    <!-- ------------------------------------------------------------------------------------------------- -->
    <div id="pleaseWaitDiv" style="display:none"> <img src="assets/images/pleaseWait.gif" width="30%" height="30%">
    </div>
    <div class="clr"></div>
</div>
<p-confirmDialog id="registerCourseInitiate" width="425"></p-confirmDialog>
<uvsity-spinner [showSpinner]="showSpinner" [showOnlyOverlay]="showOnlyOverlay"></uvsity-spinner>