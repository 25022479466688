<div class="pageblock content_area">
    <div class="sixteen columns">

        <menu-component></menu-component>

        <div id="content-top">
            <div class="boxs">
                <ol id="breadcrumb" class="twelve columns alpha breadcrumb">
                    <li class="home"><a href="javascript:void(0);" [routerLink]="['../home']">Home</a>
                    </li>
                    <li class="arrow">&nbsp;</li>
                    <li>Job Details</li>
                </ol>
                <div class="clr"></div>
            </div>
            <div class="clear"></div>
        </div>
    </div>
    <div class="clear"></div>
    <form name="frmAllJobs" method="post">
        <input type="hidden" name="jobId" id="jobId">
        <input type="hidden" name="hdProfileId" id="hdProfileId">
    </form>

    <div class="twelve columns boxs boxpad jobdetails profiledesc">
        <div class="backbtn">
            <a href="javascript: void(0);" class="pageLink4 btnstyle" [routerLink]="['../fresherjobs']">Get All Jobs</a>
        </div>
        <div class="eleven columns marginbottom" style="valign: top;">
            <div class="profilename">
                {{job.jobsHeadline}}
                <!-- <div class="fb-like fb_iframe_widget" data-href="http://uvsitytest.com/academyconnectweb/jobDetails.do?jobId=2" data-layout="button_count" data-action="like" data-show-faces="false" data-share="false" fb_ref="top_left" fb_source="profile_oneline" fb-xfbml-state="rendered" fb-iframe-plugin-query="action=like&amp;app_id=757440614303571&amp;container_width=1014&amp;href=http%3A%2F%2Fuvsitytest.com%2Facademyconnectweb%2FjobDetails.do%3FjobId%3D2&amp;layout=button_count&amp;locale=en_GB&amp;sdk=joey&amp;share=false&amp;show_faces=false"><span style="vertical-align: bottom; width: 60px; height: 20px;"><iframe name="f5ea731912d5e4" width="1000px" height="1000px" frameborder="0" allowtransparency="true" allowfullscreen="true" scrolling="no" title="fb:like Facebook Social Plugin" src="https://www.facebook.com/v2.0/plugins/like.php?action=like&amp;app_id=757440614303571&amp;channel=http%3A%2F%2Fstaticxx.facebook.com%2Fconnect%2Fxd_arbiter%2Fr%2FXBwzv5Yrm_1.js%3Fversion%3D42%23cb%3Df199e74f80c0854%26domain%3Ddev.uvsity.com%26origin%3Dhttp%253A%252F%252Fdev.uvsity.com%252Ff14c4f91dc481ac%26relation%3Dparent.parent&amp;container_width=1014&amp;href=http%3A%2F%2Fuvsitytest.com%2Facademyconnectweb%2FjobDetails.do%3FjobId%3D2&amp;layout=button_count&amp;locale=en_GB&amp;sdk=joey&amp;share=false&amp;show_faces=false" style="border: none; visibility: visible; width: 60px; height: 20px;" class=""></iframe></span>
                </div> -->
            </div>
            <label style="text-align: justify; padding-top: 5px;"> {{job.jobsDescription}}
            </label>
            <br>
            <p>
                <span class="gwt-Label profile-userBy userBy">Posted By:</span> <a href="javascript: void(0);"
                    [routerLink]="['../profile']" [queryParams]="{ user: user.userDetailsId }"
                    class="gwt-Anchor profile-name2 fblue deco-none topicowner">{{user.firstName}} {{user.lastName}}</a>
                <span class="gwt-Label profile-userBy userBy">on {{job.formattedCreatedDateForDisplay}}</span>
                <br>
                <label class="gwt-Label profile-userType userype"
                    *ngIf="user.isUserSecondaryDataCapturedForUser == 'T'">{{user.educationalInstitution}} ,
                    {{user.campus}} , </label>
                <br>
                <label> <strong>Location: </strong> {{job.cityFullName}}, {{job.stateFullName}}, {{job.countryFullName}}

                </label>
                <br>
            </p>
        </div>
    </div>
    <aside class="four columns">
        <div class="widget"></div>
    </aside>
</div>