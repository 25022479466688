<div class="pageblock content_area">
    <div id="content-wrapper2">
        <div class="sixteen columns">
            <menu-component></menu-component>
            <div class="boxs">
                <div id="content-top">
                    <ol id="breadcrumb" class="twelve columns alpha breadcrumb">
                        <li class="home"><a href="#" [routerLink]="['../home']">Home</a> </li>
                        <li class="arrow">&nbsp;</li>
                        <li>Billing Profile Planner</li>
                    </ol>
                    <div class="clr"></div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <div class="sixteen columns boxs boxpad relative">
            <div class="loader" [style.display]="editDisplay(loading)"><img src="assets/images/ajax-loader.gif" /></div>
            <table>
                <tbody>
                    <tr>
                        <td>
                            <h3>Select a plan to update</h3>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <select [(ngModel)]="planValue" (change)="planSelect()" id="planTypesDropDown">
                                <option selected="" disabled="">Select a plan</option>
                                <option [value]="plan.objId" *ngFor="let plan of $any(allBillPlans).allPlans">
                                    {{plan.planFullText}}/{{plan.planType}}</option>
                            </select>
                        </td>
                        <td style="vertical-align: top">
                            <input id="newPlan" type="button" (click)="newPlan()" value="Create New Plan"> </td>
                    </tr>
                </tbody>
            </table>
            <table id="planList" [style.display]="editDisplay(existplan)" *ngIf="planDetails.planFullText">
                <tbody id="body">
                    <tr id="trPlan_Id">
                        <td id="tdPlan_Id_Val">
                            <input type="text" id="tbPlan_Id_Txt" name="tbPlan_Id_Txt" value="1" readonly="" hidden="">
                        </td>
                    </tr>
                    <tr id="trPlan_Name">
                        <td id="tdPlan_Name">Plan Name</td>
                        <td id="tdPlan_Name_Val">
                            <input type="text" id="tbPlan_Name_Txt" name="tbPlan_Name_Txt"
                                value="{{planDetails.planFullText}}" readonly=""> </td>
                    </tr>
                    <tr id="trDefault">
                        <td id="tdDefault">Default</td>
                        <td id="tdDefault_Val">
                            <input type="text" *ngIf="planDetails.isDefault == false" id="tbDefault_Txt"
                                name="tbDefault_Txt" value="0" readonly="">
                            <input type="text" *ngIf="planDetails.isDefault != false" id="tbDefault_Txt"
                                name="tbDefault_Txt" value="1" readonly=""> </td>
                    </tr>
                    <tr id="trMembership_Fee_Per_Month">
                        <td id="tdMembership_Fee_Per_Month">Membership Fee Per Month</td>
                        <td id="tdMembership_Fee_Per_Month_Val">
                            <input type="text" id="tbMembership_Fee_Per_Month_Txt" name="tbMembership_Fee_Per_Month_Txt"
                                value="{{planDetails.membershipFeePerMonth}}" readonly=""> </td>
                    </tr>
                    <tr id="trMembership_Fee_Per_Year">
                        <td id="tdMembership_Fee_Per_Year">Membership Fee Per Year</td>
                        <td id="tdMembership_Fee_Per_Year_Val">
                            <input type="text" id="tbMembership_Fee_Per_Year_Txt" name="tbMembership_Fee_Per_Year_Txt"
                                value="{{planDetails.membershipFeePerYear}}" readonly=""> </td>
                    </tr>
                    <tr id="trFree_Course_Duration_Per_Month">
                        <td id="tdFree_Course_Duration_Per_Month">Free Session Duration Per Month</td>
                        <td id="tdFree_Course_Duration_Per_Month_Val">
                            <input type="text" id="tbFree_Course_Duration_Per_Month_Txt"
                                name="tbFree_Course_Duration_Per_Month_Txt"
                                value="{{planDetails.courseDurationPerMonth}}" readonly=""> </td>
                    </tr>
                    <tr id="trFree_Courses_Allowed">
                        <td id="tdFree_Courses_Allowed">Free Sessions Allowed</td>
                        <td id="tdFree_Courses_Allowed_Val">
                            <input type="text" id="tbFree_Courses_Allowed_Txt" name="tbFree_Courses_Allowed_Txt"
                                value="{{planDetails.numberOfCoursesAllowed}}" readonly=""> </td>
                    </tr>
                    <tr id="trDiscounts_For_Course_Taken">
                        <td id="tdDiscounts_For_Course_Taken">Discounts For Session Taken</td>
                        <td id="tdDiscounts_For_Course_Taken_Val">
                            <input type="text" id="tbDiscounts_For_Course_Taken_Txt"
                                name="tbDiscounts_For_Course_Taken_Txt"
                                value="{{planDetails.discountsForCourseTaken}}.0" readonly=""> </td>
                    </tr>
                    <tr id="trMinimum_Course_Fee">
                        <td id="tdMinimum_Course_Fee">Minimum Session Fee</td>
                        <td id="tdMinimum_Course_Fee_Val">
                            <input type="text" id="tbMinimum_Course_Fee_Txt" name="tbMinimum_Course_Fee_Txt"
                                value="{{planDetails.minCourseFee}}.0" readonly=""> </td>
                    </tr>
                    <tr id="trMax_Attendees_Per_Course">
                        <td id="tdMax_Attendees_Per_Course">Max Attendees Per Session</td>
                        <td id="tdMax_Attendees_Per_Course_Val">
                            <input type="text" id="tbMax_Attendees_Per_Course_Txt" name="tbMax_Attendees_Per_Course_Txt"
                                value="{{planDetails.maxAttendeesPerCourse}}" readonly=""> </td>
                    </tr>
                    <tr id="trCommission_Rate">
                        <td id="tdCommision_Rate">Commission Rate</td>
                        <td id="tdCommission_Rate_Val">
                            <input type="text" id="tbCommission_Rate_Txt" name="tbCommission_Rate_Txt"
                                value="{{planDetails.commissionRate}}.0" readonly=""> </td>
                    </tr>
                    <tr id="trSponsorship_Rate">
                        <td id="tdSponsorship_Rate">Session Sponsorship Rate</td>
                        <td id="tdSponsorship_Rate_Val">
                            <input type="text" id="tbSponsorship_Rate_Txt" name="tbSponsorship_Rate_Txt"
                                value="{{planDetails.sponsorshipFee}}.0" readonly=""> </td>
                    </tr>
                    <tr id="trMax60MinMeetings_Rate">
                        <td id="tdMax60MinMeetings_Rate">Max 60 min free meetings</td>
                        <td id="tdMax60MinMeetings_Rate_Val">
                            <input type="text" id="tbMax60MinMeetings_Rate_Txt" name="tbMax60MinMeetings_Rate_Txt"
                                value="{{planDetails.max60MinMeetingsPerMonth}}.0" readonly=""> </td>
                    </tr>
                    <tr id="trDisplay">
                        <td id="tdDisplay">Available to user</td>
                        <td id="tdDisplay_Val">
                            <input type="checkbox" [(ngModel)]="planDetails.display" name="planDetails.display"
                                value="1"> </td>
                    </tr>
                    <tr>
                        <td>
                            <input id="save" type="button" (click)="cPlanNew()" value="Save Changes"> </td>
                    </tr>
                </tbody>
            </table>
            <table id="planList" [style.display]="editDisplay(createPlan)">
                <tbody id="body">
                    <tr id="trPlan_Type">
                        <td id="tdPlan_Type">Plan Type</td>
                        <span *ngFor="let plan of planType">
                            <input id="{{plan}}" [(ngModel)]="cplan.planType" name="cplan.planType" type="radio"
                                value="{{plan}}">
                            <label for="{{plan}}">{{plan}} </label>
                        </span>
                    </tr>
                    <tr id="trPlan_Name">
                        <td id="tdPlan_Name">Plan Name</td>
                        <td id="tdPlan_Name_Val">
                            <input type="text" id="tbPlan_Name_Txt" [(ngModel)]="cplan.planFullText"
                                name="cplan.planFullText"> </td>
                    </tr>
                    <tr id="trDefault">
                        <td id="tdDefault">Default</td>
                        <td id="tdDefault_Val">
                            <input type="text" id="tbDefault_Txt" [(ngModel)]="cplan.isDefault" name="cplan.isDefault">
                        </td>
                    </tr>
                    <tr id="trMembership_Fee_Per_Month">
                        <td id="tdMembership_Fee_Per_Month">Membership Fee Per Month</td>
                        <td id="tdMembership_Fee_Per_Month_Val">
                            <input type="text" id="tbMembership_Fee_Per_Month_Txt"
                                [(ngModel)]="cplan.membershipFeePerMonth" name="cplan.membershipFeePerMonth"> </td>
                    </tr>
                    <tr id="trMembership_Fee_Per_Year">
                        <td id="tdMembership_Fee_Per_Year">Membership Fee Per Year</td>
                        <td id="tdMembership_Fee_Per_Year_Val">
                            <input type="text" id="tbMembership_Fee_Per_Year_Txt"
                                [(ngModel)]="cplan.membershipFeePerYear" name="cplan.membershipFeePerYear"> </td>
                    </tr>
                    <tr id="trFree_Course_Duration_Per_Month">
                        <td id="tdFree_Course_Duration_Per_Month">Free Session Duration Per Month</td>
                        <td id="tdFree_Course_Duration_Per_Month_Val">
                            <input type="text" id="tbFree_Course_Duration_Per_Month_Txt"
                                [(ngModel)]="cplan.courseDurationPerMonth" name="cplan.courseDurationPerMonth"> </td>
                    </tr>
                    <tr id="trFree_Courses_Allowed">
                        <td id="tdFree_Courses_Allowed">Free Sessions Allowed</td>
                        <td id="tdFree_Courses_Allowed_Val">
                            <input type="text" id="tbFree_Courses_Allowed_Txt"
                                [(ngModel)]="cplan.numberOfCoursesAllowed" name="cplan.numberOfCoursesAllowed"> </td>
                    </tr>
                    <tr id="trDiscounts_For_Course_Taken">
                        <td id="tdDiscounts_For_Course_Taken">Discounts For Session Taken</td>
                        <td id="tdDiscounts_For_Course_Taken_Val">
                            <input type="text" id="tbDiscounts_For_Course_Taken_Txt"
                                [(ngModel)]="cplan.discountsForCourseTaken" name="cplan.discountsForCourseTaken"> </td>
                    </tr>
                    <tr id="trMinimum_Course_Fee">
                        <td id="tdMinimum_Course_Fee">Minimum Session Fee</td>
                        <td id="tdMinimum_Course_Fee_Val">
                            <input type="text" id="tbMinimum_Course_Fee_Txt" [(ngModel)]="cplan.minCourseFee"
                                name="cplan.minCourseFee"> </td>
                    </tr>
                    <tr id="trMax_Attendees_Per_Course">
                        <td id="tdMax_Attendees_Per_Course">Max Attendees Per Session</td>
                        <td id="tdMax_Attendees_Per_Course_Val">
                            <input type="text" id="tbMax_Attendees_Per_Course_Txt"
                                [(ngModel)]="cplan.maxAttendeesPerCourse" name="cplan.maxAttendeesPerCourse"> </td>
                    </tr>
                    <tr id="trCommission_Rate">
                        <td id="tdCommision_Rate">Commission Rate</td>
                        <td id="tdCommission_Rate_Val">
                            <input type="text" id="tbCommission_Rate_Txt" [(ngModel)]="cplan.commissionRate"
                                name="cplan.commissionRate"> </td>
                    </tr>
                    
                     <tr id="trsponsorshipFee">
                        <td id="tdsponsorshipFee">Session Sponsorship Rate</td>
                        <td id="tdsponsorshipFee_Val">
                            <input type="text" id="tbsponsorshipFee_Txt" [(ngModel)]="cplan.sponsorshipFee"
                                name="cplan.sponsorshipFee"> </td>
                    </tr>
                    
                     <tr id="trmax60MinMeetingsPerMonth">
                        <td id="tdmax60MinMeetingsPerMonth">Max 60 min free meetings</td>
                        <td id="tdmax60MinMeetingsPerMonth_Val">
                            <input type="text" id="tbmax60MinMeetingsPerMonth_Txt" [(ngModel)]="cplan.max60MinMeetingsPerMonth"
                                name="cplan.max60MinMeetingsPerMonth"> </td>
                    </tr>
                                       
                    <tr id="trDisplay">
                        <td id="tdDisplay">Available to user</td>
                        <td id="tdDisplay_Val">
                            <input type="checkbox" [(ngModel)]="cplan.display" name="cplan.display" value="1"> </td>
                    </tr>
                    <tr>
                        <td>
                            <input id="save" type="button" (click)="cPlanNew()" value="Save Changes"> </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div [style.display]="editDisplay(popUp)"
    class="ui-dialog ui-widget ui-widget-content ui-corner-all ui-front ui-dialog-buttons ui-draggable ui-resizable"
    tabindex="-1" role="dialog" aria-describedby="errorDialog" aria-labelledby="ui-id-1"
    style="position: fixed; height: auto; width: 300px; top: auto; left: 801px; display: none;">
    <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix"><span id="ui-id-1"
            class="ui-dialog-title">{{msgTitle}}</span><button
            class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close"
            role="button" aria-disabled="false" title="close" style="display: none;"><span
                class="ui-button-icon-primary ui-icon ui-icon-closethick"></span><span class="ui-button-text"
                (click)="popUp = false;">close</span></button></div>
    <div class="ui-dialog-content ui-widget-content"
        style="width: auto; min-height: 33px; max-height: none; height: auto;">
        <ul>
            <li>{{msgContent}}</li>
        </ul>
    </div>
    <div class="ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
        <div class="ui-dialog-buttonset"><button type="button"
                class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" (click)="popUp = false;"
                role="button" aria-disabled="false"><span class="ui-button-text">Close</span></button></div>
    </div>
    <div class="ui-resizable-handle ui-resizable-n" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-e" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-s" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-w" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-se ui-icon ui-icon-gripsmall-diagonal-se" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-sw" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-ne" style="z-index: 90;"></div>
    <div class="ui-resizable-handle ui-resizable-nw" style="z-index: 90;"></div>
</div>